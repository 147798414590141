import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { t, translate } from "react-multi-lang";
import { connect, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import AccessControlMain from "../Post/CreatePost/AccessControlMain";
import LiveAccessControlMain from "./LiveAccessControlMain";
import LiveAccessControlSubMain from "./LiveAccessControlSubMain";
import LiveAccessControlRestrict from "./LiveAccessControlRestrict";
import { components } from "react-select";
import { getSubscriptionLevelsStart } from "../../store/actions/SubscriptionAction";
import { getUserSubscribersStart } from "../../store/actions/SubscriberAction";
import LiveAccessControlSelectMain from "./LiveAccessControlSelectMain";
import LiveAccessControlSelectSub from "./LiveAccessControlSelectSub";
import { FETCH_ALL_TRANSACTION_SUCCESS } from "../../store/actions/ActionConstant";

const LiveGoStreamIndex = (props) => {
  const { profile } = useSelector((state) => state.users);
  const [subLevelOptions, setSubLevelOptions] = useState([]);
  const [subListsOptions, setSubListsOptions] = useState([]);
  const [accessMainValue, setAccessMainValue] = useState("public");
  const [commentAccessMainValue, setCommentAccessMainValue] =useState("public");
  const [leaveCommentChatAccessMainValue, setLeaveCommentChatAccessMainValue] =useState("public");
  const [forwardAccessMainValue, setForwardAccessMainValue] = useState("public");
  const [downloadAccessMainValue, setDownloadAccessMainValue] = useState("public");
  const [fixedDownloadPriceAccessMainValue, setFixedDownloadPriceAccessMainValue] = useState("public");
  const [discountDownloadAccessMainValue, setDiscountDownloadAccessMainValue] = useState("public");
  const [fixedDiscountDownloadAccessMainValue, setFixedDiscountDownloadAccessMainValue] = useState("public");
  const [votingAccessMainValue, setVotingAccessMainValue] = useState("public");
  const [votingAccessMainSelectValue, setvotingAccessMainSelectValue] = useState("free_for_vote");
  const [fixedVotingPriceAccessMainValue, setFixedVotingPriceAccessMainValue] = useState("public");
  const [votingAccessSubSelectValue, setVotingAccessSubSelectValue] = useState({
     
    follower_vote : "free_for_vote",
    following_vote : "free_for_vote",
    subscriber_only_vote : "free_for_vote",
    subscriber_level_vote : "free_for_vote",
    subscriber_list_vote : "free_for_vote",

  });
  
  
  console.log("🚀 ~ LiveGoStreamIndex ~ votingAccessSubSelectValue:", votingAccessSubSelectValue)
  console.log("🚀 ~ LiveGoStreamIndex ~ fixedDownloadPriceAccessMainValue:", fixedDownloadPriceAccessMainValue)
  const [accessRestrictValue, setAccessRestrictValue] = useState({
    follower: false,
    following: false,
    subscriber_only: false,
    subscriber_level: false,
    subscriber_list: false,
  });

  const [forwardRestrictValue, setForwardRestrictValue] = useState({
    follower: false,
    following: false,
    subscriber_only: false,
    subscriber_level: false,
    subscriber_list: false,
  });

  const [commentRestrictValue, setCommentRestrictValue] = useState({
    follower: false,
    following: false,
    subscriber_only: false,
    subscriber_level: false,
    subscriber_list: false,
  });

  const [leaveCommentChatRestrictValue, setLeaveCommentChatRestrictValue] = useState({
    follower: false,
    following: false,
    subscriber_only: false,
    subscriber_level: false,
    subscriber_list: false,
  });
  
  const [downloadRestrictValue, setDownloadRestrictValue] = useState({
    follower: false,
    following: false,
    subscriber_only: false,
    subscriber_level: false,
    subscriber_list: false,
  });



  const [fixedDownloadPriRestrictValue, setFixedDownloadPriRestrictValue] = useState({
    follower: false,
    following: false,
    subscriber_only: false,
    subscriber_level: false,
    subscriber_list: false,
  });

  const [fixedVotingPriRestrictValue, setFixedVotingPriRestrictValue] = useState({
    follower: false,
    following: false,
    subscriber_only: false,
    subscriber_level: false,
    subscriber_list: false,
  });

  console.log("🚀 ~ LiveGoStreamIndex ~ fixedVotingPriRestrictValue:", fixedVotingPriRestrictValue)

  const [discountRestrictValue, setDiscountRestrictValue] = useState({
    follower: false,
    following: false,
    subscriber_only: false,
    subscriber_level: false,
    subscriber_list: false,
  });
  
  const [fixedDiscountPriceRestrictValue, setFixedDiscountPriceRestrictValue] = useState({
    follower: false,
    following: false,
    subscriber_only: false,
    subscriber_level: false,
    subscriber_list: false,
  });


  const [votingRestrictValue, setVotingRestrictValue] = useState({
    follower: false,
    following: false,
    subscriber_only: false,
    subscriber_level: false,
    subscriber_list: false,
  });


  console.log("🚀 ~ LiveGoStreamIndex ~ fixedDownloadPriRestrictValue:", fixedDownloadPriRestrictValue)
  


  const [listGroupedOp, setListGroupedOp] = useState({
    accessRestrictGropOpt: [
      {
        label: "Subscribers",
        options: [],
      },
      {
        label: "Followers",
        options: [],
      },
    ],

    downloadRestrictGropOpt: [
      {
        label: "Subscribers",
        options: [],
      },
      {
        label: "Followers",
        options: [],
      },
    ],

    forwardRestrictGropOpt: [
      {
        label: "Subscribers",
        options: [],
      },
      {
        label: "Followers",
        options: [],
      },
    ],

    commentRestrictGropOpt: [
      {
        label: "Subscribers",
        options: [],
      },
      {
        label: "Followers",
        options: [],
      },
    ],

    leaveCommentChatRestrictGropOpt: [
      {
        label: "Subscribers",
        options: [],
      },
      {
        label: "Followers",
        options: [],
      },
    ],

    fixedDownloadGropOpt: [
      {
        label: "Subscribers",
        options: [],
      },
      {
        label: "Followers",
        options: [],
      },
    ],

     discountGropOpt: [
      {
        label: "Subscribers",
        options: [],
      },
      {
        label: "Followers",
        options: [],
      },
    ],

    fixedDiscountDownloadGropOpt: [
      {
        label: "Subscribers",
        options: [],
      },
      {
        label: "Followers",
        options: [],
      },
    ],

    votingGropOpt : [
      {
        label: "Subscribers",
        options: [],
      },
      {
        label: "Followers",
        options: [],
      },
    ],

    fixedVotingGropOpt : [
      {
        label: "Subscribers",
        options: [],
      },
      {
        label: "Followers",
        options: [],
      },
    ]

  });
  console.log("🚀 ~ LiveGoStreamIndex ~ listGroupedOp:", listGroupedOp)

  const [accessControlMainSelected, setAccessControlMainSelected] = useState({
    selectedSubLevel: null,
    selectedSubList: null,
  });

  const [forwardControlMainSelected, setForwardControlMainSelected] = useState({
    selectedSubLevel: null,
    selectedSubList: null,
  });
  const [commentControlMainSelected, setCommentControlMainSelected] = useState({
    selectedSubLevel: null,
    selectedSubList: null,
  });
  const [leaveCommentChatControlMainSelected, setLeaveCommentChatControlMainSelected] = useState({
    selectedSubLevel: null,
    selectedSubList: null,
  });

  const [downloadControlMainSelected, setDownloadControlMainSelected] = useState({
    selectedSubLevel: null,
    selectedSubList: null,
  });

  const [fixedDownloadMainSelected, setFixedDownloadMainSelected] = useState({
    selectedSubLevel: null,
    selectedSubList: null,
  });
  



  const [discountControlMainSelected, setDiscountControlMainSelected] = useState({
    selectedSubLevel: null,
    selectedSubList: null,
  });

  const [fixedDiscountControlMainSelected, setFixedDiscountControlMainSelected] = useState({
    selectedSubLevel: null,
    selectedSubList: null,
  });

  const [votingControlMainSelected, setVotingControlMainSelected] = useState({
    selectedSubLevel: null,
    selectedSubList: null,
  });

  const [fixedVotingMainSelected, setFixedVotingMainSelected] = useState({
    selectedSubLevel: null,
    selectedSubList: null,
  });


  const [isDownloadOn, setIsDownloadOn] = useState(true);
  const [isVotingOn, setIsVotingOn] = useState(true);
  const [isTipOn, setIsTipOn] = useState(true);
  console.log("🚀 ~ LiveGoStreamIndex ~ isTipOn:", isTipOn)
  const [isSendGiftOn, setIsSendGiftOn] = useState(true);
  console.log("🚀 ~ LiveGoStreamIndex ~ isSendGiftOn:", isSendGiftOn)
  const [isDownloadAll, setIsDownloadAll] = useState(false);
  const [isVotingAll, setIsVotingAll] = useState(false);
  const [isFixedDownloadPrice, setIsFixedDownloadPrice] = useState(false);
  const [isFixedVotingPrice, setIsFixedVotingPrice] = useState(false);
  const [isDiscountAll, setIsDiscountAll] = useState(false);
  const [isFixedDiscountDownloadPrice, setIsFixedDiscountDownloadPrice] = useState(false);
  
  console.log("🚀 ~ LiveGoStreamIndex ~ isFixedVotingPrice:", isFixedVotingPrice)
  console.log("🚀 ~ LiveGoStreamIndex ~ isDownloadAll:", isDownloadAll)
  console.log("🚀 ~ LiveGoStreamIndex ~ isDiscountAll:", isDiscountAll)
  const [isFixedDPrice, setIsFixedDPrice] = useState(0);
  const [isFixedDiscountPrice, setIsFixedDiscountPrice] = useState(0);
  const [isVotingMainPrice, setIsVotingMainPrice] = useState(0);
  console.log("🚀 ~ LiveGoStreamIndex ~ isVotingMainPrice:", isVotingMainPrice)
  const [isFixedVotingPricePriceD, setIsFixedVotingPriceD] = useState(0);
  console.log("🚀 ~ LiveGoStreamIndex ~ isFixedVotingPricePriceD:", isFixedVotingPricePriceD)
  const [allDownloadFixedInputVal , setAllDownloadFixedInputVal] = useState({
     follower_price : 0,
     following_price : 0,
     friends_price : 0,
     subscriberLevel_price : 0,
     fromList_price : 0,
  });

  console.log("🚀 ~ LiveGoStreamIndex ~ allDownloadFixedInputVal:", allDownloadFixedInputVal)
  const [allDiscountDFixedInputVal , setAllDiscountDFixedInputVal] = useState({
     follower_price : 0,
     following_price : 0,
     friends_price : 0,
     subscriberLevel_price : 0,
     fromList_price : 0,
  });
  
  const [allVotingDFixedInputVal , setAllVotingDFixedInputVal] = useState({
     follower_price : 0,
     following_price : 0,
     friends_price : 0,
     subscriberLevel_price : 0,
     fromList_price : 0,
  });

  const [votingSelectInputValue , setVotingSelectInputValue] = useState({
     follower_price : 0,
     following_price : 0,
     friends_price : 0,
     subscriberLevel_price : 0,
     fromList_price : 0,
  });

  console.log("🚀 ~ LiveGoStreamIndex ~ allDiscountDFixedInputVal:", allDiscountDFixedInputVal)

  console.log("🚀 ~ LiveGoStreamIndex ~ isDownloadOn:", isDownloadOn);

  console.log("🚀 ~ LiveGoStreamIndex ~ isFixedDownloadPrice:", isFixedDownloadPrice);


  const locationD = useLocation();
  console.log("🚀 ~ LiveGoStreamIndex ~ allDownloadFixedInputVal:", allDownloadFixedInputVal)
  console.log("🚀 ~ LiveGoStreamIndex ~ votingSelectInputValue:", votingSelectInputValue)

  console.log(
    "🚀 ~ LiveGoStreamIndex ~ accessRestrictValue:",
    accessRestrictValue
  );

  const handleAccessChange = (e, type) => {
    // setIsRestritError({
    //   accessRestrictError: { subLevelError: false, subListError: false },
    //   downloadRestrictError: { subLevelError: false, subListError: false },
    //   forwardRestrictError: { subLevelError: false, subListError: false },
    //   commentRestrictError: { subLevelError: false, subListError: false },
    // });

    if (type === "LiveMainAccessControl") {
      if (e.target.value !== "restrict_user") {
        // setAccessRestrictValue({
        //   follower: false,
        //   following : false,
        //   subscriber_only: false,
        //   subscriber_level: false,
        //   subscriber_list: false,
        // });
        // setDownloadRestrictValue({
        //   follower: false,
        //   following : false,
        //   subscriber_only: false,
        //   subscriber_level: false,
        //   subscriber_list: false,
        // });
        // setForwardRestrictValue({
        //   follower: false,
        //   following : false,
        //   subscriber_only: false,
        //   subscriber_level: false,
        //   subscriber_list: false,
        // });
        // setCommentRestrictValue({
        //   follower: false,
        //   following : false,
        //   subscriber_only: false,
        //   subscriber_level: false,
        //   subscriber_list: false,
        // });
      }
      setAccessMainValue(e.target.value);

      setForwardAccessMainValue(e.target.value);

      setCommentAccessMainValue(e.target.value);

      setLeaveCommentChatAccessMainValue(e.target.value);



      setAccessRestrictValue({
        follower : false,
        following : false,
        subscriber_level : false,
        subscriber_list : false,
        subscriber_only : false
      });

      setAccessControlMainSelected({
        selectedSubLevel : null,
        selectedSubList : null
      })


      setForwardRestrictValue({

        follower : false,
        following : false,
        subscriber_level : false,
        subscriber_list : false,
        subscriber_only : false

      });


      setForwardControlMainSelected({
        selectedSubLevel : null,
        selectedSubList : null
      });


      setCommentRestrictValue({

        follower : false,
        following : false,
        subscriber_level : false,
        subscriber_list : false,
        subscriber_only : false

      });

      setCommentControlMainSelected({
        selectedSubLevel : null,
        selectedSubList : null
      })

      setLeaveCommentChatRestrictValue({
        follower : false,
        following : false,
        subscriber_level : false,
        subscriber_list : false,
        subscriber_only : false
      })
  
      setLeaveCommentChatControlMainSelected({
        selectedSubLevel : null,
        selectedSubList : null
      })




      // setDownloadAccessMainValue(e.target.value);
      // setForwardAccessMainValue(e.target.value);
      // setCommentAccessMainValue(e.target.value);

      // setAccessControlMainSelected({
      //   selectedSubList: null,
      //   selectedSubLevel: null,
      // });

      // setDownloadMainSelected({
      //   selectedSubList: null,
      //   selectedSubLevel: null,
      // });

      // setForwardMainSelected({ selectedSubList: null, selectedSubLevel: null });

      // setCommentMainSelected({ selectedSubList: null, selectedSubLevel: null });
    } else if (type === "whoCanForward") {
      if (e.target.value !== "restrict_user") {
        // setDownloadRestrictValue("following");
        setForwardRestrictValue({
          follower: false,
          following: false,
          subscriber_only: false,
          subscriber_level: false,
          subscriber_list: false,
        });
      }
      setForwardControlMainSelected({
        selectedSubList: null,
        selectedSubLevel: null,
      });
      setForwardAccessMainValue(e.target.value);
    } else if (type === "whoCanComment") {
      if (e.target.value !== "restrict_user") {
        // setDownloadRestrictValue("following");
        setCommentRestrictValue({
          follower: false,
          following: false,
          subscriber_only: false,
          subscriber_level: false,
          subscriber_list: false,
        });
      }
      setCommentControlMainSelected({
        selectedSubList: null,
        selectedSubLevel: null,
      });
      setCommentAccessMainValue(e.target.value);
    } else if(type === "whoCanLeaveCommentChat"){

      // if (e.target.value !== "restrict_user") {
      //   // setDownloadRestrictValue("following");
      //   setDownloadRestrictValue({
      //     follower: false,
      //     following: false,
      //     subscriber_only: false,
      //     subscriber_level: false,
      //     subscriber_list: false,
      //   });
      // }

      setLeaveCommentChatAccessMainValue(e.target.value);
    }
    else if(type === "whoCanDownload"){
      
      if (e.target.value !== "restrict_user") {
        // setDownloadRestrictValue("following");
        setDownloadRestrictValue({
          follower: false,
          following: false,
          subscriber_only: false,
          subscriber_level: false,
          subscriber_list: false,
        });
      }
      setDownloadControlMainSelected({
        selectedSubList: null,
        selectedSubLevel: null,
      });
      setDownloadAccessMainValue(e.target.value);
    }else if(type === "FixedDownloadPrice"){

      setAllDownloadFixedInputVal({
        follower_price : 0,
        following_price : 0,
        friends_price : 0,
        subscriberLevel_price : 0,
        fromList_price : 0,
      })


      setIsFixedDPrice(0);
      
          
      if (e.target.value !== "restrict_user") {
        // setDownloadRestrictValue("following");
        setFixedDownloadPriRestrictValue({
          follower: false,
          following: false,
          subscriber_only: false,
          subscriber_level: false,
          subscriber_list: false,
        });
      }

      setFixedDownloadMainSelected({
        selectedSubList: null,
        selectedSubLevel: null,
      });

      setFixedDownloadPriceAccessMainValue(e.target.value);


    }else if(type === "DiscountAllDownload"){


      if (e.target.value !== "restrict_user") {
        // setDownloadRestrictValue("following");
        setDiscountRestrictValue({
          follower: false,
          following: false,
          subscriber_only: false,
          subscriber_level: false,
          subscriber_list: false,
        });
      }
      

      setDiscountDownloadAccessMainValue(e.target.value);

      setDiscountControlMainSelected({
        selectedSubList: null,
        selectedSubLevel: null,
      });
      

    }else if(type === "FixedDiscountPrice"){
       setFixedDiscountDownloadAccessMainValue(e.target.value);
       setFixedDiscountPriceRestrictValue({
        follower: false,
        following: false,
        subscriber_only: false,
        subscriber_level: false,
        subscriber_list: false,
  
      });

      setIsFixedDiscountPrice(0)

      setAllDiscountDFixedInputVal({
        follower_price : 0,
        following_price : 0,
        friends_price : 0,
        subscriberLevel_price : 0,
        fromList_price : 0,
      });

    }else if(type === "votingForAll"){

      setVotingAccessMainValue(e.target.value);

      setvotingAccessMainSelectValue("free_for_vote");
      setIsVotingMainPrice(0)

      setVotingRestrictValue({
        follower: false,
        following: false,
        subscriber_only: false,
        subscriber_level: false,
        subscriber_list: false,
      });

      setVotingControlMainSelected({
        selectedSubLevel : null,
        selectedSubList : null
      })
      
    }else if(type === "FixedVotingPrice"){
       
      setFixedVotingPriceAccessMainValue(e.target.value);

      setIsFixedVotingPriceD(0);
  
      setFixedVotingMainSelected({
        selectedSubLevel : null,
        selectedSubList : null
      })

    setFixedVotingPriRestrictValue({
      follower : false,
      following : false,
      subscriber_level : false,
      subscriber_list : false,
      subscriber_only : false,
    })

    setAllVotingDFixedInputVal({
      follower_price : 0,
      following_price : 0,
      friends_price : 0,
      subscriberLevel_price : 0,
      fromList_price : 0
    });



    }

  };

  const handleAccessRestrictChange = (e, type, name, votingCheckType, votingSelectInputType) => {
    // setIsRestritError({
    //   accessRestrictError: { subLevelError: false, subListError: false },
    //   downloadRestrictError: { subLevelError: false, subListError: false },
    //   forwardRestrictError: { subLevelError: false, subListError: false },
    //   commentRestrictError: { subLevelError: false, subListError: false },
    // });

    if (type === "LevelAccessSubControl") {
      // setAccessRestrictValue(e.target.value);

      setAccessRestrictValue({
        ...accessRestrictValue,
        [name]: e.target.checked,
      });

      setForwardRestrictValue({
        ...forwardRestrictValue,
        [name]: e.target.checked,
      });

      setCommentRestrictValue({
        ...commentRestrictValue,
        [name]: e.target.checked,
      })

      setLeaveCommentChatRestrictValue({
        ...leaveCommentChatRestrictValue,
        [name]: e.target.checked,
      })

      // setDownloadRestrictValue({
      //   ...downloadRestrictValue,
      //   [name]: e.target.checked,
      // });

      // setForwardRestrictValue({
      //   ...forwardRestrictValue,
      //   [name]: e.target.checked,
      // });

      // setCommentRestrictValue({
      //   ...commentRestrictValue,
      //   [name]: e.target.checked,
      // });

      // setAccessControlMainSelected({
      //   selectedSubList: null,
      //   selectedSubLevel: null,
      // });

      // setSelectedSubList(null);
    } else if (type === "whoCanForwardSub") {
      setForwardRestrictValue({
        ...forwardRestrictValue,
        [name]: e.target.checked,
      });

      // setDownloadMainSelected({
      //   selectedSubList: null,
      //   selectedSubLevel: null,
      // });
    } else if (type === "whoCanCommentSub") {
      setCommentRestrictValue({
        ...commentRestrictValue,
        [name]: e.target.checked,
      });
    }else if(type === "whoCanLeaveCommentChatSub"){

      setLeaveCommentChatRestrictValue({
        ...leaveCommentChatRestrictValue,
        [name]: e.target.checked,

      })

    }
    else if(type === "whoCanDownloadSub"){
      
      setDownloadRestrictValue({
        ...downloadRestrictValue,
        [name]: e.target.checked,
      });

    }else if(type === "fixedDownloadPriceSub"){
      setFixedDownloadPriRestrictValue({
        ...fixedDownloadPriRestrictValue,
        [name]: e.target.checked,
      });

    }else if(type === "DiscountAllSub"){

      setDiscountRestrictValue({
        ...discountRestrictValue,
        [name]: e.target.checked
      })

    }else if(type === "fixedDiscountPriceSub"){
       
      setFixedDiscountPriceRestrictValue({
        ...fixedDiscountPriceRestrictValue,
        [name] : e.target.checked
      })
      

    }else if(type === "votingForAllSub"){

      setVotingRestrictValue({
        ...votingRestrictValue,
        [name] : e.target.checked
      });


      setVotingAccessSubSelectValue({
        ...votingAccessSubSelectValue,
        [votingCheckType] : "free_for_vote"
      });


      setVotingSelectInputValue({
         ...votingSelectInputValue,
         [votingSelectInputType] : 0
      })


      

    }else if(type === "fixedVotingPriceSub"){

      setFixedVotingPriRestrictValue({
        ...fixedVotingPriRestrictValue,
        [name] : e.target.checked
      });
      
    }

    // } else if (type === "whoCanDownloadSub") {
    //   // setDownloadRestrictValue(e.target.value);
    //   setDownloadRestrictValue({
    //     ...downloadRestrictValue,
    //     [name]: e.target.checked,
    //   });

    //   // setDownloadMainSelected({
    //   //   selectedSubList: null,
    //   //   selectedSubLevel: null,
    //   // });
    // } else if (type === "whoCanForwardSub") {
    //   setForwardMainSelected({ selectedSubList: null, selectedSubLevel: null });
    //   // setForwardRestrictValue(e.target.value);
    //   setForwardRestrictValue({
    //     ...forwardRestrictValue,
    //     [name]: e.target.checked,
    //   });
    // } else if (type === "whoCanCommentSub") {
    //   // setCommentRestrictValue(e.target.value);
    //   setCommentRestrictValue({
    //     ...commentRestrictValue,
    //     [name]: e.target.checked,
    //   });
    //   // setCommentMainSelected({ selectedSubList: null, selectedSubLevel: null });
    // }
  };



  const handleRestrictSelectChange = (e, type , selectedType) => {
   

    if(type === "ForVotingSelectRestrict"){

      setVotingAccessSubSelectValue({
        ...votingAccessSubSelectValue,
        [selectedType] : e.target.value
      })
      
    }

    
  }



  const handleDownloadOn  = (e)=>{ 
    setIsDownloadOn(e.target.checked);
    setIsDownloadAll(false);
    setIsFixedDownloadPrice(false);
    setFixedDiscountControlMainSelected({
      selectedSubLevel : null,
      selectedSubList : null
    })

    setAllDownloadFixedInputVal({
      follower_price : 0,
      following_price : 0,
      friends_price : 0,
      subscriberLevel_price : 0,
      fromList_price : 0,
    })
  }

  const handleDownloadAllChnage = (e)=>{

    setIsDownloadAll(e.target.checked)
    setIsFixedDownloadPrice(false);
    setDownloadAccessMainValue("public");
    setFixedDownloadPriceAccessMainValue("public")
    setDiscountDownloadAccessMainValue("public");
    setIsDiscountAll(false);

    setAllDownloadFixedInputVal({
      follower_price : 0,
      following_price : 0,
      friends_price : 0,
      subscriberLevel_price : 0,
      fromList_price : 0,
    });

    setDownloadRestrictValue({
      follower : false,
      following : false,
      subscriber_level : false,
      subscriber_list : false,
      subscriber_only : false
    })

  }


  const handleFixedDownloadPriceChange = (e)=>{

    setIsFixedDownloadPrice(e.target.checked);


    setIsFixedDPrice(0)


    

    setFixedDownloadPriceAccessMainValue("public");

    setFixedDownloadPriRestrictValue({
      follower: false,
      following: false,
      subscriber_only: false,
      subscriber_level: false,
      subscriber_list: false,
    })

    setAllDownloadFixedInputVal({
      follower_price : 0,
      following_price : 0,
      friends_price : 0,
      subscriberLevel_price : 0,
      fromList_price : 0,
    })

    setIsDiscountAll(false);



  }
  
  
  const handleDiscountAllChange = (e) => {

    setDiscountDownloadAccessMainValue("public");
    setDiscountRestrictValue({
      follower: false,
      following: false,
      subscriber_only: false,
      subscriber_level: false,
      subscriber_list: false,
    })

    setIsDiscountAll(e.target.checked);

    setIsFixedDiscountDownloadPrice(false);

    setIsFixedDiscountPrice(0);
  }


  const handleMainInputValue = (e, type)=> {

    if(type === "ForDownloadPrice"){
      setIsFixedDPrice(e.target.value)
    }else if(type === "ForDiscountPrice"){
      setIsFixedDiscountPrice(e.target.value)

    }else if(type === "ForVotingPriceInput"){

      setIsFixedVotingPriceD(e.target.value);

    }else if(type === "forVotingPriceInp"){

      setIsVotingMainPrice(e.target.value);
      
    }

  }

  
  // Fixed Discount Download Price Toogle

  const handleFixedDiscountDownloadPChange = (e)=>{


    setIsFixedDiscountDownloadPrice(e.target.checked);

    setFixedDiscountDownloadAccessMainValue("public");
    
    setFixedDiscountControlMainSelected({
      selectedSubLevel: null,
      selectedSubList : null
    })

    
    setFixedDiscountPriceRestrictValue({
      follower: false,
      following: false,
      subscriber_only: false,
      subscriber_level: false,
      subscriber_list: false,

    })

    setAllDiscountDFixedInputVal({
      follower_price : 0,
      following_price : 0,
      friends_price : 0,
      subscriberLevel_price : 0,
      fromList_price : 0,
    });
    

    setFixedDownloadPriceAccessMainValue("public");

    setFixedDownloadPriRestrictValue({
      follower: false,
      following: false,
      subscriber_only: false,
      subscriber_level: false,
      subscriber_list: false,
    });

    setAllDownloadFixedInputVal({
      follower_price : 0,
      following_price : 0,
      friends_price : 0,
      subscriberLevel_price : 0,
      fromList_price : 0,
    })

    
    

  }


  const handleAllInputValue = (e, type, isEqual)=> {
    console.log("🚀 ~ handleAllInputValue ~ type:", type)
    

    if(isEqual === "fixedPrice"){

      setAllDownloadFixedInputVal({
        ...allDownloadFixedInputVal,
        [type] : e.target.value
      })
    }else if(isEqual === "fixedDiscountPriceInput"){

      
      setAllDiscountDFixedInputVal({
         ...allDiscountDFixedInputVal,
         [type] : e.target.value
      })
    }else if(isEqual === "fixedVotingPriceSubInput"){
      
      setAllVotingDFixedInputVal({
        ...allVotingDFixedInputVal,
        [type] : e.target.value
      })
      
    }else if(isEqual === "votingPriceInputSub"){

      setVotingSelectInputValue({
        ...votingSelectInputValue,
        [type] : e.target.value
      })

    }

      
  }
  


  // for access stream

  const handleSetSelectedSubLevel = (selected) => {
    // setIsRestritError({
    //   ...isRestritError,
    //   accessRestrictError: { subLevelError: false, subListError: false },
    //   downloadRestrictError: { subLevelError: false, subListError: false },
    //   forwardRestrictError: { subLevelError: false, subListError: false },
    //   commentRestrictError: { subLevelError: false, subListError: false },
    // });

    setAccessControlMainSelected({
      ...accessControlMainSelected,
      selectedSubLevel: selected,
    });


    setForwardControlMainSelected({
      ...forwardControlMainSelected,
      selectedSubLevel : selected
    })

    setCommentControlMainSelected({
      ...commentControlMainSelected,
      selectedSubLevel : selected
    })

    setLeaveCommentChatControlMainSelected({
      ...leaveCommentChatControlMainSelected,
      selectedSubLevel : selected

    })

    // setDownloadMainSelected({
    //   ...downloadMainSelected,
    //   selectedSubLevel: selected,
    // });

    // setForwardMainSelected({
    //   ...forwardMainSelected,
    //   selectedSubLevel: selected,
    // });

    // setCommentMainSelected({
    //   ...commentMainSelected,
    //   selectedSubLevel: selected,
    // });
  };

  const handleSetSelectedSubList = (selected) => {
    // setIsRestritError({
    //   ...isRestritError,
    //   accessRestrictError: { subLevelError: false, subListError: false },
    //   downloadRestrictError: { subLevelError: false, subListError: false },
    //   forwardRestrictError: { subLevelError: false, subListError: false },
    //   commentRestrictError: { subLevelError: false, subListError: false },
    // });
    // setSelectedSubList(selected);
    setAccessControlMainSelected({
      ...accessControlMainSelected,
      selectedSubList: selected,
    });

    setForwardControlMainSelected({
      ...forwardControlMainSelected,
      selectedSubList: selected,
    })
    setCommentControlMainSelected({
      ...commentControlMainSelected,
      selectedSubList: selected,
    })
    setLeaveCommentChatControlMainSelected({
      ...leaveCommentChatControlMainSelected,
      selectedSubList: selected,
    })

    //   setDownloadMainSelected({
    //   ...downloadMainSelected,
    //   selectedSubList: selected,
    // });

    // setForwardMainSelected({
    //   ...forwardMainSelected,
    //   selectedSubList: selected,
    // });

    // setCommentMainSelected({
    //   ...commentMainSelected,
    //   selectedSubList: selected,
    // });
  };


  // for forward

  const handleWhoCanForwardLevelSelected = (selected) => {
    // setIsRestritError({
    //   ...isRestritError,
    //   downloadRestrictError: { subLevelError: false, subListError: false },
    // });
    setForwardControlMainSelected({
      ...forwardControlMainSelected,
      selectedSubLevel: selected,
    });
  };


  const handleWhoCanForwardListSelected = (selected) => {
    // setIsRestritError({
    //   ...isRestritError,
    //   downloadRestrictError: { subLevelError: false, subListError: false },
    // });
    setForwardControlMainSelected({
      ...forwardControlMainSelected,
      selectedSubList: selected,
    });
  };

  // for comment.

  const handleWhoCanCommentLevelSelected = (selected) => {
    // setIsRestritError({
    //   ...isRestritError,
    //   downloadRestrictError: { subLevelError: false, subListError: false },
    // });
    setCommentControlMainSelected({
      ...commentControlMainSelected,
      selectedSubLevel: selected,
    });
  };


  const handleWhoCanCommentListSelected = (selected) => {
    // setIsRestritError({
    //   ...isRestritError,
    //   downloadRestrictError: { subLevelError: false, subListError: false },
    // });
    setCommentControlMainSelected({
      ...commentControlMainSelected,
      selectedSubList: selected,
    });
  };

    // for comment in chatroom

    const handleWhoCanLeaveCommentLevelSelected = (selected) => {
      // setIsRestritError({
      //   ...isRestritError,
      //   downloadRestrictError: { subLevelError: false, subListError: false },
      // });
      setLeaveCommentChatControlMainSelected({
        ...leaveCommentChatControlMainSelected,
        selectedSubLevel: selected,
      });
    };
  
  
    const handleWhoCanLeaveCommentListSelected = (selected) => {
      // setIsRestritError({
      //   ...isRestritError,
      //   downloadRestrictError: { subLevelError: false, subListError: false },
      // });
      setLeaveCommentChatControlMainSelected({
        ...leaveCommentChatControlMainSelected,
        selectedSubList: selected,
      });
    };



  // for downlaod

  const handleWhoCanDownloadLevelSelected = (selected) => {
    // setIsRestritError({
    //   ...isRestritError,
    //   downloadRestrictError: { subLevelError: false, subListError: false },
    // });
    setDownloadControlMainSelected({
      ...downloadControlMainSelected,
      selectedSubLevel: selected,
    });
  };


  const handleWhoCanDownloadListSelected = (selected) => {
    // setIsRestritError({
    //   ...isRestritError,
    //   downloadRestrictError: { subLevelError: false, subListError: false },
    // });
    setDownloadControlMainSelected({
      ...downloadControlMainSelected,
      selectedSubList: selected,
    });
  };
  
  // for fixed download price  
  
  const handleFixedDownloadLevelSelected = (selected) => {
    // setIsRestritError({
    //   ...isRestritError,
    //   downloadRestrictError: { subLevelError: false, subListError: false },
    // });
    setFixedDownloadMainSelected({
      ...fixedDownloadMainSelected,
      selectedSubLevel: selected,
    });
  };


  const handleFixedDownloadListSelected = (selected) => {
    // setIsRestritError({
    //   ...isRestritError,
    //   downloadRestrictError: { subLevelError: false, subListError: false },
    // });
    setFixedDownloadMainSelected({
      ...fixedDownloadMainSelected,
      selectedSubList: selected,
    });
  };
  
  // for discount all

  
  
  const handleDiscountLevelSelected = (selected) => {
    // setIsRestritError({
    //   ...isRestritError,
    //   downloadRestrictError: { subLevelError: false, subListError: false },
    // });
    setDiscountControlMainSelected({
      ...discountControlMainSelected,
      selectedSubLevel: selected,
    });
  };


  const handleDiscountListSelected = (selected) => {
    // setIsRestritError({
    //   ...isRestritError,
    //   downloadRestrictError: { subLevelError: false, subListError: false },
    // });
    setDiscountControlMainSelected({
      ...discountControlMainSelected,
      selectedSubList: selected,
    });
  };


  // for download discount price

  const handleDiscountPriceLevelSelected = (selected) => {
    // setIsRestritError({
    //   ...isRestritError,
    //   downloadRestrictError: { subLevelError: false, subListError: false },
    // });
    setFixedDiscountControlMainSelected({
      ...fixedDiscountControlMainSelected,
      selectedSubLevel: selected,
    });

  };


  const handleDiscountPriceListSelected = (selected) => {
    // setIsRestritError({
    //   ...isRestritError,
    //   downloadRestrictError: { subLevelError: false, subListError: false },
    // });
    setFixedDiscountControlMainSelected({
      ...fixedDiscountControlMainSelected,
      selectedSubList: selected,
    });
  };


    // for voting

    const handleVotingLevelSelected = (selected) => {
      // setIsRestritError({
      //   ...isRestritError,
      //   downloadRestrictError: { subLevelError: false, subListError: false },
      // });
      setVotingControlMainSelected({
        ...votingControlMainSelected,
        selectedSubLevel: selected,
      });
    };
  
  
    const handleVotingListSelected = (selected) => {
      // setIsRestritError({
      //   ...isRestritError,
      //   downloadRestrictError: { subLevelError: false, subListError: false },
      // });
      setVotingControlMainSelected({
        ...votingControlMainSelected,
        selectedSubList: selected,
      });
    };


    // for fixed voting price  
  
  const handleFixedVotingLevelSelected = (selected) => {
    // setIsRestritError({
    //   ...isRestritError,
    //   downloadRestrictError: { subLevelError: false, subListError: false },
    // });
    setFixedVotingMainSelected({
      ...fixedVotingMainSelected,
      selectedSubLevel: selected,
    });
  };


  const handleFixedVotingListSelected = (selected) => {
    // setIsRestritError({
    //   ...isRestritError,
    //   downloadRestrictError: { subLevelError: false, subListError: false },
    // });
    setFixedVotingMainSelected({
      ...fixedVotingMainSelected,
      selectedSubList: selected,
    });
  };
  
    

  

  // Voting


  const handleVotingOnChange = (e) => {

    setIsVotingOn(e.target.checked);

    setVotingAccessMainValue("public")

    setIsVotingAll(false);

    setVotingAccessMainValue("public");
    setIsVotingMainPrice(0);
    setvotingAccessMainSelectValue("free_for_vote")

     
  }


  const handleVotingAllChange = (e) => {


    setIsVotingAll(e.target.checked);

    setIsFixedVotingPrice(false);

    setVotingAccessMainValue("public")
    


    setVotingRestrictValue({
      follower : false,
      following : false,
      subscriber_level : false,
      subscriber_list : false,
      subscriber_only : false,
    });

    setVotingControlMainSelected({
      selectedSubLevel : null, 
      selectedSubList : null
    });

    
    setFixedVotingPriRestrictValue({
      follower : false,
      following : false,
      subscriber_level : false,
      subscriber_list : false,
      subscriber_only : false,
    })


  }


  const handleFixedVotingPriceChange = (e) => {
     
    setIsFixedVotingPrice(e.target.checked);
   
    setFixedVotingPriceAccessMainValue("public");

    setFixedVotingPriceAccessMainValue("public");

    setIsFixedVotingPriceD(0);

    
    

    setFixedVotingPriRestrictValue({
      follower : false,
      following : false,
      subscriber_level : false , 
      subscriber_list : false, 
      subscriber_only : false    
    })


    setAllVotingDFixedInputVal({
      follower_price : 0,
      following_price : 0,
      friends_price : 0,
      subscriberLevel_price : 0,
      fromList_price : 0
    })

    setFixedVotingPriRestrictValue({
      follower: false,
      following: false,
      subscriber_only: false,
      subscriber_level: false,
      subscriber_list: false,

    });

    setFixedVotingMainSelected({
      selectedSubLevel : null,
      selectedSubList : null
    })


  }

  // tip 

  const handleTipOnChange = (e)=>{
    setIsTipOn(e.target.checked);
  }

  // send gift

  const handleSendGiftOnChange = (e)=>{
    setIsSendGiftOn(e.target.checked);
  }




  // voting select value

  const handleSelecteOnChange = (e) => {

    setvotingAccessMainSelectValue(e.target.value);

    setIsVotingMainPrice(0);

  }


  useEffect(() => {
    props.dispatch(getSubscriptionLevelsStart({ setSubLevelOptions }));
  }, []);

  useEffect(() => {
    if (accessRestrictValue.subscriber_list || downloadRestrictValue.subscriber_list || forwardRestrictValue.subscriber_list || commentRestrictValue.subscriber_list || fixedDownloadPriRestrictValue.subscriber_list || discountRestrictValue.subscriber_list || fixedDiscountPriceRestrictValue.subscriber_list || votingRestrictValue.subscriber_list || fixedVotingPriRestrictValue.subscriber_list || leaveCommentChatRestrictValue.subscriber_list ) {
      props.dispatch(
        getUserSubscribersStart({
          setSubListsOptions,
          listGroupedOp,
          setListGroupedOp,
          accessRestrictValue,
          setAccessRestrictValue,
          downloadRestrictValue,
          setDownloadRestrictValue,
          forwardRestrictValue,
          setFixedDownloadPriRestrictValue,
          fixedDownloadPriRestrictValue
          
        })
      );
    }
  }, [accessRestrictValue, downloadRestrictValue, forwardRestrictValue , commentRestrictValue , fixedDownloadPriRestrictValue , discountRestrictValue , fixedDiscountPriceRestrictValue, votingRestrictValue , fixedVotingPriRestrictValue , leaveCommentChatRestrictValue]);
 
 

  const Option = (props) => {
    
    return (
      <div className="post-level-list-option-wrpapper">
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
          <div className="post-level-list-option-img-label-wrapper">
            {
              props.data.avatar && (
                <img
                 src={props.data.avatar || props.avatar}
                 alt={props?.label}
                style={{ width: 26, height: 26, borderRadius: '50%', marginRight: 8, }}
           />

              )
            }
          <label style={{marginBottom: "0px"}}>{props.label}</label>
            </div>
        </components.Option>
      </div>
    );
  };

  const MoreSelectedBadge = ({ items }) => {
    const style = {
      marginLeft: "auto",
      // background: "#d4eefa",
      borderRadius: "4px",
      fontSize: "12px",
      fontWeight: "400",
      padding: "3px",
      // order: 99,           <-- if you want this after input serach right side then uncomment
      order: 0,
    };

    const title = items.join(", ");
    const length = items.length;
    // const label = `+ ${length} item${length !== 1 ? "s" : ""} selected`;
    const label = `+ ${length} more...`;

    return (
      <div style={style} title={title}>
        {label}
      </div>
    );
  };

  const MultiValue = ({ index, getValue, ...props }) => {
    // debugger;
    console.log(
      "🚀 ~ MultiValue ~ index:",
      index,
      props.data.label,
      getValue()
    );
    const maxToShow = 3;
    const overflow = getValue()
      .slice(maxToShow)
      .map((x) => x.label);

    return index < maxToShow ? (
      <components.MultiValue {...props}>
        <span style={{ fontSize: "12px" }}>
          {
           props.data.avatar && (
             <img style={{ width: 26, height: 26, borderRadius: '50%', marginRight: 5 }} src={props.data.avatar} alt={props.data.label} /> 

           ) 
          }
           
           {props.data.label}</span>
      </components.MultiValue>
    ) : index === maxToShow ? (
      <MoreSelectedBadge items={overflow} />
    ) : null;
  };





  const handleStartStream = (e) => {
      e.preventDefault();
      alert("stream started")
  }

  return (
    <>
      <div className="live-streaming-sec">
        <div className="live-streaming-header-sec">
          <Container fluid>
            <Row>
              <Col md={12}>
                <div className="live-streaming-header-sec">
                  {profile.loading ? (
                    t("loading")
                  ) : profile.data.is_content_creator === 2 ? (
                    <div className="live-streaming-left-sec">
                      <Link to="/live-videos" className="new-live-history-btn">
                        {t("onlive")}
                      </Link>

                      <Link
                        to="/live-videos-history"
                        className={`new-live-history-btn pull-right`}
                      >
                        {t("my_live_streams")}
                      </Link>
                      <Link
                        to="/go-live"
                        className={`new-go-live-btn pull-right ${
                          locationD.pathname === "/go-live"
                            ? "activeLiveTab"
                            : ""
                        } `}
                        // onClick={() => setGoLive(true)}
                      >
                        {t("go_live")}
                      </Link>
                    </div>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Container fluid>
          <form onSubmit={handleStartStream} className="mainLiveInputContainer" style={{ marginTop: "45px" }}>
            <div className="liveInputWrapperD">
              <div className="mainDivInputWrapper">
                <label
                  htmlFor="mainStreamLId"
                  style={{ fontSize: "13px", fontWeight: "600" }}
                >
                  Stream Title
                </label>
                <input
                  type="text"
                  className="mainStreamLInput"
                  id="mainStreamLId"
                  placeholder="Enter stream name..."
                />
              </div>

              <div className="mainDivInputWrapper">
                <label
                  htmlFor="mainCaptionLId"
                  style={{ fontSize: "13px", fontWeight: "600" }}
                >
                  Caption Title
                </label>
                {/* <input
                  type="text"
                  className="mainStreamLInput"
                  id="mainCaptionLId"
                  placeholder="Enter caption name..."
                /> */}
                
                <textarea
                                                // className="form-control"
                                                placeholder={"Enter caption title..."}
                                                name="description_level"
                                                style={{border: "1px solid rgb(210, 214, 222)", padding: "8px", width: "100%", height: '70px', outline: "none" , fontSize:"12px"}}
                                                // value={
                                                //     values.description_level
                                                // }
                                                // onChange={handleChange}
                                            />
              </div>
            </div>

            <div
              className="liveAccessControlWrapper"
              style={{ display: "flex", flexDirection: "column", gap: "9px" }}
            >
              <h3>Access Control Stream Settings</h3>

              <div
                className="subLiveAccessControlWrapper"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "22px",
                }}
              >
                <div
                  className="accessControlContainer mt-3"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <h4 style={{ color: "#8a96a3" }}>Access stream control</h4>

                  <div style={{ width: "210px" }}>
                    <LiveAccessControlMain
                      handleAccessChange={handleAccessChange}
                      accessMainValue={accessMainValue}
                      type={"LiveMainAccessControl"}
                    />
                  </div>

                  <div style={{ width: "500px" }}>
                    {accessMainValue === "restrict_user" && (
                      <LiveAccessControlSubMain
                        handleAccessRestrictChange={handleAccessRestrictChange}
                        accessRestrictValue={accessRestrictValue}
                        type={"LevelAccessSubControl"}
                        isDisble={
                          accessRestrictValue.follower &&
                          accessRestrictValue.subscriber_only
                        }
                      />
                    )}

                    {accessMainValue === "restrict_user" &&
                      accessRestrictValue.subscriber_level === true && (
                        <LiveAccessControlRestrict
                          optionsValue={subLevelOptions}
                          value={accessControlMainSelected.selectedSubLevel}
                          handleChangeValue={handleSetSelectedSubLevel}
                          Option={Option}
                          MultiValue={MultiValue}
                          labelType={"Subscriber levels"}
                        />
                      )}

                    {accessMainValue === "restrict_user" ? (
                      accessRestrictValue.follower &&
                      accessRestrictValue.subscriber_only &&
                      accessRestrictValue.subscriber_list ? (
                        ""
                      ) : accessRestrictValue.subscriber_list ? (
                        <LiveAccessControlRestrict
                          optionsValue={subListsOptions}
                          value={accessControlMainSelected.selectedSubList}
                          handleChangeValue={handleSetSelectedSubList}
                          Option={Option}
                          MultiValue={MultiValue}
                          labelType={"Subscriber list"}
                          groupOptions={
                            accessRestrictValue.follower &&
                            accessRestrictValue.subscriber_only
                              ? []
                              : accessRestrictValue.follower
                              ? [
                                  {
                                    label: "Subscribers",
                                    options:
                                      listGroupedOp.accessRestrictGropOpt[0]
                                        ?.options,
                                  },
                                ]
                              : accessRestrictValue.subscriber_only
                              ? [
                                  {
                                    label: "Followers",
                                    options:
                                      listGroupedOp.accessRestrictGropOpt[1]
                                        ?.options,
                                  },
                                ]
                              : [
                                  {
                                    label: "Subscribers",
                                    options:
                                      listGroupedOp.accessRestrictGropOpt[0]
                                        ?.options,
                                  },
                                  {
                                    label: "Followers",
                                    options:
                                      listGroupedOp.accessRestrictGropOpt[1]
                                        ?.options,
                                  },
                                ]
                          }
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                

                <div
                  className="accessControlContainer mt-3"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <h4 style={{ color: "#8a96a3" }}>Advanced settings</h4>

                  <div
                    className="who-download-container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "19px",
                      marginTop: "11px",
                    }}
                  >
                    <div
                      className="who-download-wrapper"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "0px",
                          fontSize: "13px",
                          fontWeight: "500",
                        }}
                      >
                        Access to forword
                      </p>

                      <div style={{ width: "210px" }}>
                        <LiveAccessControlMain
                          handleAccessChange={handleAccessChange}
                          accessMainValue={forwardAccessMainValue}
                          type={"whoCanForward"}
                        />
                      </div>

                      <div style={{ width: "500px" }}>
                        {forwardAccessMainValue === "restrict_user" && (
                          <LiveAccessControlSubMain
                            handleAccessRestrictChange={
                              handleAccessRestrictChange
                            }
                            accessRestrictValue={forwardRestrictValue}
                            type={"whoCanForwardSub"}
                            isDisble={
                              forwardRestrictValue.follower &&
                              forwardRestrictValue.subscriber_only
                            }
                          />
                        )}

                        {forwardAccessMainValue === "restrict_user" &&
                          forwardRestrictValue.subscriber_level === true && (
                            <LiveAccessControlRestrict
                              optionsValue={subLevelOptions}
                              value={
                                forwardControlMainSelected.selectedSubLevel
                              }
                              handleChangeValue={
                                handleWhoCanForwardLevelSelected
                              }
                              Option={Option}
                              MultiValue={MultiValue}
                              labelType={"Subscriber levels"}
                            />
                          )}

                        {forwardAccessMainValue === "restrict_user" ? (
                          forwardRestrictValue.follower &&
                          forwardRestrictValue.subscriber_only &&
                          forwardRestrictValue.subscriber_list ? (
                            ""
                          ) : forwardRestrictValue.subscriber_list ? (
                            <LiveAccessControlRestrict
                              optionsValue={subListsOptions}
                              value={forwardControlMainSelected.selectedSubList}
                              handleChangeValue={
                                handleWhoCanForwardListSelected

                              }
                              Option={Option}
                              MultiValue={MultiValue}
                              labelType={"Subscriber list"}
                              groupOptions={
                                forwardRestrictValue.follower &&
                                forwardRestrictValue.subscriber_only
                                  ? []
                                  : forwardRestrictValue.follower
                                  ? [
                                      {
                                        label: "Subscribers",
                                        options:
                                          listGroupedOp
                                            .forwardRestrictGropOpt[0]?.options,
                                      },
                                    ]
                                  : forwardRestrictValue.subscriber_only
                                  ? [
                                      {
                                        label: "Followers",
                                        options:
                                          listGroupedOp
                                            .forwardRestrictGropOpt[1]?.options,
                                      },
                                    ]
                                  : [
                                      {
                                        label: "Subscribers",
                                        options:
                                          listGroupedOp
                                            .forwardRestrictGropOpt[0]?.options,
                                      },
                                      {
                                        label: "Followers",
                                        options:
                                          listGroupedOp
                                            .forwardRestrictGropOpt[1]?.options,
                                      },
                                    ]
                              }
                            />
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}

                        {/* {isRestritError.downloadRestrictError
                              .subLevelError === true &&
                              downloadAccessMainValue === "restrict_user" &&
                              downloadRestrictValue.subscriber_level ===
                                true && (
                                <p style={{ color: "red", fontSize: "10px" }}>
                                  Select atleast one
                                </p>
                              )} */}

                        {/* {isRestritError.downloadRestrictError
                              .subListError === true &&
                              downloadAccessMainValue === "restrict_user" &&
                              downloadRestrictValue.subscriber_list ===
                                true && (
                                <p style={{ color: "red", fontSize: "10px" }}>
                                  Select atleast one
                                </p>
                              )} */}
                      </div>
                    </div>

                    
                  </div>

                  <div
                    className="who-download-container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "19px",
                      marginTop: "11px",
                    }}
                  >
                    <div
                      className="who-download-wrapper"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "0px",
                          fontSize: "13px",
                          fontWeight: "500",
                        }}
                      >
                        Access to comment
                      </p>

                      <div style={{ width: "210px" }}>
                        <LiveAccessControlMain
                          handleAccessChange={handleAccessChange}
                          accessMainValue={commentAccessMainValue}
                          type={"whoCanComment"}
                        />
                      </div>

                      <div style={{ width: "500px" }}>
                        {commentAccessMainValue === "restrict_user" && (
                          <LiveAccessControlSubMain
                            handleAccessRestrictChange={
                              handleAccessRestrictChange
                            }
                            accessRestrictValue={commentRestrictValue}
                            type={"whoCanCommentSub"}
                            isDisble={
                              commentRestrictValue.follower &&
                              commentRestrictValue.subscriber_only
                            }
                          />
                        )}

                        {commentAccessMainValue === "restrict_user" &&
                          commentRestrictValue.subscriber_level === true && (
                            <LiveAccessControlRestrict
                              optionsValue={subLevelOptions}
                              value={
                                commentControlMainSelected.selectedSubLevel
                              }
                              handleChangeValue={
                                handleWhoCanCommentLevelSelected
                              }
                              Option={Option}
                              MultiValue={MultiValue}
                              labelType={"Subscriber levels"}
                            />
                          )}

                        {commentAccessMainValue === "restrict_user" ? (
                          commentRestrictValue.follower &&
                          commentRestrictValue.subscriber_only &&
                          commentRestrictValue.subscriber_list ? (
                            ""
                          ) : commentRestrictValue.subscriber_list ? (
                            <LiveAccessControlRestrict
                              optionsValue={subListsOptions}
                              value={commentControlMainSelected.selectedSubList}
                              handleChangeValue={
                                handleWhoCanCommentListSelected

                              }
                              Option={Option}
                              MultiValue={MultiValue}
                              labelType={"Subscriber list"}
                              groupOptions={
                                commentRestrictValue.follower &&
                                commentRestrictValue.subscriber_only
                                  ? []
                                  : commentRestrictValue.follower
                                  ? [
                                      {
                                        label: "Subscribers",
                                        options:
                                          listGroupedOp
                                            .commentRestrictGropOpt[0]?.options,
                                      },
                                    ]
                                  : commentRestrictValue.subscriber_only
                                  ? [
                                      {
                                        label: "Followers",
                                        options:
                                          listGroupedOp
                                            .commentRestrictGropOpt[1]?.options,
                                      },
                                    ]
                                  : [
                                      {
                                        label: "Subscribers",
                                        options:
                                          listGroupedOp
                                            .commentRestrictGropOpt[0]?.options,
                                      },
                                      {
                                        label: "Followers",
                                        options:
                                          listGroupedOp
                                            .commentRestrictGropOpt[1]?.options,
                                      },
                                    ]
                              }
                            />
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}

                        {/* {isRestritError.downloadRestrictError
                              .subLevelError === true &&
                              downloadAccessMainValue === "restrict_user" &&
                              downloadRestrictValue.subscriber_level ===
                                true && (
                                <p style={{ color: "red", fontSize: "10px" }}>
                                  Select atleast one
                                </p>
                              )} */}

                        {/* {isRestritError.downloadRestrictError
                              .subListError === true &&
                              downloadAccessMainValue === "restrict_user" &&
                              downloadRestrictValue.subscriber_list ===
                                true && (
                                <p style={{ color: "red", fontSize: "10px" }}>
                                  Select atleast one
                                </p>
                              )} */}
                      </div>
                    </div>
                  </div>
                  <div
                    className="who-download-container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "19px",
                      marginTop: "11px",
                    }}
                  >
                    <div
                      className="who-download-wrapper"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "0px",
                          fontSize: "13px",
                          fontWeight: "500",
                        }}
                      >
                        Access to leave comment in chatroom
                      </p>

                      <div style={{ width: "210px" }}>
                        <LiveAccessControlMain
                          handleAccessChange={handleAccessChange}
                          accessMainValue={leaveCommentChatAccessMainValue}
                          type={"whoCanLeaveCommentChat"}
                        />
                      </div>

                      <div style={{ width: "500px" }}>
                        {leaveCommentChatAccessMainValue === "restrict_user" && (
                          <LiveAccessControlSubMain
                            handleAccessRestrictChange={
                              handleAccessRestrictChange
                            }
                            accessRestrictValue={leaveCommentChatRestrictValue}
                            type={"whoCanLeaveCommentChatSub"}
                            isDisble={
                              leaveCommentChatRestrictValue.follower &&
                              leaveCommentChatRestrictValue.subscriber_only
                            }
                          />
                        )}

                        {leaveCommentChatAccessMainValue === "restrict_user" &&
                          leaveCommentChatRestrictValue.subscriber_level === true && (
                            <LiveAccessControlRestrict
                              optionsValue={subLevelOptions}
                              value={
                                leaveCommentChatControlMainSelected.selectedSubLevel
                              }
                              handleChangeValue={
                                handleWhoCanLeaveCommentLevelSelected
                              }
                              Option={Option}
                              MultiValue={MultiValue}
                              labelType={"Subscriber levels"}
                            />
                          )}

                        {leaveCommentChatAccessMainValue === "restrict_user" ? (
                          leaveCommentChatRestrictValue.follower &&
                          leaveCommentChatRestrictValue.subscriber_only &&
                          leaveCommentChatRestrictValue.subscriber_list ? (
                            ""
                          ) : leaveCommentChatRestrictValue.subscriber_list ? (
                            <LiveAccessControlRestrict
                              optionsValue={subListsOptions}
                              value={leaveCommentChatControlMainSelected.selectedSubList}
                              handleChangeValue={
                                handleWhoCanLeaveCommentListSelected
                              }
                              Option={Option}
                              MultiValue={MultiValue}
                              labelType={"Subscriber list"}
                              groupOptions={
                                leaveCommentChatRestrictValue.follower &&
                                leaveCommentChatRestrictValue.subscriber_only
                                  ? []
                                  : leaveCommentChatRestrictValue.follower
                                  ? [
                                      {
                                        label: "Subscribers",
                                        options:
                                          listGroupedOp
                                            .commentRestrictGropOpt[0]?.options,
                                      },
                                    ]
                                  : commentRestrictValue.subscriber_only
                                  ? [
                                      {
                                        label: "Followers",
                                        options:
                                          listGroupedOp
                                            .commentRestrictGropOpt[1]?.options,
                                      },
                                    ]
                                  : [
                                      {
                                        label: "Subscribers",
                                        options:
                                          listGroupedOp
                                            .commentRestrictGropOpt[0]?.options,
                                      },
                                      {
                                        label: "Followers",
                                        options:
                                          listGroupedOp
                                            .commentRestrictGropOpt[1]?.options,
                                      },
                                    ]
                              }
                            />
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}

                        {/* {isRestritError.downloadRestrictError
                              .subLevelError === true &&
                              downloadAccessMainValue === "restrict_user" &&
                              downloadRestrictValue.subscriber_level ===
                                true && (
                                <p style={{ color: "red", fontSize: "10px" }}>
                                  Select atleast one
                                </p>
                              )} */}

                        {/* {isRestritError.downloadRestrictError
                              .subListError === true &&
                              downloadAccessMainValue === "restrict_user" &&
                              downloadRestrictValue.subscriber_list ===
                                true && (
                                <p style={{ color: "red", fontSize: "10px" }}>
                                  Select atleast one
                                </p>
                              )} */}
                      </div>
                    </div>
                  </div>

                  <div
                    className="who-download-container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "19px",
                      marginTop: "11px",
                    }}
                  >
                    <div
                      className="who-download-wrapper"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "0px",
                          fontSize: "13px",
                          fontWeight: "500",
                        }}
                      >
                        Access to download
                      </p>
{/* 
                      <div
                        className="access-content-switch-item"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          // justifyContent: "space-between",
                          gap: "10px",
                          marginTop : '5px'
                        }}
                      >
                        <p
                          style={{
                            all: "unset",
                            fontSize: "13px",
                            fontWeight: "700",
                          }}
                        >
                          Allow viewer download this stream
                        </p>

                        <label class="switchL" style={{ marginBottom: "0px" }}>
                          <input
                            type="checkbox"
                            className=""
                            onChange={(e) => handleDownloadOn(e)}
                            checked={isDownloadOn}
                            name={"downloadStreamToggle"}
                            // disabled={values?.accessContentData[0].mainToogle === true && accessT?.name?.mainToogle !== "fullAccess"}
                          />
                          <span class="sliderL"></span>
                        </label>
                      </div> */}

                  
                      

                      <div
                    className="who-download-container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "19px",
                      marginTop: "11px",
                    }}
                  >
               
        

                      <div
                        className="access-content-switch-item"
                        style={{
                          display: "flex",
                          // alignItems: "center",
                          flexDirection: "column",
                          // justifyContent: "space-between",
                          gap: "10px",
                          marginTop : '5px'
                        }}
                      >

                        <div style={{display: "flex", alignItems: "center", gap : "13px"}}>

                     
                        <p
                          style={{
                            all: "unset",
                            fontSize: "13px",
                            fontWeight: "700",
                          }}
                        >
                           Downlaod (Available for all)
                        </p>

                        <label class="switchL" style={{ marginBottom: "0px" }}>
                          <input
                            type="checkbox"
                            className=""
                            onChange={(e) => handleDownloadAllChnage(e)}
                            checked={isDownloadAll}
                            name={"downloadStreamAllToggle"}
                            // disabled={values?.accessContentData[0].mainToogle === true && accessT?.name?.mainToogle !== "fullAccess"}
                          />
                          <span class="sliderL"></span>
                        </label>

                        </div>
                    

                           {
                            isDownloadAll && (
                         <div style={{ width: "210px" }}>
                        <LiveAccessControlMain
                          handleAccessChange={handleAccessChange}
                          accessMainValue={downloadAccessMainValue}
                          type={"whoCanDownload"}
                        />
                          </div>

                            )
                           }

                          {
                          isDownloadAll && (

                            <div style={{ width: "500px" }}>
                        {downloadAccessMainValue === "restrict_user" && (
                          <LiveAccessControlSubMain
                            handleAccessRestrictChange={
                              handleAccessRestrictChange
                            }
                            accessRestrictValue={downloadRestrictValue}
                            type={"whoCanDownloadSub"}
                            isDisble={
                              downloadRestrictValue.follower &&
                              downloadRestrictValue.subscriber_only
                            }
                          />
                        )}

                        {downloadAccessMainValue === "restrict_user" &&
                          downloadRestrictValue.subscriber_level === true && (
                            <LiveAccessControlRestrict
                              optionsValue={subLevelOptions}
                              value={
                                downloadControlMainSelected.selectedSubLevel
                              }
                              handleChangeValue={
                                handleWhoCanDownloadLevelSelected
                              }
                              Option={Option}
                              MultiValue={MultiValue}
                              labelType={"Subscriber levels"}
                            />
                          )}

                        {downloadAccessMainValue === "restrict_user" ? (
                          downloadRestrictValue.follower &&
                          downloadRestrictValue.subscriber_only &&
                          downloadRestrictValue.subscriber_list ? (
                            ""
                          ) : downloadRestrictValue.subscriber_list ? (
                            <LiveAccessControlRestrict
                              optionsValue={subListsOptions}
                              value={downloadControlMainSelected.selectedSubList}
                              handleChangeValue={ handleWhoCanDownloadListSelected}
                              Option={Option}
                              MultiValue={MultiValue}
                              labelType={"Subscriber list"}
                              groupOptions={
                                downloadRestrictValue.follower &&
                                downloadRestrictValue.subscriber_only
                                  ? []
                                  : downloadRestrictValue.follower
                                  ? [
                                      {
                                        label: "Subscribers",
                                        options:
                                          listGroupedOp
                                            .downloadRestrictGropOpt[0]?.options,
                                      },
                                    ]
                                  : downloadRestrictValue.subscriber_only
                                  ? [
                                      {
                                        label: "Followers",
                                        options:
                                          listGroupedOp
                                            .downloadRestrictGropOpt[1]?.options,
                                      },
                                    ]
                                  : [
                                      {
                                        label: "Subscribers",
                                        options:
                                          listGroupedOp
                                            .downloadRestrictGropOpt[0]?.options,
                                      },
                                      {
                                        label: "Followers",
                                        options:
                                          listGroupedOp
                                            .downloadRestrictGropOpt[1]?.options,
                                      },
                                    ]
                              }
                            />
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}

                        {/* {isRestritError.downloadRestrictError
                              .subLevelError === true &&
                              downloadAccessMainValue === "restrict_user" &&
                              downloadRestrictValue.subscriber_level ===
                                true && (
                                <p style={{ color: "red", fontSize: "10px" }}>
                                  Select atleast one
                                </p>
                              )} */}

                        {/* {isRestritError.downloadRestrictError
                              .subListError === true &&
                              downloadAccessMainValue === "restrict_user" &&
                              downloadRestrictValue.subscriber_list ===
                                true && (
                                <p style={{ color: "red", fontSize: "10px" }}>
                                  Select atleast one
                                </p>
                              )} */}
                             </div>
                            )
                          }


                      </div>

                      {
                        isDownloadAll && isDownloadOn && (

                      <div
                        className="access-content-switch-item"
                        style={{
                          display: "flex",
                          // alignItems: "center",
                          flexDirection: "column",
                          // justifyContent: "space-between",
                          gap: "10px",
                          marginTop : '5px'
                        }}
                      >

                        <div style={{display: "flex", alignItems: "center", gap : "13px"}}>

                     
                        <p
                          style={{
                            all: "unset",
                            fontSize: "13px",
                            fontWeight: "700",
                          }}
                        >
                           Fixed Download Price
                        </p>

                        <label class="switchL" style={{ marginBottom: "0px" }}>
                          <input
                            type="checkbox"
                            className=""
                            onChange={(e) => {
                              
                              handleFixedDownloadPriceChange(e)

                             
                            }}

                            checked={isFixedDownloadPrice}
                            name={"fixedDownloadPriceToggle"}
                            // disabled={values?.accessContentData[0].mainToogle === true && accessT?.name?.mainToogle !== "fullAccess"}
                          />
                          <span class="sliderL"></span>
                        </label>

                        </div>
                    

                        {
                            isFixedDownloadPrice && (
                    
                        <LiveAccessControlMain
                          handleAccessChange={handleAccessChange}
                          accessMainValue={fixedDownloadPriceAccessMainValue}
                          type={"FixedDownloadPrice"}
                          isInput={true}
                          inputType={"ForDownloadPrice"}
                          handleInputC={handleMainInputValue}
                          inputValue={isFixedDPrice}

                        />
                         
                            )
                           }

                          {
                          isFixedDownloadPrice && (

                            <div style={{ width: "500px" }}>
                        {fixedDownloadPriceAccessMainValue === "restrict_user" && (
                          <LiveAccessControlSubMain
                            handleAccessRestrictChange={
                              handleAccessRestrictChange
                            }
                            accessRestrictValue={fixedDownloadPriRestrictValue}
                            type={"fixedDownloadPriceSub"}
                            isDisble={
                              fixedDownloadPriRestrictValue.follower &&
                              fixedDownloadPriRestrictValue.subscriber_only
                            }
                            isInput={true}
                            inputType={"fixedPrice"}
                            allInputValue={allDownloadFixedInputVal}
                            handleInputValue={handleAllInputValue}
                            
                          />
                        )}

                        {fixedDownloadPriceAccessMainValue === "restrict_user" &&
                          fixedDownloadPriRestrictValue.subscriber_level === true && (
                            <LiveAccessControlRestrict
                              optionsValue={subLevelOptions}
                              value={
                                fixedDownloadMainSelected.selectedSubLevel
                              }
                              handleChangeValue={
                                handleFixedDownloadLevelSelected
                              }
                              Option={Option}
                              MultiValue={MultiValue}
                              labelType={"Subscriber levels"}
                            />
                          )}

                        {fixedDownloadPriceAccessMainValue === "restrict_user" ? (
                          fixedDownloadPriRestrictValue.follower &&
                          fixedDownloadPriRestrictValue.subscriber_only &&
                          fixedDownloadPriRestrictValue.subscriber_list ? (
                            ""
                          ) : fixedDownloadPriRestrictValue.subscriber_list ? (
                            <LiveAccessControlRestrict
                              optionsValue={subListsOptions}
                              value={fixedDownloadMainSelected.selectedSubList}
                              handleChangeValue={
                                handleFixedDownloadListSelected
                              }
                              Option={Option}
                              MultiValue={MultiValue}
                              labelType={"Subscriber list"}
                              groupOptions={
                                fixedDownloadPriRestrictValue.follower &&
                                fixedDownloadPriRestrictValue.subscriber_only
                                  ? []
                                  : fixedDownloadPriRestrictValue.follower
                                  ? [
                                      {
                                        label: "Subscribers",
                                        options:
                                          listGroupedOp
                                            .fixedDownloadGropOpt[0]?.options,
                                      },
                                    ]
                                  : fixedDownloadPriRestrictValue.subscriber_only
                                  ? [
                                      {
                                        label: "Followers",
                                        options:
                                          listGroupedOp
                                            .fixedDownloadGropOpt[1]?.options,
                                      },
                                    ]
                                  : [
                                      {
                                        label: "Subscribers",
                                        options:
                                          listGroupedOp
                                            .fixedDownloadGropOpt[0]?.options,
                                      },
                                      {
                                        label: "Followers",
                                        options:
                                          listGroupedOp
                                            .fixedDownloadGropOpt[1]?.options,
                                      },
                                    ]
                              }
                            />
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}

                        {/* {isRestritError.downloadRestrictError
                              .subLevelError === true &&
                              downloadAccessMainValue === "restrict_user" &&
                              downloadRestrictValue.subscriber_level ===
                                true && (
                                <p style={{ color: "red", fontSize: "10px" }}>
                                  Select atleast one
                                </p>
                              )} */}

                        {/* {isRestritError.downloadRestrictError
                              .subListError === true &&
                              downloadAccessMainValue === "restrict_user" &&
                              downloadRestrictValue.subscriber_list ===
                                true && (
                                <p style={{ color: "red", fontSize: "10px" }}>
                                  Select atleast one
                                </p>
                              )} */}
                             </div>
                            )
                          }


                      </div>
                        )
                      }




                             



                    </div>


                        
                    

                      {
                        (isDownloadOn && isFixedDownloadPrice) && (
 
                        <div
                    className="who-download-container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "19px",
                      marginTop: "11px",
                    }}
                  >
               
        

                      <div
                        className="access-content-switch-item"
                        style={{
                          display: "flex",
                          // alignItems: "center",
                          flexDirection: "column",
                          // justifyContent: "space-between",
                          gap: "10px",
                          marginTop : '5px'
                        }}
                      >

                        <div style={{display: "flex", alignItems: "center", gap : "13px"}}>

                     
                        <p
                          style={{
                            all: "unset",
                            fontSize: "13px",
                            fontWeight: "700",
                          }}
                        >
                           Discount (Available for all)
                        </p>

                        <label class="switchL" style={{ marginBottom: "0px" }}>
                          <input
                            type="checkbox"
                            className=""
                            onChange={(e) =>   handleDiscountAllChange(e)}
                            checked={isDiscountAll}
                            name={"downloadStreamAllToggle"}
                            // disabled={values?.accessContentData[0].mainToogle === true && accessT?.name?.mainToogle !== "fullAccess"}
                          />
                          <span class="sliderL"></span>
                        </label>

                        </div>
                    

                           {
                            isDiscountAll && (
                         <div style={{ width: "210px" }}>
                        <LiveAccessControlMain
                          handleAccessChange={handleAccessChange}
                          accessMainValue={discountDownloadAccessMainValue}
                          type={"DiscountAllDownload"}
                        />
                          </div>

                            )
                           }

                          {
                          isDiscountAll && (

                            <div style={{ width: "500px" }}>
                        {discountDownloadAccessMainValue === "restrict_user" && (
                          <LiveAccessControlSubMain
                            handleAccessRestrictChange={
                              handleAccessRestrictChange
                            }
                            accessRestrictValue={discountRestrictValue}
                            type={"DiscountAllSub"}
                            isDisble={
                              discountRestrictValue.follower &&
                              discountRestrictValue.subscriber_only
                            }
                          />
                        )}

                        {discountDownloadAccessMainValue === "restrict_user" &&
                          discountRestrictValue.subscriber_level === true && (
                            <LiveAccessControlRestrict
                              optionsValue={subLevelOptions}
                              value={
                                discountControlMainSelected.selectedSubLevel
                              }
                              handleChangeValue={
                                handleDiscountLevelSelected
                              }
                              Option={Option}
                              MultiValue={MultiValue}
                              labelType={"Subscriber levels"}
                            />
                          )}

                        {discountDownloadAccessMainValue === "restrict_user" ? (
                          discountRestrictValue.follower &&
                          discountRestrictValue.subscriber_only &&
                          discountRestrictValue.subscriber_list ? (
                            ""
                          ) : discountRestrictValue.subscriber_list ? (
                            <LiveAccessControlRestrict
                              optionsValue={subListsOptions}
                              value={discountControlMainSelected.selectedSubList}
                              handleChangeValue={
                                handleDiscountListSelected
                              }
                              Option={Option}
                              MultiValue={MultiValue}
                              labelType={"Subscriber list"}
                              groupOptions={
                                discountRestrictValue.follower &&
                                discountRestrictValue.subscriber_only
                                  ? []
                                  : discountRestrictValue.follower
                                  ? [
                                      {
                                        label: "Subscribers",
                                        options:
                                          listGroupedOp
                                            .discountGropOpt[0]?.options,
                                      },
                                    ]
                                  : downloadRestrictValue.subscriber_only
                                  ? [
                                      {
                                        label: "Followers",
                                        options:
                                          listGroupedOp
                                            .discountGropOpt[1]?.options,
                                      },
                                    ]
                                  : [
                                      {
                                        label: "Subscribers",
                                        options:
                                          listGroupedOp
                                            .discountGropOpt[0]?.options,
                                      },
                                      {
                                        label: "Followers",
                                        options:
                                          listGroupedOp
                                            .discountGropOpt[1]?.options,
                                      },
                                    ]
                              }
                            />
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}

                        {/* {isRestritError.downloadRestrictError
                              .subLevelError === true &&
                              downloadAccessMainValue === "restrict_user" &&
                              downloadRestrictValue.subscriber_level ===
                                true && (
                                <p style={{ color: "red", fontSize: "10px" }}>
                                  Select atleast one
                                </p>
                              )} */}

                        {/* {isRestritError.downloadRestrictError
                              .subListError === true &&
                              downloadAccessMainValue === "restrict_user" &&
                              downloadRestrictValue.subscriber_list ===
                                true && (
                                <p style={{ color: "red", fontSize: "10px" }}>
                                  Select atleast one
                                </p>
                              )} */}
                             </div>
                            )
                          }


                      </div>

                      {
                        isDownloadAll && isDownloadOn && isDiscountAll && (

                      <div
                        className="access-content-switch-item"
                        style={{
                          display: "flex",
                          // alignItems: "center",
                          flexDirection: "column",
                          // justifyContent: "space-between",
                          gap: "10px",
                          marginTop : '5px'
                        }}
                      >

                        <div style={{display: "flex", alignItems: "center", gap : "13px"}}>

                     
                        <p
                          style={{
                            all: "unset",
                            fontSize: "13px",
                            fontWeight: "700",
                          }}
                        >
                           Fixed Discount Download Price
                        </p>

                        <label class="switchL" style={{ marginBottom: "0px" }}>
                          <input
                            type="checkbox"
                            className=""
                            onChange={(e) => {

                              handleFixedDiscountDownloadPChange(e)
                              
                     
                            }}

                            checked={isFixedDiscountDownloadPrice}
                            name={"fixedDownloadPriceToggle"}
                            // disabled={values?.accessContentData[0].mainToogle === true && accessT?.name?.mainToogle !== "fullAccess"}
                          />
                          <span class="sliderL"></span>
                        </label>

                        </div>
                    
                           {
                            isFixedDiscountDownloadPrice && (
                    
                        <LiveAccessControlMain
                          handleAccessChange={handleAccessChange}
                          accessMainValue={fixedDiscountDownloadAccessMainValue}
                          type={"FixedDiscountPrice"}
                          isInput={true}
                          inputType={"ForDiscountPrice"}
                          handleInputC={handleMainInputValue}
                          inputValue={isFixedDiscountPrice}

                        />
                         
                            )
                           }

                          {
                          isFixedDiscountDownloadPrice && (

                            <div style={{ width: "500px" }}>
                        {fixedDiscountDownloadAccessMainValue === "restrict_user" && (
                          <LiveAccessControlSubMain
                            handleAccessRestrictChange={
                              handleAccessRestrictChange
                            }
                            accessRestrictValue={fixedDiscountPriceRestrictValue}
                            type={"fixedDiscountPriceSub"}
                            isDisble={
                              fixedDiscountPriceRestrictValue.follower &&
                              fixedDiscountPriceRestrictValue.subscriber_only
                            }
                            isInput={true}
                            inputType={"fixedDiscountPriceInput"}
                            allInputValue={allDiscountDFixedInputVal}
                            handleInputValue={handleAllInputValue}
                            
                          />
                        )}

                        {fixedDiscountDownloadAccessMainValue === "restrict_user" &&
                          fixedDiscountPriceRestrictValue.subscriber_level === true && (
                            <LiveAccessControlRestrict
                              optionsValue={subLevelOptions}
                              value={
                                fixedDiscountControlMainSelected.selectedSubLevel
                              }
                              handleChangeValue={
                                handleDiscountPriceLevelSelected
                              }
                              Option={Option}
                              MultiValue={MultiValue}
                              labelType={"Subscriber levels"}
                            />
                          )}

                        {fixedDiscountDownloadAccessMainValue === "restrict_user" ? (
                          fixedDiscountPriceRestrictValue.follower &&
                          fixedDiscountPriceRestrictValue.subscriber_only &&
                          fixedDiscountPriceRestrictValue.subscriber_list ? (
                            ""
                          ) : fixedDiscountPriceRestrictValue.subscriber_list ? (
                            <LiveAccessControlRestrict
                              optionsValue={subListsOptions}
                              value={fixedDiscountControlMainSelected.selectedSubList}
                              handleChangeValue={
                                handleDiscountPriceListSelected
                              }
                              Option={Option}
                              MultiValue={MultiValue}
                              labelType={"Subscriber list"}
                              groupOptions={
                                fixedDiscountPriceRestrictValue.follower &&
                                fixedDiscountPriceRestrictValue.subscriber_only
                                  ? []
                                  : fixedDiscountPriceRestrictValue.follower
                                  ? [
                                      {
                                        label: "Subscribers",
                                        options:
                                          listGroupedOp
                                            .fixedDiscountDownloadGropOpt[0]?.options,
                                      },
                                    ]
                                  : fixedDiscountPriceRestrictValue.subscriber_only
                                  ? [
                                      {
                                        label: "Followers",
                                        options:
                                          listGroupedOp.fixedDiscountDownloadGropOpt[1]?.options,
                                      },
                                    ]
                                  : [
                                      {
                                        label: "Subscribers",
                                        options:
                                          listGroupedOp
                                            .fixedDiscountDownloadGropOpt[0]?.options,
                                      },
                                      {
                                        label: "Followers",
                                        options:
                                          listGroupedOp
                                            .fixedDiscountDownloadGropOpt[1]?.options,
                                      },
                                    ]
                              }
                            />
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}

                        {/* {isRestritError.downloadRestrictError
                              .subLevelError === true &&
                              downloadAccessMainValue === "restrict_user" &&
                              downloadRestrictValue.subscriber_level ===
                                true && (
                                <p style={{ color: "red", fontSize: "10px" }}>
                                  Select atleast one
                                </p>
                              )} */}

                        {/* {isRestritError.downloadRestrictError
                              .subListError === true &&
                              downloadAccessMainValue === "restrict_user" &&
                              downloadRestrictValue.subscriber_list ===
                                true && (
                                <p style={{ color: "red", fontSize: "10px" }}>
                                  Select atleast one
                                </p>
                              )} */}
                             </div>
                            )
                          }


                      </div>
                        )
                      }




                             



                       </div>


                        )
                      }

                    </div>
                  </div>


                </div>


              </div>


            </div>

              <div className="liveAccessControlWrapper"  style={{ display: "flex", flexDirection: "column", gap: "9px" }}>

                  <h3>Interactive Features Settings</h3>

                  <div
                    className="who-download-container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "19px",
                      marginTop: "11px",
                    }}
                  >
                    <div
                      className="who-download-wrapper"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >

                      <div
                        className="access-content-switch-item"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          // justifyContent: "space-between",
                          gap: "10px",
                          marginTop : '5px'
                        }}
                      >
                        <p
                          style={{
                            all: "unset",
                            fontSize: "13px",
                            fontWeight: "700",
                          }}
                        >
                          Voting Feature
                        </p>

                        <label class="switchL" style={{ marginBottom: "0px" }}>
                          <input
                            type="checkbox"
                            className=""
                            onChange={(e) => handleVotingOnChange(e)}
                            checked={isVotingOn}
                            name={"VotingStreamToggle"}
                            // disabled={values?.accessContentData[0].mainToogle === true && accessT?.name?.mainToogle !== "fullAccess"}
                          />
                          <span class="sliderL"></span>
                        </label>
                      </div>

                      
                      {

                        isVotingOn && (

                          <LiveAccessControlSelectMain
                           handleAccessChange={handleAccessChange}
                           accessMainValue={votingAccessMainValue}
                           type={"votingForAll"}
                           isInput={true}
                           isSelectDropDown={true}
                           inputType={"forVotingPriceInp"}
                           selectType={"ForVotingSelect"}
                           inputValue={isVotingMainPrice}
                           handleInputC={handleMainInputValue}
                           selecteValue={votingAccessMainSelectValue}
                           handleSelectValue={handleSelecteOnChange}
                         />
                            )
                       }

                      {
                          votingAccessMainValue === "restrict_user" && (

                            <div style={{ width: "500px" }}>
                        {votingAccessMainValue === "restrict_user" && (
                          <LiveAccessControlSelectSub
                            handleAccessRestrictChange={
                              handleAccessRestrictChange
                            }
                            accessRestrictValue={votingRestrictValue}
                            type={"votingForAllSub"}
                            isDisble={
                              votingRestrictValue.follower &&
                              votingRestrictValue.subscriber_only
                            }
                            isInput={true}
                            isSelectDropDown={true}
                            inputType={"votingPriceInputSub"}
                            allInputValue={votingSelectInputValue}
                            handleInputValue={handleAllInputValue}
                            selectType={"ForVotingSelectRestrict"}
                            selecteValue={votingAccessSubSelectValue}
                            handleSelectValue={handleRestrictSelectChange}
                          />
                        )}
                        

                        {votingAccessMainValue === "restrict_user" &&
                          votingRestrictValue.subscriber_level === true && (
                            <LiveAccessControlRestrict
                              optionsValue={subLevelOptions}
                              value={
                                votingControlMainSelected.selectedSubLevel
                              }
                              handleChangeValue={handleVotingLevelSelected}
                              Option={Option}
                              MultiValue={MultiValue}
                              labelType={"Subscriber levels"}
                            />
                          )}

                        {votingAccessMainValue === "restrict_user" ? (
                          votingRestrictValue.follower &&
                          votingRestrictValue.subscriber_only &&
                          votingRestrictValue.subscriber_list ? (
                            ""
                          ) : votingRestrictValue.subscriber_list ? (
                            <LiveAccessControlRestrict
                              optionsValue={subListsOptions}
                              value={votingControlMainSelected.selectedSubList}
                              handleChangeValue={
                                handleVotingListSelected
                              }
                              Option={Option}
                              MultiValue={MultiValue}
                              labelType={"Subscriber list"}
                              groupOptions={
                                votingRestrictValue.follower &&
                                votingRestrictValue.subscriber_only
                                  ? []
                                  : votingRestrictValue.follower
                                  ? [
                                      {
                                        label: "Subscribers",
                                        options:
                                          listGroupedOp
                                            .votingGropOpt[0]?.options,
                                      },
                                    ]
                                  : votingRestrictValue.subscriber_only
                                  ? [
                                      {
                                        label: "Followers",
                                        options:
                                          listGroupedOp.votingGropOpt[1]?.options,
                                      },
                                    ]
                                  : [
                                      {
                                        label: "Subscribers",
                                        options:
                                          listGroupedOp
                                            .votingGropOpt[0]?.options,
                                      },
                                      {
                                        label: "Followers",
                                        options:
                                          listGroupedOp
                                            .votingGropOpt[1]?.options,
                                      },
                                    ]
                              }
                            />
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}

                        {/* {isRestritError.downloadRestrictError
                              .subLevelError === true &&
                              downloadAccessMainValue === "restrict_user" &&
                              downloadRestrictValue.subscriber_level ===
                                true && (
                                <p style={{ color: "red", fontSize: "10px" }}>
                                  Select atleast one
                                </p>
                              )} */}

                        {/* {isRestritError.downloadRestrictError
                              .subListError === true &&
                              downloadAccessMainValue === "restrict_user" &&
                              downloadRestrictValue.subscriber_list ===
                                true && (
                                <p style={{ color: "red", fontSize: "10px" }}>
                                  Select atleast one
                                </p>
                              )} */}
                             </div>
                            )
                          }

                   
                 

                    </div>
                  </div>

  
                 {/* Tip feature */}

                  <div
                    className="who-download-container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "19px",
                      marginTop: "11px",
                    }}>

    <div
                        className="access-content-switch-item"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          // justifyContent: "space-between",
                          gap: "10px",
                          marginTop : '5px'
                        }}
                      >
                        <p
                          style={{
                            all: "unset",
                            fontSize: "13px",
                            fontWeight: "700",
                          }}
                        >
                          Tip
                        </p>

                        <label class="switchL" style={{ marginBottom: "0px" }}>
                          <input
                            type="checkbox"
                            className=""
                            onChange={(e) => handleTipOnChange(e)}
                            checked={isTipOn}
                            name={"tipStreamToggle"}
                            // disabled={values?.accessContentData[0].mainToogle === true && accessT?.name?.mainToogle !== "fullAccess"}
                          />
                          <span class="sliderL"></span>
                        </label>
                      </div>


                  </div>

                  {/* Send gift feature */}

                  <div
                    className="who-download-container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "19px",
                      marginTop: "11px",
                    }}>

    <div
                        className="access-content-switch-item"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          // justifyContent: "space-between",
                          gap: "10px",
                          marginTop : '5px'
                        }}
                      >
                        <p
                          style={{
                            all: "unset",
                            fontSize: "13px",
                            fontWeight: "700",
                          }}
                        >
                          Send gift
                        </p>

                        <label class="switchL" style={{ marginBottom: "0px" }}>
                          <input
                            type="checkbox"
                            className=""
                            onChange={(e) => handleSendGiftOnChange(e)}
                            checked={isSendGiftOn}
                            name={"sendGiftStreamToggle"}
                            // disabled={values?.accessContentData[0].mainToogle === true && accessT?.name?.mainToogle !== "fullAccess"}
                          />
                          <span class="sliderL"></span>
                        </label>
                      </div>


                  </div>

              </div>

                    <Button
                        type="submit"
                        className="btn gradient-btn postBtn gradientcolor text-uppercase mt-0 mt-md-3"
                        style={{ width: "fit-content" }}
                        // disabled={
                        //   props.fileUpload.buttonDisable ||
                        //   props.savePost.buttonDisable
                        // }
                      >
                       Start stream
                    </Button>
          </form>
        </Container>
      </div>
    </>
  );
};

// export default LiveGoStreamIndex;


function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapDispatchToProps
)(translate(LiveGoStreamIndex));