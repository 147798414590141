import React, { useState, useEffect } from "react";
import FanAllSec from "./FanAllSec";
import { Link } from "react-router-dom";
// import FanActiveSec from "./FanActiveSec";
import FanExpiredSec from "./FanExpiredSec";
import FanTabSec from "./FanTabSec";
import { Container, Row, Col, Image } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import {
  fetchActiveFollowersStart,
  fetchExpiredFollowersStart,
  fetchFollowersStart,
} from "../../../../store/actions/FollowAction";
import FollowingLoader from "../../../Loader/FollowingLoader";
import { translate, t } from "react-multi-lang";
import SearchInput from "../../Searchinput/SearchInput";
import FanListView from "./FanListView";
import UserCard from "../UserCard";
import NoDataFound from "../../../NoDataFound/NoDataFound";
import { fetchListsDetailsStart } from "../../../../store/actions/HomeAction";

const FanActiveSec = (props) => {
  const dispatch = useDispatch()
  
  useEffect(() => {
    dispatch(fetchListsDetailsStart());
  },[])

  return (
    <>
      <div
        role="tabpanel"
        className={
          props.activeSec === "active-sec"
            ? "tab-pane fade in active"
            : "tab-pane fade"
        }
        id="active"
      >
        {props.followers.loading ? (
           <FollowingLoader/>
        ) : (
          <>
          
            <Row>
              {props.userData && props.userData.length > 0 ? (
                props.userData.map((follower) => (
                  <UserCard 
                    pagePath={props.pagePath} 
                    user={follower.otherUser} 
                    followersType={props.pagePath === "followers" ? true : false}
                    setUserData={props?.setUserData}
                    setCheckedUserId={props?.setCheckedUserId}
                    checkedUserId={props?.checkedUserId}
                    isSelected={props?.isSelected}
                    isSelectedData={props?.userData}
                  />
                ))
              ) : (
                <NoDataFound></NoDataFound>
              )}
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default translate(FanActiveSec);
