import { FETCH_UNSUBSCRIBED_FAILURE, FETCH_UNSUBSCRIBED_START, FETCH_UNSUBSCRIBED_SUCCESS } from "./ActionConstant";

export function fetchUnsubscribedStart(data) {
	return {
		type: FETCH_UNSUBSCRIBED_START,
		data,
	};
}

export function fetchUnsubscribedSuccess(data) {
	return {
		type: FETCH_UNSUBSCRIBED_SUCCESS,
		data,
	};
}

export function fetchUnsubscribedFailure(error) {
	return {
		type: FETCH_UNSUBSCRIBED_FAILURE,
		error,
	};
}