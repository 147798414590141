import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Media,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import "./BecomeAContentCreator.css";
import { Link } from "react-router-dom";
import BecomeAContentCreatorLoader from "../Loader/BecomeAContentCreatorLoader";
import { connect } from "react-redux";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";
import { translate, t } from "react-multi-lang";
import StepZilla from "react-stepzilla";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step6 from "./Step6";

const BecomeAContentCreator = (props) => {

  const [stepsNavigation, setStepsNavigation] = useState(true);
  const [showNavigation, setShowNavigation] = useState(false);
  const [initalStep, setInitalStep] = useState(0);
  const [isTerm, setIsTerm] = useState(false);

  useEffect(() => {
    props.dispatch(fetchUserDetailsStart());
  }, []);

  const handleCheckboxChange = (isChecked) => {
    // if (isChecked) {
    //   setShowNavigation(true);
    // } else {
    //   setShowNavigation(false);
    // }
  };

  const handleNextButton = () => {
    if (props.user.data.content_creator_step === 1) {
      setShowNavigation(false)
    }

    if(isTerm){
       props.dispatch(fetchUserDetailsStart({is_approve_tc : isTerm}))
    }
  }


  const handleSubmitDocumentC = (e)=>{
    e.preventDefault();
      props.dispatch(fetchUserDetailsStart({is_approve_tc : isTerm}))
  }
  useEffect(() => {
    if (props.user.data.content_creator_step === 0 && props.user.data.is_approve_tc === 0) {
      setInitalStep(0);
    } else if (props.user.data.content_creator_step === 1 && props.user.data.is_approve_tc === 0) {
      setInitalStep(0);
      setShowNavigation(true);
    } else if (props.user.data.content_creator_step !== 2 && props.user.data.is_approve_tc === 1) {
      setInitalStep(2);
    }else if(props.user.data.content_creator_step === 2){
      setInitalStep(3);
    } else {
      setInitalStep("");
    }

  }, [props.user.data]);


  const steps = [
    { name: "Upload Documents", component: <Step1 user={props.user} /> },
    { name: "Terms and Conditions", component: <Step2 user={props.user} handleSubDoc={handleSubmitDocumentC} setInitalStep={setInitalStep} setIsTerm={setIsTerm} isTerm={isTerm} onCheckboxChange={handleCheckboxChange} showNavigation={true}/> },
    { name: "Waiting for Admin approve the document", component: <Step3 user={props.user} /> },
    { name: "Finish", component: <Step6 user={props.user} />},
  ];

  useEffect(() => {
    !props.user.loading && props.dispatch(fetchUserDetailsStart());
  }, [props.addKycDocInput.data]);

  return (
    <>
      <div className="become-content-creator-sec">
        <Container>
          {/**<BecomeAContentCreatorLoader/> **/}
          {initalStep === "" ? null : (
            <div className="become-content-creator-box">
              <div className="step-progress">
                <StepZilla
                  steps={steps}
                  preventEnterSubmission={true}
                  stepsNavigation={stepsNavigation}
                  startAtStep={initalStep}
                  showNavigation={showNavigation}
                  onStepChange={handleNextButton}
                />
              </div>
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  user: state.users.profile,
  addKycDocInput: state.kycDocument.addKycDocInput,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(BecomeAContentCreator));
