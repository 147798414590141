import React from "react";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import { translate, t } from "react-multi-lang";
import UserCard from "../FansFollowing/UserCard";
import NoDataFound from "../../NoDataFound/NoDataFound";
import FollowingLoader from "../../Loader/FollowingLoader";
import SubscribingCard from "../SubscribingCard";

const SubscriberActiveSec = (props) => {
	return (
    <>
      <div
        role="tabpanel"
        className={
          props.activeSec === "active-sec"
            ? "tab-pane fade in active"
            : "tab-pane fade"
        }
        id="active"
      >
        { props.subscribers.loading ? (
           <FollowingLoader/>
        ) : (
          <>
            <Row>
              {props?.userData && props?.userData.length > 0 ? (
                props.userData?.map((item) => (
                  <SubscribingCard 
                    pagePath={props.pagePath} 
                    userData={item} 
                    user={item?.from_user} 
                    subscribers={"subscribers"} 
                    isFavUser={item?.is_fav_user} 
                    isFriend={item?.is_friend}
                    subscribersType={props?.pagePath === "subscriber" ? true : false}
                    setUserData={props?.setUserData}
                    setCheckedUserId={props?.setCheckedUserId}
                    checkedUserId={props?.checkedUserId}
                    isSelected={props?.isSelected}
                    isSelectedData={props?.userData}
                  />
                ))
              ) : (
                <NoDataFound></NoDataFound>
              )}
            </Row>
          </>
        )}
      </div>
    </>
  );
}

export default translate(SubscriberActiveSec);
