import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  CHANGE_POST_STATUS_START,
  DELETE_POST_START,
  FETCH_POSTS_START,
  FETCH_SINGLE_POST_START,
  POST_FILE_UPLOAD_START,
  POST_FILE_REMOVE_START,
  SAVE_POST_START,
  PPV_PAYMENT_STRIPE_START,
  PPV_PAYMENT_WALLET_START,
  SAVE_REPORT_POST_START,
  FETCH_REPORT_POSTS_START,
  PPV_PAYMENT_PAYPAL_START,
  PPV_PAYMENT_CCBILL_START,
  FETCH_EXPLORE_START,
  PPV_PAYMENT_COINPAYMENT_START,
  FETCH_POST_CATEGORIES_START,
  FETCH_REPORT_REASON_START,
  POST_FILTER_UPLOAD_START,
  POST_FILE_THUMBNAIL_UPLOAD_START,
  POST_FILE_REMOVE_THUBNAIL_START,
  SAVE_POST_DRAFT_START,
  GET_POST_DRAFT_START,
  SAVE_SINGLE_POST_DRAFT_START,
  DELETE_DRAFT_POST_START,
  SAVE_SCHADUAL_POST_START,
  GET_SCHEDUAL_POST_DRAFT_START,
  DELETE_SCHEDUAL_DRAFT_POST_START,
  EDIT_SCHADUAL_POST_START,
  SAVE_SINGLE_SCHEDUAL_POST_DRAFT_START,
  FETCH_SEARCH_PAGE_CONTENT_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  changePostStatusFailure,
  changePostStatusSuccess,
  deletePostFailure,
  deletePostSuccess,
  fetchPostsFailure,
  fetchPostsSuccess,
  fetchSinglePostFailure,
  fetchSinglePostSuccess,
  postFileUploadFailure,
  postFileUploadSuccess,
  savePostFailure,
  savePostSuccess,
  PPVPaymentStripeFailure,
  PPVPaymentStripeSuccess,
  PPVPaymentWalletSuccess,
  PPVPaymentWalletFailure,
  saveReportPostSuccess,
  saveReportPostFailure,
  fetchReportPostsSuccess,
  fetchReportPostsFailure,
  PPVPaymentPaypalFailure,
  PPVPaymentPaypalSuccess,
  PPVPaymentCCBillSuccess,
  PPVPaymentCCBillFailure,
  PPVPaymentCoinPaymentSuccess,
  PPVPaymentCoinPaymentFailure,
  fetchExploreFailure,
  fetchExploreSuccess,
  postFileRemoveFailure,
  postFileRemoveSuccess,
  fetchPostCategoriesFailure,
  fetchPostCategoriesSuccess,
  fetchReportReasonFailure,
  fetchReportReasonSuccess,
  saveDraftPostSuccess,
  saveDraftPostFailure,
  fetchDraftPostsSuccess,
  fetchDraftPostsFailure,
  saveSingleDraftPostsSuccess,
  saveSingleDraftPostsFailure,
  deleteDraftPostSuccess,
  deleteDraftPostFailure,
  setDraftPostsSuccess,
  saveSchadualTimeSuccess,
  saveSchadualTimeFailure,
  fetchSchedualDraftPostsSuccess,
  fetchSchedualDraftPostsFailure,
  deleteSchedualDraftPostSuccess,
  setSchedualDraftPostsSuccess,
  fetchSearchPageContentSuccess,
  fetchSearchPageContentFailure,
} from "../actions/PostAction";
import { checkLogoutStatus } from "../actions/ErrorAction";
import { ContentState, EditorState } from "draft-js";

function* savePostAPI() {
  try {
    const inputData = yield select((state) => state.post.savePost.inputData);

    if (!inputData.content && !inputData.post_files) {
      // !!!!! Dont change this condition. If changing get confirmation vidhya
      yield put(savePostFailure("Please fill the content"));
      const notificationMessage = getErrorNotificationMessage(
        "Please fill the content"
      );
      yield put(createNotification(notificationMessage));
    } else {
      const response = yield api.postMethod("posts_save_for_owner", inputData);
      if (response.data.success) {
        yield put(savePostSuccess(response.data.data));
        const notificationMessage = getSuccessNotificationMessage(
          response.data.message
        );
        yield put(createNotification(notificationMessage));
        window.location.assign("/post/" + response.data.data.post_unique_id);
      } else {
        yield put(savePostFailure(response.data.error));
        const notificationMessage = getErrorNotificationMessage(
          response.data.error
        );
        yield put(checkLogoutStatus(response.data));
        yield put(createNotification(notificationMessage));
      }
    }
  } catch (error) {
    yield put(savePostFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* saveDraftPostAPI({data}) {
  try {
    const inputData = yield select((state) => state.post.draftSavePost.inputData);

    if (!inputData.content && !inputData.post_files) {
      // !!!!! Dont change this condition. If changing get confirmation vidhya
      yield put(savePostFailure("Please fill the content"));
      const notificationMessage = getErrorNotificationMessage(
        "Please fill the content"
      );
      yield put(createNotification(notificationMessage));
    } else {
      const response = yield api.postMethod("save_post_draft_forowner", data);
      if (response.data.success) {
        yield put(saveDraftPostSuccess(response.data.data));
        const notificationMessage = getSuccessNotificationMessage(
          response.data.message
        );
        yield put(createNotification(notificationMessage));
        window.location.assign("/draft-posts");
      } else {
        yield put(saveDraftPostFailure(response.data.error));
        const notificationMessage = getErrorNotificationMessage(
          response.data.error
        );
        yield put(checkLogoutStatus(response.data));
        yield put(createNotification(notificationMessage));
      }
    }
  } catch (error) {
    yield put(saveDraftPostFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}


function* saveSchadualPostAPI({data}) {
  console.log("🚀 ~ function*saveSchadualPostAPI ~ data:", data)
  try {
    const inputData = yield select((state) => state.post.schadualSavePost.inputData);

    if (!inputData.content && !inputData.post_files && data?.post_id === undefined) {
      // !!!!! Dont change this condition. If changing get confirmation vidhya
      yield put(savePostFailure("Please fill the content"));
      const notificationMessage = getErrorNotificationMessage(
        "Please fill the content"
      );
      yield put(createNotification(notificationMessage));
    } else {
      const response = yield api.postMethod("save_post_schedule_forowner", data);
      if (response.data.success) {
        yield put(saveSchadualTimeSuccess(response.data.data));
        const notificationMessage = getSuccessNotificationMessage(
          response.data.message
        );
        yield put(createNotification(notificationMessage));
        window.location.assign("/save-schedule-posts");
      } else {
        yield put(saveSchadualTimeFailure(response.data.error));
        const notificationMessage = getErrorNotificationMessage(
          response.data.error
        );
        yield put(checkLogoutStatus(response.data));
        yield put(createNotification(notificationMessage));
      }
    }
  } catch (error) {
    yield put(saveSchadualTimeFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchPostsAPI() {
  try {
    const inputData = yield select((state) => state.post.posts.inputData);
    const response = yield api.postMethod("posts_for_owner", inputData);
    if (response.data.success) {
      yield put(fetchPostsSuccess(response.data.data));
    } else {
      yield put(fetchPostsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchPostsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* editSchedualDraftTimeAPI({data}) {  
  try {
    const response = yield api.postMethod("schedule_posts_edit_for_owner", data.schedualData);
    if (response.data.success) {
    
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));

      const filtreData = data.schedualSaveDraftPosts?.posts?.map((ele)=>{
          
        if(ele?.id === response?.data?.data?.id){
          return {
            ...ele,
            schedule_time : response?.data?.data?.schedule_time,
            schedule_note : response?.data?.data?.schedule_note,
          }
        }else {
          return ele
        }
         
      });

      yield put(setSchedualDraftPostsSuccess({posts: filtreData , total : filtreData?.length}));

      data.closeEditModal();

    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
      data.closeEditModal();
    }
  } catch (error) {
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
    data.closeEditModal();
  }
}

function* fetchDraftPostsAPI() {
  try {
    const inputData = yield select((state) => state.post.draftPosts.inputData);
    const response = yield api.postMethod("draft_posts_for_owner", inputData);
    console.log("🚀 ~ function*fetchDraftPostsAPI ~ response:", response)
    if (response.data?.success) {
      yield put(fetchDraftPostsSuccess(response.data?.data));
    } else {
      yield put(fetchDraftPostsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data?.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchDraftPostsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}


function* fetchSchedualDraftPostsAPI() {
  try {
    const inputData = yield select((state) => state.post.schedualSaveDraftPosts.inputData);
    const response = yield api.postMethod("schedule_posts_for_owner", inputData);
    console.log("🚀 ~ function*fetchDraftPostsAPI ~ response:", response)
    if (response.data?.success) {
      yield put(fetchSchedualDraftPostsSuccess(response.data?.data));
    } else {
      yield put(fetchSchedualDraftPostsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data?.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSchedualDraftPostsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSinglePostAPI() {
  try {
    const inputData = yield select((state) => state.post.singlePost.inputData);
    const response = yield api.postMethod("posts_view_for_others", inputData);
    if (response.data.success) {
      yield put(fetchSinglePostSuccess(response.data.data));
    } else {
      yield put(fetchSinglePostFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      window.location.assign("/home");
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSinglePostFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function extractTextFromHTML(html) {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || "";
}

function* fetchSingleDraftPostAPI({data}) {
  try {
    const inputData = yield select((state) => state.post.singleDraftPost.inputData);
    const response = yield api.postMethod("draft_single_posts_for_owner", {dragt_post_id : data.dragt_post_id});
    const user_sub_level_response = yield api.postMethod("list_user_level");
    const user_sub_list_response = yield api.postMethod("user_subscriber_list");
    const user_follower_list_response = yield api.postMethod("active_followers");
    // const response = yield api.postMethod("post_categories_list", inputData);

    
    // console.log("🚀 ~ function*fetchSingleDraftPostAPI ~ user_sub_level_response:", user_sub_level_response)
    // console.log("🚀 ~ function*fetchSingleDraftPostAPI ~ user_sub_list_response:", user_sub_list_response)

    if (response.data.success) {
     
      const resData = response.data?.data?.posts?.find((ele)=> ele)
      console.log("🚀 ~ function*fetchSingleDraftPostAPI ~ resData:", resData)

      if(response.data?.data?.posts.length > 0){
        if(data.setEditorState !== undefined){

          const extractedText = extractTextFromHTML(response.data?.data?.posts[0]?.content);
          const content = ContentState.createFromText(extractedText);
          data.setEditorState(EditorState.createWithContent(content));

          // data.setEditorHtmlContent(response.data?.data?.posts[0]?.content);

        }
         
        data.setFileUploadStatus(true);

          // AccessMain
        if(data.setAccessMainValue !== undefined){
            data.setAccessMainValue(resData?.access_control === 1 ? "public" : resData?.access_control === 2 ? "restrict_user" : "no_one") 
        }
        if(data.setDownloadAccessMainValue !== undefined){
            data.setDownloadAccessMainValue(resData?.download_type === 1 ? "public" : resData?.download_type === 2 ? "restrict_user" : "no_one") 
        }
        if(data.setForwardAccessMainValue !== undefined){
            data.setForwardAccessMainValue(resData?.forward_type === 1 ? "public" : resData?.forward_type === 2 ? "restrict_user" : "no_one") 
        }

        if(data.setCommentAccessMainValue !== undefined){
            data.setCommentAccessMainValue(resData?.comment_type === 1 ? "public" : resData?.comment_type === 2 ? "restrict_user" : "no_one") 
        }
        // Access restirct main

        if(data.setAccessRestrictValue !== undefined){
           
          const accessResData = resData?.ac_restrict_list_ids;
          const accessResData2 = resData?.ac_restrict_follower_ids;

          const bothAreNull = (accessResData === "null" || accessResData === null) && (accessResData2 === "null" || accessResData2 === null);
          const anyIsNotNull = (accessResData !== "null" && accessResData !== null) || (accessResData2 !== "null" && accessResData2 !== null);

           data.setAccessRestrictValue({
            follower : resData?.ac_follower_only === 0 ? false : true,
            following : resData?.ac_follwing_only === 0 ? false : true,
            subscriber_only : resData?.ac_subscriber_only === 0 ? false : true,
            // subscriber_level : resData?.ac_restrict_level_ids == null || "null" ? false : true,
            subscriber_level :  resData?.ac_restrict_level_ids == null ? false : resData?.ac_restrict_level_ids == "null" ? false : true,
            // subscriber_list : resData?.ac_restrict_list_ids === null ? false : true
            subscriber_list : anyIsNotNull ? true : bothAreNull  ? false : undefined
          })
        }

        if(data.setDownloadRestrictValue !== undefined){
         
          const downloadData = resData?.download_restrict_list_ids;
          const downloadData2 = resData?.download_restrict_follower_ids;

          const bothAreNull = (downloadData === "null" || downloadData === null) && (downloadData2 === "null" || downloadData2 === null);
          const anyIsNotNull = (downloadData !== "null" && downloadData !== null) || (downloadData2 !== "null" && downloadData2 !== null);

           data.setDownloadRestrictValue({
            follower : resData?.download_follower_only === 0 ? false : true,
            following : resData?.download_following_only === 0 ? false : true,
            subscriber_only : resData?.download_subscriber_only === 0 ? false : true,
            subscriber_level : resData?.download_restrict_level_ids == null ? false : resData?.download_restrict_level_ids == "null" ? false : true,
            // subscriber_list : resData?.download_restrict_list_ids === null ? false : true
            subscriber_list : anyIsNotNull ? true : bothAreNull  ? false : undefined
           });
        }

        if(data.setForwardRestrictValue !== undefined){

          const forwardResData = resData?.forward_restrict_list_ids;
          const forwardResData2 = resData?.forward_restrict_follower_ids;

          const bothAreNull = (forwardResData === "null" || forwardResData === null) && (forwardResData2 === "null" || forwardResData2 === null);
          const anyIsNotNull = (forwardResData !== "null" && forwardResData !== null) || (forwardResData2 !== "null" && forwardResData2 !== null);
           
           data.setForwardRestrictValue({
            follower : resData?.forward_follower_only === 0 ? false : true,
            following : resData?.forward_following_only === 0 ? false : true,
            subscriber_only : resData?.forward_subscriber_only === 0 ? false : true,
            // subscriber_level : resData?.forward_restrict_level_ids == null || "null" ? false : true,
            subscriber_level : resData?.forward_restrict_level_ids == null ? false : resData?.forward_restrict_level_ids == "null" ? false : true,
            subscriber_list : anyIsNotNull ? true : bothAreNull  ? false : undefined
           })
        }

        if(data.setCommentRestrictValue !== undefined){
           
          const commentResData = resData?.comment_restrict_list_ids;
          const commentResData2 = resData?.comment_restrict_follower_ids;

          const bothAreNull = (commentResData === "null" || commentResData === null) && (commentResData2 === "null" || commentResData2 === null);
          const anyIsNotNull = (commentResData !== "null" && commentResData !== null) || (commentResData2 !== "null" && commentResData2 !== null);

           data.setCommentRestrictValue({
            follower : resData?.comment_follower_only === 0 ? false : true,
            following : resData?.comment_following_only === 0 ? false : true,

            subscriber_only : resData?.comment_subscriber_only === 0 ? false : true,
            // subscriber_level : resData?.comment_restrict_level_ids == null || "null" ? false : true,
            subscriber_level : resData?.comment_restrict_level_ids == null ? false : resData?.comment_restrict_level_ids == "null" ? false : true,
            // subscriber_list : resData?.comment_restrict_list_ids == null || "null"  || resData?.comment_restrict_follower_ids !== null ? true : false
            subscriber_list :  anyIsNotNull ? true : bothAreNull  ? false : undefined
           })
        }

        // Restrict user level

        const userLevelData = user_sub_level_response?.data?.data?.data;
        const userListData = user_sub_list_response?.data?.data;
        const userFollowerData = user_follower_list_response?.data?.data?.followers;
        console.log("🚀 ~ function*fetchSingleDraftPostAPI ~ userFollowerData:", userFollowerData)
        console.log("🚀 ~ function*fetchSingleDraftPostAPI ~ userListData:", userListData)
   
        const ac_convert_level_id = resData?.ac_restrict_level_ids === null ? [] : resData?.ac_restrict_level_ids === "null" ? [] :   resData?.ac_restrict_level_ids.split(",");
        const ac_convert_list_id = resData?.ac_restrict_list_ids === null ? [] : resData?.ac_restrict_list_ids === "null" ? [] :  resData?.ac_restrict_list_ids.split(",");
        const ac_convert_follower_id = resData?.ac_restrict_follower_ids === null ? [] : resData?.ac_restrict_follower_ids === "null" ? [] :  resData?.ac_restrict_follower_ids.split(",");
        // console.log("🚀 ~ function*fetchSingleDraftPostAPI ~ ac_convert_list_id:", ac_convert_list_id)
        // console.log("🚀 ~ function*fetchSingleDraftPostAPI ~ ac_convert_level_id:", ac_convert_level_id)
        const download_convert_level_id = resData?.download_restrict_level_ids === "null" ? [] :  resData?.download_restrict_level_ids === null ? [] :  resData?.download_restrict_level_ids.split(",");
        const download_convert_list_id = resData?.download_restrict_list_ids === "null" ? [] : resData?.download_restrict_list_ids === null ? [] :   resData?.download_restrict_list_ids.split(",");
        const download_convert_follower_id = resData?.download_restrict_follower_ids === "null" ? [] : resData?.download_restrict_follower_ids === null ? [] :   resData?.download_restrict_follower_ids.split(",");
        const forward_convert_level_id = resData?.forward_restrict_level_ids === "null" ? [] : resData?.forward_restrict_level_ids === null ? [] :  resData?.forward_restrict_level_ids.split(",");
        const forward_convert_list_id = resData?.forward_restrict_list_ids === "null" ? [] : resData?.forward_restrict_list_ids === null ? [] :  resData?.forward_restrict_list_ids.split(",");
        const forward_convert_follower_id = resData?.forward_restrict_follower_ids === "null" ? [] : resData?.forward_restrict_follower_ids === null ? [] :  resData?.forward_restrict_follower_ids.split(",");
        const comment_convert_level_id = resData?.comment_restrict_level_ids === "null" ? [] : resData?.comment_restrict_level_ids === null ? [] :  resData?.comment_restrict_level_ids.split(",");
        const comment_convert_list_id = resData?.comment_restrict_list_ids === "null" ? [] : resData?.comment_restrict_list_ids === null ? [] :  resData?.comment_restrict_list_ids.split(",");
        const comment_convert_follower_id = resData?.comment_restrict_follower_ids === "null" ? [] : resData?.comment_restrict_follower_ids === null ? [] :  resData?.comment_restrict_follower_ids.split(",");
         
        //  1. for access control

        const filterAccessLevels = userLevelData?.filter((mainEle)=>{
          return ac_convert_level_id?.find((ele)=> ele?.toString() === mainEle.id?.toString());
         })
         
        const filtredLevelAccessData = filterAccessLevels?.map((ele) => {
           return {value : ele?.alias_of_level, label : ele?.alias_of_level , id : ele?.id} 
         });

        const filterAccessLists = userListData?.filter((mainEle)=>{
          return ac_convert_list_id?.find((ele)=> ele?.toString() === mainEle.from_user_id?.toString());
         });

         const filtredListAccessData = filterAccessLists?.map((ele) => {
           return {value : ele?.from_user_unique_id , label : ele?.from_user_unique_id, id : ele?.from_user_id }
           
         })


         const filterAccessFollower = userFollowerData?.filter((mainEle)=> {
          return ac_convert_follower_id?.find((ele)=> ele?.toString() === mainEle?.follower_id?.toString());
         });

         const filterAccessFollowerData = filterAccessFollower?.map((ele)=> {
           return {value : ele?.username , label : ele?.username, id : ele?.follower_id }
         })
         

         if(data.setAccessControlMainSelected !== undefined){

           data.setAccessControlMainSelected({
             selectedSubLevel : filtredLevelAccessData?.length && filtredLevelAccessData !== undefined ?  filtredLevelAccessData : null,
             selectedSubList : (filtredListAccessData?.length && filtredListAccessData !== undefined) || (filterAccessFollowerData?.length && filterAccessFollowerData !== undefined) ?  [...filtredListAccessData , ...filterAccessFollowerData ] : null,
           })
           
         }


         // 2. for download

         const filterdownloadLevels = userLevelData?.filter((mainEle)=>{
          return download_convert_level_id?.find((ele)=> ele?.toString() === mainEle.id?.toString());
         })
         
        const filtredLevelDownloadData = filterdownloadLevels?.map((ele) => {
           return {value : ele?.alias_of_level, label : ele?.alias_of_level , id : ele?.id} 
         });

        const filterDownloadLists = userListData?.filter((mainEle)=>{
          return download_convert_list_id?.find((ele)=> ele?.toString() === mainEle.from_user_id?.toString());
         });

         const filtredListDownloadData = filterDownloadLists?.map((ele) => {
           return {value : ele?.from_user_unique_id , label : ele?.from_user_unique_id, id : ele?.from_user_id }
           
          })

          const filterDownloadFollower = userFollowerData?.filter((mainEle)=> {
            return download_convert_follower_id?.find((ele)=> ele?.toString() === mainEle?.follower_id?.toString());
           });
  
           const filterDownloadFollowerData = filterDownloadFollower?.map((ele)=> {
             return {value : ele?.username , label : ele?.username, id : ele?.follower_id }
           })

         

         if(data.setDownloadMainSelected !== undefined){

           data.setDownloadMainSelected({
             selectedSubLevel : filtredLevelDownloadData?.length && filtredLevelDownloadData !== undefined ?  filtredLevelDownloadData : null,
             selectedSubList : (filtredListDownloadData?.length && filtredListDownloadData !== undefined) || (filterDownloadFollowerData?.length && filterDownloadFollowerData !== undefined) ?  [...filtredListDownloadData , ...filterDownloadFollowerData ]: null,
           })
           
         }




          // 3. for forward

          const filterForwardLevels = userLevelData?.filter((mainEle)=>{
            return forward_convert_level_id?.find((ele)=> ele?.toString() === mainEle.id?.toString());
           })
           
          const filtredLevelForwardData = filterForwardLevels?.map((ele) => {
             return {value : ele?.alias_of_level, label : ele?.alias_of_level , id : ele?.id} 
           });
  
          const filterForwardLists = userListData?.filter((mainEle)=>{
            return forward_convert_list_id?.find((ele)=> ele?.toString() === mainEle.from_user_id?.toString());
          });
  
           const filtredListForwardData = filterForwardLists?.map((ele) => {
             return {value : ele?.from_user_unique_id , label : ele?.from_user_unique_id, id : ele?.from_user_id }
             
            })

            const filterForwardFollower = userFollowerData?.filter((mainEle)=> {
              return forward_convert_follower_id?.find((ele)=> ele?.toString() === mainEle?.follower_id?.toString());
             });
    
             const filterForwardFollowerData = filterForwardFollower?.map((ele)=> {
               return {value : ele?.username , label : ele?.username, id : ele?.follower_id }
             })

           
           if(data.setForwardMainSelected !== undefined){
 
             data.setForwardMainSelected({
               selectedSubLevel : filtredLevelForwardData?.length && filtredLevelForwardData !== undefined ?  filtredLevelForwardData : null,
               selectedSubList : (filtredListForwardData?.length && filtredListForwardData !== undefined) || (filterForwardFollowerData?.length && filterForwardFollowerData !== undefined) ? [...filtredListForwardData ,  ...filterForwardFollowerData] : null,
             })
             
           }


         // 4. for comment

         const filterCommentLevels = userLevelData?.filter((mainEle)=>{
          return comment_convert_level_id?.find((ele)=> ele?.toString() === mainEle.id?.toString());
         })
         
        const filtredLevelCommentData = filterCommentLevels?.map((ele) => {
           return {value : ele?.alias_of_level, label : ele?.alias_of_level , id : ele?.id} 
         });

        const filterCommentLists = userListData?.filter((mainEle)=>{
          return comment_convert_list_id?.find((ele)=> ele?.toString() === mainEle.from_user_id?.toString());
         });

         const filtredListCommentData = filterCommentLists?.map((ele) => {
           return {value : ele?.from_user_unique_id , label : ele?.from_user_unique_id, id : ele?.from_user_id }
           
         })

         const filterCommentFollower = userFollowerData?.filter((mainEle)=> {
          return comment_convert_follower_id?.find((ele)=> ele?.toString() === mainEle?.follower_id?.toString());
         });

         const filterCommentFollowerData = filterCommentFollower?.map((ele)=> {
           return {value : ele?.username , label : ele?.username, id : ele?.follower_id }
         })

          

          if(data.setCommentMainSelected !== undefined){

            data.setCommentMainSelected({
              selectedSubLevel : filtredLevelCommentData?.length && filtredLevelCommentData !== undefined ?  filtredLevelCommentData : null,
              selectedSubList : (filtredListCommentData?.length && filtredListCommentData !== undefined) || (filterCommentFollowerData?.length && filterCommentFollowerData !== undefined) ?  [...filtredListCommentData, ...filterCommentFollowerData ] : null,
            })
            
          }

          

          
        // Multi checkbox

        if(data.setMultiCheckbox){

          data.setMultiCheckbox({
            tipsCheck : resData?.tips === 1 ? true : false ,
            bookMarkCheck : resData?.bookmark === 1 ? true : false ,
            LikeCheck : resData?.like === 1 ? true : false ,
            reportCheck : resData?.report === 1 ? true : false ,
            commentCheck : resData?.comment === 1 ? true : false ,
          })
          
        }

      //  for categories
        if(data.setIsCategoriesSelected){

          data.setIsCategoriesSelected(resData?.postCategoryDetails);
            
        }

        // for img post
          
         if(resData?.postFiles?.find((ele)=> ele.file_type === "image")){
            
          const filtredPostImgD = resData?.postFiles?.map((ele)=> {
            return {
              file : ele?.post_file,
              post_file_id : ele?.post_file_id.toString(),
              duration : 0
            }
          })
          
          data.setDisableVideo(true);
          data.setDisableAudio(true);
          data.setPreviewImage(true);
          data.setPostFileData([...data.postFileData , ...filtredPostImgD])
          
          
          console.log("🚀 ~ filtredPostImgD ~ filtredPostImgD:", filtredPostImgD)
          // file,
          // post_file_id: postFileIds[index].trim(),
          // duration: 0,

           data.setGetAllId(resData?.post_file_id);
          
         }

         if(resData?.postFiles?.find((ele)=> ele.file_type === "video")){
      
          const filtredPostVideoD = resData?.postFiles?.map((ele)=> {

            
            return {
              file : ele?.post_file,
              post_file_id : ele?.post_file_id.toString(),
              duration : 0,
              durationsArray : [6]
            }
          })
         
          data.setVideoPreviewUrl(true);
          data.setDisableAudio(true);
          data.setDisableImage(true);
          data.setDisableVideo(true);

          

          data.setPostFileData([...data.postFileData , ...filtredPostVideoD])


         }

         if(resData?.postFiles?.find((ele)=> ele.file_type === "audio")){

          const filtredPostAudioD = resData?.postFiles?.map((ele)=> {
            return {
              file : ele?.post_file,
              post_file_id : ele?.post_file_id.toString(),
              duration : 0
            }
          });
          
          data.setAudioThumbnail({
            file: resData?.postFiles?.find((ele)=> ele.file_type === "audio")?.preview_file,
            post_file_id : resData?.post_file_id,
            type : "audioThubmnail"
          });
          data.setAudioThumbnailStatus(true);

          data.setAudioPreviewUrl(true);
          data.setDisableVideo(true);
          data.setDisableImage(true);
         

          data.setPostFileData([...data.postFileData , ...filtredPostAudioD])

          data.setGetAllId(resData?.post_file_id);
          
         }
      

        yield put(saveSingleDraftPostsSuccess(response.data.data));
      }else{
       
        const notificationMessage = getErrorNotificationMessage(
          "No Data Found"
        );
        yield put(createNotification(notificationMessage));

        window.location.assign("/draft-posts");

      }
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(saveSingleDraftPostsFailure(response.data.error));
      window.location.assign("/draft-posts");
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(saveSingleDraftPostsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
    window.location.assign("/draft-posts");
  }
}


function* fetchSingleSchedualTimeDraftPostAPI({data}) {
  try {
    const inputData = yield select((state) => state.post.singleDraftPost.inputData);
    const response = yield api.postMethod("schedule_single_posts_for_owner", {schedule_post_id : data.schedule_post_id});
    const user_sub_level_response = yield api.postMethod("list_user_level");
    const user_sub_list_response = yield api.postMethod("user_subscriber_list");
    const user_follower_list_response = yield api.postMethod("active_followers");
    // const response = yield api.postMethod("post_categories_list", inputData);
    
    console.log("🚀 ~ function*fetchSingleSchedualTimeDraftPostAPI ~ response:", response)
    
    // console.log("🚀 ~ function*fetchSingleDraftPostAPI ~ user_sub_level_response:", user_sub_level_response)
    // console.log("🚀 ~ function*fetchSingleDraftPostAPI ~ user_sub_list_response:", user_sub_list_response)

    if (response.data.success) {
     
      const resData = response.data?.data?.posts?.find((ele)=> ele)
      console.log("🚀 ~ function*fetchSingleDraftPostAPI ~ resData:", resData)

      if(response.data?.data?.posts.length > 0){
        if(data.setEditorState !== undefined){

          const extractedText = extractTextFromHTML(response.data?.data?.posts[0]?.content);
          const content = ContentState.createFromText(extractedText);
          data.setEditorState(EditorState.createWithContent(content));

          // data.setEditorHtmlContent(response.data?.data?.posts[0]?.content);

        }
         
        data.setFileUploadStatus(true);

        // schedual time

        if(data.setStartDate !== undefined){

          data.setStartDate(response.data?.data?.posts[0]?.schedule_time);

        }

        if(data.setDescriptionNoteS !== undefined){
          data.setDescriptionNoteS(response.data?.data?.posts[0]?.schedule_note)
        }

          // AccessMain
        if(data.setAccessMainValue !== undefined){
            data.setAccessMainValue(resData?.access_control === 1 ? "public" : resData?.access_control === 2 ? "restrict_user" : "no_one") 
        }
        if(data.setDownloadAccessMainValue !== undefined){
            data.setDownloadAccessMainValue(resData?.download_type === 1 ? "public" : resData?.download_type === 2 ? "restrict_user" : "no_one") 
        }
        if(data.setForwardAccessMainValue !== undefined){
            data.setForwardAccessMainValue(resData?.forward_type === 1 ? "public" : resData?.forward_type === 2 ? "restrict_user" : "no_one") 
        }

        if(data.setCommentAccessMainValue !== undefined){
            data.setCommentAccessMainValue(resData?.comment_type === 1 ? "public" : resData?.comment_type === 2 ? "restrict_user" : "no_one") 
        }
        // Access restirct main

        if(data.setAccessRestrictValue !== undefined){
           
          const accessResData = resData?.ac_restrict_list_ids;
          const accessResData2 = resData?.ac_restrict_follower_ids;

          const bothAreNull = (accessResData === "null" || accessResData === null) && (accessResData2 === "null" || accessResData2 === null);
          const anyIsNotNull = (accessResData !== "null" && accessResData !== null) || (accessResData2 !== "null" && accessResData2 !== null);

           data.setAccessRestrictValue({
            follower : resData?.ac_follower_only === 0 ? false : true,
            following : resData?.ac_follwing_only === 0 ? false : true,
            subscriber_only : resData?.ac_subscriber_only === 0 ? false : true,
            // subscriber_level : resData?.ac_restrict_level_ids == null || "null" ? false : true,
            subscriber_level :  resData?.ac_restrict_level_ids == null ? false : resData?.ac_restrict_level_ids == "null" ? false : true,
            // subscriber_list : resData?.ac_restrict_list_ids === null ? false : true
            subscriber_list : anyIsNotNull ? true : bothAreNull  ? false : undefined
          })
        }

        if(data.setDownloadRestrictValue !== undefined){
         
          const downloadData = resData?.download_restrict_list_ids;
          const downloadData2 = resData?.download_restrict_follower_ids;

          const bothAreNull = (downloadData === "null" || downloadData === null) && (downloadData2 === "null" || downloadData2 === null);
          const anyIsNotNull = (downloadData !== "null" && downloadData !== null) || (downloadData2 !== "null" && downloadData2 !== null);

           data.setDownloadRestrictValue({
            follower : resData?.download_follower_only === 0 ? false : true,
            following : resData?.download_following_only === 0 ? false : true,
            subscriber_only : resData?.download_subscriber_only === 0 ? false : true,
            subscriber_level : resData?.download_restrict_level_ids == null ? false : resData?.download_restrict_level_ids == "null" ? false : true,
            // subscriber_list : resData?.download_restrict_list_ids === null ? false : true
            subscriber_list : anyIsNotNull ? true : bothAreNull  ? false : undefined
           });
        }

        if(data.setForwardRestrictValue !== undefined){

          const forwardResData = resData?.forward_restrict_list_ids;
          const forwardResData2 = resData?.forward_restrict_follower_ids;

          const bothAreNull = (forwardResData === "null" || forwardResData === null) && (forwardResData2 === "null" || forwardResData2 === null);
          const anyIsNotNull = (forwardResData !== "null" && forwardResData !== null) || (forwardResData2 !== "null" && forwardResData2 !== null);
           
           data.setForwardRestrictValue({
            follower : resData?.forward_follower_only === 0 ? false : true,
            following : resData?.forward_following_only === 0 ? false : true,
            subscriber_only : resData?.forward_subscriber_only === 0 ? false : true,
            // subscriber_level : resData?.forward_restrict_level_ids == null || "null" ? false : true,
            subscriber_level : resData?.forward_restrict_level_ids == null ? false : resData?.forward_restrict_level_ids == "null" ? false : true,
            subscriber_list : anyIsNotNull ? true : bothAreNull  ? false : undefined
           })
        }

        if(data.setCommentRestrictValue !== undefined){
           
          const commentResData = resData?.comment_restrict_list_ids;
          const commentResData2 = resData?.comment_restrict_follower_ids;

          const bothAreNull = (commentResData === "null" || commentResData === null) && (commentResData2 === "null" || commentResData2 === null);
          const anyIsNotNull = (commentResData !== "null" && commentResData !== null) || (commentResData2 !== "null" && commentResData2 !== null);

           data.setCommentRestrictValue({
            follower : resData?.comment_follower_only === 0 ? false : true,
            following : resData?.comment_following_only === 0 ? false : true,

            subscriber_only : resData?.comment_subscriber_only === 0 ? false : true,
            // subscriber_level : resData?.comment_restrict_level_ids == null || "null" ? false : true,
            subscriber_level : resData?.comment_restrict_level_ids == null ? false : resData?.comment_restrict_level_ids == "null" ? false : true,
            // subscriber_list : resData?.comment_restrict_list_ids == null || "null"  || resData?.comment_restrict_follower_ids !== null ? true : false
            subscriber_list :  anyIsNotNull ? true : bothAreNull  ? false : undefined
           })
        }

        // Restrict user level

        const userLevelData = user_sub_level_response?.data?.data?.data;
        const userListData = user_sub_list_response?.data?.data;
        const userFollowerData = user_follower_list_response?.data?.data?.followers;
        console.log("🚀 ~ function*fetchSingleDraftPostAPI ~ userFollowerData:", userFollowerData)
        console.log("🚀 ~ function*fetchSingleDraftPostAPI ~ userListData:", userListData)
   
        const ac_convert_level_id = resData?.ac_restrict_level_ids === null ? [] : resData?.ac_restrict_level_ids === "null" ? [] :   resData?.ac_restrict_level_ids.split(",");
        const ac_convert_list_id = resData?.ac_restrict_list_ids === null ? [] : resData?.ac_restrict_list_ids === "null" ? [] :  resData?.ac_restrict_list_ids.split(",");
        const ac_convert_follower_id = resData?.ac_restrict_follower_ids === null ? [] : resData?.ac_restrict_follower_ids === "null" ? [] :  resData?.ac_restrict_follower_ids.split(",");
        // console.log("🚀 ~ function*fetchSingleDraftPostAPI ~ ac_convert_list_id:", ac_convert_list_id)
        // console.log("🚀 ~ function*fetchSingleDraftPostAPI ~ ac_convert_level_id:", ac_convert_level_id)
        const download_convert_level_id = resData?.download_restrict_level_ids === "null" ? [] :  resData?.download_restrict_level_ids === null ? [] :  resData?.download_restrict_level_ids.split(",");
        const download_convert_list_id = resData?.download_restrict_list_ids === "null" ? [] : resData?.download_restrict_list_ids === null ? [] :   resData?.download_restrict_list_ids.split(",");
        const download_convert_follower_id = resData?.download_restrict_follower_ids === "null" ? [] : resData?.download_restrict_follower_ids === null ? [] :   resData?.download_restrict_follower_ids.split(",");
        const forward_convert_level_id = resData?.forward_restrict_level_ids === "null" ? [] : resData?.forward_restrict_level_ids === null ? [] :  resData?.forward_restrict_level_ids.split(",");
        const forward_convert_list_id = resData?.forward_restrict_list_ids === "null" ? [] : resData?.forward_restrict_list_ids === null ? [] :  resData?.forward_restrict_list_ids.split(",");
        const forward_convert_follower_id = resData?.forward_restrict_follower_ids === "null" ? [] : resData?.forward_restrict_follower_ids === null ? [] :  resData?.forward_restrict_follower_ids.split(",");
        const comment_convert_level_id = resData?.comment_restrict_level_ids === "null" ? [] : resData?.comment_restrict_level_ids === null ? [] :  resData?.comment_restrict_level_ids.split(",");
        const comment_convert_list_id = resData?.comment_restrict_list_ids === "null" ? [] : resData?.comment_restrict_list_ids === null ? [] :  resData?.comment_restrict_list_ids.split(",");
        const comment_convert_follower_id = resData?.comment_restrict_follower_ids === "null" ? [] : resData?.comment_restrict_follower_ids === null ? [] :  resData?.comment_restrict_follower_ids.split(",");
         
        //  1. for access control

        const filterAccessLevels = userLevelData?.filter((mainEle)=>{
          return ac_convert_level_id?.find((ele)=> ele?.toString() === mainEle.id?.toString());
         })
         
        const filtredLevelAccessData = filterAccessLevels?.map((ele) => {
           return {value : ele?.alias_of_level, label : ele?.alias_of_level , id : ele?.id} 
         });

        const filterAccessLists = userListData?.filter((mainEle)=>{
          return ac_convert_list_id?.find((ele)=> ele?.toString() === mainEle.from_user_id?.toString());
         });

         const filtredListAccessData = filterAccessLists?.map((ele) => {
           return {value : ele?.from_user_unique_id , label : ele?.from_user_unique_id, id : ele?.from_user_id }
           
         })


         const filterAccessFollower = userFollowerData?.filter((mainEle)=> {
          return ac_convert_follower_id?.find((ele)=> ele?.toString() === mainEle?.follower_id?.toString());
         });

         const filterAccessFollowerData = filterAccessFollower?.map((ele)=> {
           return {value : ele?.username , label : ele?.username, id : ele?.follower_id }
         })
         

         if(data.setAccessControlMainSelected !== undefined){

           data.setAccessControlMainSelected({
             selectedSubLevel : filtredLevelAccessData?.length && filtredLevelAccessData !== undefined ?  filtredLevelAccessData : null,
             selectedSubList : (filtredListAccessData?.length && filtredListAccessData !== undefined) || (filterAccessFollowerData?.length && filterAccessFollowerData !== undefined) ?  [...filtredListAccessData , ...filterAccessFollowerData ] : null,
           })
           
         }


         // 2. for download

         const filterdownloadLevels = userLevelData?.filter((mainEle)=>{
          return download_convert_level_id?.find((ele)=> ele?.toString() === mainEle.id?.toString());
         })
         
        const filtredLevelDownloadData = filterdownloadLevels?.map((ele) => {
           return {value : ele?.alias_of_level, label : ele?.alias_of_level , id : ele?.id} 
         });

        const filterDownloadLists = userListData?.filter((mainEle)=>{
          return download_convert_list_id?.find((ele)=> ele?.toString() === mainEle.from_user_id?.toString());
         });

         const filtredListDownloadData = filterDownloadLists?.map((ele) => {
           return {value : ele?.from_user_unique_id , label : ele?.from_user_unique_id, id : ele?.from_user_id }
           
          })

          const filterDownloadFollower = userFollowerData?.filter((mainEle)=> {
            return download_convert_follower_id?.find((ele)=> ele?.toString() === mainEle?.follower_id?.toString());
           });
  
           const filterDownloadFollowerData = filterDownloadFollower?.map((ele)=> {
             return {value : ele?.username , label : ele?.username, id : ele?.follower_id }
           })

         

         if(data.setDownloadMainSelected !== undefined){

           data.setDownloadMainSelected({
             selectedSubLevel : filtredLevelDownloadData?.length && filtredLevelDownloadData !== undefined ?  filtredLevelDownloadData : null,
             selectedSubList : (filtredListDownloadData?.length && filtredListDownloadData !== undefined) || (filterDownloadFollowerData?.length && filterDownloadFollowerData !== undefined) ?  [...filtredListDownloadData , ...filterDownloadFollowerData ]: null,
           })
           
         }




          // 3. for forward

          const filterForwardLevels = userLevelData?.filter((mainEle)=>{
            return forward_convert_level_id?.find((ele)=> ele?.toString() === mainEle.id?.toString());
           })
           
          const filtredLevelForwardData = filterForwardLevels?.map((ele) => {
             return {value : ele?.alias_of_level, label : ele?.alias_of_level , id : ele?.id} 
           });
  
          const filterForwardLists = userListData?.filter((mainEle)=>{
            return forward_convert_list_id?.find((ele)=> ele?.toString() === mainEle.from_user_id?.toString());
          });
  
           const filtredListForwardData = filterForwardLists?.map((ele) => {
             return {value : ele?.from_user_unique_id , label : ele?.from_user_unique_id, id : ele?.from_user_id }
             
            })

            const filterForwardFollower = userFollowerData?.filter((mainEle)=> {
              return forward_convert_follower_id?.find((ele)=> ele?.toString() === mainEle?.follower_id?.toString());
             });
    
             const filterForwardFollowerData = filterForwardFollower?.map((ele)=> {
               return {value : ele?.username , label : ele?.username, id : ele?.follower_id }
             })

           
           if(data.setForwardMainSelected !== undefined){
 
             data.setForwardMainSelected({
               selectedSubLevel : filtredLevelForwardData?.length && filtredLevelForwardData !== undefined ?  filtredLevelForwardData : null,
               selectedSubList : (filtredListForwardData?.length && filtredListForwardData !== undefined) || (filterForwardFollowerData?.length && filterForwardFollowerData !== undefined) ? [...filtredListForwardData ,  ...filterForwardFollowerData] : null,
             })
             
           }


         // 4. for comment

         const filterCommentLevels = userLevelData?.filter((mainEle)=>{
          return comment_convert_level_id?.find((ele)=> ele?.toString() === mainEle.id?.toString());
         })
         
        const filtredLevelCommentData = filterCommentLevels?.map((ele) => {
           return {value : ele?.alias_of_level, label : ele?.alias_of_level , id : ele?.id} 
         });

        const filterCommentLists = userListData?.filter((mainEle)=>{
          return comment_convert_list_id?.find((ele)=> ele?.toString() === mainEle.from_user_id?.toString());
         });

         const filtredListCommentData = filterCommentLists?.map((ele) => {
           return {value : ele?.from_user_unique_id , label : ele?.from_user_unique_id, id : ele?.from_user_id }
           
         })

         const filterCommentFollower = userFollowerData?.filter((mainEle)=> {
          return comment_convert_follower_id?.find((ele)=> ele?.toString() === mainEle?.follower_id?.toString());
         });

         const filterCommentFollowerData = filterCommentFollower?.map((ele)=> {
           return {value : ele?.username , label : ele?.username, id : ele?.follower_id }
         })

          

          if(data.setCommentMainSelected !== undefined){

            data.setCommentMainSelected({
              selectedSubLevel : filtredLevelCommentData?.length && filtredLevelCommentData !== undefined ?  filtredLevelCommentData : null,
              selectedSubList : (filtredListCommentData?.length && filtredListCommentData !== undefined) || (filterCommentFollowerData?.length && filterCommentFollowerData !== undefined) ?  [...filtredListCommentData, ...filterCommentFollowerData ] : null,
            })
            
          }

          

          
        // Multi checkbox

        if(data.setMultiCheckbox){

          data.setMultiCheckbox({
            tipsCheck : resData?.tips === 1 ? true : false ,
            bookMarkCheck : resData?.bookmark === 1 ? true : false ,
            LikeCheck : resData?.like === 1 ? true : false ,
            reportCheck : resData?.report === 1 ? true : false ,
            commentCheck : resData?.comment === 1 ? true : false ,
          })
          
        }

      //  for categories
        if(data.setIsCategoriesSelected){

          data.setIsCategoriesSelected(resData?.postCategoryDetails);
            
        }

        // for img post
          
         if(resData?.postFiles?.find((ele)=> ele.file_type === "image")){
            
          const filtredPostImgD = resData?.postFiles?.map((ele)=> {
            return {
              file : ele?.post_file,
              post_file_id : ele?.post_file_id.toString(),
              duration : 0
            }
          })
          
          data.setDisableVideo(true);
          data.setDisableAudio(true);
          data.setPreviewImage(true);
          data.setPostFileData([...data.postFileData , ...filtredPostImgD])
          
          
          console.log("🚀 ~ filtredPostImgD ~ filtredPostImgD:", filtredPostImgD)
          // file,
          // post_file_id: postFileIds[index].trim(),
          // duration: 0,

           data.setGetAllId(resData?.post_file_id);
          
         }

         if(resData?.postFiles?.find((ele)=> ele.file_type === "video")){
      
          const filtredPostVideoD = resData?.postFiles?.map((ele)=> {

            
            return {
              file : ele?.post_file,
              post_file_id : ele?.post_file_id.toString(),
              duration : 0,
              durationsArray : [6]
            }
          })
         
          data.setVideoPreviewUrl(true);
          data.setDisableAudio(true);
          data.setDisableImage(true);
          data.setDisableVideo(true);

          

          data.setPostFileData([...data.postFileData , ...filtredPostVideoD])


         }

         if(resData?.postFiles?.find((ele)=> ele.file_type === "audio")){

          const filtredPostAudioD = resData?.postFiles?.map((ele)=> {
            return {
              file : ele?.post_file,
              post_file_id : ele?.post_file_id.toString(),
              duration : 0
            }
          });
          
          data.setAudioThumbnail({
            file: resData?.postFiles?.find((ele)=> ele.file_type === "audio")?.preview_file,
            post_file_id : resData?.post_file_id,
            type : "audioThubmnail"
          });
          data.setAudioThumbnailStatus(true);

          data.setAudioPreviewUrl(true);
          data.setDisableVideo(true);
          data.setDisableImage(true);
         

          data.setPostFileData([...data.postFileData , ...filtredPostAudioD])

          data.setGetAllId(resData?.post_file_id);
          
         }
      

        yield put(saveSingleDraftPostsSuccess(response.data.data));
      }else{
       
        const notificationMessage = getErrorNotificationMessage(
          "No Data Found"
        );
        yield put(createNotification(notificationMessage));

        window.location.assign("/draft-posts");

      }
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(saveSingleDraftPostsFailure(response.data.error));
      window.location.assign("/draft-posts");
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(saveSingleDraftPostsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
    window.location.assign("/draft-posts");
  }
}

function* deletePostAPI(action) {
  try {
    const deleteData = action.data?.isFromUser ? {post_id: action.data?.post_id} : action.data 
    // const inputData = yield select((state) => state.post.delPost.inputData);
    const response = yield api.postMethod(
      "posts_delete_for_owner",
      deleteData
    );
    if (response.data.success) {
      yield put(deletePostSuccess(response.data.data));

      if (action.data?.isFromUser) {
        let postData = yield select(state => state.post.posts.data)

        console.log(postData)
        let newPostData = postData.posts.filter((post) => post.post_id !== Number(response.data.data.post_id))

        yield put(fetchPostsSuccess({postData: newPostData, isFromUser: action.data?.isFromUser, totalCount: postData.total - 1}));
        console.log(postData)
      }

      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));

      if (action.data?.isFromUser) {
        return action.data?.handleCloseProfilePostModal();
      }
      // window.location.assign("/profile");
    } else {
      yield put(deletePostFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(deletePostFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}


function* deleteDraftPostAPI(action) {
  try {
    // const inputData = yield select((state) => state.post.delPost.inputData);
    const response = yield api.postMethod(
      "draft_posts_delete_for_owner",
      {post_id :  action.data.post_id }
    );
    if (response.data?.success) {
      yield put(deleteDraftPostSuccess(response.data.data));
      
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      const filtreData = action.data.draftData.data.posts.filter((ele)=>{
        return ele.id.toString() !== response.data?.data?.post_id; 
      });
      
      yield put(setDraftPostsSuccess({posts: filtreData , total : filtreData?.length}));


      yield put(createNotification(notificationMessage));
      // window.location.assign("/profile");
    } else {
      yield put(deleteDraftPostFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(deleteDraftPostFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* deleteSchedualDraftPostAPI(action) {
  try {
    // const inputData = yield select((state) => state.post.delPost.inputData);
    const response = yield api.postMethod(
      "schedule_posts_delete_for_owner",
      {post_id :  action.data.post_id }
    );
    console.log("🚀 ~ function*deleteSchedualDraftPostAPI ~ action:", action)
    if (response.data?.success) {
      yield put(deleteSchedualDraftPostSuccess(response.data?.data));
      
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      
      console.log("🚀 ~ function*deleteSchedualDraftPostAPI ~ response:", response?.data)

      const filtreData = action.data.post_data.posts.filter((ele)=>{
        return ele.id.toString() !== response.data?.data?.post_id; 
      });
      console.log("🚀 ~ filtreData ~ filtreData:", filtreData);
      
      yield put(setSchedualDraftPostsSuccess({posts: filtreData , total : filtreData?.length}));

      yield put(createNotification(notificationMessage));
      // window.location.assign("/profile");
    } else {
      yield put(deleteDraftPostFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(deleteDraftPostFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* changePostStatusAPI() {
  try {
    const inputData = yield select(
      (state) => state.post.changePostStatus.inputData
    );
    const response = yield api.postMethod("posts_status", inputData);
    if (response.data.success) {
      yield put(changePostStatusSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(changePostStatusFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(changePostStatusFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* postFileUploadAPI() {
  try {
    const inputData = yield select((state) => state.post.fileUpload.inputData);
    const response = yield api.postMethod("post_files_upload", inputData?.data);
    if (response.data?.success) {
      
      const fileData = [response?.data?.data]?.flatMap((ele) => {
        const files = Array.isArray(ele.file) ? ele.file : [ele.file];
        const postFileIds = ele.post_file_id.split(",");

        return files?.map((file, index) => ({
          file,
          post_file_id: postFileIds[index].trim(),
          duration: 0,
        }));
       });

      const getPostIdData = [...inputData?.postFileData, ...fileData]?.map(
        (ele) => {
          return ele?.post_file_id;
        }
      );

      inputData.setGetAllFile([...inputData?.getAllFile, response?.data?.data]);
      inputData.setPostFileData([...inputData?.postFileData, ...fileData]);
      inputData.setGetAllId(getPostIdData?.toString());

      yield put(postFileUploadSuccess(response?.data?.data));
    } else {
      yield put(postFileUploadFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(postFileUploadFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* postFileThumbnailUploadApi({ data }) {
  try {
    // const inputData = yield select((state) => state.post.fileUpload.inputData);
    const response = yield api.postMethod("post_files_upload", data?.data);
    if (response.data?.success) {  
      if(data.for === "audio"){
        data.setAudioThumbnail({
          ...data.audioThumbnail,
          // file: response.data?.data?.file[0],
          file: response.data?.data?.preview_file,
          post_file_id : response.data?.data?.post_file_id,
        });
      }
       
      if(data.for === "video"){

        data.setVideoThumbnail({
          ...data.videoThumbnail,
           file : response.data?.data?.preview_file,
           post_file_id :  response.data?.data?.post_file_id
        })

        data.setDurationT({
          durationValue: "",
          videoId: "",
        })

      }


      // yield put(postFileUploadSuccess(response?.data?.data));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(postFileUploadFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* postFilterFileUploadAPI() {
  const inputData = yield select( (state) => state.post.fileFilterUpload.inputData);  
  try {

    inputData.setIsLoading(true);
    
    const payLoadData = {
      image: inputData.filterImageBase64,
      post_id: inputData.postFileData.post_file_id,
    };

    const thumbnailPayLoadData = {
      image: inputData.filterImageBase64,
      post_id: inputData.getAllId,
      upload_type: "tumbnail",
    };
    const response = yield api.postMethod("posts_update_for_owner", inputData.postFileData.forImg ?  payLoadData :   thumbnailPayLoadData);

    if (response.data?.success) {
      inputData.setIsLoading(false);

      const filterImageData = inputData.allPostFileData?.map((ele) => {
        if (ele?.post_file_id == inputData.postFileData.post_file_id) {
          return {
            ...ele,
            file: response.data?.data,
          };
        } else {
          return ele;
        }
      });

      if (inputData.postFileData.type === "audioThubmnail") {
        inputData.setAudioThumbnail({
          ...inputData.postFileData,
          file: response.data?.data,
        });
      }else if(inputData.postFileData.type === "videoThubmnail"){
        inputData.setVideoThumbnail({
          ...inputData.postFileData,
          file: response.data?.data,  
        });
        inputData.setPostFileData([...inputData.allPostFileData]);
      } else {
        inputData.setPostFileData(filterImageData);
      }

      // yield put(postFileUploadSuccess(response?.data?.data));
    } else {
      yield put(postFileUploadFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    inputData.setIsLoading(false);
    yield put(postFileUploadFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* PPVPaymentPaypalAPI() {
  try {
    const paymentInputData = yield select(
      (state) => state.post.ppvPayPal.inputData
    );
    const response = yield api.postMethod(
      "posts_payment_by_paypal",
      paymentInputData
    );
    if (response.data.success) {
      yield put(PPVPaymentPaypalSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign("/post/" + response.data.data.post.post_unique_id);
    } else {
      yield put(PPVPaymentPaypalFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(PPVPaymentPaypalFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* PPVPaymentStripeAPI() {
  try {
    const paymentInputData = yield select(
      (state) => state.post.ppvPayStripe.inputData
    );
    const response = yield api.postMethod(
      "posts_payment_by_stripe",
      paymentInputData
    );
    if (response.data.success) {
      yield put(PPVPaymentStripeSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign("/post/" + response.data.data.post.post_unique_id);
    } else {
      yield put(PPVPaymentStripeFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(PPVPaymentStripeFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* PPVPaymentWalletAPI() {
  try {
    const paymentInputData = yield select(
      (state) => state.post.ppvPayWallet.inputData
    );
    const response = yield api.postMethod(
      "posts_payment_by_wallet",
      paymentInputData
    );

    if (response.data.success) {
      yield put(PPVPaymentWalletSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign("/post/" + response.data.data.post.post_unique_id);
    } else {
      yield put(PPVPaymentWalletFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(PPVPaymentWalletFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchReportPostsAPI() {
  try {
    const response = yield api.postMethod("report_posts");
    if (response.data.success) {
      yield put(fetchReportPostsSuccess(response.data.data));
    } else {
      yield put(fetchReportPostsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchReportPostsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* saveReportPostAPI() {
  try {
    const inputData = yield select(
      (state) => state.post.saveReportPost.inputData
    );
    const response = yield api.postMethod("report_posts_save", inputData);
    if (response.data.success) {
      yield put(saveReportPostSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(saveReportPostFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(saveReportPostFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* PPVPaymentCCBillAPI() {
  try {
    const paymentInputData = yield select(
      (state) => state.post.ppvPayCCBill.inputData
    );
    const response = yield api.postMethod(
      "posts_payment_by_ccbill",
      paymentInputData
    );
    if (response.data.success) {
      yield put(PPVPaymentCCBillSuccess(response.data.data));
      window.location.assign(`${response.data.data.redirect_web_url}`);
    } else {
      yield put(PPVPaymentCCBillFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(PPVPaymentCCBillFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* postFileRemoveAPI() {
  try {
    const inputData = yield select((state) => state.post.fileRemove.inputData);
    const response = yield api.postMethod("post_files_remove", inputData.data);
    if (response.data.success) {
      const filterPostData = inputData?.postFileData?.filter(
        (element, index) => {
          return element?.post_file_id !== response.data?.data?.post_file_id;
        }
      );

      const filterPostIdData = filterPostData?.map((ele) => {
        return ele.post_file_id;
      });
       
      const findPost = inputData.postFileData.find((ele)=>{
        return inputData.durationT.videoId == ele.post_file_id
      });

       
      if(inputData.type === "video"){
        if(!inputData.videoThumbnail.file){
          inputData.setDurationT({
            durationValue : filterPostData[0]?.durationsArray[0],
            videoId : filterPostData[0]?.post_file_id,
          });

        }
        inputData.setVideoThumbnail({
          ...inputData.videoThumbnail,
          post_file_id : filterPostIdData?.toString()
        })        
      }

      if(findPost){
        inputData.setDurationT({
          durationValue : filterPostData[0]?.durationsArray[0],
          videoId : filterPostData[0]?.post_file_id,
        })
      }
          
      inputData.setPostFileData(filterPostData);
      inputData.setGetAllId(filterPostIdData?.toString());

      //  need post_file value from backend currently it set hello by default;
      yield put(
        postFileRemoveSuccess({ ...response.data.data, post_file: ["hello"] })
      );
    } else {
      yield put(postFileRemoveFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(PPVPaymentCCBillFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}


function* postFileRemoveThumbnailAPI({data}) {

  try {
    const response = yield api.postMethod("posts_thumbnail_remove_for_owner",  {post_id :  data.data.id });
    if (response.data.success) {
      if(data.type === "audio"){
        data.setAudioThumbnail({
          file: "",
          post_file_id: "",
          type: "audioThubmnail",
        });

        if(data.audioThumbRef !== undefined){
          data.audioThumbRef.current.value = ""
        }

      }else{
        const durationValue = data.postFileData[0]?.durationsArray[0];
        const videoId = data.postFileData[0]?.post_file_id;

        data.setVideoThumbnail({
          file: "",
          post_file_id: "",
          type: "videoThubmnail",
        });
        if(data.inputFileVideoThumb.current){ 
          data.inputFileVideoThumb.current.value = "";
        }

        data.setDurationT({
          durationValue : durationValue,
          videoId : videoId
         });
      }
    } else {
      yield put(postFileRemoveFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(PPVPaymentCCBillFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchExploreAPI() {
  try {
    const inputData = yield select(
      (state) => state.post.explorePosts.inputData
    );
    const response = yield api.postMethod("explore", inputData);
    if (response.data.success) {
      yield put(fetchExploreSuccess(response.data.data));
    } else {
      yield put(fetchExploreFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchExploreFailure(error));
    yield put(postFileRemoveFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* PPVPaymentCoinPaymentAPI() {
  try {
    const paymentInputData = yield select(
      (state) => state.post.ppvPayCoinPayment.inputData
    );
    const response = yield api.postMethod(
      "posts_payment_by_coinpayment",
      paymentInputData
    );
    if (response.data.success) {
      yield put(PPVPaymentCoinPaymentSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      setTimeout(() => {
        window.location.assign(`${response.data.data.redirect_web_url}`);
      }, 3000);
    } else {
      yield put(PPVPaymentCoinPaymentFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(PPVPaymentCoinPaymentFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}
function* fetchPostCategories() {
  try {
    const inputData = yield select(
      (state) => state.post.postCategories.inputData
    );
    const response = yield api.postMethod("post_categories_list", inputData);
    if (response.data.success) {
      const modifiedData = response.data?.data?.post_categories?.map((ele)=>{
        return{
          name : ele?.name,
          label : ele?.name,
          id : ele?.category_id
        }
      })
      yield put(fetchPostCategoriesSuccess({data : response.data.data, cateData : modifiedData}));
      // yield put(fetchPostCategoriesSuccess(response.data.data));
    } else {
      yield put(fetchPostCategoriesFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchPostCategoriesFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchReportReason() {
  try {
    const response = yield api.postMethod("report_reasons_index");
    if (response.data.success) {
      yield put(fetchReportReasonSuccess(response.data.data));
    } else {
      yield put(fetchReportReasonFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchReportReasonFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSearchPageContentAPI() {
  try {
    const inputData = yield select((state) => state.post.searchPageData.inputData);
    const response = yield api.postMethod("global_users_search", inputData);
    if (response.data.success) {
      yield put(fetchSearchPageContentSuccess(response.data.data));
    } else {
      yield put(fetchSearchPageContentFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSearchPageContentFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}


export default function* pageSaga() {
  yield all([yield takeLatest(SAVE_POST_START, savePostAPI)]);
  yield all([yield takeLatest(FETCH_POSTS_START, fetchPostsAPI)]);
  yield all([yield takeLatest(GET_POST_DRAFT_START, fetchDraftPostsAPI)]);
  yield all([yield takeLatest(GET_SCHEDUAL_POST_DRAFT_START, fetchSchedualDraftPostsAPI)]);
  yield all([yield takeLatest(FETCH_EXPLORE_START, fetchExploreAPI)]);
  yield all([yield takeLatest(FETCH_SINGLE_POST_START, fetchSinglePostAPI)]);
  yield all([yield takeLatest(SAVE_SINGLE_POST_DRAFT_START, fetchSingleDraftPostAPI)]);
  yield all([yield takeLatest(SAVE_SINGLE_SCHEDUAL_POST_DRAFT_START, fetchSingleSchedualTimeDraftPostAPI)]);
  yield all([yield takeLatest(DELETE_POST_START, deletePostAPI)]);
  yield all([yield takeLatest(DELETE_DRAFT_POST_START, deleteDraftPostAPI)]);
  yield all([yield takeLatest(DELETE_SCHEDUAL_DRAFT_POST_START,  deleteSchedualDraftPostAPI)]);
  yield all([yield takeLatest(CHANGE_POST_STATUS_START, changePostStatusAPI)]);
  yield all([yield takeLatest(POST_FILE_UPLOAD_START, postFileUploadAPI)]);
  yield all([
    yield takeLatest(
      POST_FILE_THUMBNAIL_UPLOAD_START,
      postFileThumbnailUploadApi
    ),
  ]);
  yield all([
    yield takeLatest(POST_FILTER_UPLOAD_START, postFilterFileUploadAPI),
  ]);
  yield all([yield takeLatest(POST_FILE_REMOVE_START, postFileRemoveAPI)]);
  yield all([yield takeLatest(POST_FILE_REMOVE_THUBNAIL_START, postFileRemoveThumbnailAPI)]);
  yield all([yield takeLatest(SAVE_POST_DRAFT_START, saveDraftPostAPI)]);
  yield all([yield takeLatest(SAVE_SCHADUAL_POST_START, saveSchadualPostAPI)]);
  yield all([yield takeLatest(EDIT_SCHADUAL_POST_START, editSchedualDraftTimeAPI)]);

  yield all([yield takeLatest(PPV_PAYMENT_STRIPE_START, PPVPaymentStripeAPI)]);
  yield all([yield takeLatest(PPV_PAYMENT_WALLET_START, PPVPaymentWalletAPI)]);
  yield all([yield takeLatest(SAVE_REPORT_POST_START, saveReportPostAPI)]);
  yield all([yield takeLatest(FETCH_REPORT_POSTS_START, fetchPostsAPI)]);
  yield all([yield takeLatest(PPV_PAYMENT_PAYPAL_START, PPVPaymentPaypalAPI)]);
  yield all([yield takeLatest(PPV_PAYMENT_CCBILL_START, PPVPaymentCCBillAPI)]);
  yield all([
    yield takeLatest(PPV_PAYMENT_COINPAYMENT_START, PPVPaymentCoinPaymentAPI),
  ]);
  yield all([
    yield takeLatest(FETCH_POST_CATEGORIES_START, fetchPostCategories),
  ]);
  yield all([yield takeLatest(FETCH_REPORT_REASON_START, fetchReportReason)]);
  yield all([yield takeLatest(FETCH_SEARCH_PAGE_CONTENT_START, fetchSearchPageContentAPI)]);
}
