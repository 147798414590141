import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSchedualDraftPostStart,
  editSchadualTimeStart,
  fetchSchedualDraftPostsStart,
  saveSchadualTimeStart,
} from "../../../store/actions/PostAction";
import { Col, Container, Image, Row, Table } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { t } from "react-multi-lang";
import NoDataFound from "../../NoDataFound/NoDataFound";
import { ContentState, EditorState } from "draft-js";
import PostSchedualDateTModal from "../../Post/CreatePost/PostSchedualDateTModal";
import moment from "moment";

const SchedulePostsIndex = () => {
  const { schedualSaveDraftPosts } = useSelector((state) => state.post);
  const [activeSection, setActiveSection] = useState("all");
  const [take, setTake] = useState(12);
  const [isModalSchedualOpen , setIsModalSchedualOpen ]  = useState(false);
  const [descriptionNote, setDescriptionNote] = useState("");
  const [isStartDateError , setIsStartDateError] = useState(false);
  const [isSchedualData, setIsSchedualData ] = useState(null);
  // console.log("🚀 ~ SchedulePostsIndex ~ isSchedualData:", isSchedualData)
  const [selectedDate , setSelectedDate] = useState(isSchedualData?.schedule_time || null);
  // console.log("🚀 ~ SchedulePostsIndex ~ selectedDate:", selectedDate)

  const dispatch = useDispatch();
  const history = useHistory();




  const handleScheduleModalOpen = ()=>{
    setIsModalSchedualOpen(true);
  }

  const handleScheduleModalClose = ()=>{
    setIsModalSchedualOpen(false);
    setSelectedDate(null);
    setDescriptionNote("");
  }


  const handleChangeNote = (e) => {
    setDescriptionNote(e.target.value);
  }


  const handleSubmitSchadual = () => {
 
    dispatch(
      editSchadualTimeStart({ 
        schedualData : { 
          post_id : isSchedualData?.id,
          schedule_time : moment(selectedDate || isSchedualData?.schedule_time).format('YYYY-MM-DD HH:mm:ss'),
          schedule_note : descriptionNote || isSchedualData?.schedule_note,
        },
       closeEditModal : handleScheduleModalClose,
       schedualSaveDraftPosts : schedualSaveDraftPosts?.data
    }));
     
  }

  useEffect(() => {
    dispatch(
      fetchSchedualDraftPostsStart({ type: activeSection, skip: 0, take: take })
    );
  }, []);

  return (
    <>
      {/* <div className='schedual-main-posts-container'>

           
       </div> */}

      <div className="lists">
        <Container>
          <Row>
            <Col sm={12} md={12} xs={12}>
              <div className="profile-post-area">
                <div className="bookmarkes-list bookmarks-right-side">
                  <div className="pull-left">
                    <h3>
                      <Link
                        className="bookmarkes-list"
                        to={"/home"}
                        onClick={() => history.goBack()}
                      >
                        <Image
                          src={
                            window.location.origin +
                            "/assets/images/icons/back.svg"
                          }
                          className="svg-clone"
                        />
                        Schedual Posts
                      </Link>
                    </h3>
                  </div>
                </div>
              </div>

              <div className="payment-tabs-content">
                {schedualSaveDraftPosts?.loading ? (
                  t("loading")
                ) : schedualSaveDraftPosts?.data?.posts?.length > 0 ? (
                  <Table borderedless responsive>
                    <thead>
                      <tr className="bg-white">
                        <th>
                          <h4>Title</h4>
                        </th>
                        <th>
                          <h4>Post</h4>
                        </th>
                        <th>
                          <h4>Date & Time</h4>
                        </th>
                        <th>
                          <h4>Actions</h4>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {schedualSaveDraftPosts?.data?.posts?.map(
                        (post, index) => {
                          const parser = new DOMParser();
                          const doc = parser.parseFromString(
                            post?.content,
                            "text/html"
                          ); // Parse as HTML
                          const extractedText = doc.querySelector("p").textContent;

                          return (
                            <tr key={post?.id}>
                              <td>
                                {" "}
                                <h5>{extractedText}</h5>
                              </td>
                              <td>
                                {post?.postFiles[0]?.file_type === "image" ? (
                                  <img
                                    style={{ height: "50px", width: "50px" }}
                                    src={post?.postFiles[0]?.post_file}
                                    alt={post?.postFiles[0]?.file_type || ""}
                                  />
                                ) : post?.postFiles[0]?.file_type ===
                                  "video" ? (
                                  <svg
                                    style={{
                                      color: "#6661e7",
                                      height: "50px",
                                      width: "50px",
                                    }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-file-earmark-play"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M6 6.883v4.234a.5.5 0 0 0 .757.429l3.528-2.117a.5.5 0 0 0 0-.858L6.757 6.454a.5.5 0 0 0-.757.43z" />
                                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                                  </svg>
                                ) : (
                                  // <svg
                                  //   style={{
                                  //     color: "#6661e7",
                                  //     height: "50px",
                                  //     width: "50px",
                                  //   }}
                                  //   xmlns="http://www.w3.org/2000/svg"
                                  //   width="16"
                                  //   height="16"
                                  //   fill="currentColor"
                                  //   class="bi bi-file-play"
                                  //   viewBox="0 0 16 16"
                                  // >
                                  //   <path d="M6 10.117V5.883a.5.5 0 0 1 .757-.429l3.528 2.117a.5.5 0 0 1 0 .858l-3.528 2.117a.5.5 0 0 1-.757-.43z" />
                                  //   <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
                                  // </svg>
                                  <svg
                                    style={{
                                      color: "#6661e7",
                                      height: "50px",
                                      width: "50px",
                                    }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-file-earmark-music"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M11 6.64a1 1 0 0 0-1.243-.97l-1 .25A1 1 0 0 0 8 6.89v4.306A2.6 2.6 0 0 0 7 11c-.5 0-.974.134-1.338.377-.36.24-.662.628-.662 1.123s.301.883.662 1.123c.364.243.839.377 1.338.377s.974-.134 1.338-.377c.36-.24.662-.628.662-1.123V8.89l2-.5z" />
                                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                                  </svg>

                                  // <svg
                                  //   style={{
                                  //     color: "#6661e7",
                                  //     height: "50px",
                                  //     width: "50px",
                                  //   }}
                                  //   xmlns="http://www.w3.org/2000/svg"
                                  //   width="16"
                                  //   height="16"
                                  //   fill="currentColor"
                                  //   class="bi bi-file-music"
                                  //   viewBox="0 0 16 16"
                                  // >
                                  //   <path d="M10.304 3.13a1 1 0 0 1 1.196.98v1.8l-2.5.5v5.09c0 .495-.301.883-.662 1.123C7.974 12.866 7.499 13 7 13s-.974-.134-1.338-.377C5.302 12.383 5 11.995 5 11.5s.301-.883.662-1.123C6.026 10.134 6.501 10 7 10c.356 0 .7.068 1 .196V4.41a1 1 0 0 1 .804-.98z" />
                                  //   <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
                                  // </svg>
                                  
                                )}
                              </td>
                              <td>
                                <h5>{moment(post?.schedule_time).format('MMM D, YYYY HH:mm a')} 

</h5>
                              </td>
                              <td style={{ display: "flex", gap: "16px" }}>
                                <button
                                  style={{
                                    all: "unset",
                                    color: "red",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    dispatch(
                                      deleteSchedualDraftPostStart({
                                        post_id: post?.id,
                                        post_data: schedualSaveDraftPosts?.data,
                                      })
                                    )
                                  }
                                >
                                  <svg
                                    style={{ height: "22px", width: "23px" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-trash"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                  </svg>
                                </button>
                                <button
                                  style={{
                                    all: "unset",
                                    color: "#ff781f",
                                    cursor: "pointer",
                                  }}

                                  onClick={() => {
                                     history.push(`/schedual-draft-post/${post?.id}`);
                                   }}

                                >
                                  <svg
                                    style={{ height: "22px", width: "23px" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-pencil-square"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                    <path
                                      fill-rule="evenodd"
                                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                    />
                                  </svg>
                                </button>
                                <button
                                  style={{
                                    all: "unset",
                                    color: "#6661e7",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    handleScheduleModalOpen()
                                    setIsSchedualData(post);
                                  }}
                                >
                                  <svg
                                    style={{ height: "22px", width: "23px" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-calendar-plus"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7" />
                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                                  </svg>
                                </button>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                ) : (
                  <NoDataFound />
                )}
                
                <PostSchedualDateTModal   isSchedualData={isSchedualData} IsStartDateError={isStartDateError} setIsStartDateError={setIsStartDateError} handleSubmitSchadual={handleSubmitSchadual} descriptionNote={descriptionNote} handleChangeNote={handleChangeNote}  startDate={selectedDate} setStartDate={setSelectedDate} isScheduleModdalOpen={isModalSchedualOpen}  handleCloseModal={handleScheduleModalClose} />

              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SchedulePostsIndex;
