import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { deleteSubscriptionLevelStart } from '../../../store/actions/SubscriptionAction';

const DeleteSubLevelModal = ({isSubDeleteModal, handleDeleteLModalClose, isDeleteId , allsubLevelData}) => {
 
    const dispatch = useDispatch();
    

  return (
    <Modal
    show={isSubDeleteModal}
    onHide={handleDeleteLModalClose}
    backdrop="static"
    keyboard={false}
    centered
    className={`${
      localStorage.getItem("theme") !== "" &&
      localStorage.getItem("theme") !== null &&
      localStorage.getItem("theme") !== undefined &&
      localStorage.getItem("theme") === "dark"
        ? "dark-theme-modal"
        : ""
    }
    `}
  >
    <Modal.Header closeButton>
      <Modal.Title>Delete subscription level</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {/* {t("cancel_subscription_conformation")} */}
     
       <p style={{fontSize : "16px", fontWeight: "500"}}>Are you sure you want to delete?</p>

    </Modal.Body>
    <Modal.Footer>
      <Button
        variant="secondary"
        size="lg"
        onClick={handleDeleteLModalClose}
      >
        {/* {t("close")} */} Back
      </Button>
      <Button
        variant=""
        size="lg"
        className="ftr-btn"
        onClick={()=> { 
            dispatch(deleteSubscriptionLevelStart({level_id : isDeleteId , handleClose : handleDeleteLModalClose , allsubLevelData}))
        }}
        >
         Delete
      </Button>
    </Modal.Footer>
  </Modal>
  )
}

export default DeleteSubLevelModal