import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const DescriptionLModal = ({isDescriptonOpen, handleIsDescriptonClose, isDescriptionData}) => {
  return (
    <Modal
    show={isDescriptonOpen}
    onHide={handleIsDescriptonClose}
    backdrop="static"
    keyboard={false}
    centered
    className={`${
      localStorage.getItem("theme") !== "" &&
      localStorage.getItem("theme") !== null &&
      localStorage.getItem("theme") !== undefined &&
      localStorage.getItem("theme") === "dark"
        ? "dark-theme-modal"
        : ""
    }
    `}
  >
    <Modal.Header closeButton>
      <Modal.Title>Description level</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {/* {t("cancel_subscription_conformation")} */}

     <textarea
 
            name="description_level"
            style={{resize: "none", border: "none", padding: "8px", width: "100%", outline: "none" , fontSize:"12px", height:"100px",}}
            value={isDescriptionData?.description_level ? isDescriptionData?.description_level : ""}
            readOnly
      />

    </Modal.Body>
    <Modal.Footer>
      <Button
        variant="secondary"
        size="lg"
        onClick={handleIsDescriptonClose}
      >
        {/* {t("close")} */} Back
      </Button>
    </Modal.Footer>
  </Modal>
  )
}

export default DescriptionLModal