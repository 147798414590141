import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Col,
  Image,
  Media,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchBlockUsersStart, saveTagUntagUserStart } from "../../../store/actions/UserAction";
import { saveBlockUserStart } from "../../../store/actions/UserAction";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../../helper/NotificationMessage";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import NoDataFound from "../../NoDataFound/NoDataFound";
import { CopyToClipboard } from "react-copy-to-clipboard";
import VerifiedBadgeNoShadow from "../../Handlers/VerifiedBadgeNoShadow";
import { translate, t } from "react-multi-lang";
import FollowingLoader from "../../Loader/FollowingLoader";
import AddUserCustomUserList from "../AddUserList/AddUserCustomUserList";
import { getCheckUserCustomListStart } from "../../../store/actions/CustomListAction";
import { fetchListsDetailsStart } from "../../../store/actions/HomeAction";
import SubscriptionPaymentModal from "../../Model/PaymentModal/SubscriptionPaymentModal";
import { subscriptionPaymentStripeStart } from "../../../store/actions/SubscriptionAction";
import {
  unFollowUserStart,
  unFollowUserSubStart,
} from "../../../store/actions/FollowAction";
import SendTipModal from "../../helper/SendTipModal";
import ReportUserModel from "../ReportModel/ReportUserModel";
import TagUntagUser from "../TagUntagUser/TagUntagUser";

const BlockedActiveSec = (props) => {
  const [isMoveModalOpen, setIsMoveModalOpen] = useState(false);

  const [userId, setUserId] = useState("");
  const [sendTip, setSendTip] = useState(false);

  const [subscriptionData, setSubscriptionData] = useState({
    is_free: 0,
    plan_type: "months",
    amount: 0,
    amount_formatted: 0,
  });

  const [showUnfollow, setShowUnfollow] = useState(false);
  const [subscrptionPayment, setPaymentModal] = useState(false);
  const [reportMode, setReportMode] = useState(false);
  const [tagUntagMode, setTagUntagMode] = useState(false);

  const handleMoveModalClose = () => setIsMoveModalOpen(false);
  const handleMoveModalOpen = () => setIsMoveModalOpen(true);

  const handleUnfollowModalClose = () => setShowUnfollow(false);
  const handleUnfollowModalShow = () => setShowUnfollow(true);
  const closeReportModeModal = () => setReportMode(false);

  const isCheckUserCustom = useSelector(
    (state) => state.customList.getCheckUserCustomList.data
  );

  const dispatch = useDispatch();

  const customListCount = useSelector((state) => state.home.lists.data);

  const handleBlockUser = (event, status, user_id) => {
    event.preventDefault();
    dispatch(
      saveBlockUserStart({
        user_id: user_id,
        is_other_profile: 1,
      })
    );
  };

  const onCopy = (event) => {
    const notificationMessage = getSuccessNotificationMessage(
      t("profile_link_copied")
    );
    dispatch(createNotification(notificationMessage));
  };

  const handleCheckUserList = (event) => {
    event.preventDefault();

    handleMoveModalOpen();
  };

  const handleSetUserId = (e, id) => {
    e.preventDefault();
    setUserId(id);

    dispatch(
      getCheckUserCustomListStart({
        user_id: id,
      })
    );
  };

  const handleReportModel = (event) => {
    event.preventDefault();
    setReportMode(true);
  };

  function handleCheckToast(event) {
    event.preventDefault();
    const notificationMessage = getSuccessNotificationMessage(
      "User is already present in all custom list"
    );
    dispatch(createNotification(notificationMessage));
  }

  const subscriptionPayment = (
    event,
    plan_type,
    amount,
    amount_formatted,
    is_free = 0
  ) => {
    event.preventDefault();
    if (localStorage.getItem("userId")) {
      setSubscriptionData({
        ...subscriptionData,
        is_free: is_free,
        plan_type: plan_type,
        amount: amount,
        amount_formatted: amount_formatted,
      });
      setPaymentModal(true);
    } else {
      const notificationMessage = getErrorNotificationMessage(
        t("login_to_continue")
      );
      props.dispatch(createNotification(notificationMessage));
    }
  };

  const closePaymentModal = () => {
    setPaymentModal(false);
  };

  const handleUnfollow = (event, user_id) => {
    event.preventDefault();
    dispatch(
      unFollowUserStart({
        user_id: user_id,
      })
    );
  };

  const handleUnSubscriber = (event, user_id) => {
    event.preventDefault();
    dispatch(
      unFollowUserSubStart({
        user_id: user_id,
      })
    );
  };

  const closeSendTipModal = () => {
    setSendTip(false);
  };

  

  const handleRemoveTag = (event, colorTag, tagTitle) => {
    event.preventDefault();

    if (props?.pagePath === "blocked") {
      dispatch(saveTagUntagUserStart({
        user_id: userId, 
        untag: true, 
        blockType: props?.pagePath === "blocked" ? true : false,
        tag_colour_code: colorTag ,
        tag_title: tagTitle
      }))
    }
  };

  const handleTagUntagModel = (event) => {
    event.preventDefault();

    setTagUntagMode(true);
  };

  const closeTagUntagModel = () => setTagUntagMode(false);

  const handleUserChecked = (e, id) => {
    const {setUserData, setCheckedUserId,checkedUserId } = props;
    const updatedUserSelected = props?.userData?.map(elem => {
      
      if (elem?.blockeduser?.user_id === id) {
        return {
          ...elem,
          blockeduser: {
            ...elem.blockeduser,
            isSelected: e.target.checked
          }
        }
      } else {
        return elem
      }
    }) 
    
    setTimeout(() => {
      setUserData(updatedUserSelected)
    }, 20)

    if (e.target.checked === true) {
      setTimeout(() => {
        setCheckedUserId((prev) => [...prev, id])
      }, 10)
    }

    if (e.target.checked === false) {
      const filterCheckUserID = checkedUserId?.filter(elem => elem !== id)
      setTimeout(() => {
        setCheckedUserId(filterCheckUserID)
      }, 10)
    }
  }


  return (
    <div
      role="tabpanel"
      className={
        props.activeSec === "active-sec"
          ? "tab-pane fade in active"
          : "tab-pane fade"
      }
      id="active"
    >
      {props.followers.loading ? (
        <FollowingLoader />
      ) : (
        <Row>
          {props.userData && props.userData.length > 0 ? (
            props.userData.map((block_user) => (
              <Col sm={12} md={6} lg={4} xs={12}>
                <div className="follower-lists">
                  <div className="follower-subscription-lists">
                    <div className="follower-subscription-inner">
                      <Link to={`/` + block_user.blockeduser.user_unique_id}>
                        <div className="follower-wrapper">
                        {
                          props?.isSelected 
                            &&
                          <input
                            type="checkbox"
                            className="tick-box"
                            style={{
                              position: "absolute",
                              top: "10px",
                              left: "8px",
                              zIndex: "1"
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault(); 
                            }}
                            checked={block_user?.blockeduser?.isSelected}
                            onChange={(e) => handleUserChecked(e, block_user?.blockeduser?.user_id)}
                          />
                        }
                        <div
                          style={{
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            position: "absolute",
                            top: "10px",
                            right: "1px",
                            borderRadius: "5px",
                            zIndex: "1",
                            color: "#ffffff",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "0 12px",
                          }}  
                        >
                          <p style={{margin: 0, fontSize: "11px"}}>{block_user.blockeduser?.user_account_type_formatted}</p>
                        </div>
                          <Image
                            className="follower-cover"
                            src={block_user.blockeduser.cover}
                          />
                        </div>
                      </Link>
                      <div className="follower-profile-header">
                        <Link to={`/` + block_user.blockeduser.user_unique_id}>
                          <span className="follower-profile-avatar">
                            <Image
                              src={block_user.blockeduser.picture}
                              className=""
                            />
                          </span>
                        </Link>
                        <div className="follower-info">
                          <div className="follower-profile-status">
                            <div className="follower-profile-toggle-dropdown">
                              <Dropdown
                                className="btn dropdown-toggle btn-link"
                                onClick={(e) =>
                                  handleSetUserId(
                                    e,
                                    block_user?.blockeduser?.user_id
                                  )
                                }
                              >
                                <Dropdown.Toggle
                                  className="user-dropdown-dots dropdown-toggle"
                                  type="button"
                                  id="dropdown-basic"
                                >
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/images/icons/vertical-dots-white.svg"
                                    }
                                    className="svg-clone vertical-dots"
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                  {customListCount?.total_Custom_list !==
                                    Number(false) && (
                                    <Media as="li">
                                      <Link
                                        to="#"
                                        onClick={(event) => {
                                          if (isCheckUserCustom.length > 0) {
                                            handleCheckUserList(event);
                                          } else {
                                            handleCheckToast(event);
                                          }
                                        }}
                                      >
                                        Move to custom list
                                      </Link>
                                    </Media>
                                  )}

                                  <Media as="li">
                                    <Link
                                      to="#"
                                      onClick={(event) =>
                                        handleReportModel(event)
                                      }
                                    >
                                      {t("report")}
                                    </Link>
                                  </Media>
                                  {block_user?.tag_info &&
                                  Object.keys(block_user?.tag_info).length >
                                    0 ? (
                                    <Media as="li">
                                      <Link
                                        to="#"
                                        onClick={(event) =>
                                          handleRemoveTag(event, block_user?.tag_info?.colour, block_user?.tag_info?.tag_title)
                                        }
                                      >
                                        {/* {t("report")} */}
                                        Untag
                                      </Link>
                                    </Media>
                                  ) : (
                                    <Media as="li">
                                      <Link
                                        to="#"
                                        onClick={(event) =>
                                          handleTagUntagModel(event)
                                        }
                                      >
                                        {/* {t("report")} */}
                                        Tag
                                      </Link>
                                    </Media>
                                  )}
                                  <CopyToClipboard
                                    text={block_user.blockeduser.share_link}
                                    onCopy={onCopy}
                                  >
                                    <Media as="li">
                                      <Link to="#">
                                        {" "}
                                        {t("copy_link_to_profile")}{" "}
                                      </Link>
                                    </Media>
                                  </CopyToClipboard>

                                  <Media as="li">
                                    <Link
                                      to="#"
                                      onClick={(event) =>
                                        handleBlockUser(
                                          event,
                                          "unblocked",
                                          block_user.blockeduser.user_id
                                        )
                                      }
                                    >
                                      {t("unblock")}
                                    </Link>
                                  </Media>
                                </Dropdown.Menu>
                              </Dropdown>
                              {reportMode && (
                                <ReportUserModel
                                  reportMode={reportMode}
                                  closeReportModeModal={closeReportModeModal}
                                  userId={userId}
                                />
                              )}
                              {tagUntagMode && (
                                <TagUntagUser
                                  tagUntagMode={tagUntagMode}
                                  closeTagUntagModel={closeTagUntagModel}
                                  userId={userId}
                                  blockType={
                                    props?.pagePath === "blocked" ? true : false
                                  }
                                />
                              )}
                              {isMoveModalOpen && (
                                <AddUserCustomUserList
                                  isMoveModalOpen={isMoveModalOpen}
                                  handleMoveModalClose={handleMoveModalClose}
                                  handleMoveModalOpen={handleMoveModalOpen}
                                  isCheckUserCustom={isCheckUserCustom}
                                  user_id={userId}
                                />
                              )}
                            </div>
                          </div>
                          <div className="follower-wrapper-name">
                            <div className="follower-profile-names">
                              {/* <div className="follower-name-row">
                                <Link
                                  to={block_user.blockeduser.user_unique_id}
                                >
                                  <div className="follower-user-name">
                                    {block_user.blockeduser.name}{" "}
                                    {block_user.blockeduser.is_verified_badge ==
                                    1 ? (
                                      <VerifiedBadgeNoShadow />
                                    ) : null}
                                  </div>
                                </Link>
                              </div> */}
                              {/* <div className="follower-name-row">
                                <Link
                                  to={
                                    `/` + block_user.blockeduser.user_unique_id
                                  }
                                  className="g-user-realname__wrapper"
                                >
                                  <div className="follower-user-id">
                                    @{block_user.blockeduser.username}
                                  </div>
                                </Link>
                              </div> */}
                              <div className="follower-name-row">
                                <Link
                                  to={
                                    `/` + block_user.blockeduser.user_unique_id
                                  }
                                  className="g-user-realname__wrapper"
                                >
                                  <div className="follower-user-name">
                                    <span style={{display: "flex", gap: "7px"}}>
                            {block_user.blockeduser.username}

                            {block_user.blockeduser?.is_certified_content_creator ===
                              2 ? (
                                <Image
                                  // style={{ marginBottom: "2px" }}
                                  className="sidebar-verified-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/verified-icon.svg"
                                  }
                                />
                              ) : null}

                            </span>
                                  </div>
                                </Link>
                              </div>
                            </div>

                            <div className="group-follower-btns">
                              <CopyToClipboard
                                text={block_user.blockeduser.share_link}
                                onCopy={onCopy}
                              >
                                <Button
                                  type="button"
                                  className="g-btn m-rounded m-border m-icon m-icon-only m-colored has-tooltip"
                                >
                                  <Image
                                    src="assets/images/icons/share.svg"
                                    className="svg-clone "
                                  />
                                </Button>
                              </CopyToClipboard>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                      {
                       (
                        block_user?.tag_info &&
                        Object.keys(block_user?.tag_info).length > 0
                       ) && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "8px",
                            padding: "0 7px",
                            // margin: "0 12px",
                            background: "rgba(138, 150, 163, 0.08)",
                            borderRadius: "6px",
                          }}
                        >
                          <div
                            style={{
                              width: "15px",
                              height: "15px",
                              borderRadius: "50%",
                              background: block_user?.tag_info?.colour,
                            }}
                          ></div>
                          <p
                            style={{
                              margin: "0",
                            }}
                          >
                            {block_user?.tag_info?.tag_title}
                          </p>
                        </div>
                      )}
                      {block_user?.subscription_level !== "" &&
                        block_user?.subscription_level_badge !== "" && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                              // padding: "0 12px",
                              margin: "6px 0",
                            }}
                          >
                            <p
                              style={{
                                margin: "0",
                                borderRadius: "6px",
                                background: "rgba(138, 150, 163, 0.08)",
                                padding: "0 10px",
                              }}
                            >
                              {block_user?.subscription_level}
                            </p>
                            <Image
                              src={block_user?.subscription_level_badge}
                              // className="svg-clone my-p-icons"
                              // width="12"
                              style={{ width: "20px" }}
                            />
                          </div>
                        )}
                      </div>
                      {block_user?.payment_info?.unsubscribe_btn_status == 0 &&
                      block_user?.payment_info?.subscription_info
                        ?.monthly_amount ? (
                        <div>
                          <div className="user-subscription-btn-sec">
                            <div
                              className="subscription-outline-btn"
                              onClick={(event) =>
                                subscriptionPayment(
                                  event,
                                  "months",
                                  block_user?.payment_info.subscription_info
                                    .monthly_amount,
                                  block_user?.payment_info.subscription_info
                                    .monthly_amount_formatted
                                )
                              }
                              style={{ fontSize: "1.0em" }}
                            >
                              {`Subscribe ${
                                block_user?.payment_info.subscription_info
                                  .monthly_amount_formatted
                              } / ${t("month")}`}
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {subscrptionPayment ? (
                        <SubscriptionPaymentModal
                          paymentsModal={subscrptionPayment}
                          closepaymentsModal={closePaymentModal}
                          name={block_user.blockeduser?.name}
                          user_unique_id={
                            block_user.blockeduser?.user_unique_id
                          }
                          subscriptionData={subscriptionData}
                          pageType={"userCard"}
                        />
                      ) : null}

                      {block_user?.payment_info?.unsubscribe_btn_status ==
                        1 && (
                        <>
                          <div className="user-subscription-btn-sec">
                            <div
                              className="subscription-btn"
                              onClick={() => handleUnfollowModalShow()}
                              style={{ fontSize: "1em", backgroundColor: "#dc3545", border: "none"}}
                            >
                              {t("Subscribing")}
                            </div>
                          </div>

                          <Modal
                            show={showUnfollow}
                            onHide={handleUnfollowModalClose}
                            backdrop="static"
                            keyboard={false}
                            centered
                            className={`${
                              localStorage.getItem("theme") !== "" &&
                              localStorage.getItem("theme") !== null &&
                              localStorage.getItem("theme") !== undefined &&
                              localStorage.getItem("theme") === "dark"
                                ? "dark-theme-modal"
                                : ""
                            }
                          `}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>{t("unsubscribe")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              {t("cancel_subscription_conformation")}
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                size="lg"
                                onClick={handleUnfollowModalClose}
                              >
                                {t("close")}
                              </Button>
                              <Button
                                variant="primary"
                                size="lg"
                                onClick={(event) =>
                                  handleUnSubscriber(
                                    event,
                                    block_user.blockeduser?.user_id
                                  )
                                }
                              >
                                {t("yes")}
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </>
                      )}
                      {block_user?.show_follow === 1 && (
                        <div className="user-subscription-btn-sec">
                          <div
                            className="subscription-btn"
                            style={{ fontSize: "1em" }}
                            onClick={(event) => {
                              if (localStorage.getItem("userId")) {
                                dispatch(
                                  subscriptionPaymentStripeStart({
                                    user_unique_id:
                                      block_user.blockeduser?.user_unique_id,
                                    plan_type: "months",
                                    is_free: 0,
                                    pageType: "userCard",
                                  })
                                );
                              } else {
                                const notificationMessage =
                                  getErrorNotificationMessage(
                                    t("login_to_continue")
                                  );
                                dispatch(
                                  createNotification(notificationMessage)
                                );
                              }
                            }}
                          >
                            {t("subscribe_for_free")}
                          </div>
                        </div>
                      )}
                      {block_user?.show_unfollow === 1 && (
                        <div className="user-subscription-btn-sec">
                          <div
                            className="subscription-btn"
                            style={{fontSize: "1em", backgroundColor: "#17a2b8", border: "none"}}
                            // onClick={() => handleUnfollowModalShow()}
                            onClick={(event) =>
                              handleUnfollow(
                                event,
                                block_user.blockeduser?.user_id
                              )
                            }
                          >
                            {t("following")}
                          </div>
                        </div>
                      )}

                      <div className="lists-button-group post-icons">
                        <Button
                          type="button"
                          className="btn gradient-btn gradientcolor"
                          onClick={(event) =>
                            handleBlockUser(
                              event,
                              "unblocked",
                              block_user.blockeduser.user_id
                            )
                          }
                        >
                          <span className="b-btn-text">{t("unblock")}</span>
                        </Button>
                      </div>

                      {block_user.blockeduser?.is_certified_content_creator ===
                        2 && (
                        <div className="lists-button-group post-icons">
                          <Button
                            type="button"
                            className="btn gradient-btn gradientcolor flexAlignCenter"
                            onClick={() => setSendTip(true)}
                          >
                            <Image
                              src="assets/images/icons/tip.svg"
                              className="svg-clone"
                            />
                            <span className="b-btn-text">{t("tip")}</span>
                          </Button>
                        </div>
                      )}
                      {sendTip && (
                        <SendTipModal
                          sendTip={sendTip}
                          closeSendTipModal={closeSendTipModal}
                          username={block_user.blockeduser?.username}
                          userPicture={block_user.blockeduser?.picture}
                          name={block_user.blockeduser?.usernamee}
                          post_id={null}
                          user_id={block_user.blockeduser?.user_id}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <NoDataFound></NoDataFound>
          )}
        </Row>
      )}
    </div>
  );
};

export default BlockedActiveSec;
