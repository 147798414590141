import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { addPrimaryInfoStart } from "../../store/actions/KycDocumentAction";
import { useHistory} from "react-router-dom"
import {BecomeCertifiedCreatorModal} from "./BecomeCertifiedCreatorModal";

const BecomeCreatorModel = ({ isOpen, handleClose  ,handleWantCreator ,handleMaturePostSend , wantToBeCreator , matureChecked , setMatureChecked , setWantToBeCreator , simpleHandleClose, handleCheckToast}) => {

  const [isButtonEnabled, setIsButtonEnabled] = useState(false)
	const [wantToBeCertifiedCreator, setWantToBeCertifiedCreator] = useState('');

  const isLoading = useSelector((state)=> state.kycDocument.addPrimaryInfo.loading);
  const isUserContentCreator = useSelector((state) => state.users.profile.data.is_content_creator)

  const dispatch = useDispatch();
  const navigate = useHistory()

  console.log(isUserContentCreator, "Lets check the primary info")
  const handleSubmit = (e)=>{
    e.preventDefault();
    if(wantToBeCertifiedCreator === "yes"){
      dispatch(addPrimaryInfoStart({createrInfo : {want_to_creator :  1 , muture_content : wantToBeCertifiedCreator === 'yes' ? 1 : 0 , key : "update_creator_status "}, isFromCreator : true , isRedirectToCertified : true , navigate : navigate, handleClose: simpleHandleClose}))
    }else{
      dispatch(addPrimaryInfoStart({createrInfo : {want_to_creator :  1 , muture_content : wantToBeCertifiedCreator === 'yes' ? 1 : 0 , key : "update_creator_status "}, isFromCreator : true}))
    } 
  }

  const handleCloseModal = (e) => {
    e.preventDefault();

    handleClose(setMatureChecked);
    handleCheckToast(e)
  }

   
  console.log(isOpen, "askdjks")
  
  return (
    <Modal
      show={isOpen}
      onHide={() => handleClose(setMatureChecked)}
      // backdrop="static"
      keyboard={false}  
      centered
      className={`${
        localStorage.getItem("theme") !== "" &&
        localStorage.getItem("theme") !== null &&
        localStorage.getItem("theme") !== undefined &&
        localStorage.getItem("theme") === "dark"
          ? "dark-theme-modal"
          : ""  
      }
    `}
    >
      <div>
        <Modal.Header closeButton>
          <Modal.Title>{isUserContentCreator === 2 ? 'Become a certfied creator' : 'Become a creator'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>  
          {/* <div className="main_comunity_guid_wrapper" dangerouslySetInnerHTML={{__html : configuration.get("configData.guidelines")}} /> */}
          {
            isUserContentCreator === 2
            ?
            <BecomeCertifiedCreatorModal 
              handleWantCreator={handleWantCreator}
              handleMaturePostSend={handleMaturePostSend}
              wantToBeCreator={wantToBeCreator}
              simpleHandleClose={simpleHandleClose}
              matureChecked={matureChecked}
              setIsButtonEnabled={setIsButtonEnabled}
              wantToBeCertifiedCreator={wantToBeCertifiedCreator}
              setWantToBeCertifiedCreator={setWantToBeCertifiedCreator}
            />  
            // <div className="form-group">
            // <div className="want-to-be-certified-wrapper">
            //   <h4>Hey creator?</h4>

                      
            // </div>
            // </div>
            :
            <div className="form-group">
            <div className="want-to-be-certified-wrapper">
              <h4>Want to become a content creator?</h4>

                      
            </div>
            </div>
          }
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            size="lg"
            onClick={()=> handleClose(setMatureChecked , setWantToBeCreator)}
          >
            {/* {t("close")} */} Cancel
          </Button>
          {
            isUserContentCreator == 2 
            ? (
              <Button
                variant=""
                size="lg"
                className="ftr-btn"
                disabled={!isButtonEnabled}
                onClick={(e)=> {
                  return wantToBeCertifiedCreator === 'no' ? handleCloseModal(e) : handleSubmit(e) 
                } }
              >
                { 
                  isLoading ? "Loading...": "Confirm"
                }
              </Button>
            ) : (
              <Button
                variant=""
                size="lg"
                className="ftr-btn"
                disabled={isLoading}
                onClick={(e)=> handleSubmit(e)}
              >
                { 
                  isLoading ? "Loading..." : "Yes"
                }
              </Button>
            )
          }
          
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default BecomeCreatorModel;
