import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { t, translate } from "react-multi-lang";
import AddListModal from "./AddListModal";
import SingleListUserCard from "./SingleListUserCard";
import NoDataFound from "../../NoDataFound/NoDataFound";
import FollowingLoader from "../../Loader/FollowingLoader";
import { allUserListStart, getAllUserCustomListStart, getSingleSearchListUserStart, getUserAllColorTagStart } from "../../../store/actions/CustomListAction";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import UserSearchListIndex from "./UserSearchListIndex";
import { Multiselect } from "multiselect-react-dropdown";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import { getAllFriendStart } from "../../../store/actions/FriendRequestAction";
import { fetchSubscribingStart } from "../../../store/actions/SubscribingAction";
import { fetchActiveFollowersStart, fetchActiveFollowingStart } from "../../../store/actions/FollowAction";
import { fetchBlockUsersStart } from "../../../store/actions/UserAction";
import { fetchSubscribersStart } from "../../../store/actions/SubscriberAction";
import { fetchFormerSubscriberStart } from "../../../store/actions/FormerSubscriberAction";
import { fetchUnsubscribedStart } from "../../../store/actions/UnsubscribedAction";
import { fetchFavStart } from "../../../store/actions/FavAction";

const usersFilters = [
  { name: "All users", id: 1, label: "All users" , payload : "all_users" },
  { name: "All creators", id: 2, label: "All creators" , payload : "all_creator" },
  { name: "Star creator", label: "Star creator", payload: "" },
  { name: "Certified creator", id: 4, label: "Certified creator", payload : "all_certified" },
  { name: "Creator", id: 5, label: "Creator", payload : "creator" },
  { name: "All Non-creator", id: 6, label: "All Non-creator" , payload: "all_non_creator" },
  { name: "Suscribers", id: 7, label: "Suscribers" , payload: "subscriber" },
  { name: "Followers", id: 8, label: "Followers" , payload : "followers" },
  { name: "By color tag", id: 9, label: "By color tag" , payload: "by_color_tag" },
];

const Option = (props) => {
  
return (
  <div className="post-level-list-option-wrpapper">
    <components.Option {...props}>
        <div className="search-user-list-option-label-wrapper" style={{}}>
           <div style={{backgroundColor: `${props.data?.color_tag_code}`, height: "10px", width: "10px", borderRadius: "50%"}}></div>
           <label style={{marginBottom: "0px"}}>{props.label}</label>
        </div>
    </components.Option>
  </div>
);
};


const SinglePostUserData = (props) => {

  const params = useParams();

  const [isEditCustomList, setIsEditCustomList] = useState({});
  const [isAddListModalOpen, setIsAddListModalOpen] = useState(false);
  const [selectedListValues, setSelectedListValues] = useState([]);
  const [sortingToogle, setSortingToogle] = useState({
    aToz : false,
    upToDown : false
  });
  const [searchUserVal, setSearchUserVal] = useState(params.id ? localStorage.getItem("list-username") : "");

  const [isSelectedUserCate, setIsSelectedUserCate] = useState([]);
  const [sortUsers, setSortUsers] = useState("");
  const [selectedColorTags, setSelectedColorTags] = useState(null);
  const { data: colorTagData, loading: colorTagLoader } = useSelector(
    (state) => state.customList.userAllColorTag
  );
  const customList = useSelector(
    (state) => state.customList.allCustomList.data
  );
  const [listItem, setListItem] = useState([]);
  const { allListData } = useSelector((state) => state.customList.addUser);
  const [selectedAccro, setSelectedAccro] = useState("");

  const [selectedCustomId, setSelectedCustomId] = useState(null);
  const [isLoadingList, setIsLoadingList] = useState(false);

  const customeAddUserListData = useSelector(
    (state) => state.customList.addUser
  );


  const navigate = useHistory();


  const {loading : isSingleUserLoading, data : isSingleUserData , error : isSingleUserError} = useSelector((state) => state.customList.singleUserListSerach);
 
  const handleAddListModalClose = () => setIsAddListModalOpen(false);
  const handleAddListModalOpen = () => setIsAddListModalOpen(true);

  const handleSelectedCheckbox = (e) => {
    const { checked, value } = e.target;

    if (checked) {
      setSelectedListValues((prevSelected) => [...prevSelected, value]);
    } else {
      setSelectedListValues((prevSelected) =>
        prevSelected.filter((val) => val !== value)
      );
    }
  };

  useEffect(()=>{
    
      props.dispatch(getSingleSearchListUserStart({user_id : params?.id}));


  },[params?.id])

  useEffect(() => {
    if (selectedAccro === "Friends") {
      props.dispatch(
        getAllFriendStart({
          allListData: allListData,
          typeList: "Friends",
          setIsLoadingList: setIsLoadingList,
        })
      );
    } else if (selectedAccro === "Subscribing") {
      props.dispatch(
        fetchSubscribingStart({
          allListData: allListData,
          typeList: "Subscribing",
          setIsLoadingList: setIsLoadingList,
        })
      );
    } else if (selectedAccro === "Following") {
      props.dispatch(
        fetchActiveFollowingStart({
          allListData: allListData,
          typeList: "Following",
          setIsLoadingList: setIsLoadingList,
        })
      );
    } else if (selectedAccro === "Blocked") {
      props.dispatch(
        fetchBlockUsersStart({
          allListData: allListData,
          typeList: "Blocked",
          setIsLoadingList: setIsLoadingList,
        })
      );
    } else if (selectedAccro === "Restricted") {
      alert("comming soon");
    } else if (selectedAccro === "Subscribers") {
      props.dispatch(
        fetchSubscribersStart({
          allListData: allListData,
          typeList: "Subscribers",
          setIsLoadingList: setIsLoadingList,
        })
      );
    } else if (selectedAccro === "Followers") {
      props.dispatch(
        fetchActiveFollowersStart({
          allListData: allListData,
          typeList: "Followers",
          setIsLoadingList: setIsLoadingList,
        })
      );
    } else if (selectedAccro === "Former Subscriber") {
      props.dispatch(
        fetchFormerSubscriberStart({
          allListData: allListData,
          typeList: "Former Subscriber",
          setIsLoadingList: setIsLoadingList,
        })
      );
    } else if (selectedAccro === "Unsubscribed") {
      props.dispatch(
        fetchUnsubscribedStart({
          allListData: allListData,
          typeList: "Unsubscribed",
          setIsLoadingList: setIsLoadingList,
        })
      );
    } else if (selectedAccro === "Favorites") {
      props.dispatch(
        fetchFavStart({
          allListData: allListData,
          typeList: "Favorites",
          setIsLoadingList: setIsLoadingList,
        })
      );
    } else if (selectedAccro === "custom_list") {
      props.dispatch(
        getAllUserCustomListStart({
          allListData: allListData,
          typeList: "Favorites",
          id: selectedCustomId,
          setIsLoadingList: setIsLoadingList,
        })
      );
    }
  }, [selectedAccro, selectedCustomId]);


  useEffect(() => {
    if (
      isSelectedUserCate.some((ele) => ele.id === 9) &&
      colorTagData.length <= 0
    ) {
      props.dispatch(getUserAllColorTagStart());
    }
  }, [isSelectedUserCate]);

  return (
    <div className="lists">
    <Container>
      <Row>
        <Col sm={12} md={12}>
            <div className="vertical-menu">
              <div className="bookmarkes-list bookmarks-right-side list-header">
                <div className="pull-left">
                  <Link
                    className="bookmarkes-list h2"
                    to={"/list"}
                    // onClick={() => {
                    //   navigate.push("/list");
                    //   // props.dispatch(isListUtilsIsList(true))
                    // }}
                  >
                    <Image
                      src={
                        window.location.origin +
                        "/assets/images/icons/back.svg"
                      }
                      className="svg-clone"
                    />
                    {t("lists")}
                  </Link>
                </div>

                <div className="btn-container">
                  <Button
                    className="list-submit-btn"
                    type="button"
                    onClick={() => {
                      setIsEditCustomList({});
                      handleAddListModalOpen();
                      setSelectedListValues([]);
                      props.dispatch(allUserListStart());
                    }}
                  >
                    Add List
                  </Button>

                  <Button
                    type="button"
                    className="list-submit-btn"
                    onClick={() => navigate.push("/friend-requests")}
                  >
                    Friend Requests
                  </Button>
                </div>
              </div>

              <div className="search-list-con-main">
                <UserSearchListIndex searchUserVal={searchUserVal} setSearchUserVal={setSearchUserVal} sortingToogle={sortingToogle} SelectedUserCate={isSelectedUserCate} sortUser={sortUsers} selectedColorTags={selectedColorTags} />

                <div className="list-filter-search-sort-container">
                  <div className="list-filter-search-container">
                    <Multiselect
                      name="category_ids"
                      id="selectedCategories"
                      selectedValues={isSelectedUserCate}
                      options={usersFilters}
                      displayValue="name"
                      avoidHighlightFirstOption="true"
                      placeholder={t("Filter")}
                      // onSelect={(selectedList)=> {
                      //   debugger;
                      // } }
                      onSelect={(selectedList) => {
                        setIsSelectedUserCate(selectedList)
                        setSearchUserVal("");
                      }}
                      onRemove={(selectedList) => { 
                        console.log("🚀 ~ ListIndex ~ selectedList:", selectedList)
                         const isByColor = selectedList.some((ele)=> ele.id === 9);
                         if(!isByColor){
                          setSelectedColorTags(null);
                         }
                        setIsSelectedUserCate(selectedList);
                      
                      }}
                    />
                    {isSelectedUserCate.some((ele) => ele.id === 9) ? (
                      <div style={{display: "flex", flexDirection: "column"}}>
                      
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        // defaultValue={usersFilters[0]}
                        isDisabled={colorTagLoader}
                        isLoading={colorTagLoader}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={false}
                        components={{ Option , animatedComponents: makeAnimated()}}
                        placeholder="Color tag"
                        name="color"
                        // value={selectedColorTags}
                        options={colorTagData?.map((ele) => {
                          return {
                            name: ele.tag_title,
                            label: ele.tag_title,
                            color_tag_code: ele.tag_colour_code,
                          };
                        })}
                        onChange={(selected) => {
                          // debugger;
                          setSelectedColorTags(selected);
                        }}
                      />
                      {
                        selectedColorTags === null && (
                             <p className="error-serach-user">Please select color tag</p>
                        )
                      }
                       
                   </div>
                    ) : (
                      //  <select name="color-tag" id="color-tag-id" className="color-tag-list-select">
                      //    {
                      //     colorTagLoader ? (
                      //       <option disabled={true} value="">Loading...</option>
                      //     ) : (
                      //       colorTagData?.length ? (
                      //         colorTagData?.map((colorTag, index)=>{
                      //           return(
                      //             <option value={colorTag.tag_title} key={index}>
                      //                <div style={{background: `${colorTag.tag_colour_code}`}} > </div>
                      //                <p>{colorTag.tag_title}</p>
                      //             </option>
                      //           )
                      //         })

                      //       ) : (

                      //         <option value="">No color tags</option>

                      //       )

                      //     )
                      //    }
                      //  </select>

                      ""
                    )}
                  </div>

                  <div className="list-filter-search-sort-container">
                    <select
                      name="color-tag"
                      id="color-tag-id"
                      className="color-tag-list-select"
                      onChange={(e) => { 
                        setSortUsers(e.target.value);
                        setSortingToogle({
                          aToz : false,
                          upToDown : false
                        })
                      }}
                    >
                      <option value="">Sort</option>
                      <option value="username">Name</option>
                      <option value="latest">Latest</option>
                    </select>

                    {sortUsers !== "" ? (
                      sortUsers === "username" ? (
                        <>
                           {
                            !sortingToogle.aToz ? (
                              <img
                              onClick={()=> setSortingToogle({ ...sortingToogle, aToz : !sortingToogle.aToz })}
                              className="list-sort-arrow-up"
                              style={{cursor : "pointer"}}
                              src={
                                window.location.origin +
                                "/assets/images/icons/sort-up.png"
                              }
                              alt="arrow-up"
                            />
                            ) : (
                              <img
                              onClick={()=> setSortingToogle({ ...sortingToogle, aToz : !sortingToogle.aToz })}
                              style={{cursor : "pointer"}}
                              className="list-sort-arrow-down"
                              src={
                                window.location.origin +
                                "/assets/images/icons/sort.png"
                              }
                              alt="arrow-down"
                            />
                            )
                           }
                                         
                        </>
                      ) : (
                        <>
                          {
                            !sortingToogle.upToDown ? (
                              <img
                              onClick={()=> setSortingToogle({ ...sortingToogle, upToDown : !sortingToogle.upToDown })}
                              style={{cursor : "pointer"}}
                              className="same-arrow"
                              src={
                                window.location.origin +
                                "/assets/images/icons/down-arrow.png"
                              }
                              alt="arrow-down"
                            />
                             

                            ) : (
                              <img
                              onClick={()=> setSortingToogle({ ...sortingToogle, upToDown : !sortingToogle.upToDown })}
                              style={{cursor : "pointer"}}
                              className="same-arrow"
                              src={
                                window.location.origin +
                                "/assets/images/icons/up-arrow.png"
                              }
                              alt="arrow-up"
                            />
                            )
                          }
                         
                       
                        </>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <AddListModal
                isEditCustomList={isEditCustomList}
                handleAddListModalClose={handleAddListModalClose}
                handleAddListModalOpen={handleAddListModalOpen}
                isAddListModalOpen={isAddListModalOpen}
                setListItem={setListItem}
                listItem={listItem}
                customList={customList}
                selectedListValues={selectedListValues}
                handleSelectedCheckbox={handleSelectedCheckbox}
                allList={allListData}
                setSelectedAccro={setSelectedAccro}
                setSelectedCustomId={setSelectedCustomId}
                isLoadingList={isLoadingList}
                customeAddUserListData={customeAddUserListData}
              />


            <div className="all-list-user-container-wrapper" style={{marginTop: "30px"}}>

              {
                  
                   
                    !isSingleUserLoading ? (
                      isSingleUserData?.length ? (
                        isSingleUserData?.map((ele)=>{
                          return <SingleListUserCard user={ele} key={ele.user_id}  />
                        })
                      ) : (
                        <NoDataFound />
                      )
                    ) : (
                      <FollowingLoader show={1}/>
                    )
                   
              
              }
           
              </div>         
            </div>
        </Col>
      </Row>
    </Container>
  </div>
  )
}





const mapStateToPros = (state) => ({
  lists: state.home.lists,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(SinglePostUserData));