import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory, useParams, useLocation, Link } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { translate, t } from "react-multi-lang";
import {
  deletePostStart,
  fetchSinglePostStart,
} from "../../store/actions/PostAction";
import {
  fetchCommentRepliesStart,
  fetchCommentsStart,
  fetchMoreCommentRepliesStart,
  fetchMoreCommentsStart,
} from "../../store/actions/CommentsAction";
import { getSuccessNotificationMessage } from "./NotificationMessage";
import { createNotification } from "react-redux-notify";
import { saveBlockUserStart } from "../../store/actions/UserAction";
import { savePostLikeStart } from "../../store/actions/PostLikesAction";
import { saveBookmarkStart } from "../../store/actions/BookmarkAction";
import {
  Dropdown,
  Container,
  Row,
  Col,
  Button,
  Form,
  Image,
  Media,
  Modal,
  InputGroup,
} from "react-bootstrap";
import NewSinglePostSlider from "../NewHome/NewSingleView/NewSinglePostSlider";
import CommonCenterLoader from "../Loader/CommonCenterLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import NewCommentReplies from "./NewCommentReplies";
import NewComments from "./NewComments";
import ReportModeModal from "./ReportModeModal";
import SendTipPaymentModal from "../Model/PaymentModal/SendTipPaymentModal";
import { format } from "timeago.js";
import "../NewHome/NewHome.css";
import "../NewHome/NewSingleView/NewSingleComment.css";
import "./ProfilePostPreviousNext.css";

const ProfilePostPreviousNext = (props) => {
  const params = useParams();
  const history = useHistory();

  const [reportMode, setReportMode] = useState(false);
  const [sendTip, setTipModal] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [lastPostId, setLastPostId] = useState(null);
  const [selectedComment, setSelectedComment] = useState(null);

  const locationD = useLocation();

  const [userData, setUserData] = useState([]);
  const [userProfilePost, setUserProfilePost] = useState({});

  const {
    isOpenProfilePostModal,
    handleCloseProfilePostModal,
    profilePostId,
    otherUserPost,
    profileUserPost,
  } = props;

  const [postUniqueId, setPostUniqueId] = useState(profilePostId);
  const [loaction, setLocation] = useState("");

  const userPostData = useSelector((state) => state.post.posts.data);
  const otherUserData = useSelector((state) => state.otherUser.userPosts.data);

  const postData = otherUserPost ? otherUserData : userPostData;
  console.log(otherUserPost, "Other user profile post");
  useEffect(() => {
    if (props?.userProfile === "userProfile") {
      const filterUserPost = postData?.posts?.find(
        (item) => item.post_unique_id === profilePostId
      );

      setUserProfilePost(filterUserPost);
      setUserData(postData.posts);
    }
  }, [profilePostId]);

  const handleNext = (id) => {
    let findIndex = postData.posts.findIndex(
      (item) => item.post_unique_id === id
    );
    findIndex++;

    if (findIndex !== -1) {
      setPostUniqueId(postData.posts[findIndex].post_unique_id);
      setUserProfilePost(postData.posts[findIndex]);
    }
  };

  const handlePrevious = (id) => {
    let findIndex = postData.posts.findIndex(
      (item) => item.post_unique_id === id
    );
    findIndex--;

    if (findIndex !== -1) {
      setPostUniqueId(postData.posts[findIndex].post_unique_id);
      setUserProfilePost(postData.posts[findIndex]);
    }
  };

  const closeReportModeModal = () => {
    setReportMode(false);
  };

  const closeSendTipModal = () => {
    setTipModal(false);
  };

  useEffect(() => {
    if (
      !skipRender &&
      // !props.singlePost.loading &&
      Object.keys(userProfilePost).length > 0 &&
      lastPostId !== userProfilePost.post_id
    ) {
      props.dispatch(
        fetchCommentsStart({
          post_id: userProfilePost.post_id,
          skip: 0,
          take: 4,
        })
      );
      setLastPostId(userProfilePost.post_id);
    }
  }, [userProfilePost]);

  const fetchMoreComments = () => {
    props.dispatch(
      fetchMoreCommentsStart({
        post_id: userProfilePost.post_id,
        skip: props.comments.data.post_comments.length,
        take: 4,
      })
    );
  };

  const fetchCommentReply = (commentId) => {
    if (commentId != null) {
      props.dispatch(
        fetchCommentRepliesStart({
          // post_id: userProfilePost.post_id,
          post_id: userProfilePost.post_id,
          post_comment_id: commentId,
          skip: 0,
          take: 1,
        })
      );
    }
    setSelectedComment(commentId);
  };

  const fetchMoreCommentReply = (commentId) => {
    props.dispatch(
      fetchMoreCommentRepliesStart({
        post_id: userProfilePost.post_id,
        post_comment_id: commentId,
        skip: props.commentReplies.data.post_comment_replies.length,
        take: 1,
      })
    );
  };

  const onCopy = (event) => {
    const notificationMessage = getSuccessNotificationMessage(
      t("profile_link_copied")
    );
    props.dispatch(createNotification(notificationMessage));
  };

  const handleDeletePost = (event, post) => {
    event.preventDefault();
    props.dispatch(
      deletePostStart({
        post_id: post.post_id,
        isFromUser: true,
        handleCloseProfilePostModal: handleCloseProfilePostModal,
      })
    );
    // props.dispatch(deletePostStart({ post_id: post.post_id}))
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.delPost.loading &&
      Object.keys(props.delPost.data).length > 0
    ) {
      history.push(`/profile`);
    }
  }, [props.delPost]);

  const handleBlockUser = (event, post) => {
    event.preventDefault();
    props.dispatch(saveBlockUserStart({ user_id: post.user_id }));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.saveBlockUser.loading &&
      Object.keys(props.saveBlockUser.data).length > 0
    ) {
      history.push(`/home`);
    }
    setSkipRender(false);
  }, [props.saveBlockUser]);

  const handleLike = (event, post) => {
    event.preventDefault();
    props.dispatch(
      savePostLikeStart({
        post_id: post.post_id,
        isFromUser: true,
        setUserProfilePost: setUserProfilePost,
        profileUserPost: profileUserPost,
        otherUserPost: otherUserPost,
      })
    );
  };

  const handleBookmark = (event, post) => {
    event.preventDefault();
    props.dispatch(
      saveBookmarkStart({
        post_id: post.post_id,
        isFromUser: true,
        setUserProfilePost: setUserProfilePost,
        profileUserPost: profileUserPost,
        otherUserPost: otherUserPost,
      })
    );
    // props.dispatch(saveBookmarkStart({ post_id: post.post_id }));
  };

  
  const shareUrl = {
    facebook: "https://www.facebook.com/sharer/sharer.php?u",
    twitter: "https://twitter.com/intent/tweet?url",
    whatsapp: "https://api.whatsapp.com/send?text",
    messanger: "https://www.messenger.com/t/?link",
    pintrest: "https://www.pinterest.com/pin/create/button/?url",
    reddit: "https://www.reddit.com/submit?url",
    vk: "https://vk.com/share.php?url",
    linkedin: "https://www.linkedin.com/sharing/share-offsite/?url",
  }


  return (
    <Modal
      show={isOpenProfilePostModal}
      onHide={handleCloseProfilePostModal}
      keyboard={false}
      centered
      className={`${
        localStorage.getItem("theme") !== "" &&
        localStorage.getItem("theme") !== null &&
        localStorage.getItem("theme") !== undefined &&
        localStorage.getItem("theme") === "dark"
          ? "dark-theme-modal custom-modal-container"
          : "custom-modal-container"
      }`}
    >
      <Modal.Body>
        <div className="new-modal-profile-post">
          <button className="close-btn" onClick={handleCloseProfilePostModal}>
            <Image
              className="close-arrow"
              src={window.location.origin + "/assets/images/icons/plus.svg"}
            />
          </button>
          {postData.posts.length > 0 && (
            <>
              {postUniqueId === postData.posts[0]?.post_unique_id ? null : (
                <button
                  className="prev-btn"
                  disabled={postUniqueId === postData.posts[0]?.post_unique_id}
                  onClick={() => handlePrevious(postUniqueId)}
                >
                  <Image
                    className="previous-arrow"
                    src={
                      window.location.origin +
                      "/assets/images/icons/arrow-down.svg"
                    }
                  />
                </button>
              )}
              {postUniqueId ===
              postData.posts[postData.posts.length - 1]
                ?.post_unique_id ? null : (
                <button
                  className="next-btn"
                  disabled={
                    postUniqueId ===
                    postData.posts[postData.posts.length - 1]?.post_unique_id
                  }
                  onClick={() => handleNext(postUniqueId)}
                >
                  <Image
                    className="next-arrow"
                    src={
                      window.location.origin +
                      "/assets/images/icons/arrow-down.svg"
                    }
                  />
                </button>
              )}
            </>
          )}
          <Container fluid>
            <Row>
              <Col md={12}>
                <div className="new-single-page-box">
                  <div className="new-single-page-left">
                    <NewSinglePostSlider
                      post={userProfilePost}
                      postId={userProfilePost?.post_id}
                      // postId={postUniqueId}
                    />
                  </div>
                  <div className="new-single-page-right">
                    {/* <div className="back-to-profile">
												{userData.length > 0 || locationD?.state?.data ? <button onClick={handleGoBack} className="settings-submit-btn btn btn-primary back-cross-btn"  >{locationD?.state?.from === "home" ? "×" : "×"}</button> : "" }
											</div> */}
                    <div className="new-single-post-view-card">
                      <div className="new-single-post-view-header">
                        <div className="new-feed-user-info">
                          <div className="live-streaming-user-img-sec">
                            <Image
                              className="new-feed-user-img"
                              src={userProfilePost.user_picture}
                            />
                          </div>
                          <div className="new-feed-user-details">
                            <h3>
                              <span style={{ display: "flex", gap: "7px" }}>
                                {userProfilePost.username}
                                {userProfilePost?.user
                                  ?.is_certified_content_creator === 2 ? (
                                  <Image
                                    // style={{ marginBottom: "2px" }}
                                    className="sidebar-verified-icon"
                                    src={
                                      window.location.origin +
                                      "/assets/images/new-home/verified-icon.svg"
                                    }
                                  />
                                ) : null}
                              </span>
                              {/* @{userProfilePost.username} */}
                              {userProfilePost.is_verified_badge == 1 ? (
                                <span>
                                  <Image
                                    className="sidebar-verified-icon"
                                    src={
                                      window.location.origin +
                                      "/assets/images/new-home/verified-icon.png"
                                    }
                                  />
                                </span>
                              ) : null}
                            </h3>
                            {/* <Link
																to={`/${userProfilePost.user_unique_id}`}
															>
																@{userProfilePost.username}
															</Link>
															<p
																dangerouslySetInnerHTML={{
																	__html:
																		userProfilePost.content_formatted,
																}}
															></p> */}
                          </div>
                        </div>
                        <div className="new-feed-user-btn-sec">
                          {localStorage.getItem("userId") !=
                          userProfilePost.user_id ? (
                            userProfilePost?.user
                              ?.is_certified_content_creator == 2 ? (
                              <Button
                                className="sent-tip-btn"
                                onClick={() => setTipModal(true)}
                              >
                                <Image
                                  className="sent-tip-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/feed-story/sent-tip-1.svg"
                                  }
                                />
                                <span>{t("send_tip")}</span>
                              </Button>
                            ) : (
                              ""
                            )
                          ) : null}
                          <Dropdown className="feed-post-dropdown">
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                              className="feed-post-dropdown-btn"
                            >
                              <Image
                                className="three-dots-icon"
                                src={
                                  window.location.origin +
                                  "/assets/images/feed-story/3-vertical-dots.svg"
                                }
                              />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <CopyToClipboard
                                text={userProfilePost.share_link}
                                onCopy={onCopy}
                              >
                                <Media as="li">
                                  <Link to="#" className="dropdown-a">
                                    {" "}
                                    {t("copy_link_to_post")}{" "}
                                  </Link>
                                </Media>
                              </CopyToClipboard>
                              <Media as="li" className="divider"></Media>
                              {localStorage.getItem("userId") ==
                              userProfilePost.user_id ? (
                                //Own post
                                <Media as="li">
                                  <Link
                                    to="#"
                                    onClick={(event) =>
                                      handleDeletePost(event, userProfilePost)
                                    }
                                    className="dropdown-a"
                                  >
                                    {t("delete_post")}
                                  </Link>
                                </Media>
                              ) : (
                                <>
                                  <Media as="li">
                                    <Link
                                      to="#"
                                      // onClick={(event) => handleReportPost(event, post)}
                                      onClick={() => setReportMode(true)}
                                      className="dropdown-a"
                                    >
                                      {t("report")}
                                    </Link>
                                  </Media>
                                  <Media as="li">
                                    <Link
                                      to="#"
                                      onClick={(event) =>
                                        handleBlockUser(event, userProfilePost)
                                      }
                                      className="dropdown-a"
                                    >
                                      {" "}
                                      {t("add_to_blocklist_para")}
                                    </Link>
                                  </Media>
                                </>
                              )}

                              {/* <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
																<Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
																<Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>

                      {/* Comment Section */}
                      <div className="new-single-post-view-body">
                        <div
                          className="scroll-comment-sec"
                          id="commentScrollDiv"
                          style={{
                            maxHeight: "calc(100vh - 350px)",
                            minHeight: "318px",
                            overflowY: "auto",
                          }}
                        >
                          {props.comments.loading ? (
                            <CommonCenterLoader />
                          ) : (
                            <InfiniteScroll
                              dataLength={
                                props.comments.data.post_comments.length
                              }
                              next={fetchMoreComments}
                              hasMore={
                                props.comments.data.post_comments.length <
                                props.comments.data.total
                              }
                              loader={<CommonCenterLoader />}
                              scrollableTarget="commentScrollDiv"
                              style={{ height: "auto", overflow: "hidden" }}
                            >
                              <div className="user-profile-pic-description">
                                <div className="profile-picture">
                                  <Image
                                    className="comment-section-user-profile-pic"
                                    src={userProfilePost.user_picture}
                                  />
                                </div>
                                <div className="user-name-description">
                                  <div className="user-name-desc-inner">
                                    <h5
                                      style={{
                                        margin: "0",
                                        cursor: "pointer",
                                        fontWeight: "600",
                                        fontSize: "1.6em",
                                      }}
                                      onClick={() =>
                                        history.push(
                                          `/${userProfilePost.user_unique_id}`
                                        )
                                      }
                                    >
                                      {/* @{userProfilePost.username} */}
                                      <span
                                        style={{ display: "flex", gap: "7px" }}
                                      >
                                        {userProfilePost.username}
                                        {userProfilePost?.user
                                          ?.is_certified_content_creator ===
                                        2 ? (
                                          <Image
                                            // style={{ marginBottom: "2px" }}
                                            className="sidebar-verified-icon"
                                            src={
                                              window.location.origin +
                                              "/assets/images/new-home/verified-icon.svg"
                                            }
                                          />
                                        ) : null}
                                      </span>
                                      {userProfilePost.is_verified_badge ==
                                      1 ? (
                                        <span>
                                          <Image
                                            className="sidebar-verified-icon"
                                            src={
                                              window.location.origin +
                                              "/assets/images/new-home/verified-icon.png"
                                            }
                                          />
                                        </span>
                                      ) : null}{" "}
                                    </h5>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          userProfilePost.content_formatted,
                                      }}
                                      style={{ margin: "0", fontSize: "14px	" }}
                                    ></span>
                                  </div>
                                </div>
                              </div>
                              {props.comments.data.post_comments.map(
                                (comment, i) => (
                                  <div className="view-reply-comment">
                                    <div className="comment-profile">
                                      <div className="comment-profile-name">
                                        <div className="comment-profile-img-sec">
                                          <Image
                                            className="comments-profile-img"
                                            src={comment.user_picture}
                                          />
                                        </div>
                                        <div className="comment-profile-details">
                                          {/* <h5>1{comment.user_displayname}</h5> */}
                                          <span
                                            style={{
                                              display: "flex",
                                              gap: "7px",
                                              alignItems: "center",
                                            }}
                                          >
                                            <h6 style={{ fontSize: "1.2rem" }}>
                                              {comment.username}
                                            </h6>
                                            {comment?.is_certified_content_creator ===
                                            1 ? (
                                              <Image
                                                style={{
                                                  marginBottom: "6px",
                                                  fontSize: "14px",
                                                }}
                                                className="sidebar-verified-icon"
                                                src={
                                                  window.location.origin +
                                                  "/assets/images/new-home/verified-icon.svg"
                                                }
                                              />
                                            ) : null}
                                          </span>
                                          {/* <h6>2{comment.username}</h6> */}
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: comment.comment,
                                            }}
                                          ></p>
                                          <div className="comment-reply-btn">
                                            <p className="time-text">
                                              {format(comment.created_at)}
                                            </p>
                                            {selectedComment ===
                                            comment.post_comment_id ? (
                                              <Button
                                                onClick={() =>
                                                  fetchCommentReply(null)
                                                }
                                              >
                                                {t("hide")}
                                              </Button>
                                            ) : (
                                              <Button
                                                onClick={() =>
                                                  fetchCommentReply(
                                                    comment.post_comment_id
                                                  )
                                                }
                                              >
                                                {comment.total_comment_replies >
                                                0 ? (
                                                  <>
                                                    {" "}
                                                    {t(`view_reply`)}{" "}
                                                    {
                                                      comment.total_comment_replies
                                                    }
                                                  </>
                                                ) : (
                                                  t(`reply`)
                                                )}
                                              </Button>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      {/* <div className="comment-profile-end">
                                        <p className="time-text">
                                          {format(comment.created_at)}
                                        </p>
                                      </div> */}
                                    </div>
                                    {/* Comment reply */}
                                    {selectedComment ===
                                    comment.post_comment_id ? (
                                      <div className="new-comment-reply-sec">
                                        {props.commentReplies.loading ? (
                                          <CommonCenterLoader />
                                        ) : props.commentReplies.data
                                            .post_comment_replies.length > 0 ? (
                                          <>
                                            {props.commentReplies.data.post_comment_replies.map(
                                              (reply, i) => (
                                                <div
                                                  className="view-reply-sec"
                                                  key={i}
                                                >
                                                  <div className="comment-profile-name">
                                                    <div className="comment-profile-img-sec">
                                                      <Image
                                                        className="comments-profile-img"
                                                        src={reply.user_picture}
                                                      />
                                                    </div>
                                                    <div className="comment-profile-details">
                                                      <h5>
                                                        {reply.user_displayname}
                                                        <span>
                                                          {
                                                            reply.reply_formatted
                                                          }
                                                        </span>
                                                      </h5>

                                                      <div className="comment-reply-btn">
                                                        <p className="time-text">
                                                          {reply.created}
                                                        </p>
                                                        {/* <Button>Reply</Button> */}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                            {props.commentReplies.data
                                              .post_comment_replies.length <
                                            props.commentReplies.data.total ? (
                                              <div className="comment-reply-btn">
                                                <Button
                                                  onClick={() =>
                                                    fetchMoreCommentReply(
                                                      comment.post_comment_id
                                                    )
                                                  }
                                                >
                                                  {t("view_reply")}
                                                </Button>
                                              </div>
                                            ) : (
                                              <Button
                                                onClick={() =>
                                                  fetchCommentReply(null)
                                                }
                                              >
                                                {t("hide")}
                                              </Button>
                                            )}
                                          </>
                                        ) : null}
                                        <NewCommentReplies comment={comment} />
                                      </div>
                                    ) : null}
                                  </div>
                                )
                              )}
                            </InfiniteScroll>
                          )}
                        </div>
                      </div>

                      <div className="new-single-post-view-footer">
                        <div className="new-feed-footer-action-btn-sec">
                          <div className="new-single-post-view-action-btn-sec">
                            <div className="new-feed-footer-action-left-sec">
                              <Button
                                className="new-feed-wishlist-btn"
                                onClick={(e) => handleLike(e, userProfilePost)}
                              >
                                {userProfilePost.is_user_liked === 1 ? (
                                  <Image
                                    className="new-feed-wishlist-icon"
                                    src={
                                      window.location.origin +
                                      "/assets/images/feed-story/heart.svg"
                                    }
                                  />
                                ) : (
                                  <Image
                                    className="new-feed-wishlist-icon"
                                    src={
                                      window.location.origin +
                                      "/assets/images/feed-story/heart-outline.svg"
                                    }
                                  />
                                )}
                                <span>{userProfilePost.like_count}</span>
                              </Button>
                              <Button className="new-feed-wishlist-btn">
                                <Image
                                  className="new-feed-wishlist-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/feed-story/comments.svg"
                                  }
                                />
                                <span>{userProfilePost.total_comments}</span>
                              </Button>

                              <Dropdown className="forward-post-dropdown">
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="forward-post-dropdown-btn"
                  style={{background: "none", border: "none", justifyContent: "flex-start", padding:"0"}}
                >
                  <Button className="new-feed-wishlist-btn">
                    <Image
                      className="new-feed-wishlist-icon"
                      src={
                        window.location.origin +
                        "/assets/images/feed-story/send.png"
                      }
                    />
                  </Button>
                </Dropdown.Toggle>

                <Dropdown.Menu className="drop-d-m-forward">
                  <div className="drop-d-m-forward-wrapper">
                    <Media as="li">
                        <a href={`${shareUrl.facebook}=${userProfilePost?.share_link}`}  target="_blank" >
                           <img className="drop-d-social-icon" src={window.location.origin + "/assets/images/new-settings/social-icons/facebook2.png"} alt="facebook-icon" />
                        </a>
                    </Media>
                    <Media as="li">
                        <a href={`${shareUrl.twitter}=${userProfilePost?.share_link}`}   target="_blank" >
                           <img className="drop-d-social-icon" src={window.location.origin + "/assets/images/new-settings/social-icons/twitter2.png"} alt="twitter-icon" />
                        </a>
                    </Media>
                    <Media as="li">
                        <a href={`${shareUrl.whatsapp}=${userProfilePost?.share_link}`}   target="_blank" >
                           <img className="drop-d-social-icon" src={window.location.origin + "/assets/images/new-settings/social-icons/whatsapp.png"} alt="whatsapp-icon" />
                        </a>
                    </Media>
                    <Media as="li">
                        <a href={`${shareUrl.messanger}=${userProfilePost?.share_link}`}   target="_blank" >
                           <img className="drop-d-social-icon" src={window.location.origin + "/assets/images/new-settings/social-icons/messenger.png"} alt="messanger-icon" />
                        </a>
                    </Media>
                    <Media as="li">
                        <a href={`${shareUrl.pintrest}=${userProfilePost?.share_link}`}   target="_blank" >
                           <img className="drop-d-social-icon" src={window.location.origin + "/assets/images/new-settings/social-icons/social.png"} alt="pintrest-icon" />
                        </a>
                    </Media>
                    <Media as="li">
                        <a href={`${shareUrl.reddit}=${userProfilePost?.share_link}`}   target="_blank" >
                           <img className="drop-d-social-icon" src={window.location.origin + "/assets/images/new-settings/social-icons/reddit.png"} alt="reddit-icon" />
                        </a>
                    </Media>
                    <Media as="li">
                        <a href={`${shareUrl.vk}=${userProfilePost?.share_link}`}   target="_blank" >
                           <img className="drop-d-social-icon" src={window.location.origin + "/assets/images/new-settings/social-icons/vk.png"} alt="vk-icon" />
                        </a>
                    </Media>
                    <Media as="li">
                        <a href={`${shareUrl.linkedin}=${userProfilePost?.share_link}`}   target="_blank" >
                           <img className="drop-d-social-icon" src={window.location.origin + "/assets/images/new-settings/social-icons/linkedin.png"} alt="linkdin-icon" />
                        </a>
                    </Media>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
                            </div>
                            <div className="new-single-post-view-time-sec">
                              <p className="time-text">
                                {userProfilePost.created}
                              </p>
                            </div>
                          </div>
                          <div className="new-feed-footer-action-right-sec">
                            <Button
                              className="new-feed-bookmark-btn"
                              onClick={(e) =>
                                handleBookmark(e, userProfilePost)
                              }
                            >
                              {userProfilePost.is_user_bookmarked === 1 ? (
                                <Image
                                  className="new-feed-bookmark-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/feed-story/bookmark-fill.svg"
                                  }
                                />
                              ) : (
                                <Image
                                  className="new-feed-bookmark-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/feed-story/bookmark-outline.svg"
                                  }
                                />
                              )}
                            </Button>
                          </div>
                        </div>
                        {/* Comment Type Form */}
                        <NewComments
                          post={userProfilePost}
                          isRestricted={props?.isRestricted}
                          restrictedByUser={props?.restrictedByUser}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {reportMode ? (
          <ReportModeModal
            reportMode={reportMode}
            closeReportModeModal={closeReportModeModal}
            post={userProfilePost}
          />
        ) : null}
        {sendTip ? (
          <SendTipPaymentModal
            paymentsModal={sendTip}
            closepaymentsModal={closeSendTipModal}
            post_id={userProfilePost.post_id}
            user_id={userProfilePost.user_id}
            type="post"
          />
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToPros = (state) => ({
  singlePost: state.post.singlePost,
  delPost: state.post.delPost,
  comments: state.comment.comments,
  commentReplies: state.comment.commentReplies,
  saveBlockUser: state.users.saveBlockUser,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(ProfilePostPreviousNext));

// export default ProfilePostPreviousNext
