import {
  GET_KYC_DOCUMENT_START,
  GET_KYC_DOCUMENT_SUCCESS,
  GET_KYC_DOCUMENT_FAILURE,
  ADD_KYC_DOCUMENT_START,
  ADD_KYC_DOCUMENT_SUCCESS,
  ADD_KYC_DOCUMENT_FAILURE,
  SENT_OTP_START,
  SENT_OTP_SUCCESS,
  SENT_OTP_FAILURE,
  VERIFY_OTP_START,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
  Add_PRIMARIY_INFO_SETP_8_START,
  Add_PRIMARIY_INFO_SETP_8_SUCCESS,
  Add_PRIMARIY_INFO_SETP_8_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  addKycDocInput: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  kycDocDetails: {
    data: {},
    loading: true,
    error: false,
  },
  sentOtp : {
    data : {},
    loading : false,
    error : false
  },
  verifyOtp: {
    data : {},
    loading : false,
    error : false
  },
  addPrimaryInfo: {
    data : {},
    loading : false,
    error : false
  },
  buttonDisable: false,
  loadingButtonContent: null,
};

const KycDocumentReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_KYC_DOCUMENT_START:
      return {
        ...state,
        addKycDocInput: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case ADD_KYC_DOCUMENT_SUCCESS:
      return {
        ...state,
        addKycDocInput: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: "Send for Approval",
        },
      };
    case ADD_KYC_DOCUMENT_FAILURE:
      return {
        ...state,
        addKycDocInput: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: "Send for Approval",
        },
      };
    case GET_KYC_DOCUMENT_START:
      return {
        ...state,
        kycDocDetails: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case GET_KYC_DOCUMENT_SUCCESS:
      return {
        ...state,
        kycDocDetails: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case GET_KYC_DOCUMENT_FAILURE:
      return {
        ...state,
        kycDocDetails: {
          data: {},
          loading: true,
          error: action.error,
        },
      };

    case SENT_OTP_START:
      return {
        ...state,
        sentOtp: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case SENT_OTP_SUCCESS:
      return {
        ...state,
        sentOtp: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case SENT_OTP_FAILURE:
      return {
        ...state,
        sentOtp: {
          data: {},
          loading: false,
          error: action.error,
        },
      };

    case VERIFY_OTP_START:
      return {
        ...state,
        verifyOtp: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        verifyOtp: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case VERIFY_OTP_FAILURE:
      return {
        ...state,
        verifyOtp: {
          data: {},
          loading: false,
          error: action.error,
        },
      };

    case Add_PRIMARIY_INFO_SETP_8_START:
      return {
        ...state,
        addPrimaryInfo: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case Add_PRIMARIY_INFO_SETP_8_SUCCESS:
      return {
        ...state,
        addPrimaryInfo: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case Add_PRIMARIY_INFO_SETP_8_FAILURE:
      return {
        ...state,
        addPrimaryInfo: {
          data: {},
          loading: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default KycDocumentReducer;
