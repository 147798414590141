import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  GET_REFERRAL_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  getReferralSuccess,
  getReferralFailure,
} from "../actions/ReferralAction";
import {
  checkLogoutStatus,
} from "../actions/ErrorAction";

function* getReferralAPI({data}) {
  try {
    const response = yield api.postMethod("referral_code", data?.payload?.periodTypeValue ?  {period_type : data.payload.periodTypeValue , period : data.payload.periodValue} : null);
    yield put(getReferralSuccess(response.data.data));
    if (response.data.success) {
      const getCreatedYear = response.data?.data?.user?.created_at;

      const userCreatedyear = new Date(getCreatedYear || data?.created_at ).getFullYear();
      const userCreatedMonth = new Date(getCreatedYear || data?.created_at ).getMonth() + 1;


      // const userCreatedyear = new Date("2023-02-15T06:10:38.000000Z").getFullYear();
      // const userCreatedMonth = new Date("2023-02-15T06:10:38.000000Z").getMonth() + 1;

      // const year = new Date(getCreatedYear).getFullYear();
      if(data?.setUserCreatedDate){
        data.setUserCreatedDate({year : userCreatedyear, month : userCreatedMonth});
      }

    } else {
      yield put(getReferralFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(getReferralFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(GET_REFERRAL_START, getReferralAPI)]);
}
