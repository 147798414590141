import React, { useEffect } from "react";
import { translate, t } from "react-multi-lang";
import NoDataFound from "../../NoDataFound/NoDataFound";
import FollowingLoader from "../../Loader/FollowingLoader";
import {
  Row,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { fetchListsDetailsStart } from "../../../store/actions/HomeAction";
import FavouriteCard from "./FavouriteCard";

const FavoritesActiveSec = (props) => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchListsDetailsStart());
  }, []);

  return (
    <>
      <div
        role="tabpanel"
        className={
          props.activeSec === "active-sec"
            ? "tab-pane fade in active"
            : "tab-pane fade"
        }
        id="active"
      >
        {props.favorites.loading ? (
          <FollowingLoader />
        ) : (
          <>
            <Row>
              {props.userData && props.userData.length > 0 ? (
                props.userData.map((fav_user) =>
                  fav_user.fav_user ? (

                    <FavouriteCard
                      key={fav_user?.fav_user?.user_id}
                      fav_user={fav_user}
                      activeSec={props.activeSec}
                      setActiveSec={props.setActiveSec}
                      favorites={props.favorites}
                      userData={props.userData}
                      pagePath={props.pagePath}
                      isSelected={props.isSelected}
                      setUserData={props.setUserData}
                      setCheckedUserId={props.setCheckedUserId}
                      checkedUserId={props.checkedUserId}
                    />
                  ) : (
                    ""
                  )
                )
              ) : (
                <NoDataFound></NoDataFound>
              )}
              {/* {noMoreData !== true ? (
                <>{isFetching && "Fetching more list items..."}</>
              ) : (
                t("no_more_data")
              )} */}
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default translate(FavoritesActiveSec);
