import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  InputGroup,
  FormControl,
  Table,
} from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { getReferralStart } from "../../store/actions/ReferralAction";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  RedditShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  RedditIcon,
} from "react-share";
import "./Referrals.css";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { getSuccessNotificationMessage } from "../helper/NotificationMessage";
import ReferralsLoader from "../Loader/ReferralsLoader";
import { Link } from "react-router-dom";
import NoDataFound from "../NoDataFound/NoDataFound";
import { useHistory } from "react-router-dom";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { load } from "@fingerprintjs/fingerprintjs";

const $ = window.$;

const ReferralsIndex = (props) => {

  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    props.dispatch(getReferralStart()); 
  }, []);

  const { data } = useSelector((state) => state.users.profile);

  useEffect(() => {
    if (data?.is_certified_content_creator && data?.is_certified_content_creator !== 2) {
       history.push('/home') 
    }
  }, [data])

  const onCopy = () => {
    const referralCode =
    props?.referrals?.data?.referral_code;
    const notificationMessage = getSuccessNotificationMessage(
      t("link_copied") + " " + referralCode
    );
    props.dispatch(createNotification(notificationMessage));
  };
  // const onCopy = () => {
  //   const notificationMessage = getSuccessNotificationMessage(t("link_copied"));
  //   props.dispatch(createNotification(notificationMessage));
  // };

  const formatDate = (createdAt) =>
    new Date(createdAt).toLocaleString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });


  return (
    <>
      <div className="referrals-sec">
        {props.referrals.loading ? (
          <ReferralsLoader />
        ) : (
          <Container>
            <h4 className="head-title">
              <Image
                src={
                  window.location.origin +
                  "/assets/images/icons/referral-friend.svg"
                }
                className="referrals-header-icons"
              />{" "}
              {t("tell_friends_about")}{" "}
              {configuration.get("configData.site_name")}
               
               {
                props?.referrals?.data?.my_parent_username !== undefined && props?.referrals?.data?.my_parent_username !== "" && (
                  <p>Referred by :  <Link to={`/${props?.referrals?.data?.my_parent_username}`}>
                  @{props?.referrals?.data?.my_parent_username}
                </Link></p>
                ) 
               }
            </h4>
            <Row>
              <Col md={12}>
                <div className="referrals-box">
                  <h3>{t("referral_code_note")}</h3>
                  <div className="referrals-sub-sec">
                    <div className="align-items-center">
                      <Col md={6}>
                        <div className="referrals-email">
                          <InputGroup className="">
                            <FormControl
                              disabled
                              placeholder={props?.referrals?.data?.referral_code}
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                            />
                            {console.log(
                              props?.referrals?.data?.referral_code,
                              "reffre url"
                            )}
                            <CopyToClipboard
                              onCopy={onCopy}
                              text={`${props?.referrals?.data?.referral_code}`}
                            >
                              <InputGroup.Text id="basic-addon2">
                                <button className="btn btn-referal">
                                  {t("Copy Code")}
                                </button>
                              </InputGroup.Text>
                            </CopyToClipboard>
                          </InputGroup>
                        </div>
                        <div className="referrals-social-link-sec">
                          <div className="referrals-social-link-card">
                            <div className="email-bg">
                              <EmailShareButton
                                subject={configuration.get(
                                  "configData.site_name"
                                )}
                                body={props?.referrals?.data?.share_message}
                                className="Demo__some-network__share-button"
                                url=""
                              >
                                <EmailIcon size={32} round />
                              </EmailShareButton>
                            </div>
                            <p>{t("email")}</p>
                          </div>
                          <div className="referrals-social-link-card">
                            <div className="whatsapp-bg">
                              <WhatsappShareButton
                                url={props?.referrals?.data?.share_message}
                                // title={props.referrals.data.share_message}
                                // separator=":: "
                                className="Demo__some-network__share-button"
                              >
                                <WhatsappIcon size={32} round />
                              </WhatsappShareButton>
                            </div>
                            <p>{t("whatsapp")}</p>
                          </div>
                          <div className="referrals-social-link-card">
                            <div className="facebook-bg">
                              <FacebookShareButton
                                url={props?.referrals?.data?.referrals_signup_url}
                                quote={props?.referrals?.data?.share_message}
                                className="Demo__some-network__share-button"
                              >
                                <FacebookIcon size={32} round />
                              </FacebookShareButton>
                            </div>
                            <p>{t("facebook")}</p>
                          </div>
                          <div className="referrals-social-link-card">
                            <div className="twitter-bg">
                              <TwitterShareButton
                                url={props?.referrals?.data?.share_message}
                                // title={props.referrals.data.share_message}
                                className="Demo__some-network__share-button"
                              >
                                <TwitterIcon size={32} round />
                              </TwitterShareButton>
                            </div>
                            <p>{t("twitter")}</p>
                          </div>
                          <div className="referrals-social-link-card">
                            <div className="reddit-bg">
                              <RedditShareButton
                                url={props?.referrals?.data?.share_message}
                                // title={props.referrals.data.share_message}
                                windowWidth={660}
                                windowHeight={460}
                                className="Demo__some-network__share-button"
                              >
                                <RedditIcon size={32} round />
                              </RedditShareButton>
                            </div>
                            <p>{t("reddit")}</p>
                          </div>
                        </div>
                        <div className="referrals-count-sec referal-card">
                          <div className="referrals-card">
                            <div className="referrals-left">
                              <p>{t("total_referrals")}</p>
                            </div>
                            <div className="referrals-right">
                              <p>{props?.referrals?.data?.total_referrals}</p>
                            </div>
                          </div>
                          <div className="referrals-card">
                            <div className="referrals-left">
                              <p>{t("referral_earnings")}</p>
                            </div>
                            <div className="referrals-right">
                              <p>
                                {
                                  props?.referrals?.data?.referral_earnings_formatted
                                }
                              </p>
                            </div>
                          </div>
                          <div className="referrals-card">
                            <div className="referrals-left">
                              <p>{t("referee_earnings")}</p>
                            </div>
                            <div className="referrals-right">
                              <p>
                                {
                                  props?.referrals?.data?.referee_earnings_formatted
                                }
                              </p>
                            </div>
                          </div>
                          <div className="referrals-card">
                            <div className="referrals-left">
                              <p>{t("total_credits")}</p>
                            </div>
                            <div className="referrals-right">
                              <p>{props?.referrals?.data?.total_formatted}</p>
                            </div>
                          </div>
                          {/* <div className="referrals-card">
                            <div className="referrals-left">
                              <p>{t("used_credits")}</p>
                            </div>
                            <div className="referrals-right">
                              <p>{props.referrals.data.used_formatted}</p>
                            </div>
                          </div> */}
                          {/* <div className="referrals-card">
                            <div className="referrals-left">
                              <p>{t("remaining_credits")}</p>
                            </div>
                            <div className="referrals-right">
                              <p>{props.referrals.data.remaining_formatted}</p>
                            </div>
                          </div> */}
                        </div>
                      </Col>
                      {/* <Col md={6}>
                        
                      </Col> */}
                    </div>
                    <Row>
                      {/* <Col md={6}>
                        
                      </Col> */}
                      {props?.referrals?.data?.userReferrals?.length ? (
                        <Col md={12}>
                          <div className="referrals-count-sec refree-card">
                            <div
                              className="referrals-heading"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <p
                                style={{
                                  "font-weight": "900",
                                  "font-size": "15px",
                                }}
                              >
                                {t("Refrees List")}
                              </p>

                              {props?.referrals?.data?.userReferrals?.length >
                                5 && (
                                <Link
                                   to="/refrees"
                                  style={{
                                    "font-weight": "900",
                                    "font-size": "15px",
                                    border: "none",
                                    background: "none",
                                    marginBottom: "1rem",
                                    color: "#6661e7",
                                    paddingRight : "20px"
                                  }}
                                >
                                  View more
                                </Link>
                              )}
                            </div>

                            <div className="refree-card-main">
                              {/* {props?.referrals?.data?.userReferrals &&
                                props?.referrals?.data?.userReferrals?.slice(0, 5).map(
                                  (ref) => (
                                    <>
                                      <div className="refreeCard">
                                        <div className="referrals-left">
                                          <p>
                                            {ref.user ? (
                                              <Link
                                                to={`/${ref.user.user_unique_id}`}
                                              >
                                                @{ref.user.username}
                                              </Link>
                                            ) : null}
                                            (
                                            {ref.user
                                              ? formatDate(ref.user.created_at)
                                              : null}
                                            )
                                          </p>
                                        </div>
                                        <div className="referrals-right">
                                          <p>
                                            {(
                                              Math.round( ref?.coin_amount * 100) / 100
                                            ).toFixed(2)}{" "}
                                            {t("coin")}
                                          </p>
                                        </div>
                                      </div>
                                    </>
                                  )
                                )} */}


             <div className="payment-tabs-content">
                       {props?.referrals?.data?.userReferrals &&  props?.referrals?.data?.userReferrals?.length > 0 ? (
                    <Table borderedless responsive>
                    <thead>
                      <tr className="bg-white">
                        <th style={{fontSize: "14px"}}>{t("refree_username")}</th>
                        <th style={{fontSize: "14px"}}>{t("refree_name")}</th>
                       
                        <th style={{fontSize: "14px"}}>{t("refree_join_date")}</th>
                        <th style={{fontSize: "14px"}}>{t("refree_amount")}</th>
                      </tr> 
                    </thead>
                    <tbody>
                      {props?.referrals?.data?.userReferrals?.slice(0, 5).map(
                        (refree, index) => (
                          <tr key={index}>
                            {refree?.user ? (
                              <td style={{ fontSize: "15px" }}>
                                <Link to={`/${refree?.user?.user_unique_id}`}>
                                  @{refree?.user?.username}
                                </Link>
                              </td>
                            ) : null}
                            <td style={{fontSize: "13px"}}>{refree?.user?.name}</td>
                           
                            <td style={{fontSize: "13px"}}>{formatDate(refree?.created_at)}</td>
                            <td style={{fontSize: "13px"}}>
                              {(
                                Math.round(refree?.coin_amount * 100) / 100
                              ).toFixed(2)}{" "}
                              {t("coin")}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                ) : (
                  <NoDataFound />
                )}
              </div>
                            </div>
                          </div>
                        </Col>
                      ) : (
                        <></>
                      )}
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <h4>{t("how_it_works_referral")}</h4>
                <div className="how-its-work-sec">
                  <div className="how-its-work-card">
                    <div classname="how-its-work-icon-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/icons/share-referal.svg"
                        }
                        className="how-its-work-icons"
                      />
                    </div>
                    <div className="how-its-work-info">
                      <h5>{t("referral_step1")}</h5>
                      <p>{t("referral_step1_content")}</p>
                    </div>
                  </div>
                  <div className="how-its-work-card">
                    <div classname="how-its-work-icon-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/icons/referral-friend.svg"
                        }
                        className="how-its-work-icons"
                      />
                    </div>
                    <div className="how-its-work-info">
                      <h5>{t("referral_step2")}</h5>
                      <p>{t("referral_step2_content")}</p>
                    </div>
                  </div>
                  <div className="how-its-work-card">
                    <div classname="how-its-work-icon-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/icons/message-1.svg"
                        }
                        className="how-its-work-icons"
                      />
                    </div>
                    <div className="how-its-work-info">
                      <h5>{t("referral_step3")}</h5>
                      <p>{t("referral_step3_content")}</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  referrals: state.referral.referralDetails,
  profile: state.users.profile
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(ReferralsIndex));
