import React from "react";
import { Button } from "react-bootstrap";
import { t } from "react-multi-lang";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import { useDispatch, useSelector } from "react-redux";
import { usernameValidationDefault } from "../../store/actions/UserAction";

const SignUpStep3 = (props) => {
  const {loading: signUpLoader} = useSelector((state)=> state.users.registerInputData)
  const dispatch = useDispatch();
  return (
    <div>
      <div className="form-group muture-content-grp">
        <div
          class="input-group"
          style={{
            display: "flex",
            gap: "11px",
          }}
        >
          <input
            type="checkbox"
            style={{
              marginTop: "0px",
              cursor: "pointer",
            }}
            name="creatorCheck"
            onChange={props.handleChange}
            checked={props.value.creatorCheck}
            id="creatorCheck"
          />
          <label
            style={{
              alignSelf: "baseline",
              fontSize: "18px",
              color: "rgb(151 157 163)",
              fontWeight: "400",
              marginBottom: "0px",
              cursor: "pointer",
            }}
            htmlFor="creatorCheck"
          >
            Want to be creator?
          </label>
        </div>

        {props.value.creatorCheck && props.isAdult && !props.isCensorship ? (
          <div
            class="input-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <p
              style={{
                alignSelf: "baseline",
                fontSize: "18px",
                color: "rgb(151 157 163)",
                fontWeight: "400",
                marginBottom: "0px",
              }}
            >
              Will you post any mature content?
            </p>
            <label class="switchL" style={{ marginBottom: "0px" }}>
              <input
                type="checkbox"
                onChange={props.handleChange}
                checked={props.value.matureChecked}
                name="matureChecked"
              />
              <span class="sliderL"></span>
            </label>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="register_step_next_prv_btn_wrapper register_step_btn_2_wrapper">
        <Button
          className="register_stpe_next_btn"
          onClick={() => props.jumpToStep(1)}
        >
          Previous
        </Button>
        <Button type="submit" className="register_stpe_next_btn" disabled={signUpLoader}>
          {signUpLoader ? "Loading..." : "Sign Up"} 
        </Button>
      </div>

      <div className="signup_already_account_container">
        <p id="two">{t("already_have_an_account")}</p>
        <p>
          <Link
            className="signup"
            href="#"
            id="signin"
            onClick={(event) => {
              event.preventDefault();
              props.setShow("login");
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              props.setValues({
                name: "",
                username: "",
                email: "",
                password: "",
                date_of_birth: "",
                selectedCategories: [],
                creatorCheck: false,
                matureChecked: false,
              });
              props.setInitalStep(0);
              dispatch(usernameValidationDefault());
            }}
          >
            {" "}
            {t("login_for")} {configuration.get("configData.site_name")}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default SignUpStep3;
