import React from "react";

const AccessContentW = ({
  accessT,
  values,
  handleChange,
  setValues,
  errors,
  accessContentData,
}) => {

  
  const handleMainToggle = (e) =>{
  
    const findAccess = values?.accessContentData?.find((item)=> item?.type === accessT?.name?.mainToogle);
    
    if(findAccess.type === "fullAccess"){

     const transformedData = values?.accessContentData.map((ele)=> {

       if(findAccess.type === "fullAccess"){
         return {
           ...ele,
           mainToogle : e.target.checked,
           limitName : "No Limit",
           inputName : ""
         }
       }
       
  });

   setValues({...values, accessContentData: transformedData });

}else if(findAccess.type !== "fullAccess"){

     const transformedData = values?.accessContentData.map((ele)=> {
       if(ele.type === findAccess.type){
        return {
          ...ele,
          mainToogle : e.target.checked,
          limitName : "No Limit",
          inputName: ""
        }

       }else{
         
        return ele
       }
  })
   setValues({...values, accessContentData: transformedData });
     
    }else{
      setValues({...values});
    }

  }


  const handleLimitChange = (e) => {
     
    const findAccess = values?.accessContentData?.find((item)=> item?.type === accessT?.name?.mainToogle);
     
    if(findAccess){

      const transformedData = values?.accessContentData.map((ele) => {
          
         if(findAccess.type === ele.type){
            return {
              ...ele,
              limitName : e.target.value,
              inputName: e.target.value === "No Limit" ? "" : ele.inputName
            }
         }else{
           return ele;
         }

      })

      setValues({...values, accessContentData: transformedData });

    }else{
      return setValues({...values})
    }

  }



  const handleInputValue = (e)=> {
     
    const findAccess = values?.accessContentData?.find((item)=> item?.type === accessT?.name?.mainToogle);
     
     if(findAccess){
       
      const transformedData = values?.accessContentData?.map((ele)=> {
         
        if(findAccess?.type === ele.type){
           
           return{
             ...ele,
             inputName : e.target.value
           } 

        }else{
          return ele
        }

       })


       setValues({...values,accessContentData : transformedData })
      

     }else {
      return setValues({...values}) 
     }

  }
 

  return (
    <div
      className="access-content-switch-wrapper"
      style={{ marginTop: "10px", width: "190px" }}
    >
      <div
        className="access-content-switch-item"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            fontSize: "13px",
            fontWeight: "700",
          }}
        >
          {accessT?.text}
        </p>
        <label class="switchL">
          <input
            type="checkbox"
            className={values?.accessContentData[0].mainToogle === true && accessT?.name?.mainToogle !== "fullAccess" ? "disableToggle" : ''}
            onChange={handleMainToggle}
            checked={values?.accessContentData?.find((item)=> item.type === accessT?.name?.mainToogle)?.mainToogle}
            name={accessT?.name?.mainToogle}
            disabled={values?.accessContentData[0].mainToogle === true && accessT?.name?.mainToogle !== "fullAccess"}
          />
          <span class="sliderL"></span>
        </label>
      </div>
      {accessT?.name?.limitName && accessT?.name?.inputName && values?.accessContentData?.find((item)=> item.type === accessT?.name?.mainToogle)?.mainToogle && (
        <div
          className="switch-access-content-checkbox-wrapper"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            <div style={{ display: "flex", gap: "5px" }}>
              <label
                className="radio-btn"
                htmlFor="no-limit-c"
                style={{ marginBottom: "0px", fontSize: "12px" }}
              >
                No limit
              </label>
              <input
                style={{ margin: "0px" }}
                type="radio"
                id="no-limit-c"
                name={accessT?.name?.limitName}
                onChange={handleLimitChange}
                value="No Limit"
                checked={values?.accessContentData?.find((item)=> item.type === accessT?.name?.mainToogle)?.limitName === "No Limit"}
                disabled={values?.accessContentData[0].mainToogle === true && accessT?.name?.mainToogle !== "fullAccess"}
              />
            </div>

            <div style={{ display: "flex", gap: "5px" }}>
              <label
                className="radio-btn"
                htmlFor="no-limit-c"
                style={{ marginBottom: "0px", fontSize: "12px" }}
              >
                Limit
              </label>
              <input
                style={{ margin: "0px" }}
                type="radio"
                id="no-limit-c"
                name={accessT?.name?.limitName}
                value="Limit"
                onChange={handleLimitChange}
                checked={values?.accessContentData?.find((item)=> item.type === accessT?.name?.mainToogle)?.limitName === "Limit"}
                disabled={values?.accessContentData[0].mainToogle === true && accessT?.name?.mainToogle !== "fullAccess"}
              />
            </div>
          </div>
          
          {
            values?.accessContentData?.find((item)=> item.type === accessT?.name?.mainToogle)?.limitName === "Limit" && (
              <input
                required
                className="switch-access-input"
                type="number"
                name={accessT?.name?.inputName}
                onChange={handleInputValue}
                value={values?.accessContentData?.find((item)=> item.type === accessT?.name?.mainToogle)?.inputName}
                min={1}
                style={{ width: "100%", marginTop: "7px" }}
              />
            )
          }
        </div>
      )}
    </div>
  );
};

export default AccessContentW;
