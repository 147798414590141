import React, {useEffect, useState} from 'react'
import { Button, Container, Row, Col, Table, Image } from "react-bootstrap";
import CommonCenterLoader from '../../Loader/CommonCenterLoader';
import VerifiedBadgeNoShadow from '../../Handlers/VerifiedBadgeNoShadow';
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from '../../helper/NotificationMessage';
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { translate, t } from "react-multi-lang";
import { useDispatch } from 'react-redux';
import SendTipModal from '../../helper/SendTipModal';
import NoDataFound from '../../NoDataFound/NoDataFound';
import '../FansFollowing/FollowingListView.css';
import { saveBlockUserStart, saveRestrictUserStart } from '../../../store/actions/UserAction';

const RestrictListView = (props) => {

	const dispatch = useDispatch()

	const handleRestrictedUser = (event, user_id) => {
    event.preventDefault();
    dispatch(
      saveRestrictUserStart({
        user_id: user_id,
      })
    );
  };

	return (
		<div className='following-table-sec'>
			<Container>
				<Row>
					<Col sm={12} md={12}>
						<div className="following-table">
							<Table borderedless responsive>
								<thead>
									<tr>
										<th>Profile Picture</th>
										<th>Name</th>
										<th>Username</th>
										<th>Share Link</th>
										<th>Unblock</th>
									</tr>
								</thead>
								<tbody>
									{
										props.followers.loading
											?
											(
												<CommonCenterLoader></CommonCenterLoader>
											)
											:props.userData && props.userData.length > 0 
											?	
												(
													props.userData.map((restrict_user) =>
														restrict_user.restricteduser	? (
															// <UserCard user={restrict_user.restricteduser} />
															<tr key={restrict_user.restricteduser.user_unique_id}>
																<td className='amount'>
																	{/* <span className="favorite-profile-avatar"> */}
																		<Image 
																			src={restrict_user.restricteduser.picture} className="" 
																			style={{
																				width: "50px",
																				height: "50px",
																				borderRadius: "50%"
																			}}
																		/>
																	{/* </span> */}
																</td>
																<td className='amount'> 
																	{/* <div className="favorite-name-row"> */}
																		<Link to={restrict_user.restricteduser.user_unique_id}>
																			<div className="favorite-user-name">
																				{restrict_user.restricteduser.name}{" "}
																				{restrict_user.restricteduser.is_verified_badge == 1 ? (
																					<VerifiedBadgeNoShadow />
																				) : null}
																			</div>
																		</Link>
																	{/* </div> */}
																</td>
																<td className='amount'>
																	<div className="favorite-name-row">
																		<Link
																			to={`/` + restrict_user.restricteduser.user_unique_id}
																			className="g-user-realname__wrapper"
																		>
																			<div className="favorite-user-id">
																				@{restrict_user.restricteduser.username}
																			</div>
																		</Link>
																	</div>	
																</td>
																<td className='amount'>
																	<div className="group-favorite-btns">
																		<CopyToClipboard
																			text={restrict_user.restricteduser.share_link}
																			onCopy={() => {
																				const notificationMessage = getSuccessNotificationMessage(
																					t('profile_link_copied')
																				);
																				dispatch(createNotification(notificationMessage));
																			}}
																		>
																			<Button
																				type="button"
																				className="g-btn m-rounded m-border m-icon m-icon-only m-colored has-tooltip"
																			>
																				<Image
																					src="assets/images/icons/share.svg"
																					className="svg-clone "
																				/>
																			</Button>
																		</CopyToClipboard>
																	</div>
																</td>
																<td className='amount'>
																	<div className="lists-button-group post-icons">
																		<Button
																			type="button"
																			className="btn gradient-btn gradientcolor"
																			onClick={(event) =>
																				handleRestrictedUser(
																					event,
																					restrict_user.restricteduser.user_id
																				)
																			}
																		>
																			<span className="b-btn-text">
																				{/* {t("unblock")} */}
																				Unrestrict
																			</span>
																		</Button>
																	</div>
																</td>
															</tr>
														) : (
															""
														)
													)
												)
											: (
												<NoDataFound></NoDataFound>
											)
									}
								</tbody>
							</Table>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default RestrictListView