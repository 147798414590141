import { FETCH_SUBSCRIBERS_FAILURE, FETCH_SUBSCRIBERS_START, FETCH_SUBSCRIBERS_SUCCESS, GET_SUBSCRIPTION_LEVELS_FAILURE, GET_USER_SUBSCRIBERS_START, GET_USER_SUBSCRIBERS_SUCCESS } from "./ActionConstant"



export const getUserSubscribersStart = (data)=>{
 
    return {
        type: GET_USER_SUBSCRIBERS_START,
        payload : data
      };

}
export const getUserSubscriberSuceess = (data)=>{
 
    return {
        type : GET_USER_SUBSCRIBERS_SUCCESS,
        payload : data
    }

}

export const getUserSubscriberFailure = (data)=>{
 
    return {
        type : GET_SUBSCRIPTION_LEVELS_FAILURE,
        payload : data
    }
}


export function fetchSubscribersStart(data) {
    return {
      type: FETCH_SUBSCRIBERS_START,
      payload: data,
    };
  }
  
  export function fetchSubscribersSuccess(data) {
    return {
      type: FETCH_SUBSCRIBERS_SUCCESS,
      payload : data,
    };
  }
  
  export function fetchSubscribersFailure(error) {
    return {
      type: FETCH_SUBSCRIBERS_FAILURE,
      error,
    };
  }