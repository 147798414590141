import React, { useEffect, useState } from 'react'
import SearchInput from '../Accounts/Searchinput/SearchInput'
import { fetchActiveFollowersStart, fetchExpiredFollowersStart, fetchFollowersStart } from '../../store/actions/FollowAction'
import { Col, Container, Row , Image} from 'react-bootstrap'
import { Link } from "react-router-dom";
import { t, translate } from 'react-multi-lang';
import { connect } from 'react-redux';
import { getAllFriendRequestStart } from '../../store/actions/FriendRequestAction';
import FollowingLoader from '../Loader/FollowingLoader';
import UserCard from '../Accounts/FansFollowing/UserCard';
import NoDataFound from '../NoDataFound/NoDataFound';
import { friendRequestSeenStart } from '../../store/actions/CustomListAction';


const FriendRequestIndex = (props) => {
    const [userData, setUserData] = useState(props.activeFollowers?.data?.followers)
    const [list, setList] = useState(true)
    const [activeSec, setActiveSec] = useState("active-sec");


    
    useEffect(() => {
      props.dispatch(getAllFriendRequestStart());
      props.dispatch(friendRequestSeenStart());
    }, []);
    
    useEffect(() => {
      setUserData(props.activeFollowers?.data?.followers)
    },[props.activeFollowers?.data?.followers])
    
    const handleFilterUserData = (searchItem) => {
      const filterUserData = props.activeFollowers?.data?.followers.filter(user => {
        return user?.otherUser?.name.toLowerCase().includes(searchItem.toLowerCase())
      })
  
      setUserData(filterUserData)
    }
  

    
  return (
    <div className="lists">
    <Container>
      <Row>
        <Col sm={12} md={12} xs={12}>
          <div className="profile-post-area">
            <div className="bookmarkes-list bookmarks-right-side">
              <div className="pull-left">
                <h3>
                  <Link
                    className="bookmarkes-list"
                    to={"/home"}
                    onClick={() => props.history.goBack()}
                  >
                    <Image
                      src={
                        window.location.origin +
                        "/assets/images/icons/back.svg"
                      }
                      className="svg-clone"
                    />
                    {t("friend_request_p")}
                  </Link>
                </h3>
                </div>
            </div>
            {/* <div className="search-component"> */}
                {/* <NewHomeSearch desktop/> */}
                {/* <SearchInput handleFilterUserData={handleFilterUserData} followers={props.activeFollowers}/> */}
                {/* <div className="list-icons" onClick={() => setList(true)}>
                  <svg width="16" height="16" fill="currentColor" class="bi bi-microsoft" viewBox="0 0 16 16">
                  <path fill="#6661e7" d="M7.462 0H0v7.19h7.462zM16 0H8.538v7.19H16zM7.462 8.211H0V16h7.462zm8.538 0H8.538V16H16z"/>
                  </svg>
                </div> */}
                {/* <div className="list-icons" onClick={handleList}>
                  <svg width="16" height="16" fill="currentColor" class="bi bi-list-task" viewBox="0 0 16 16">
                    <path fill="#6661e7" fill-rule="evenodd" d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5zM3 3H2v1h1z"/>
                    <path fill="#6661e7" d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1z"/>
                    <path fill="#6661e7" fill-rule="evenodd" d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5zM2 7h1v1H2zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm1 .5H2v1h1z"/>
                  </svg>
              </div> */}
            {/* </div> */}
          </div>

          <div className="listing-tab">
            <div className="tab" role="tabpanel">
              {/* <div className="tab-content tabs">
                {
                  list
                   ?
                    <FanActiveSec
                      activeSec={activeSec}
                      setActiveSec={setActiveSec}
                      followers={props.activeFollowers}
                      userData={userData}
                    />
                   :
                    <FanListView
                      activeSec={activeSec}
                      setActiveSec={setActiveSec}
                      followers={props.activeFollowers}
                      userData={userData}
                    />
                }
              </div> */}

     {props.allFriendRequestsData.loading ? (
           <FollowingLoader/>
        ) : (
          <>
            <Row>
              {props.allFriendRequestsData.data && props.allFriendRequestsData.data.length > 0 ? (
                props.allFriendRequestsData.data.map((request) => (
                  <UserCard  page={"friendRequest"} friendData={props.allFriendRequestsData.data} user={request.otherUser || request.user} friendListID={request?.id}  isFriendC={true}  key={request.id} />
                ))
              ) : (
                <NoDataFound></NoDataFound>
              )}
            </Row>
          </>
        )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
  )
}

const mapStateToPros = (state) => ({
    // followers: state.follow.followers,
    // activeFollowers: state.follow.activeFollowers,
    // expiredFollowers: state.follow.expiredFollowers,

    allFriendRequestsData : state.friendRequest.allFriendRequestsData
  });
  
  function mapDispatchToProps(dispatch) {
    return { dispatch };
  }

  export default connect(mapStateToPros, mapDispatchToProps)(translate(FriendRequestIndex));
