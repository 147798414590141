import { ACCEPT_FRIEND_REQUEST_FAILURE, ACCEPT_FRIEND_REQUEST_START, ACCEPT_FRIEND_REQUEST_SUCCESS, ADD_FRIEND_REQUEST_FAILURE, ADD_FRIEND_REQUEST_START, ADD_FRIEND_REQUEST_SUCCESS, GET_ALL_FRIEND_FAILURE, GET_ALL_FRIEND_REQUEST_FAILURE, GET_ALL_FRIEND_REQUEST_START, GET_ALL_FRIEND_REQUEST_SUCCESS, GET_ALL_FRIEND_START, GET_ALL_FRIEND_SUCCESS, REMOVE_FRIEND_REQUEST_FAILURE, REMOVE_FRIEND_REQUEST_START, REMOVE_FRIEND_REQUEST_SUCCESS } from "./ActionConstant";









export function addFriendRequestStart(data) {
    return {
      type: ADD_FRIEND_REQUEST_START,
      data,
    };
  }
  
  export function addFriendRequestSuccess(data) {
    return {
      type: ADD_FRIEND_REQUEST_SUCCESS,
      data,
    };
  }
  
  export function addFriendRequestFailure(error) {
    return {
      type: ADD_FRIEND_REQUEST_FAILURE,
      error,
    };
  }


export function getAllFriendRequestStart(data) {
    return {
      type: GET_ALL_FRIEND_REQUEST_START,
      data,
    };
  }
  
  export function getAllFriendRequestSuccess(data) {
    return {
      type: GET_ALL_FRIEND_REQUEST_SUCCESS,
      data,
    };
  }
  
  export function getAllFriendRequestFailure(error) {
    return {
      type: GET_ALL_FRIEND_REQUEST_FAILURE,
      error,
    };
  }


  export function removeRejectFriendRequestStart(data) {
    return {
      type: REMOVE_FRIEND_REQUEST_START,
      data,
    };
  }
  export function removeRejectFriendRequestSuuceess(data) {
    return {
      type: REMOVE_FRIEND_REQUEST_SUCCESS,
      data,
    };
  }
  
  export function removeRejectFriendRequestFailure(error) {
    return {
      type: REMOVE_FRIEND_REQUEST_FAILURE,
      error,
    };
  }
  




  export function acceptFriendRequestStart(data) {
    return {
      type: ACCEPT_FRIEND_REQUEST_START,
      data,
    };
  }
  
  export function acceptFriendRequestSuccess(data) {
    return {
      type: ACCEPT_FRIEND_REQUEST_SUCCESS,
      data,
    };
  }
  
  export function acceptFriendRequestFailure(error) {
    return {
      type: ACCEPT_FRIEND_REQUEST_FAILURE,
      error,
    };
  }


  export function getAllFriendStart(data) {
    return {
      type: GET_ALL_FRIEND_START,
      data,
    };
  }
  
  export function getAllFriendSuccess(data) {
    return {
      type: GET_ALL_FRIEND_SUCCESS,
      data,
    };
  }
  
  export function getAllFriendFailure(error) {
    return {
      type: GET_ALL_FRIEND_FAILURE,
      error,
    };
  }