import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  Form,
  Image,
  InputGroup,
  FormControl,
  Media,
  Tab,
  Nav
} from "react-bootstrap";
import "./NewHome.css";
import { Link } from "react-router-dom";
import NewFeatureStoryIndex from "./NewFeatureStoryIndex";
import NewFeedIndex from "./NewFeedIndex";
import NewFeedRightIndex from "./NewFeedRightIndex";
import {
  fetchHomeFollowingPostsStart,
  fetchHomePostsStart,
  fetchHomeSubscribingPostsStart,
  fetchMoreHomePostsStart,
  fetchTrendingUsersStart,
  searchUserStart,
} from "../../store/actions/HomeAction";
import useInfiniteScroll from "../helper/useInfiniteScroll";
import {
  fetchCommentRepliesStart,
  fetchCommentsStart,
  fetchMoreCommentRepliesStart,
  fetchMoreCommentsStart,
  saveCommentStart,
} from "../../store/actions/CommentsAction";
import { saveBookmarkStart } from "../../store/actions/BookmarkAction";
import { connect, useSelector } from "react-redux";
import { translate, t } from "react-multi-lang";
import CommonCenterLoader from "../Loader/CommonCenterLoader";
import VerifiedBadgeNoShadow from "../Handlers/VerifiedBadgeNoShadow";
import NewHomeSearch from "./NewSingleView/NewHomeSearch";
import HomeLoader from "../Loader/HomeLoader";
import NoDataFound from "../NoDataFound/NoDataFound";
import NewFeedDisplayCard from "./NewFeedDisplayCard";
import InfiniteScroll from "react-infinite-scroll-component";
import NewFeedSuggestionCard from "./NewFeedSuggestionCard";
import NewFeedTrendingCard from "./NewFeedTrendingCard";
import configuration from "react-global-configuration";
import Skeleton from "react-loading-skeleton";
import PostAddCommentModal from "../Home/PostAddCommentModal";

const NewHomeIndex = (props) => {
  // useEffect(() => {
  //   props.dispatch(fetchHomePostsStart({
  //     skip: 0,
  //     take: 12,
  //   }));
  // }, []);

  const fetchMoreData = () => {
    props.dispatch(fetchMoreHomePostsStart({
      skip: props.posts.data.posts.length,
      take: 12,
    }))
  }

  // const fetchHomeData = () => {
  //   setTimeout(() => {
  //     if (props.posts.length !== 0) {
  //       props.dispatch(fetchHomePostsStart());
  //       setIsFetching(false);
  //     } else {
  //       setNoMoreData(true);
  //     }
  //   }, 3000);
  // };

  // useEffect(() => {
  //   const installGoogleAds = () => {
  //     const elem = document.createElement("script");
  //     elem.src = "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
  //     elem.async = true;
  //     elem.defer = true;
  //     document.body.insertBefore(elem, document.body.firstChild);
  //   };
  //   installGoogleAds();
  // }, []);

  // const [isFetching, setIsFetching] = useInfiniteScroll(fetchHomeData);

  const [noMoreData, setNoMoreData] = useState(false);

  const [isOpenAddCommentModal , setIsOpenAddCommentModal] = useState(false);
  const [singlePostData, setSinglePostData] = useState({});
  const [selectedComment, setSelectedComment] = useState(null);
  const {comments , commentReplies}  = useSelector((state)=> state.comment);
  const [postUniqueId, setPostUniqueId] = useState("")
  // const ref = useRef(null)

  
  // const handleClick = () => ref.current?.scrollIntoView({behavior: 'smooth'});

  const scrollToTop = () =>{ 
    window.scrollTo({ 
      top: 0,  
      behavior: 'smooth'
    }); 
  }; 

  const [isActiveTab, setIsActiveTab] = useState("all");

  const [sendTip, setSendTip] = useState(false);

  const closeSendTipModal = () => {
    setSendTip(false);
  };


  //  comment

  const handleCloseAddCommentModal = ()=>{
    setIsOpenAddCommentModal(false)
  } 

  const handleOpenAddCommentModal = (postData)=> {
    
    setPostUniqueId(postData?.post_unique_id)

    setIsOpenAddCommentModal(true)

    props.dispatch(
      fetchCommentsStart({
        post_id: postData?.post_id,
        skip: 0,
        take: 4,
      })
    );
    
    setSinglePostData(postData);
  } 

  useEffect(()=>{

    if(isActiveTab === "all"){

      props.dispatch(fetchHomePostsStart({
        skip: 0,
        take: 12,
      }));

    }else if(isActiveTab === "subscribing"){

      props.dispatch(fetchHomeSubscribingPostsStart({
        skip: 0,
        take: 12,
      }));
      
    }else if(isActiveTab === "following"){
      props.dispatch(fetchHomeFollowingPostsStart({
        skip: 0,
        take: 12,
      }));
      
    }else{

      props.dispatch(fetchHomePostsStart({
        skip: 0,
        take: 12,
      }));

    }


  },[isActiveTab])




  return (
    <>
      <div className="new-home-page-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div style={{display: 'flex'}} >
                <div className="new-home-page-left">
                <div className="profile-tab-sec">
                                <Tab.Container id="left-tabs-example" defaultActiveKey="all">
                                    <Row>
                                        <Col sm={12}>
                                            <Nav variant="pills" className="grid-three-col">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="all"
                                                        onClick={(event) => {
                                                            setIsActiveTab("all")
                                                            // handleClick() 
                                                            scrollToTop();
                                                          }
                                                        }>
                                                        <span>
                                                            <Image
                                                                className="profile-post-tab-icon"
                                                                src={
                                                                    window.location.origin + "/assets/images/new-home/icon/all-post-1.svg"
                                                                }
                                                            />
                                                        </span>
                                                        <span className="resp-display-none">{t("all")}</span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="image"
                                                        onClick={(event) => {
                                                          setIsActiveTab("subscribing")
                                                          // handleClick(); 
                                                          scrollToTop();
                                                        }
                                                        }>
                                                        <span>
                                                            <Image
                                                                className="profile-post-tab-icon"
                                                                src={
                                                                    window.location.origin + "/assets/images/new-home/icon/subscription.png"
                                                                }
                                                            />
                                                        </span>
                                                        <span className="resp-display-none">{t("subscribing")}</span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="video"
                                                        onClick={(event) =>{

                                                          setIsActiveTab("following")
                                                          // handleClick() 
                                                          scrollToTop();
                                                        }
                                                        }>
                                                        <span>
                                                            <Image
                                                                className="profile-post-tab-icon"
                                                                src={
                                                                    window.location.origin + "/assets/images/new-home/icon/add.png"
                                                                }
                                                            />
                                                        </span>
                                                        <span className="resp-display-none">{t("following")}</span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                              
                                            </Nav>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                </div>
                {/* <div className="new-home-page-right">
                <NewHomeSearch desktop />
                </div> */}
              </div>
              <div className="new-home-page-box">
                <div className="new-home-page-left">
                  {/* <div className="mobile-visible">
                    <div className="new-feed-search-sec">
                      <InputGroup className="mb-0">
                        <FormControl
                          placeholder="Search"
                          aria-describedby="basic-addon2"
                          onChange={handleSearch}
                        />
                        <InputGroup.Text id="basic-addon2">
                          <Image
                            className="new-feeds-search-icon"
                            src={
                              window.location.origin +
                              "/assets/images/feed-story/search-icon.svg"
                            }
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    
                    </div>
                  </div> */}
                  <NewHomeSearch />
                  {configuration.get(
                    "configData.is_story_feature_enable"
                  ) == 1 ? (
                    <NewFeatureStoryIndex />
                  ) : null}
                  {/* <NewFeedIndex /> */}

                  {props.posts.loading ?
                    <HomeLoader />
                    : props.posts.data.posts.length > 0 ?
                      <InfiniteScroll
                        dataLength={props.posts.data.posts}
                        next={fetchMoreData}
                        hasMore={props.posts.data.posts.length < props.posts.data.total}
                        loader={<HomeLoader />}
                        style={{ height: 'auto', overflow: 'hidden' }}
                      >
                        <div className="new-feed-sec">
                          {props.posts.data.posts.map((post, index) =>
                            <NewFeedDisplayCard post={post} key={index} index={index} fromType={"Home"} isActiveTab={isActiveTab} handleOpenAddCommentModal={handleOpenAddCommentModal} />
                          )}
                        </div>
                      </InfiniteScroll>

                      : <NoDataFound />
                  }

                  {
                    isOpenAddCommentModal
                     &&
                    <PostAddCommentModal 
                     isOpenModal={isOpenAddCommentModal}  
                     handleCloseModal={handleCloseAddCommentModal} 
                     postUniqueId={postUniqueId} 
                     singlePostData = {singlePostData}
                     setSinglePostData ={setSinglePostData}
                   />  
                  }
                  
                </div>
                {/* <div className="new-home-page-right"> */}
                  {/* <div className="new-feed-right-sec"> */}
                    {/* <div className="new-feed-suggestions-trending-sec"> */}
                      {/* <NewFeedSuggestionCard /> */}
                      {/* <NewFeedTrendingCard /> */}
                    {/* </div> */}
                  {/* </div> */}
                {/* </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  posts: state.home.homePost,
  searchUser: state.home.searchUser,
  trendingUsers: state.home.trendingUsers,
  postSug: state.home.postSug,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(NewHomeIndex));
