import React, { useState, useEffect } from "react";
import FollowingAllSec from "./FollowingAllSec";
import FollowingActiveSec from "./FollowingActiveSec";
import FollowingExpiredSec from "./FollowingExpiredSec";
import FollowingTabSec from "./FollowingTabSec";
import { Container, Row, Col, Image } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchActiveFollowingStart,
  fetchExpiredFollowingStart,
  fetchFollowingStart,
} from "../../../../store/actions/FollowAction";
import FollowingLoader from "../../../Loader/FollowingLoader";
import { translate, t } from "react-multi-lang";
import NewHomeSearch from "../../../NewHome/NewSingleView/NewHomeSearch";
import SearchInput from "../../Searchinput/SearchInput";
import FollowingListView from "./FollowingListView";
import "./Styles.css";
import AddUserCustomFavourite from "../../AddUserCustomFavourite/AddUserCustomFavourite";
import RemoveUserFromList from "../../RemoveUserFromList/RemoveUserFromList";
import ColorTagCustomizeModal from "../../ColorTagCustomization/ColorTagCustomizeModal";

const FollowingIndex = (props) => {
  const [userData, setUserData] = useState(
    props.activeFollowing?.data?.followers
  );

  const [activeSec, setActiveSec] = useState("active-sec");

  const [list, setList] = useState(true);

  const [isSelected, setIsSelected] = useState(false);
  const [checkedUserId, setCheckedUserId] = useState([]);
  const [isAddUser, setIsAddUser] = useState(false);
  const [isRemoveUser, setIsRemoveUser] = useState(false);
  const [isTagUser, setIsTagUser] = useState(false)

  useEffect(() => {
    props.dispatch(fetchActiveFollowingStart());
  }, []);

  useEffect(() => {
    setUserData(props.activeFollowing?.data?.followers);
  }, [props.activeFollowing?.data?.followers]);

  const handleFilterUserData = (searchItem) => {
    const filterUserData = props.activeFollowing?.data?.followers.filter(
      (user) => {
        return user?.otherUser?.name
          .toLowerCase()
          .includes(searchItem.toLowerCase());
      }
    );

    setUserData(filterUserData);
  };

  const changeSection = (event, type) => {
    setActiveSec(type);
    if (type === "active-sec") props.dispatch(fetchActiveFollowingStart());
    if (type === "expired-sec") props.dispatch(fetchExpiredFollowingStart());
    if (type === "all-sec") props.dispatch(fetchFollowingStart());
  };

  const handleSelectAllUser = (e) => {
    const allSelectedFollowingUser = userData?.map((follower) => {
      return {
        ...follower,
        otherUser: {
          ...follower.otherUser,
          isSelected: e.target.checked,
        },
      };
    });

    if (e.target.checked === true) {
      const allSelectedCheckedUser = userData?.map((elem) => elem.user_id);
      setCheckedUserId(allSelectedCheckedUser);
    }

    if (e.target.checked === false) {
      setCheckedUserId([]);
    }

    setUserData(allSelectedFollowingUser);
  };

  const handleIsSelected = () => {
    setIsSelected((prev) => !prev);

    const allSelectedFollowingUser = userData?.map((follower) => {
      return {
        ...follower,
        otherUser: {
          ...follower.otherUser,
          isSelected: false,
        },
      };
    });
    setCheckedUserId([]);
    setUserData(allSelectedFollowingUser);
  };

  const handleOpenAddUserModal = () => setIsAddUser(true);
  const handleCloseAddUserModal = () => setIsAddUser(false);

  const handleOpenRemoveUserModal = () => setIsRemoveUser(true);
  const handleCloseRemoveUserModal = () => setIsRemoveUser(false);

  const handleOpenRemoveUserTagModal = () => setIsTagUser(true);
  const handleCloseRemoveUserTagModal = () => setIsTagUser(false);

  const handleList = () => {
    setList(false);
  };
  return (
    <div className="lists">
      <Container>
        <Row>
          <Col sm={12} md={12} xs={12}>
            <div className="profile-post-area">
              <div className="bookmarkes-list bookmarks-right-side">
                <div className="pull-left">
                  <h3>
                    <Link
                      className="bookmarkes-list"
                      to={"#"}
                      onClick={() => props.history.goBack()}
                    >
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/icons/back.svg"
                        }
                        className="svg-clone"
                      />
                      {t("following")}
                    </Link>
                  </h3>
                </div>
              </div>

              <div className="search-component">
                <SearchInput
                  handleFilterUserData={handleFilterUserData}
                  following={props.activeFollowing}
                />
                <div className="list-icons" onClick={() => setList(true)}>
                  <svg
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-microsoft"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="#6661e7"
                      d="M7.462 0H0v7.19h7.462zM16 0H8.538v7.19H16zM7.462 8.211H0V16h7.462zm8.538 0H8.538V16H16z"
                    />
                  </svg>
                </div>
                <div className="list-icons" onClick={handleList}>
                  <svg
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-list-task"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="#6661e7"
                      fill-rule="evenodd"
                      d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5zM3 3H2v1h1z"
                    />
                    <path
                      fill="#6661e7"
                      d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1z"
                    />
                    <path
                      fill="#6661e7"
                      fill-rule="evenodd"
                      d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5zM2 7h1v1H2zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm1 .5H2v1h1z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="select-button-container">
              {userData?.length > 0 ? (
                <button className="select-btn" onClick={handleIsSelected}>
                  {isSelected ? t("Unselect") : t("Select")}
                </button>
              ) : (
                ""
              )}
              {isSelected && (
                <label htmlFor="select-all-input" className="select-all-user">
                  <input
                    type="checkbox"
                    checked={userData?.every(
                      (elem) => elem?.otherUser?.isSelected
                    )}
                    onChange={(e) => handleSelectAllUser(e)}
                    className="select-all-input"
                    id="select-all-input"
                  />
                  {t("Select_all")}
                </label>
              )}

              {userData?.some((elem) => elem?.otherUser?.isSelected) && (
                <button
                  className="Add-to-custom-favourite-list"
                  onClick={handleOpenAddUserModal}
                >
                  Add to
                </button>
              )}

              {userData?.some((elem) => elem?.otherUser?.isSelected) ? (
                <>
                  <button
                    className="remove__from__list"
                    onClick={handleOpenRemoveUserModal}
                  >
                    Remove from list
                  </button>

                  <button
                    className="color__tag__user"
                    onClick={handleOpenRemoveUserTagModal}
                  >
                    Tag by
                  </button>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="listing-tab">
              <div className="tab" role="tabpanel">
                <div className="tab-content tabs">
                  {list ? (
                    <FollowingActiveSec
                      activeSec={activeSec}
                      setActiveSec={setActiveSec}
                      following={props.activeFollowing}
                      userData={userData}
                      pagePath={"following"}
                      isSelected={isSelected}
                      setUserData={setUserData}
                      setCheckedUserId={setCheckedUserId}
                      checkedUserId={checkedUserId}
                    />
                  ) : (
                    <FollowingListView
                      activeSec={activeSec}
                      setActiveSec={setActiveSec}
                      following={props.activeFollowing}
                      userData={userData}
                    />
                  )}
                </div>
              </div>
            </div>

            {isAddUser && (
              <AddUserCustomFavourite
                isAddUser={isAddUser}
                handleCloseAddUserModal={handleCloseAddUserModal}
                checkedUserId={checkedUserId}
                setIsSelected={setIsSelected}
                setUserData={setUserData}
                userData={userData}
                setCheckedUserId={setCheckedUserId}
                followingType={"followingType"}
              />
            )}

            {isRemoveUser && (
              <RemoveUserFromList
                isRemoveUser={isRemoveUser}
                handleCloseRemoveUserModal={handleCloseRemoveUserModal}
                checkedUserId={checkedUserId}
                setIsSelected={setIsSelected}
                setUserData={setUserData}
                userData={userData}
                setCheckedUserId={setCheckedUserId}
                listType={"following"}
                userList={"Following"}
                removeType={"Unfollow"}
              />
            )}

            {isTagUser && (
              <ColorTagCustomizeModal
                isTagUser={isTagUser}
                handleCloseRemoveUserTagModal={handleCloseRemoveUserTagModal}
                checkedUserId={checkedUserId}
                setIsSelected={setIsSelected}
                setUserData={setUserData}
                userData={userData}
                setCheckedUserId={setCheckedUserId}
                listType={"following"}
              />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );    
};

const mapStateToPros = (state) => ({
  following: state.follow.following,
  activeFollowing: state.follow.activeFollowing,
  expiredFollowing: state.follow.expiredFollowing,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(FollowingIndex));
