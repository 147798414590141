import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import UserCard from "../UserCard";
import { translate, t } from "react-multi-lang";
import NoDataFound from "../../../NoDataFound/NoDataFound";
import FollowingLoader from "../../../Loader/FollowingLoader";
import { fetchListsDetailsStart } from "../../../../store/actions/HomeAction";
import { useDispatch } from "react-redux";

const FollowingActiveSec = (props) => {

  const dispatch = useDispatch()
  
  useEffect(() => {
    dispatch(fetchListsDetailsStart());
  },[])

  return (
    <>
      <div
        role="tabpanel"
        className={
          props.activeSec === "active-sec"
            ? "tab-pane fade in active"
            : "tab-pane fade"
        }
        id="active"
      >
           

        {props.following.loading  ? (
          <FollowingLoader/>
        ) : (
          <>
           
            <Row>
              {props.userData && props.userData.length > 0 ? (
                
                props.userData.map((follower) =>
                  follower.otherUser || follower.user ? (
                    <UserCard 
                      pagePath={props.pagePath} 
                      friendData={props.following.data.friends} 
                      friendListID={follower?.id} 
                      page={props.page} 
                      setUserData={props?.setUserData}
                      setCheckedUserId={props?.setCheckedUserId}
                      checkedUserId={props?.checkedUserId}
                      user={follower.otherUser || follower.user} 
                      isSelectedData={props?.userData}
                      isRestricted={follower?.is_resticted}
                      friendExtra={props.componentType === "friends" ? follower : null}
                      friendType={props.componentType === "friends" ? true : false}
                      followingType={props.pagePath === "following" ? true : false}
                      isSelected={props?.isSelected}
                    />
                  ) : (
                    ""
                  )
                )
              ) : (
                <NoDataFound></NoDataFound>
              )}
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default translate(FollowingActiveSec);
