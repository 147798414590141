import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image, Button, Media, Form, InputGroup, FormControl } from "react-bootstrap";
import "./BecomeAContentCreator.css";
import { createNotification } from "react-redux-notify";
import {
	getErrorNotificationMessage,
} from "../helper/NotificationMessage";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import { Field } from "formik";

const Step2 = (props) => {

	const handleCheckboxChange = (e) => {
		props.onCheckboxChange(e.target.checked); 
		props.setIsTerm(e.target.checked);
	  };

	return (
		<>
			<div className="step-2-content-sec">
				<div className="step-2-info">
					<h4>{t('conditions')}</h4>
					<p>{t('here_are_some_important_points_about_your_account')}</p>
				</div>
				<div className="step-2-footer-sec">
					<p>{t('if_you_are_reading_this_page')}</p>
				</div>

				<div className="step-2-footer-sec d-flex ">
				<input type="checkbox" id="terms" name="terms" checked={props.isTerm} onChange={handleCheckboxChange} />
					<p className="ml-2">{t("terms_and_conditions")}</p>
				</div>

				<div className="edit-save">
			  {
                 props.isTerm && (
					<Button
					className="btn gradient-btn gradientcolor addBank"
					type="button"
					onClick={(e) => props.handleSubDoc(e, props.jumpToStep)}
					// disabled={props.addBankAccount.buttonDisable}
				  >
					{/* {props.addBankAccount.loadingButtonContent !== null
					  ? props.addBankAccount.loadingButtonContent
					  : "Next"} */}
					  Next
				  </Button>
				 )
			  }
          
            </div>


			</div>
		</>
	);
};

function mapDispatchToProps(dispatch) {
	return { dispatch };
}

export default connect(null, mapDispatchToProps)(translate(Step2));
