import React from 'react'

const AccessControlMain = ({handleAccessChange , accessMainValue, type}) => {
  return (
    <div
    className="accessControlContainer-radio-wrapper"
    style={{
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    }}
  >
    <div style={{ display: "flex", gap: "5px", flexDirection : "row-reverse"}}>
      <label
        className="radio-btn"
        htmlFor={`${type}-id-1`}
        style={{ marginBottom: "0px", fontSize: "13px" }}
      >
        Public
      </label>
      <input
        style={{ margin: "0px" }}
        type="radio"
        id={`${type}-id-1`}
        name={type}
        onChange={(e)=> handleAccessChange(e, type)}
        value="public"
        checked={accessMainValue === "public"}
        // disabled={
        //   values?.accessContentData[0].mainToogle ===
        //     true &&
        //   accessT?.name?.mainToogle !== "fullAccess"
        // }
      />
    </div>
    <div style={{ display: "flex", gap: "5px", flexDirection : "row-reverse" }}>
      <label
        className="radio-btn"
        htmlFor={`${type}-id-2`}
        style={{ marginBottom: "0px", fontSize: "13px" }}
      >
        Restrict user
      </label>
      <input
        style={{ margin: "0px" }}
        type="radio"
        id={`${type}-id-2`}
        name={type}
        onChange={(e)=> handleAccessChange(e, type)}
        value="restrict_user"
        checked={accessMainValue === "restrict_user"}
        // disabled={
        //   values?.accessContentData[0].mainToogle ===
        //     true &&
        //   accessT?.name?.mainToogle !== "fullAccess"
        // }
      />
    </div>
    <div style={{ display: "flex", gap: "5px",flexDirection : "row-reverse" }}>
      <label
        className="radio-btn"
        htmlFor={`${type}-id-3`}
        style={{ marginBottom: "0px", fontSize: "13px" }}
      >
        No one
      </label>
      <input
        style={{ margin: "0px" }}
        type="radio"
        id={`${type}-id-3`}
        name={type}
        onChange={(e)=> handleAccessChange(e, type)}
        value="no_one"
        checked={accessMainValue === "no_one"}
        // disabled={
        //   values?.accessContentData[0].mainToogle ===
        //     true &&
        //   accessT?.name?.mainToogle !== "fullAccess"
        // }
      />
    </div>
  </div>
  )
}

export default AccessControlMain