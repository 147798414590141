import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchListsDetailsStart } from "../../../store/actions/HomeAction";
import { translate, t } from "react-multi-lang";
import ListsLoader from "../../Loader/ListsLoader";
import "./ListStyle.css";
import AddListModal from "./AddListModal";
import {
  allUserListStart,
  getAllUserCustomListStart,
  getCustomListStart,
  getFriendRequestSeenStart,
  getUserAllColorTagStart,
} from "../../../store/actions/CustomListAction";
import DeleteListModal from "./DeleteListModal";
import { useHistory } from "react-router-dom";
import { getAllFriendRequestStart, getAllFriendStart } from "../../../store/actions/FriendRequestAction";
import { fetchSubscribingStart } from "../../../store/actions/SubscribingAction";
import {
  fetchActiveFollowersStart,
  fetchActiveFollowingStart,
} from "../../../store/actions/FollowAction";
import { fetchBlockUsersStart } from "../../../store/actions/UserAction";
import { fetchSubscribersStart } from "../../../store/actions/SubscriberAction";
import { fetchFormerSubscriberStart } from "../../../store/actions/FormerSubscriberAction";
import { fetchUnsubscribedStart } from "../../../store/actions/UnsubscribedAction";
import { fetchFavStart } from "../../../store/actions/FavAction";
import UserSearchListIndex from "./UserSearchListIndex";
import { Multiselect } from "multiselect-react-dropdown";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { components } from "react-select";



const usersFilters = [
  { name: "All users", id: 1, label: "All users" , payload : "all_users" },
  { name: "All creators", id: 2, label: "All creators" , payload : "all_creator" },
  { name: "Star creator", label: "Star creator", payload: "" },
  { name: "Certified creator", id: 4, label: "Certified creator", payload : "all_certified" },
  { name: "Creator", id: 5, label: "Creator", payload : "creator" },
  { name: "All Non-creator", id: 6, label: "All Non-creator" , payload: "all_non_creator" },
  { name: "Suscribers", id: 7, label: "Suscribers" , payload: "subscriber" },
  { name: "Followers", id: 8, label: "Followers" , payload : "followers" },
  { name: "By color tag", id: 9, label: "By color tag" , payload: "by_color_tag" },
];

const Option = (props) => {
    console.log("🚀 ~ Option ~ props:", props);
    
  return (
    <div className="post-level-list-option-wrpapper">
      <components.Option {...props}>
          <div className="search-user-list-option-label-wrapper" style={{}}>
             <div style={{backgroundColor: `${props.data?.color_tag_code}`, height: "10px", width: "10px", borderRadius: "50%"}}></div>
             <label style={{marginBottom: "0px"}}>{props.label}</label>
          </div>
      </components.Option>
    </div>
  );
};

const ListIndex = (props) => {
  const [isAddListModalOpen, setIsAddListModalOpen] = useState(false);
  const [isCustomListDeleteModal, setIsCustomListDeleteModal] = useState(false);
  const [searchUserVal, setSearchUserVal] = useState("");


  const [listItem, setListItem] = useState([]);
  const [isDeleteId, setIsDeleteId] = useState(null);
  const [isEditCustomList, setIsEditCustomList] = useState({});

  const handleAddListModalClose = () => setIsAddListModalOpen(false);
  const handleAddListModalOpen = () => setIsAddListModalOpen(true);

  const handleDeleteListModalClose = () => setIsCustomListDeleteModal(false);
  const handleDeleteListModalOpen = () => setIsCustomListDeleteModal(true);

  const [isLoadingList, setIsLoadingList] = useState(false);


  const [selectedAccro, setSelectedAccro] = useState("");
  const [selectedCustomId, setSelectedCustomId] = useState(null);
  // console.log("🚀 ~ ListIndex ~ selectedCustomId:", selectedCustomId);
  // console.log("🚀 ~ ListIndex ~ selectedAccro:", selectedAccro)
  const [isSelectedUserCate, setIsSelectedUserCate] = useState([]);
  const [selectedColorTags, setSelectedColorTags] = useState(null);
  const [sortUsers, setSortUsers] = useState("");
  const [sortingToogle, setSortingToogle] = useState({
    aToz : false,
    upToDown : false
  })
  // console.log("🚀 ~ ListIndex ~ sortUsers:", sortUsers);
  // console.log("🚀 ~ ListIndex ~ isSelectedUserCate:", isSelectedUserCate);
  // console.log("🚀 ~ ListIndex ~ selectedColorTags:", selectedColorTags);

  const { is_certified_content_creator } = useSelector(
    (state) => state.users.profile.data
  );

  const customList = useSelector(
    (state) => state.customList.allCustomList.data
  );
  const { allListData } = useSelector((state) => state.customList.addUser);
  const { data: colorTagData, loading: colorTagLoader } = useSelector(
    (state) => state.customList.userAllColorTag
  );
  // console.log("🚀 ~ ListIndex ~ data:", colorTagData);
  const customeAddUserListData = useSelector(
    (state) => state.customList.addUser
  );


  const [selectedListValues, setSelectedListValues] = useState([]);

  const navigate = useHistory();

  useEffect(() => {
    props.dispatch(fetchListsDetailsStart());
    props.dispatch(getCustomListStart({ allListsData: allListData }));
  }, []);

  const handleDeleteCustomList = (id) => {
    setIsDeleteId(id);
    handleDeleteListModalOpen();
  };

  const handleSelectedCheckbox = (e) => {
    const { checked, value } = e.target;

    if (checked) {
      setSelectedListValues((prevSelected) => [...prevSelected, value]);
    } else {
      setSelectedListValues((prevSelected) =>
        prevSelected.filter((val) => val !== value)
      );
    }
  };

  useEffect(() => {
    if (selectedAccro === "Friends") {
      props.dispatch(
        getAllFriendStart({
          allListData: allListData,
          typeList: "Friends",
          setIsLoadingList: setIsLoadingList,
        })
      );
    } else if (selectedAccro === "Subscribing") {
      props.dispatch(
        fetchSubscribingStart({
          allListData: allListData,
          typeList: "Subscribing",
          setIsLoadingList: setIsLoadingList,
        })
      );
    } else if (selectedAccro === "Following") {
      props.dispatch(
        fetchActiveFollowingStart({
          allListData: allListData,
          typeList: "Following",
          setIsLoadingList: setIsLoadingList,
        })
      );
    } else if (selectedAccro === "Blocked") {
      props.dispatch(
        fetchBlockUsersStart({
          allListData: allListData,
          typeList: "Blocked",
          setIsLoadingList: setIsLoadingList,
        })
      );
    } else if (selectedAccro === "Restricted") {
      alert("comming soon");
    } else if (selectedAccro === "Subscribers") {
      props.dispatch(
        fetchSubscribersStart({
          allListData: allListData,
          typeList: "Subscribers",
          setIsLoadingList: setIsLoadingList,
        })
      );
    } else if (selectedAccro === "Followers") {
      props.dispatch(
        fetchActiveFollowersStart({
          allListData: allListData,
          typeList: "Followers",
          setIsLoadingList: setIsLoadingList,
        })
      );
    } else if (selectedAccro === "Former Subscriber") {
      props.dispatch(
        fetchFormerSubscriberStart({
          allListData: allListData,
          typeList: "Former Subscriber",
          setIsLoadingList: setIsLoadingList,
        })
      );
    } else if (selectedAccro === "Unsubscribed") {
      props.dispatch(
        fetchUnsubscribedStart({
          allListData: allListData,
          typeList: "Unsubscribed",
          setIsLoadingList: setIsLoadingList,
        })
      );
    } else if (selectedAccro === "Favorites") {
      props.dispatch(
        fetchFavStart({
          allListData: allListData,
          typeList: "Favorites",
          setIsLoadingList: setIsLoadingList,
        })
      );
    } else if (selectedAccro === "custom_list") {
      props.dispatch(
        getAllUserCustomListStart({
          allListData: allListData,
          typeList: "Favorites",
          id: selectedCustomId,
          setIsLoadingList: setIsLoadingList,
        })
      );
    }
  }, [selectedAccro, selectedCustomId]);

  useEffect(() => {
    if (isSelectedUserCate.some((ele) => ele.id === 9) && colorTagData.length <= 0
    ) {
      props.dispatch(getUserAllColorTagStart());
    }
  }, [isSelectedUserCate]);
  
  

  return (
    <div className="lists">
      <Container>
        <Row>
          <Col sm={12} md={12}>
            {props.lists.loading ? (
              <ListsLoader />
            ) : (
              <div className="vertical-menu">
                <div className="bookmarkes-list bookmarks-right-side list-header">
                  <div className="pull-left">
                    <Link
                      className="bookmarkes-list h2"
                      to={"/home"}
                      // onClick={() => {
                      //   props.history.goBack()
                      //   // props.dispatch(isListUtilsIsList(true))
                      // }}
                    >
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/icons/back.svg"
                        }
                        className="svg-clone"
                      />
                      {t("lists")}
                    </Link>
                  </div>

                  <div className="btn-container">
                    <Button
                      className="list-submit-btn"
                      type="button"
                      onClick={() => {
                        setIsEditCustomList({});
                        handleAddListModalOpen();
                        setSelectedListValues([]);
                        props.dispatch(allUserListStart());
                      }}
                    >
                      Add List
                    </Button>

                    <Button
                      type="button"
                      className="list-submit-btn list-friend-req-add-btn"
                      onClick={() => { 
                        navigate.push("/friend-requests");
                      }}
                    >
                      Friend Requests
                     {props.listUtils.isFriendRequest === 1 && <div className="list-friend-req-red-dot"></div>} 
                    </Button>
                  </div>
                </div>

                <div className="search-list-con-main">
                  <UserSearchListIndex searchUserVal={searchUserVal} setSearchUserVal={setSearchUserVal} sortingToogle={sortingToogle} SelectedUserCate={isSelectedUserCate} sortUser={sortUsers} selectedColorTags={selectedColorTags} />

                  <div className="list-filter-search-sort-container">
                    <div className="list-filter-search-container">
                      <Multiselect
                        name="category_ids"
                        id="selectedCategories"
                        selectedValues={isSelectedUserCate}
                        options={usersFilters}
                        displayValue="name"
                        avoidHighlightFirstOption="true"
                        placeholder={t("Filter")}
                        // onSelect={(selectedList)=> {
                        //   debugger;
                        // } }
                        onSelect={(selectedList) =>
                          setIsSelectedUserCate(selectedList)
                        }
                        onRemove={(selectedList) => { 
                          console.log("🚀 ~ ListIndex ~ selectedList:", selectedList)
                           const isByColor = selectedList.some((ele)=> ele.id === 9);
                           if(!isByColor){
                            setSelectedColorTags(null);
                           }
                          setIsSelectedUserCate(selectedList);
                        
                        }}
                      />
                      {isSelectedUserCate.some((ele) => ele.id === 9) ? (
                        <div style={{display: "flex", flexDirection: "column"}}>
                        
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          // defaultValue={usersFilters[0]}
                          isDisabled={colorTagLoader}
                          isLoading={colorTagLoader}
                          isClearable={false}
                          isRtl={false}
                          isSearchable={false}
                          components={{ Option , animatedComponents: makeAnimated()}}
                          placeholder="Color tag"
                          name="color"
                          // value={selectedColorTags}
                          options={colorTagData?.map((ele) => {
                            return {
                              name: ele.tag_title,
                              label: ele.tag_title,
                              color_tag_code: ele.tag_colour_code,
                            };
                          })}
                          onChange={(selected) => {
                            // debugger;
                            setSelectedColorTags(selected);
                          }}
                        />
                         
                     </div>
                      ) : (
                        //  <select name="color-tag" id="color-tag-id" className="color-tag-list-select">
                        //    {
                        //     colorTagLoader ? (
                        //       <option disabled={true} value="">Loading...</option>
                        //     ) : (
                        //       colorTagData?.length ? (
                        //         colorTagData?.map((colorTag, index)=>{
                        //           return(
                        //             <option value={colorTag.tag_title} key={index}>
                        //                <div style={{background: `${colorTag.tag_colour_code}`}} > </div>
                        //                <p>{colorTag.tag_title}</p>
                        //             </option>
                        //           )
                        //         })

                        //       ) : (

                        //         <option value="">No color tags</option>

                        //       )

                        //     )
                        //    }
                        //  </select>

                        ""
                      )}
                    </div>

                    <div className="list-filter-search-sort-container">
                      <select
                        name="color-tag"
                        id="color-tag-id"
                        className="color-tag-list-select"
                        onChange={(e) => { 
                          setSortUsers(e.target.value);
                          setSortingToogle({
                            aToz : false,
                            upToDown : false
                          })
                        }}
                      >
                        <option value="">Sort</option>
                        <option value="username">Name</option>
                        <option value="latest">Latest</option>
                      </select>

                      {sortUsers !== "" ? (
                        sortUsers === "username" ? (
                          <>
                             {
                              !sortingToogle.aToz ? (
                                <img
                                onClick={()=> setSortingToogle({ ...sortingToogle, aToz : !sortingToogle.aToz })}
                                className="list-sort-arrow-up"
                                style={{cursor : "pointer"}}
                                src={
                                  window.location.origin +
                                  "/assets/images/icons/sort-up.png"
                                }
                                alt="arrow-up"
                              />
                              ) : (
                                <img
                                onClick={()=> setSortingToogle({ ...sortingToogle, aToz : !sortingToogle.aToz })}
                                style={{cursor : "pointer"}}
                                className="list-sort-arrow-down"
                                src={
                                  window.location.origin +
                                  "/assets/images/icons/sort.png"
                                }
                                alt="arrow-down"
                              />
                              )
                             }
                                           
                          </>
                        ) : (
                          <>
                            {
                              !sortingToogle.upToDown ? (
                                <img
                                onClick={()=> setSortingToogle({ ...sortingToogle, upToDown : !sortingToogle.upToDown })}
                                style={{cursor : "pointer"}}
                                className="same-arrow"
                                src={
                                  window.location.origin +
                                  "/assets/images/icons/down-arrow.png"
                                }
                                alt="arrow-down"
                              />
                               

                              ) : (
                                <img
                                onClick={()=> setSortingToogle({ ...sortingToogle, upToDown : !sortingToogle.upToDown })}
                                style={{cursor : "pointer"}}
                                className="same-arrow"
                                src={
                                  window.location.origin +
                                  "/assets/images/icons/up-arrow.png"
                                }
                                alt="arrow-up"
                              />
                              )
                            }
                           
                         
                          </>
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <AddListModal
                  isEditCustomList={isEditCustomList}
                  handleAddListModalClose={handleAddListModalClose}
                  handleAddListModalOpen={handleAddListModalOpen}
                  isAddListModalOpen={isAddListModalOpen}
                  setListItem={setListItem}
                  listItem={listItem}
                  customList={customList}
                  selectedListValues={selectedListValues}
                  handleSelectedCheckbox={handleSelectedCheckbox}
                  allList={allListData}
                  setSelectedAccro={setSelectedAccro}
                  setSelectedCustomId={setSelectedCustomId}
                  isLoadingList={isLoadingList}
                  customeAddUserListData={customeAddUserListData}
                />


              <div className="all-list-user-container-wrapper" style={{marginTop: "30px"}}>

          
                    <div className="all-list-main-conainer">

                    {customList.length > 0 &&
                      customList.map((elem) => (
                    <div className="user-lists" key={elem?.CID}>
                      <Link
                        to={{
                          pathname: `/list-view/${elem?.CID}`,
                          state: {
                            data: true,
                            from: elem?.list_name,
                          },
                        }}
                        className="nav-link"
                      >
                        <div className="pull-left">
                          <h3>{elem?.list_name}</h3>
                          <span className="user-list-count">
                            {elem?.total_user} {t("people")}
                          </span>
                        </div>
                      </Link>
                      <div className="pull-right right-custom-container">
                        <i
                          className="fa fa-pencil-square-o"
                          aria-hidden="true"
                          style={{
                            fontSize: "18px",
                            color: "#8c2be2",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setIsEditCustomList(elem);
                            handleAddListModalOpen();
                          }}
                        />
                        <i
                          className="fa fa-trash"
                          aria-hidden="true"
                          style={{
                            fontSize: "18px",
                            color: "red",
                            cursor: "pointer",
                          }}
                          onClick={() => handleDeleteCustomList(elem?.CID)}
                        />
                        {/* <button onClick={() => handleDeleteCustomList(elem?.CID)}>delete</button> */}
                      </div>
                    </div>
                  ))}

                <DeleteListModal
                  isDeleteId={isDeleteId}
                  isCustomListDeleteModal={isCustomListDeleteModal}
                  handleDeleteListModalClose={handleDeleteListModalClose}
                  customList={customList}
                />
                <div className="user-lists">
                  <Link to="/friends">
                    <div className="pull-left">
                      <h3>Friends</h3>
                      <span className="user-list-count">
                        {props.lists.data.total_friends || 0} {t("people")}
                      </span>
                    </div>
                    <div className="pull-right"></div>
                  </Link>
                </div>

                <div className="user-lists">
                  <Link to={"/subscribing"}>
                    <div className="pull-left">
                      <h3>{t("subscribing_main")}</h3>
                      <span className="user-list-count">
                        {props.lists.data.total_subscribing} {t("people")}
                      </span>
                    </div>
                    <div className="pull-right"></div>
                  </Link>
                </div>

                <div className="user-lists">
                  <Link to={"/following"}>
                    <div className="pull-left">
                      <h3>{t("following")}</h3>
                      <span className="user-list-count">
                        {props.lists.data.total_followings} {t("people")}
                      </span>
                    </div>
                  </Link>
                </div>

                <div className="user-lists">
                  <Link to={"/blocked-users"}>
                    <div className="pull-left">
                      <h3>{t("blocked_users")}</h3>
                      <span className="user-list-count">
                        {props.lists.data.blocked_users} {t("people")}
                      </span>
                    </div>
                    <div className="pull-right"></div>
                  </Link>
                </div>

                <div className="user-lists">
                  <Link to={"/restricted-users"}>
                    <div className="pull-left">
                      <h3>Restricted</h3>
                      <span className="user-list-count">
                        {props.lists.data.restricted_users} {t("people")}
                      </span>
                    </div>
                    <div className="pull-right"></div>
                  </Link>
                </div>

                {is_certified_content_creator == 2 && (
                  <div className="user-lists">
                    <Link to={"/subscribers"}>
                      <div className="pull-left">
                        <h3>{t("subscribers_main")} </h3>
                        <span className="user-list-count">
                          {props.lists.data.total_subscriber} {t("people")}
                        </span>
                      </div>
                      <div className="pull-right"></div>
                    </Link>
                  </div>
                )}
                <div className="user-lists">
                  <Link to={"/followers"}>
                    <div className="pull-left">
                      <h3>{t("followers")}</h3>
                      <span className="user-list-count">
                        {props.lists.data.total_followers} {t("people")}
                      </span>
                    </div>
                    <div className="pull-right"></div>
                  </Link>
                </div>

                {is_certified_content_creator == 2 && (
                  <div className="user-lists">
                    <Link to={"/formersubscriber"}>
                      <div className="pull-left">
                        <h3>{t("former_subscriber")}</h3>
                        <span className="user-list-count">
                          {props.lists.data.total_former_subscribed}{" "}
                          {t("people")}
                        </span>
                      </div>
                      <div className="pull-right"></div>
                    </Link>
                  </div>
                )}

                <div className="user-lists">
                  <Link to={"/unsubscribed"}>
                    <div className="pull-left">
                      <h3>{t("unsubscribed")}</h3>
                      <span className="user-list-count">
                        {props.lists.data.total_unsubscribed} {t("people")}
                      </span>
                    </div>
                    <div className="pull-right"></div>
                  </Link>
                </div>

                <div className="user-lists">
                  <Link to={"/favorites"}>
                    <div className="pull-left">
                      <h3>{t("favorites")}</h3>
                      <span className="user-list-count">
                        {props.lists.data.total_fav_users} {t("people")}
                      </span>
                    </div>
                    <div className="pull-right"></div>
                  </Link>
                </div>

                    </div>
                  
                
             
                </div>         
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToPros = (state) => ({
  lists: state.home.lists,
  listUtils : state.customList.customeListUtils,
  userProfile : state.users.profile.data
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(ListIndex));
