import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { FETCH_NOTIFICATIONS_START, FETCH_MORE_NOTIFICATIONS_START, FETCH_NOTIFICATION_SETTING_START, POST_NOTIFICATION_SETTING_START } from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";

import {
  fetchNotificationSettingFailure,
  fetchNotificationSettingSuccess,
  fetchNotificationsFailure,
  fetchNotificationsSuccess,
  postNotificationSettingFailure,
  postNotificationSettingSuccess,
} from "../actions/NotificationAction";

function* fetchNotificationAPI(action) {
  try {
    const response = yield api.postMethod(
      "bell_notifications_index", action.data
    );
    if (response.data.success) {
      yield put(fetchNotificationsSuccess(response.data.data));
    } else {
      yield put(fetchNotificationsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchNotificationsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchNotificationSetting() {
  try {
    const response = yield api.postMethod("get_post_setting");

    if (response.data?.success) {
      yield put(fetchNotificationSettingSuccess(response.data?.data));
    } else {
      yield put(fetchNotificationSettingFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      ); 
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
      yield put(fetchNotificationSettingFailure(error));
      const notificationMessage = getErrorNotificationMessage(error.message);
      yield put(createNotification(notificationMessage));
  }
}

function* postNotificationApi({payload}) {
  try {
    const response = yield api.postMethod(
      "update_post_setting", payload
    );

    if (response.data?.success) {
      yield put(postNotificationSettingSuccess(response.data?.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(postNotificationSettingFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      ); 
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(postNotificationSettingFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_NOTIFICATIONS_START, fetchNotificationAPI),
  ]);
  yield all([
    yield takeLatest(FETCH_MORE_NOTIFICATIONS_START, fetchNotificationAPI),
  ]);
  yield all([yield takeLatest(FETCH_NOTIFICATION_SETTING_START, fetchNotificationSetting)]);
  yield all([yield takeLatest(POST_NOTIFICATION_SETTING_START, postNotificationApi)])
}
