import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Field, Formik } from "formik";
import { t } from "react-multi-lang";
import "./ListStyle.css";
import { useDispatch, useSelector } from "react-redux";
import { addCustomListStart } from "../../../store/actions/CustomListAction";
import AddListUserModel from "./AddListUserModel";

const AddListModal = (props) => {

  const {
    isAddListModalOpen,
    handleAddListModalOpen,
    handleAddListModalClose,
    customList,
    isEditCustomList,
  } = props;

  const initialValues = {
    addLists: isEditCustomList?.list_name || "",
  };

  const isLoading = useSelector(
    (state) => state.customList.customeListResponse.loading
  );
  const dispatch = useDispatch();

  function validateCustomList(value) {
    let error;

    if (!value) {
      error = "list is required";
    }

    const isValueChecked = customList.find((item) => {
      return item.list_name === value;
    });

    const isEditValueChecked = isEditCustomList?.list_name ? true : false;

    if (!isEditValueChecked) {
      if (isValueChecked?.list_name !== undefined) {
        if (isValueChecked?.list_name === value) {
          error = `${value} is already exist, please give another try`;
        }
      }
    }

    const isUpdateValue = customList.filter((item) => {
      return item?.list_name !== isEditCustomList?.list_name;
    });

    const isCheckUpdateValue = isUpdateValue?.find((item) => {
      return item?.list_name === value;
    });

    if (isEditValueChecked) {
      if (isCheckUpdateValue?.list_name === value) {
        error = `can not update the existing ${value} list`;
      }
    }

    return error;
  }

  return (
    <Modal
      show={isAddListModalOpen}
      onHide={handleAddListModalClose}
      backdrop="static"
      keyboard={false}
      centered
      className={`${
        localStorage.getItem("theme") !== "" &&
        localStorage.getItem("theme") !== null &&
        localStorage.getItem("theme") !== undefined &&
        localStorage.getItem("theme") === "dark"
          ? "dark-theme-modal"
          : ""
      }
        `}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          dispatch(
            addCustomListStart({
              listValue: { list_name: values.addLists ,  users :props.selectedListValues.length ? props.selectedListValues.toString() : ""},
              handleClose: handleAddListModalClose,
              customList: customList,
              isEditCustomList: isEditCustomList,
              totalSelectedListUsers : props.selectedListValues.length,

            })
          );
        }}
      >
        {({ errors, touched, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>
                {isEditCustomList?.CID
                  ? "Update custome list"
                  : "Add custom list"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                className="update-custome-model"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div className="field-container">
                  <label htmlFor="addLists">
                    {isEditCustomList?.CID ? "Update list" : "Add list"}
                  </label>
                  <Field
                    id="addLists"
                    name="addLists"
                    className="add-list"
                    validate={validateCustomList}
                    placeholder={
                      isEditCustomList?.CID ? "" : "Enter custom list"
                    }
                  />
                  {errors.addLists && touched.addLists ? (
                    <div className="list-error">{errors.addLists}</div>
                  ) : null}
                </div>
              
              {
                !isEditCustomList?.CID ? (
                  <div className="field-container">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontSize: "13px", fontWeight: "700" }}>
                      Add user
                    </p>
                    <p style={{ fontSize: "13px", fontWeight: "700" }}>
                      {props.selectedListValues.length ? props.selectedListValues?.length : 0} selected
                    </p>
                  </div>

                  

                  {
                  
                  props.customeAddUserListData.loading ? (
                    <h4 style={{textAlign: "center" , marginTop: "10px"}}>Loading...</h4>

                  ) : (

                    props.allList.length ? (
                      <div
                        style={{
                          height: "359px",
                          overflow: "auto",
                          paddingRight: "6px",
                        }}
                      >
                        {props.allList.map((ele, index) => {
                          return (
                            <AddListUserModel
                              setSelectedCustomId={props.setSelectedCustomId}
                              setSelectedAccro={props.setSelectedAccro}
                              key={ele.CID}
                              singleData={ele}
                              labelType={ele.list_name}
                              Option={props.Option}
                              optionsValue={props.listOptions}
                              MultiValue={props.MultiValue}
                              handleChangeValue={props.handleSelectedCheckbox}
                              value={props.selectedListValues}
                              isLoadingList={props.isLoadingList}
                              index={index}
                            />
                          );
                        })}
                      </div>
                    ): ""
                         
                  ) 
                  
                    }
                </div>

                ) : "" 
              }

                
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                size="lg"
                onClick={() => {
                  handleAddListModalClose();
                }}
              >
                Back
              </Button>
              <Button
                size="lg"
                className="ftr-btn"
                disabled={isLoading ? true : false}
                type="submit"
              >
                {isLoading
                  ? "loading..."
                  : isEditCustomList?.CID
                  ? "Update"
                  : "Add"}
              </Button>
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddListModal;
