import React, {useEffect, useState} from 'react'
import { Button, Container, Row, Col, Table, Image } from "react-bootstrap";
import CommonCenterLoader from '../../Loader/CommonCenterLoader';
import VerifiedBadgeNoShadow from '../../Handlers/VerifiedBadgeNoShadow';
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from '../../helper/NotificationMessage';
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { translate, t } from "react-multi-lang";
import { useDispatch } from 'react-redux';
import { saveFavStart } from '../../../store/actions/FavAction';
import SendTipModal from '../../helper/SendTipModal';
import NoDataFound from '../../NoDataFound/NoDataFound';
import '../FansFollowing/FollowingListView.css'
import './FavoriteList.css'

const FavoritesListView = (props) => {
	const [sendTip, setSendTip] = useState(false);
	const [filterUser, setFilterUser] = useState(props.userData)
	const [tipModel, setTipModel] = useState({})

	useEffect(() => {
		setFilterUser(props.userData)
	}, [props.userData])

	const closeSendTipModal = () => {
    setSendTip(false);
  };

	const openSendTipModel = () => {
		setSendTip(true)
	}

	const handleStar = (e, id) => {
		e.preventDefault()

		dispatch(
			saveFavStart({
				user_id: id,
			})
		);
		// console.log(filterUser, "filter user")
		const filterUserFavorites = filterUser.map(favorite => {
			if (favorite?.fav_user?.user_id === id) {
					return {
						...favorite,
						fav_user : {
							...favorite.fav_user,	
							is_fav_user: favorite?.fav_user?.is_fav_user ==1 ? 0 : 1
						} 
					}
			} else {
				return favorite
			}
		})

		setFilterUser(filterUserFavorites)
	}

	const handleRemoveFavorite = (e, id) => {
		e.preventDefault()

		const removeFavoriteUser = filterUser.filter(favorite => {
			return favorite?.fav_user?.user_id !== id
		})

		setFilterUser(removeFavoriteUser)
		console.log(removeFavoriteUser, "checking the remove favorite user")
	}

	const handleTipModel = ( tipObj) => {
		setTipModel(tipObj)
	}

	const dispatch = useDispatch()

	return (
		<div className='following-table-sec'>
			<Container>
				<Row>
					<Col sm={12} md={12}>
						<div className="following-table">
							<Table borderedless responsive>
								<thead>
									<tr>
										<th>Profile Picture</th>
										<th>Name</th>
										<th>Username</th>
										<th>Share Link</th>
										<th>Favorites</th>
										<th>Tip</th>
									</tr>
								</thead>
								<tbody>
									{
										props.favorites.loading
											?
											(
												<CommonCenterLoader></CommonCenterLoader>
											)
											:filterUser && filterUser.length > 0 
											?	
												(
													filterUser.map((favorite) =>
														favorite.fav_user ? (
															// <UserCard user={favorite.fav_user} />
															<tr key={favorite.fav_user.user_unique_id}>
																<td className='amount'>
																	{/* <span className="favorite-profile-avatar"> */}
																		<Image 
																			src={favorite.fav_user.picture} className="" 
																			style={{
																				width: "50px",
																				height: "50px",
																				borderRadius: "50%"
																			}}
																		/>
																	{/* </span> */}
																</td>
																<td className='amount'> 
																	{/* <div className="favorite-name-row"> */}
																		<Link to={favorite.fav_user.user_unique_id}>
																			<div className="favorite-user-name">
																				{favorite.fav_user.name}{" "}
																				{favorite.fav_user.is_verified_badge == 1 ? (
																					<VerifiedBadgeNoShadow />
																				) : null}
																			</div>
																		</Link>
																	{/* </div> */}
																</td>
																<td className='amount'>
																	<div className="favorite-name-row">
																		<Link
																			to={`/` + favorite.fav_user.user_unique_id}
																			className="g-user-realname__wrapper"
																		>
																			<div className="favorite-user-id">
																				@{favorite.fav_user.username}
																			</div>
																		</Link>
																	</div>	
																</td>
																<td className='amount'>
																	<div className="group-favorite-btns">
																		<CopyToClipboard
																			text={favorite.fav_user.share_link}
																			onCopy={() => {
																				const notificationMessage = getSuccessNotificationMessage(
																					t('profile_link_copied')
																				);
																				dispatch(createNotification(notificationMessage));
																			}}
																		>
																			<Button
																				type="button"
																				className="g-btn m-rounded m-border m-icon m-icon-only m-colored has-tooltip"
																			>
																				<Image
																					src="assets/images/icons/share.svg"
																					className="svg-clone "
																				/>
																			</Button>
																		</CopyToClipboard>
																	</div>
																</td>
																<td className='amount'>
																	<div className="swiper-favorite">
																			<Link
																				type="button"
																				className="swiper-btn-fav"
																				onClick={(event) => {
																					
																					handleStar(event, favorite.fav_user.user_id)
																					handleRemoveFavorite(event, favorite.fav_user.user_id)					
																				}} 
																			>
																				{
																					favorite.fav_user.is_fav_user == 1 
																						?
																							<Image
																								src="assets/images/icons/star-active.svg"
																								className="svg-clone my-p-icons"
																								width="12"
																							/>
																						:
																						<Image
																							src="assets/images/icons/star.svg"
																							className="svg-clone my-p-icons"
																							width="12"
																						/>
																				}
																				
																			</Link>
																	</div>
																</td>
																<td className='amount'>
																	{
																		favorite.fav_user?.is_certified_content_creator === 2
																			&&
																			<div>
																			<span
																				style={{
																					padding: "10px",
																					backgroundColor: "#6661e7	",
																					display: "flex",
																					alignItems: "center",
																					width: "50px",
																					height: "50px",
																					borderRadius: "50%",
																					justifyContent: "center",
																					cursor: "pointer"
																				}}
																				onClick={() => {
																					openSendTipModel()
																					handleTipModel({
																						username:favorite.fav_user.username,
																						userPicture:favorite.fav_user.picture,
																						name:favorite.fav_user.usernamee,
																						post_id:null,
																						user_id:favorite.fav_user.user_id
																					})}
																				}
																			>
																				<Image
																					src="assets/images/icons/tip.svg"
																					className="svg-clone"
																				/>
																			</span>
																		</div>
																	}
																	
																</td>
															</tr>
														) : (
															""
														)
													)
												)
											: (
												<NoDataFound></NoDataFound>
											)
									}
								</tbody>
							</Table>
							<SendTipModal
								sendTip={sendTip}
								closeSendTipModal={closeSendTipModal}
								username={tipModel.username}
								userPicture={tipModel.userPicture}
								name={tipModel.name}
								post_id={null}
								user_id={tipModel.user_id}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default FavoritesListView