import { FETCH_FORMER_SUBSCRIBER_FAILURE, FETCH_FORMER_SUBSCRIBER_START, FETCH_FORMER_SUBSCRIBER_SUCCESS, FETCH_SUBSCRIBERS_FAILURE, FETCH_SUBSCRIBERS_START, FETCH_SUBSCRIBERS_SUCCESS, FETCH_SUBSCRIBING_FAILURE, FETCH_SUBSCRIBING_START, FETCH_SUBSCRIBING_SUCCESS, FETCH_UNSUBSCRIBED_FAILURE, FETCH_UNSUBSCRIBED_START, FETCH_UNSUBSCRIBED_SUCCESS, GET_USER_SUBSCRIBERS_FAILURE, GET_USER_SUBSCRIBERS_SUCCESS } from "../actions/ActionConstant";

const initialState = {
    subscribers: {
      data: {},
      loading: true,
      error: false,
    },

    activeSubscribers : {
      data: {},
      loading: true,
      error: false,
    },

    subscribingUser: {
      data: {},
      loading: true,
      error: false,
    },

    unsubscribed: {
      data: {},
      loading: true,
      error: false,
    },

    formerSubscriber: {
      data: {},
      loading: true,
      error: false,
    }

}

const SubscriberReducer = (state=initialState, action)=>{

    switch(action.type){
         
        case GET_USER_SUBSCRIBERS_SUCCESS:
            return {
              ...state,
              subscribers: {
                data: action.payload,
                loading: false,
                error: false,
              },

            };

        case GET_USER_SUBSCRIBERS_FAILURE:
            return {
              ...state,
              subscribers : {
                ...state.subscribers,
                error : action.payload
              }
            };

        
        case FETCH_SUBSCRIBERS_START:
          return {
            ...state,
            subscribers: {
              data: {},
              loading: true,
              error: false,
            }
          }

        case FETCH_SUBSCRIBERS_SUCCESS:
          return {
            ...state,
            subscribers: {
              data: action.payload,
              loading: false,
              error: false,
            },

          };

        case FETCH_SUBSCRIBERS_FAILURE:
            return {
              ...state,
              subscribers : {
                data: {},
                loading: true,
                error: action.error,
              }
            };

        case FETCH_SUBSCRIBING_START:
          return {
            ...state,
            subscribingUser: {
              data: {},
              loading: true,
              error: false,
            }
          }
    
        case FETCH_SUBSCRIBING_SUCCESS: 
          return {
            ...state,
            subscribingUser: {
              data: action.data,
              loading: false,
              error: false,
            }
          }
    
        case FETCH_SUBSCRIBING_FAILURE:
          return {
            ...state,
            subscribingUser: {
              data: {},
              loading: true,
              error: action.error,
            }
          }

        case FETCH_UNSUBSCRIBED_START:
          return {
            ...state,
            unsubscribed: {
              data: {},
              loading: true,
              error: false,
            }
          }

        case FETCH_UNSUBSCRIBED_SUCCESS:
          return {
            ...state,
            unsubscribed: {
              data: action.data,
              loading: false,
              error: false,
            }
          }

        case FETCH_UNSUBSCRIBED_FAILURE:
          return {
            ...state,
            unsubscribed: {
              data: {},
              loading: true,
              error: action.error,
            }
          }

        case FETCH_FORMER_SUBSCRIBER_START:
          return {
            ...state,
            formerSubscriber: {
              data: {},
              loading: true,
              error: false,
            }
          }

        case FETCH_FORMER_SUBSCRIBER_SUCCESS:
          return {
            ...state,
            formerSubscriber: {
              data: action.data,
              loading: false,
              error: false,
            }
          }

        case FETCH_FORMER_SUBSCRIBER_FAILURE:
          return {
            ...state,
            formerSubscriber: {
              data: {},
              loading: true,
              error: action.error,
            }
          }
        default:
         return state;
    }

}

export default SubscriberReducer;
