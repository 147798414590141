import {
    ADD_FRIEND_REQUEST_START,
    ADD_FRIEND_REQUEST_SUCCESS,
    ADD_FRIEND_REQUEST_FAILURE,
    GET_ALL_FRIEND_REQUEST_START,
    GET_ALL_FRIEND_REQUEST_SUCCESS,
    GET_ALL_FRIEND_REQUEST_FAILURE,
    REMOVE_FRIEND_REQUEST_START,
    REMOVE_FRIEND_REQUEST_SUCCESS,
    REMOVE_FRIEND_REQUEST_FAILURE,
    ACCEPT_FRIEND_REQUEST_START,
    ACCEPT_FRIEND_REQUEST_SUCCESS,
    ACCEPT_FRIEND_REQUEST_FAILURE,
    GET_ALL_FRIEND_START,
    GET_ALL_FRIEND_SUCCESS,
    GET_ALL_FRIEND_FAILURE,
  } from "../actions/ActionConstant";
  
  const initialState = {

    AddFriendReqUser: {
      data: {},
      loading: false,
      error: false,
    },
   
    removeFriend : {
      data: {},
      loading: false,
      error: false,
    },

    allFriendRequestsData : {
        data: {},
        loading: false,
        error: false,
    },

    acceptFriend : {
      
      data : {},
      loading : false,
      error : false

    },

    allActiveFriends : {  
      data : {},
      loading : false,
      error : false
    }

  };
  
  const FriendRequestReducer = (state = initialState, action) => {
    switch (action.type) {



        case ADD_FRIEND_REQUEST_START:
            return {
              ...state,
              AddFriendReqUser: {
                data: {},
                loading: true,
                error: false,
              },
            };
          case ADD_FRIEND_REQUEST_SUCCESS:
            return {
              ...state,
              AddFriendReqUser: {
                data: action.data,
                loading: false,
                error: false,
              },
            };
          case ADD_FRIEND_REQUEST_FAILURE:
            return {
              ...state,
              AddFriendReqUser: {
                data: {},
                loading: false,
                error: action.error,
              },
            };


            case REMOVE_FRIEND_REQUEST_START:
              return {
                ...state,
                removeFriend: {
                  data: {},
                  loading: true,
                  error: false,
                  id : action.data,
                },
              };
            case REMOVE_FRIEND_REQUEST_FAILURE:
              return {
                ...state,
                removeFriend: {
                  data: {},
                  loading: false,
                  error: action.error,
                  id : null,
                },
              };

            case REMOVE_FRIEND_REQUEST_SUCCESS:
              return {
                ...state,
                removeFriend: {
                  data: action.data,
                  loading: false,
                  error: false,
                  id : null,
                },
              };


        case GET_ALL_FRIEND_REQUEST_START:
            return {
              ...state,
              allFriendRequestsData: {
                data: {},
                loading: true,
                error: false,
              },
            };

            
          case GET_ALL_FRIEND_REQUEST_SUCCESS:
            return {
              ...state,
              allFriendRequestsData: {
                data: action.data,
                loading: false,
                error: false,
              },
            };

          case GET_ALL_FRIEND_REQUEST_FAILURE:
            return {
              ...state,
              allFriendRequestsData: {
                data: {},
                loading: false,
                error: action.error,
              },
            };


            case ACCEPT_FRIEND_REQUEST_START:
              return {
                ...state,
                acceptFriend: {
                  data: {},
                  loading: true,
                  error: false,
                },
              };
            case ACCEPT_FRIEND_REQUEST_SUCCESS:
              return {
                ...state,
                acceptFriend: {
                  data: action.data,
                  loading: false,
                  error: false,
                },
              };
            case ACCEPT_FRIEND_REQUEST_FAILURE:
              return {
                ...state,
                acceptFriend: {
                  data: {},
                  loading: false,
                  error: action.error,
                },
              };


              
        case GET_ALL_FRIEND_START:
          return {
            ...state,
            allActiveFriends: {
              data: {},
              loading: true,
              error: false,
            },
          };
          
        case GET_ALL_FRIEND_SUCCESS:
          return {
            ...state,
            allActiveFriends: {
              data: action.data,
              loading: false,
              error: false,
            },
          };


        case GET_ALL_FRIEND_FAILURE:
          return {
            ...state,
            allActiveFriends: {
              data: {},
              loading: false,
              error: action.error,
            },
          };



      default:
        return state;
    }
  };
  
  export default FriendRequestReducer;
  