import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  FETCH_POST_LIKED_START,
  SAVE_POST_LIKE_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  fetchPostLikedFailure,
  fetchPostLikedSuccess,
  savePostLikeFailure,
  savePostLikeSuccess,
} from "../actions/PostLikesAction";
import {
  checkLogoutStatus,
} from "../actions/ErrorAction";
import { homePostsSuccess } from "../actions/HomeAction";
import { fetchPostsSuccess, fetchSinglePostSuccess } from "../actions/PostAction";
import { fetchSingleUserPostsSuccess } from "../actions/OtherUserAction";

function* savePostLikesAPI(action) {
  try {
    // const inputData = yield select(
    //   (state) => state.postLike.saveLike.inputData
    // );
    const likeData = action.data?.isFromUser ? {post_id: action.data?.post_id} : action.data?.isFromHome ? {post_id: action.data?.post_id} : action.data 
    const response = yield api.postMethod("post_likes_save", likeData);
    if (response.data.success) {
      
      yield put(savePostLikeSuccess(response.data.data));
      
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      
      yield put(createNotification(notificationMessage));
      
      if (action.data?.profileUserPost) {
        let postData = yield select(state => state.post.posts.data.posts)

        postData =  postData.map((post) => {
          if (post.post_unique_id === response.data.data.post_unique_id) {
            return {
              ...post,
              is_user_liked : response.data.data.is_user_liked ? 1 : 0,
              like_count : response.data.data.is_user_liked 
                              ? response.data.data.like_count
                              : response.data.data.like_count,
              recent_likes : response.data.data.is_user_liked 
                              ? response.data.data.recent_likes 
                              : response.data.data.recent_likes,
              total_likes : response.data.data.is_user_liked 
                              ? response.data.data.total_likes
                              : response.data.data.total_likes
            }
          } else {
            return post
          }
        })

        yield put(fetchPostsSuccess({postData: postData, isFromUser: action.data?.profileUserPost}));
      }
      
      if (action.data?.otherUserPost) {
        let postData = yield select(state => state.otherUser.userPosts.data.posts)

        postData =  postData.map((post) => {
          if (post.post_unique_id === response.data.data.post_unique_id) {
            return {
              ...post,
              is_user_liked : response.data.data.is_user_liked ? 1 : 0,
              like_count : response.data.data.is_user_liked 
                              ? response.data.data.like_count
                              : response.data.data.like_count,
              recent_likes : response.data.data.is_user_liked 
                              ? response.data.data.recent_likes 
                              : response.data.data.recent_likes,
              total_likes : response.data.data.is_user_liked 
                              ? response.data.data.total_likes
                              : response.data.data.total_likes
            }
          } else {
            return post
          }
        })

        yield put(fetchSingleUserPostsSuccess({postData: postData, isFromUser: action.data?.otherUserPost}))
      }
      
      let homeData = yield select((state) => state.home.homePost.data);
      homeData = {
        ...homeData,
        posts: homeData.posts.map((post) => post.post_unique_id === response.data.data.post_unique_id ? response.data.data : post)
      }
      yield put(homePostsSuccess(homeData));
      let singlePostData = yield select((state) => state.post.singlePost.data);
      if(Object.keys(singlePostData).length>0){
        if(singlePostData.post.post_unique_id === response.data.data.post_unique_id) {
          yield put(fetchSinglePostSuccess({ post: response.data.data }));
        }
      }
      if (action.data?.profileUserPost || action.data?.otherUserPost) {
        return action.data?.setUserProfilePost(response.data.data);
      }

      if (action.data?.isFromHome) {
        return action.data?.setSinglePostData(response.data.data)
      }
    } else {
      yield put(savePostLikeFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(savePostLikeFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchPostLikesAPI() {
  try {
    const inputData = yield select(
      (state) => state.postLike.saveLike.inputData
    );
    const response = yield api.postMethod("post_likes", inputData);
    if (response.data.success) {
      yield put(fetchPostLikedSuccess(response.data.data));
    } else {
      yield put(fetchPostLikedFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchPostLikedFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(SAVE_POST_LIKE_START, savePostLikesAPI)]);
  yield all([yield takeLatest(FETCH_POST_LIKED_START, fetchPostLikesAPI)]);
}
