import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const BecomeCertifiedCreatorModal = ({handleWantCreator, handleMaturePostSend, simpleHandleClose, setIsButtonEnabled, matureChecked, wantToBeCertifiedCreator, setWantToBeCertifiedCreator}) => {

	const [isAdult, setIsAdult] = useState(null);
  const {date_of_birth } = useSelector((state)=> state.users.profile.data);
  const {isCensorship } = useSelector((state)=> state.users.userCountryInfo.data);

	useEffect(()=>{
     
    function is18OrOlder(dobStr) {
        // Parse the date of birth string into a Date object
        const dob = new Date(dobStr);
        // Get the current date
        const today = new Date();
        // Calculate age
        let age = today.getFullYear() - dob.getFullYear();
        const monthDiff = today.getMonth() - dob.getMonth();
        console.log("🚀 ~ is18OrOlder ~ age:", age)
        
        // Adjust age if the birthday hasn't occurred yet this year
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
            age--;
        }
        // Check if age is 18 or older
        setIsAdult(age >= 18);
        return age >= 18;
   }

    is18OrOlder(date_of_birth);

   },[])

	const handelWantTobeCertifiedCreator = (e) => {
		setWantToBeCertifiedCreator(e.target.value)

		setIsButtonEnabled(true)
	} 

  return (
    <div className="form-group">
      <div className="want-to-be-certified-wrapper">
        <h4>Would you like to earn income by becoming a certified creator?</h4>

        <div className="beCreator-certi-container">
          <div className="be-certi-creator-wrapper">
            <input
              type="radio"
              name="beCreator"
              id="beCreator-yes"
              value="yes"
              style={{ margin: "0px" }}
              onChange={handelWantTobeCertifiedCreator}
              checked={wantToBeCertifiedCreator === "yes"}
            />
            <label
              htmlFor="beCreator-yes"
              className="be-certi-radio-label"
              style={{ margin: "0px" }}
            >
              Yes
            </label>
          </div>
          <div className="be-certi-creator-wrapper">
            <input
              type="radio"
              name="beCreator"
              id="beCreator-no"
              value="no"
              onChange={handelWantTobeCertifiedCreator}
              checked={wantToBeCertifiedCreator === "no"}
              style={{ margin: "0px" }}
            />
            <label
              htmlFor="beCreator-no"
              className="be-certi-radio-label"
              style={{ margin: "0px" }}
            >
              Not now
            </label>
          </div>
        </div>

        {(wantToBeCertifiedCreator === "yes" && isAdult && !isCensorship) && (
          <div
            class="input-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <p
              style={{
                alignSelf: "baseline",
                fontSize: "1.5rem",
                color: "rgb(151 157 163)",
                fontWeight: "400",
                marginBottom: "0px",
              }}
            >
              Will you post any mature content?
            </p>
            <label class="switchL" style={{ marginBottom: "0px" }}>
              <input
                type="checkbox"
                onChange={handleMaturePostSend}
                checked={matureChecked}
                name="matureOrNot"
              />
              <span class="sliderL"></span>
            </label>
          </div>
        )}
      </div>
    </div>
  );
};
