import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import configuration from "react-global-configuration";


const HandleSignUpModel = ({
  isOpen,
  handleClose,
  isAcceptTerm,
  handleIsAcceptTerm,
  data,
  handleSignup,
  signUpData
}) => {

  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      // backdrop="static"
      keyboard={false}
      centered
      className={`${
        localStorage.getItem("theme") !== "" &&
        localStorage.getItem("theme") !== null &&
        localStorage.getItem("theme") !== undefined &&
        localStorage.getItem("theme") === "dark"
          ? "dark-theme-modal"
          : ""
      }
      `}
    >
      <div>
        <Modal.Header closeButton>
          <Modal.Title>Community Guidelines</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="main_comunity_guid_wrapper" dangerouslySetInnerHTML={{__html : configuration.get("configData.guidelines")}} />
          <div className="form-group">
            <div
              class="input-group"
              style={{ display: "flex", gap: "11px", marginTop: "13px" }}
            >
              <input
                type="checkbox"
                style={{ marginTop: "0px" }}
                name="creatorCheck"
                onChange={handleIsAcceptTerm}
                checked={isAcceptTerm}
              />
              <p
                style={{
                  alignSelf: "baseline",
                  fontSize: "14px",
                  //   color: "rgb(151 157 163)",
                  fontWeight: "600",
                  marginBottom: "0px",
                }}
              >
                I have read and agree to the terms
              </p>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            size="lg"
            // onClick={handleAddSubLModalClose}
          >
            {/* {t("close")} */} Back
          </Button>
          <Button
            variant=""
            size="lg"
            className="ftr-btn"
            disabled={!isAcceptTerm || signUpData.buttonDisable}
            onClick={()=>  handleSignup(data)}
          >
            {signUpData.loadingButtonContent !== null
                                  ? signUpData.loadingButtonContent
                                  : "Accept"}
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default HandleSignUpModel;
