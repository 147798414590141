import React, { useEffect, useState } from "react";
import FollowingActiveSec from "../Accounts/FansFollowing/Following/FollowingActiveSec";
import FollowingListView from "../Accounts/FansFollowing/Following/FollowingListView";
import SearchInput from "../Accounts/Searchinput/SearchInput";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { t, translate } from "react-multi-lang";
import { connect } from "react-redux";

import {
  fetchActiveFollowingStart,
  fetchExpiredFollowingStart,
  fetchFollowingStart,
} from "../../store/actions/FollowAction";
import { getAllFriendStart } from "../../store/actions/FriendRequestAction";
import AddUserCustomFavourite from "../Accounts/AddUserCustomFavourite/AddUserCustomFavourite";
import RemoveUserFromList from "../Accounts/RemoveUserFromList/RemoveUserFromList";
import ColorTagCustomizeModal from "../Accounts/ColorTagCustomization/ColorTagCustomizeModal";

const FriendsIndex = (props) => {
  const [userData, setUserData] = useState(props.friendRequest?.data?.friends);

  const [activeSec, setActiveSec] = useState("active-sec");

  const [list, setList] = useState(true);

  const [isSelected, setIsSelected] = useState(false);
  const [checkedUserId, setCheckedUserId] = useState([]);
  const [isAddUser, setIsAddUser] = useState(false);
  const [isRemoveUser, setIsRemoveUser] = useState(false);
  const [isTagUser, setIsTagUser] = useState(false);

  useEffect(() => {
    props.dispatch(getAllFriendStart());
  }, []);

  useEffect(() => {
    setUserData(props.friendRequest?.data?.friends);
  }, [props.friendRequest?.data?.friends]);

  const handleFilterUserData = (searchItem) => {
    const filterUserData = props.friendRequest?.data?.friends.filter((user) => {
      return user?.user?.name.toLowerCase().includes(searchItem.toLowerCase());
    });

    setUserData(filterUserData);
  };

  const changeSection = (event, type) => {
    setActiveSec(type);
    if (type === "active-sec") props.dispatch(fetchActiveFollowingStart());
    if (type === "expired-sec") props.dispatch(fetchExpiredFollowingStart());
    if (type === "all-sec") props.dispatch(fetchFollowingStart());
  };

  const handleSelectAllUser = (e) => {
    const allSelectedFriendsUser = userData?.map((item) => {
      return {
        ...item,
        user: {
          ...item.user,
          isSelected: e.target.checked,
        },
      };
    });

    if (e.target.checked === true) {
      const allSelectedCheckedUser = userData?.map((elem) => elem.user_id);
      setCheckedUserId(allSelectedCheckedUser);
    }

    if (e.target.checked === false) {
      setCheckedUserId([]);
    }

    setUserData(allSelectedFriendsUser);
  };

  const handleIsSelected = () => {
    setIsSelected((prev) => !prev);

    const allSelectedFriendsUser = userData?.map((item) => {
      return {
        ...item,
        user: {
          ...item.user,
          isSelected: false,
        },
      };
    });
    setCheckedUserId([]);
    setUserData(allSelectedFriendsUser);
  };

  const handleOpenAddUserModal = () => setIsAddUser(true);
  const handleCloseAddUserModal = () => setIsAddUser(false);

  const handleOpenRemoveUserModal = () => setIsRemoveUser(true);
  const handleCloseRemoveUserModal = () => setIsRemoveUser(false);

  const handleOpenRemoveUserTagModal = () => setIsTagUser(true);
  const handleCloseRemoveUserTagModal = () => setIsTagUser(false);

  const handleList = () => {
    setList(false);
  };

  return (
    <div className="lists">
      <Container>
        <Row>
          <Col sm={12} md={12} xs={12}>
            <div className="profile-post-area">
              <div className="bookmarkes-list bookmarks-right-side">
                <div className="pull-left">
                  <h3>
                    <Link
                      className="bookmarkes-list"
                      to={"#"}
                      onClick={() => props.history.goBack()}
                    >
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/icons/back.svg"
                        }
                        className="svg-clone"
                      />
                      {t("friends")}
                    </Link>
                  </h3>
                </div>
              </div>
              <div className="search-component">
                <SearchInput
                  handleFilterUserData={handleFilterUserData}
                  following={props.activeFollowing}
                />
                <div className="list-icons" onClick={() => setList(true)}>
                  <svg
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-microsoft"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="#6661e7"
                      d="M7.462 0H0v7.19h7.462zM16 0H8.538v7.19H16zM7.462 8.211H0V16h7.462zm8.538 0H8.538V16H16z"
                    />
                  </svg>
                </div>
                <div className="list-icons" onClick={handleList}>
                  <svg
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-list-task"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="#6661e7"
                      fill-rule="evenodd"
                      d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5zM3 3H2v1h1z"
                    />
                    <path
                      fill="#6661e7"
                      d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1z"
                    />
                    <path
                      fill="#6661e7"
                      fill-rule="evenodd"
                      d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5zM2 7h1v1H2zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm1 .5H2v1h1z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="select-button-container">
              {userData?.length > 0 ? (
                <button className="select-btn" onClick={handleIsSelected}>
                  {isSelected ? t("Unselect") : t("Select")}
                </button>
              ) : (
                ""
              )}

              {isSelected && (
                <label htmlFor="select-all-input" className="select-all-user">
                  <input
                    type="checkbox"
                    checked={userData?.every((elem) => elem?.user?.isSelected)}
                    onChange={(e) => handleSelectAllUser(e)}
                    className="select-all-input"
                    id="select-all-input"
                  />
                  {t("Select_all")}
                </label>
              )}

              {userData?.some((elem) => elem?.user?.isSelected) && (
                <button
                  className="Add-to-custom-favourite-list"
                  onClick={handleOpenAddUserModal}
                >
                  Add to
                </button>
              )}

              {userData?.some((elem) => elem?.user?.isSelected) ? (
                <>
                  <button
                    className="remove__from__list"
                    onClick={handleOpenRemoveUserModal}
                  >
                    Remove from list
                  </button>

                  <button
                    className="color__tag__user"
                    onClick={handleOpenRemoveUserTagModal}
                  >
                    Tag by
                  </button>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="listing-tab">
              <div className="tab" role="tabpanel">
                <div className="tab-content tabs">
                  {list ? (
                    <FollowingActiveSec
                      activeSec={activeSec}
                      setActiveSec={setActiveSec}
                      following={props.friendRequest}
                      userData={userData}
                      componentType={"friends"}
                      page={"friendLists"}
                      isSelected={isSelected}
                      setUserData={setUserData}
                      setCheckedUserId={setCheckedUserId}
                      checkedUserId={checkedUserId}
                    />
                  ) : (
                    <FollowingListView
                      activeSec={activeSec}
                      setActiveSec={setActiveSec}
                      following={props.friendRequest}
                      userData={userData}
                      componentType={"friends"}
                    />
                  )}
                </div>
              </div>
            </div>

            {isAddUser && (
              <AddUserCustomFavourite
                isAddUser={isAddUser}
                handleCloseAddUserModal={handleCloseAddUserModal}
                checkedUserId={checkedUserId}
                setIsSelected={setIsSelected}
                setUserData={setUserData}
                userData={userData}
                setCheckedUserId={setCheckedUserId}
                friendType={"friendType"}
              />
            )}
            {isRemoveUser && (
              <RemoveUserFromList
                isRemoveUser={isRemoveUser}
                handleCloseRemoveUserModal={handleCloseRemoveUserModal}
                checkedUserId={checkedUserId}
                setIsSelected={setIsSelected}
                setUserData={setUserData}
                userData={userData}
                setCheckedUserId={setCheckedUserId}
                listType={"friends"}
                userList={"Friends"}
                removeType={"Unfriend"}
              />
            )}

            {isTagUser && (
              <ColorTagCustomizeModal
                isTagUser={isTagUser}
                handleCloseRemoveUserTagModal={handleCloseRemoveUserTagModal}
                checkedUserId={checkedUserId}
                setIsSelected={setIsSelected}
                setUserData={setUserData}
                userData={userData}
                setCheckedUserId={setCheckedUserId}
                listType={"friends"}
              />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToPros = (state) => ({
  friendRequest: state.friendRequest.allActiveFriends,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(FriendsIndex));
