import React from 'react'
import { useSelector } from 'react-redux';

const LiveAccessControlSubMain = ({handleAccessRestrictChange, accessRestrictValue, type , isDisble , isInput , inputType, allInputValue , handleInputValue}) => {

  const { data } = useSelector((state) => state.users.profile);

  return (
    <div
    className="accessControlContainer-radio-wrapper"
    style={{
      display: "flex",
      width: "100%",
      justifyContent: isInput ? "" :  "space-between",
      flexDirection : isInput ? "column" : "row" ,
      gap : isInput ? "10px" : "",
      marginTop: "7px",
      alignItems : isInput ? "baseline" : "" ,
    }}
  >
  
    <div style={{ display: "flex", gap: "5px" ,flexDirection : "row-reverse" , alignItems :"center" }}>
          
    {
      isInput && accessRestrictValue.follower && (
        <input required min={1} style={{border :"1px solid silver" , borderRadius: "4px", fontSize: "11px" , padding :"2px 7px"}} type="number" name={`${inputType}-1`} id={`${inputType}-id-1`} value={allInputValue.follower_price} onChange={(e)=> handleInputValue(e, "follower_price" , inputType)} />
      )
    }

      <label
        className="radio-btn"
        htmlFor={`${type}-id-0`}
        style={{
          marginBottom: "0px",
          fontSize: "13px",
        }}
      >
        Follower
      </label>
      <input
        style={{ margin: "0px" }}
        type="checkbox"
        id={`${type}-id-0`}
        name={`${type}-0`}
        onChange={(e)=> handleAccessRestrictChange(e, type, "follower")}
        value="follower"
        checked={accessRestrictValue.follower}
        // disabled={
        //   values?.accessContentData[0].mainToogle ===
        //     true &&
        //   accessT?.name?.mainToogle !== "fullAccess"
        // }
      />
    </div>

    <div style={{ display: "flex", gap: "5px" ,flexDirection : "row-reverse" }}>
    {
      isInput && accessRestrictValue.following && (
        <input  required min={1}  style={{border :"1px solid silver" , borderRadius: "4px", fontSize: "11px" , padding :"2px 7px"}} type="number" name={`${inputType}-2`} id={`${inputType}-id-2`} value={allInputValue.following_price} onChange={(e)=> handleInputValue(e, "following_price", inputType)} />
      )
    }
      <label
        className="radio-btn"
        htmlFor={`${type}-id-1`}
        style={{
          marginBottom: "0px",
          fontSize: "13px",
        }}
      >
        Following
      </label>
      <input
        style={{ margin: "0px" }}
        type="checkbox"
        id={`${type}-id-1`}
        name={`${type}-1`}
        onChange={(e)=> handleAccessRestrictChange(e, type, "following")}
        value="following"
        checked={accessRestrictValue.following}
        // disabled={
        //   values?.accessContentData[0].mainToogle ===
        //     true &&
        //   accessT?.name?.mainToogle !== "fullAccess"
        // }
      />
    </div>

    <div style={{ display: "flex", gap: "5px" ,flexDirection : "row-reverse" }}>
    {
      isInput && accessRestrictValue.subscriber_only && (
        <input  required min={1}  style={{border :"1px solid silver" , borderRadius: "4px", fontSize: "11px" , padding :"2px 7px"}} type="number" name={`${inputType}-3`} id={`${inputType}-id-3`} value={allInputValue.friends_price} onChange={(e)=> handleInputValue(e, "friends_price", inputType)} />
      )
    }
      <label
        className="radio-btn"
        htmlFor={`${type}-id-2`}
        style={{
          marginBottom: "0px",
          fontSize: "13px",
        }}
      >
        Friends
      </label>
      <input
        style={{ margin: "0px" }}
        type="checkbox"
        id={`${type}-2`}
        name={`${type}-2`}
        onChange={(e)=> handleAccessRestrictChange(e, type, "subscriber_only")}
        value="subscriber_only"
        checked={ accessRestrictValue.subscriber_only}
        // disabled={
        //   values?.accessContentData[0].mainToogle ===
        //     true &&
        //   accessT?.name?.mainToogle !== "fullAccess"
        // }
      />
    </div>
    {
      data?.is_certified_content_creator === 2 && (

    <div style={{ display: "flex", gap: "5px" ,flexDirection : "row-reverse" }}>
     
     {
      isInput && accessRestrictValue.subscriber_level && (
        <input  required min={1}  style={{border :"1px solid silver" , borderRadius: "4px", fontSize: "11px" , padding :"2px 7px"}} type="number" name={`${inputType}-4`} id={`${inputType}-id-4`} value={allInputValue.subscriberLevel_price} onChange={(e)=> handleInputValue(e, "subscriberLevel_price" , inputType)} />
      )
    }

      <label
        className="radio-btn"
        htmlFor={`${type}-id-3`}
        style={{
          marginBottom: "0px",
          fontSize: "13px",
        }}
      >
        Subscriber Level
      </label>
      <input
        style={{ margin: "0px" }}
        type="checkbox"
        id={`${type}-id-3`}
        name={`${type}-3`}
        onChange={(e)=> handleAccessRestrictChange(e, type, "subscriber_level")} 
        value="subscriber_level"
        checked={accessRestrictValue.subscriber_level}

        // disabled={
        //   values?.accessContentData[0].mainToogle ===
        //     true &&
        //   accessT?.name?.mainToogle !== "fullAccess"
        // }
      />
    </div>
      )
    }
    <div style={{ display: "flex", gap: "5px" ,flexDirection : "row-reverse" }}>
    {
      isInput && accessRestrictValue.subscriber_list &&  !isDisble && (
        <input  required min={1}  style={{border :"1px solid silver" , borderRadius: "4px", fontSize: "11px" , padding :"2px 7px"}} type="number" name={`${inputType}-5`} id={`${inputType}-id-5`} value={allInputValue.fromList_price} onChange={(e)=> handleInputValue(e, "fromList_price", inputType)} />
      )
    }
      <label
        className="radio-btn"
        htmlFor={`${type}-id-4`}
        style={{
          marginBottom: "0px",
          fontSize: "13px",
        }}
      >
        From List
      </label>
      <input
        style={{ margin: "0px" }}
        type="checkbox"
        id={`${type}-id-4`}
        name={`${type}-4`}
        onChange={(e)=> handleAccessRestrictChange(e, type, "subscriber_list")} 
        value="select_from_list"
        checked={isDisble ? true : accessRestrictValue.subscriber_list}

        // disabled={
        //   values?.accessContentData[0].mainToogle ===
        //     true &&
        //   accessT?.name?.mainToogle !== "fullAccess"
        // }
        disabled={isDisble}
      />
    </div>
  </div>
  )
}

export default LiveAccessControlSubMain