import React, { useEffect, useState } from 'react';
import {
  InputGroup,
  FormControl,
  Image,
  Modal,
  Media,
  Row,
  Col,
  Form,
  Button,
} from "react-bootstrap";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import configuration from "react-global-configuration";
import PaypalExpressBtn from "react-paypal-express-checkout";
import { translate, t } from 'react-multi-lang';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const WalletAmountSec = (props) => {
  const [show, setShow] = useState(false);
 const [success, setSuccess] = useState(false);
 const [ErrorMessage, setErrorMessage] = useState("")
 const [orderID, setOrderID] = useState(false)

  let env = configuration.get("configData.PAYPAL_MODE"); // you can set here to 'production' for production
  let currency = "USD"; // or you can set this value from your props or state

  const client = {
    sandbox: configuration.get("configData.PAYPAL_ID"),
    production: configuration.get("configData.PAYPAL_ID"),
  };

    // creates a paypal order
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Wallet Amount Add",
            amount: {
              currency_code: "USD",
              value: configuration.get("configData.token_amount") * props.amount,
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      setSuccess(true);
    });
  };
  //capture likely error
  const onError = (data, actions) => {
    props.paypalOnError("An Error occured with your payment ")
    setErrorMessage("An Error occured with your payment ");
  };

  useEffect(() => {
    if (success) {
        // alert("Payment successful!!");
        const payment = { paymentID : orderID }
        props.paypalOnSuccess(payment)
      }
    },
    [success]
  );

  console.log(1, orderID);
  console.log(2, success);
  console.log(3, ErrorMessage);

  return (
    <>
      <div className="wallet-modal-details mt-5">
        <h4 className="payment-modal-title">{t("add_wallet_token")}</h4>
        <p>{t("looking_beatiful_para")}</p>
        <Form onSubmit={props.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Control type="text"
              value={props.amount}
              min="50"
              onChange={e => props.setAmount(e.target.value)}
            />
          </Form.Group>
          <div className="wallet-account-balance mt-5 mb-5">
            {t("amount")} ({configuration.get("configData.token_amount")} * {props.amount}) = {configuration.get("configData.currency")}{configuration.get("configData.token_amount") * props.amount}
          </div>
          <div className="add-card-btn">
            {props.paymentType === "PAYPAL" ?
              <>
              {/* <PaypalExpressBtn
                env={env}
                client={client}
                currency={currency}
                total={props.amount}
                onError={props.paypalOnError}
                onSuccess={props.paypalOnSuccess}
                onCancel={props.paypalOnCancel}
              /> */}
              <PayPalScriptProvider options={{ "client-id": "AdmK6j3a3PobXXz0mopam4Atuzjorm82ECpkfxPLLdJNC2Pxgv9TZJ3-t-nO1MCpJu6sfnD7tKnNdw4i" }}>
                  <PayPalButtons 
                    style={{ layout: "horizontal" }} 
                    createOrder={createOrder}
                    onApprove={onApprove}
                    onError={onError}
                  />
              </PayPalScriptProvider>
              </>
              : <Button type="submit"
                disabled={props.amount && props.amount > 0 ? false : true || props.buttonDisable}
              >{
                  props.loadingButtonContent ?
                    props.loadingButtonContent
                    : t("add")
                }</Button>
            }
          </div>
        </Form>
      </div>
    </>
  )

}

export default translate(WalletAmountSec);