import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Field, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  addUserCustomFavouriteListStart,
  getCustomListStart,
} from "../../../store/actions/CustomListAction";
import "./AddUserCustomFavourite.css";

const AddUserCustomFavourite = (props) => {
  const {
    isAddUser,
    handleCloseAddUserModal,
    checkedUserId,
    setIsSelected,
    setCheckedUserId,
    userData,
    setUserData,
  } = props;

  const dispatch = useDispatch();
  const initialValues = {
    checkCustomList: [],
    checkFavouriteList: false,
  };

  useEffect(() => {
    dispatch(getCustomListStart());
  }, []);

  const getCustomList = useSelector(
    (state) => state.customList.allCustomList.data
  );
  const isLoading = useSelector(
    (state) => state.customList.addUserCustomFavouriteList.loading
  );
  return (
    <>
      <Modal
        show={isAddUser}
        onHide={handleCloseAddUserModal}
        // backdrop="static"
        keyboard={false}
        centered
        className={`${
          localStorage.getItem("theme") !== "" &&
          localStorage.getItem("theme") !== null &&
          localStorage.getItem("theme") !== undefined &&
          localStorage.getItem("theme") === "dark"
            ? "dark-theme-modal"
            : ""
        }`}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {

            dispatch(
              addUserCustomFavouriteListStart({
                customlist_ids: values.checkCustomList.toString(),
                users_ids: checkedUserId.toString(),
                is_customlist: values.checkCustomList.length > 0 ? "yes" : "no",
                is_fav: values.checkFavouriteList ? "yes" : "no",
                handleCloseAddUserModal: handleCloseAddUserModal,
                setIsSelected: setIsSelected,
                setCheckedUserId: setCheckedUserId,
                setUserData: setUserData,
                userData: userData,
                pageType:
                  props?.followingType === "followingType"
                    ? props?.followingType
                    : props?.followersType === "followersType"
                    ? props?.followersType
                    : props?.friendType === "friendType"
                    ? props?.friendType
                    : props?.subscribingType === "subscribingType"
                    ? props?.subscribingType
                    : props?.subscribersType === "subscribersType"
                    ? props?.subscribersType
                    : props?.unsubscribedType === "unsubscribedType"
                    ? props?.unsubscribedType
                    : props?.formerSubscribersType === "formerSubscribersType"
                    ? props?.formerSubscribersType
                    : props?.restrictedUserType === "restrictedUserType"
                    ? props?.restrictedUserType
                    : props?.blockedUserType === "blockedUserType"
										? props?.blockedUserType
										: props?.customListUserType === "customListUserType"
										? props?.customListUserType
										: props?.favouriteType === "favouriteType"
										? props?.favouriteType
										: null,
              })
            );
          }}
        >
          {({ errors, touched, handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Move to custom and favorites list</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="container-custom-favourite">
                  {getCustomList.length > 0 ? (
                    <div className="custom-list-container">
                      <h3 className="heading-list">Custom list</h3>
                      {getCustomList.map((item) => (
                        <div
                          className="check-container"
                          role="group"
                          aria-labelledby="checkbox-group"
                          key={item?.CID}
                        >
                          <label className="custom-list-check">
                            <Field
                              name="checkCustomList"
                              type="checkbox"
                              value={`${item?.CID}`}
                              style={{
                                margin: 0,
                              }}
                            />
                            {item?.list_name}
                          </label>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div>
                      <p>Custom list is not present</p>
                    </div>
                  )}
                  {(props?.blockedUserType || props?.favouriteType) ? (
                    ""
                  ) : (
                    <div className="favourite-list-container">
                      <h3 className="heading-list">Favorites list</h3>
                      <div className="check-container">
                        <label className="favourite-list-checked">
                          <Field
                            type="checkbox"
                            name="checkFavouriteList"
                            style={{
                              margin: 0,
                            }}
                          />
                          Favorites
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  size="lg"
                  onClick={() => {
                    handleCloseAddUserModal();
                  }}
                >
                  Back
                </Button>
                {`${values.checkCustomList.length}` === `0` &&
                `${values.checkFavouriteList}` === `false` ? (
                  <Button
                    size="lg"
                    className="ftr-btn"
                    type="submit"
                    disabled={true}
                  >
                    Add
                  </Button>
                ) : (
                  <Button
                    size="lg"
                    className="ftr-btn"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? "loading..." : "Add"}
                  </Button>
                )}
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default AddUserCustomFavourite;
