import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  Image,
  Media,
  Form,
} from "react-bootstrap";
import "./NewSettings.css";
import SettingsSidebar from "./SettingsSidebar";
import { connect, useDispatch, useSelector } from "react-redux";
import { translate, t } from "react-multi-lang";
import { Form as FORM, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  fetchNotificationSettingStart,
  postNotificationSettingStart,
} from "../../../store/actions/NotificationAction";

const NotificationSettings = (props) => {
  
  const notificationToggle = useSelector(
    (state) => state.notification.notificationSetting
  );

  const updateNotificationLoading = useSelector(
    (state) => state.notification.notificationUpdate.loading
  );

  const { data } = useSelector((state) => state.users.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchNotificationSettingStart());
  }, []);

  const notificationTitle = [
    {
      title: "Followed by someone",
      name: "following",
      isCertified: true,
      isCreator: true,
    },
    {
      title: "Subscribed by someone",
      name: "subscribed",
      isCertified: true,
    },
    {
      title: "Replies",
      name: "replies_comments",
      isCertified: true,
      isCreator: true,
      isUser: true,
    },
    {
      title: "Comments on my content",
      name: "comments",
      isCertified: true,
      isCreator: true,
    },
    {
      title: "Post liked by someone",
      name: "post_like",
      isCertified: true,
      isCreator: true,
    },
    {
      title: "Tips from someone",
      name: "tips",
      isCertified: true,
    },
    {
      title: "Ticket sold",
      name: "ticket_sold",
      isCertified: true,
    },
    {
      title: "Ticket Event Reminder",
      name: "ticket_event_reminder",
      isCertified: true,
      isCreator: true,
      isUser: true,
    },
    {
      title: "Private call reminder",
      name: "private_call_reminder",
      isCertified: true,
      isCreator: true,
      isUser: true,
    },
    {
      title: "Private call request reply",
      name: "reply_request_privatecall",
      isCertified: true,
      isCreator: true,
      isUser: true,
    },
    {
      title: "New private call request",
      name: "new_private_call",
      isCertified: true,
      isCreator: true,
      isUser: true,
    },
    {
      title: "Reply for Proposed Private Call",
      name: "reply_propose_private_call",
      isCertified: true,
      isCreator: true,
      isUser: true,
    },
    {
      title: "New PPV content request",
      name: "ppv_content",
      isCertified: true,
    },
    {
      title: "PPV content request reply",
      name: "ppv_content_replay",
      isCertified: true,
      isCreator: true,
      isUser: true,
    },
    {
      title: "Referral",
      name: "refferal",
      isCertified: true,
    },
  ];

  const initialValues = {
    comments: notificationToggle?.data[0]?.comments ? true : false,
    post_like: notificationToggle?.data[0]?.post_like ? true : false,
    following: notificationToggle?.data[0]?.following ? true : false,
    tips: notificationToggle?.data[0]?.tips ? true : false,
    subscribed: notificationToggle?.data[0]?.subscribed ? true : false,
    refferal: notificationToggle?.data[0]?.refferal ? true : false,
    ticket_event_reminder: notificationToggle?.data[0]?.ticket_event_reminder
      ? true
      : false,
    ticket_sold: notificationToggle?.data[0]?.ticket_sold ? true : false,
    private_call_reminder: notificationToggle?.data[0]?.private_call_reminder
      ? true
      : false,
    reply_propose_private_call: notificationToggle?.data[0]
      ?.reply_propose_private_call
      ? true
      : false,
    new_private_call: notificationToggle?.data[0]?.new_private_call
      ? true
      : false,
    reply_request_privatecall: notificationToggle?.data[0]
      ?.reply_request_privatecall
      ? true
      : false,
    ppv_content: notificationToggle?.data[0]?.ppv_content ? true : false,
    ppv_content_replay: notificationToggle?.data[0]?.ppv_content_replay
      ? true
      : false,
    replies_comments: notificationToggle?.data[0]?.replies_comments
      ? true
      : false,
  };

  return (
    <>
      <div className="new-settings-sec new-change-password">
        <div className="new-settings-box">
          <SettingsSidebar />
          <div className="new-settings-main-wrapper">
            <div className="new-changes-password-box">
              <div className="settings-personal-info-card">
                <div className="settings-personal-info-header">
                  <h3>{t("turnOnOff_notification")}</h3>
                  <p>{t("turnOnOff_notification_note")}</p>
                </div>

                {notificationToggle?.loading ? (
                  <p>loading...</p>
                ) : (
                  <div className="notification-container">
                    <Formik
                      initialValues={initialValues}
                      onSubmit={(values) => {
                        const updateValues = {
                          comments: Number(values.comments),
                          post_like: Number(values.post_like),
                          following: Number(values.following),
                          tips: Number(values.tips),
                          subscribed: Number(values.subscribed),
                          fans: Number(values.fans),
                          refferal: Number(values.refferal),
                          ticket_event_reminder: Number(
                            values.ticket_event_reminder
                          ),
                          ticket_sold: Number(values.ticket_sold),
                          private_call_reminder: Number(
                            values.private_call_reminder
                          ),
                          reply_propose_private_call: Number(
                            values.reply_propose_private_call
                          ),
                          new_private_call: Number(values.new_private_call),
                          reply_request_privatecall: Number(
                            values.reply_request_privatecall
                          ),
                          ppv_content: Number(values.ppv_content),
                          ppv_content_replay: Number(values.ppv_content_replay),
                          replies_comments: Number(values.replies_comments),
                        };
                        dispatch(postNotificationSettingStart(updateValues));
                      }}
                    >
                      {({ handleSubmit, isValid, dirty }) => (
                        <FORM onSubmit={handleSubmit}>
                          <div className="containter">
                            <div
                              className="inner-container"
                              style={{
                                display: "grid",
                                gridTemplateColumns: "repeat(2, 1fr)",
                                columnGap: "61px",
                                rowGap: "10px",
                              }}
                            >
                              {notificationTitle.length > 0 &&
                                notificationTitle.map((elem, index) => {
                                  if (
                                    elem?.isCertified &&
                                    data?.is_certified_content_creator === 2 &&
                                    data?.is_content_creator === 2
                                  ) {
                                    return (
                                      <div
                                        key={index}
                                        className="notification-toggle-container"
                                      >
                                        <div className="toggle-switch">
                                          <div>
                                            <span>{elem.title}</span>
                                          </div>
                                          <label
                                            className="switchL switch-label"
                                            style={{ border: "none" }}
                                          >
                                            <Field
                                              type="checkbox"
                                              className="fullAccess"
                                              name={elem.name}
                                            />
                                            <span class="sliderL"></span>
                                          </label>
                                        </div>
                                      </div>
                                    );
                                  }
                                  if (
                                    elem?.isCreator &&
                                    data?.is_content_creator === 2 &&
                                    data?.is_certified_content_creator === 1
                                  ) {
                                    return (
                                      <div
                                        key={index}
                                        className="notification-toggle-container"
                                      >
                                        <div className="toggle-switch">
                                          <div>
                                            <span>{elem.title}</span>
                                          </div>
                                          <label
                                            className="switchL switch-label"
                                            style={{ border: "none" }}
                                          >
                                            <Field
                                              type="checkbox"
                                              className="fullAccess"
                                              name={elem.name}
                                            />
                                            <span class="sliderL"></span>
                                          </label>
                                        </div>
                                      </div>
                                    );
                                  }
                                  if (
                                    elem?.isUser &&
                                    data?.is_content_creator === 1 &&
                                    data?.is_content_creator === 1
                                  ) {
                                    return (
                                      <div
                                        key={index}
                                        className="notification-toggle-container"
                                      >
                                        <div className="toggle-switch">
                                          <div>
                                            <span>{elem.title}</span>
                                          </div>
                                          <label
                                            className="switchL switch-label"
                                            style={{ border: "none" }}
                                          >
                                            <Field
                                              type="checkbox"
                                              className="fullAccess"
                                              name={elem.name}
                                            />
                                            <span class="sliderL"></span>
                                          </label>
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                            </div>

                            <Button
                              className="notification-submit-btn"
                              type="submit"
                              disabled={
                                !isValid 
                                  ||
                                updateNotificationLoading
                              }
                            >
                              {updateNotificationLoading
                                ? "loading..."
                                : "Submit"}
                            </Button>
                          </div>
                        </FORM>
                      )}
                    </Formik>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationSettings;
