import React, { useEffect } from "react";
import { t, translate } from "react-multi-lang";
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchPostCategoriesStart } from "../../store/actions/PostAction";
import { Field } from "formik";
import { Multiselect } from "multiselect-react-dropdown";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import { usernameValidationDefault } from "../../store/actions/UserAction";

const SignUpStep2 = (props) => {
  const dispatch = useDispatch();
  const handleNext = async () => {
    const validationErrors = await props.validateForm();
    console.log("🚀 ~ handleNext ~ validationErrors:", validationErrors);

    if (!validationErrors.selectedCategories) {
      console.log("submited step 2");
      props.jumpToStep(2);
    }

    console.log("values step 2", props.values);
  };

  useEffect(() => {
    props.dispatch(fetchPostCategoriesStart());
  }, []);
  return (
    <div className="form-group muture-content-grp">
      {props.postCategories?.data?.post_categories?.length > 0 ? (
        <div className="register_step_category_container">
          <h4 style={{ textAlign: "left" }}>Choose category</h4>
          {props.postCategories.data.post_categories ? (
            <Field>
              {({ field, form }) => (
                <Multiselect
                  name="category_ids"
                  id="selectedCategories"
                  selectedValues={props.values.selectedCategories}
                  options={props.postCategories.data.post_categories}
                  displayValue="name"
                  avoidHighlightFirstOption="true"
                  placeholder={t("select")}
                  onSelect={(selectedList) =>
                    form.setFieldValue("selectedCategories", selectedList)
                  }
                  onRemove={(selectedList) =>
                    form.setFieldValue("selectedCategories", selectedList)
                  }
                />
              )}
            </Field>
          ) : null}
          {props.errors.selectedCategories  && (
              <p
                className="text-danger text-right"
                style={{
                  fontSize: "12px",
                  marginBottom: "11px",
                }}
              >
                {props.errors.selectedCategories}
              </p>
            )}
        </div>
      ) : (
        ""
      )}

      <div className="register_step_next_prv_btn_wrapper register_step_btn_2_wrapper">
        <Button
          className="register_stpe_next_btn"
          onClick={() => props.jumpToStep(0)}
        >
          Previous
        </Button>
        <Button
          className="register_stpe_next_btn"
          onClick={(e) => handleNext(e)}
        >
          Next
        </Button>
      </div>

      <div className="signup_already_account_container">
        <p id="two">{t("already_have_an_account")}</p>
        <p>
          <Link
            className="signup"
            href="#"
            id="signin"
            onClick={(event) => {
              event.preventDefault();
              props.setShow("login");
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              props.setValues({
                name: "",
                username: "",
                email: "",
                password: "",
                date_of_birth: "",
                selectedCategories: [],
                creatorCheck: false,
                matureChecked: false,
              });
              props.setInitalStep(0);
              dispatch(usernameValidationDefault());
            }}
          >
            {" "}
            {t("login_for")} {configuration.get("configData.site_name")}
          </Link>
        </p>
      </div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  login: state.users.loginInputData,
  signup: state.users.registerInputData,
  forgotPassword: state.users.forgotPasswordInputData,
  validation: state.users.validationInputData,
  postCategories: state.post.postCategories,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(SignUpStep2));
