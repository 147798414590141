import React, { useState } from 'react'
import { Button, Modal } from "react-bootstrap";
import { translate, t } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { saveBlockUserStart } from '../../../store/actions/UserAction';

const BlockModal = (props) => {
	const { blockModalFlag, closeBlockModal, userId, certifiedCreator, isFollowing, subscribeByUser, isUserPrepaid, creator} = props;

  const dispatch = useDispatch();

	const BlockBulletStyle = {
		margin: "0",
		fontSize: "14px"
	}

  return (
    <>
      <Modal
        show={blockModalFlag}
        onHide={closeBlockModal}
        backdrop="static"
        keyboard={false}
        centered
        className={`${
          localStorage.getItem("theme") !== "" &&
          localStorage.getItem("theme") !== null &&
          localStorage.getItem("theme") !== undefined &&
          localStorage.getItem("theme") === "dark"
            ? "dark-theme-modal"
            : ""
        }`}
      >
              <Modal.Header closeButton>
                <Modal.Title>Block user</Modal.Title>
              </Modal.Header>

              <Modal.Body>
								<ul
									style={BlockBulletStyle}
								>
								{
									(certifiedCreator === 2 && subscribeByUser)
										?
										<>
											<li>This user is your Level ? subscriber, this user's subscription of this month and other prepaid payments will be refunded after blocking.</li>
											<li>This user will not be able to view your profile and message you (including Push and PPV request).</li>
											<li>You cannot message this user either but you can still view, purchase, follow, and subscribe content, and send PPV request to this user.</li>
											<li>This user will not be informed but the coins prepaid will be refunded.</li>
											<li>You can unblock this user from "Lists" or by visiting this user's profile, yet the refunded payments will not be resumed.</li>
										</>
										:
											(certifiedCreator === 2 && (isFollowing !== 0 || isUserPrepaid !== 0))
												?
											<>
												<li>This user's prepaid payments to you will be refunded after blocking.</li>
												<li>This user will not be able to view your profile and message you (including Push).</li>
												<li>You cannot message this user either but you can still view, purchase, follow, and subscribe content, and send PPV request to this user.</li>
												<li>This user will not be informed but the coins prepaid will be refunded.</li>
												<li>You can unblock this user from "Lists" or by visiting this user's profile, yet the refunded payments will not be resumed.</li>
											</>
											:
											(certifiedCreator === 2 && subscribeByUser === 0 && isFollowing === 0)
											?
												<>
													<li>This user will not be able to view your wall and message you (including Push).</li>
													<li>You cannot message this user either but you can still view, purchase, follow, and subscribe content, and send PPV request to this user.</li>
													<li>This user will not be informed.</li>
													<li>You can unblock this user from "Lists" or by visiting this user's wall.</li>
												</>
											:
										(creator === 2 && subscribeByUser) 
											?
											<>
												<li>This user is your Level ? subscriber, you will not see comments and messages from this user but you can still send Push and receive PPV request.</li>
												<li>You cannot comment and message this user either but you can still view and follow this user.</li>
												<li>This user will not be informed.</li>
												<li>You can remove your restriction on this user from "List" or by visiting this user's profile.</li>
											</>
											: 
											(creator && (isFollowing !== 0 || isUserPrepaid !== 0))
												?
												<>
													<li>This user's prepaid payments to you will be refunded after blocking.</li>
													<li>This user will not be able to view your profile and message you.</li>
													<li>You cannot message this user either but you can still view and follow this user.</li>
													<li>This user will not be informed but the coins prepaid will be refunded.</li>
													<li>You can unblock this user from "Lists" or by visiting this user's profile, yet the refunded payments will not be resumed.</li>
												</>
												:
												(creator && isFollowing === 0 && isFollowing === 0)
												?
												<>
													<li>This user will not be able to view your profile and message you.</li>
													<li>You cannot message this user either but you can still view and follow this user.</li>
													<li>This user will not be informed.</li>
													<li>You can unblock this user from "Lists" or by visiting this user's profile.</li>
												</>
												:
												<>
													<li>This user will not be able to view your profile and message you.</li>
													<li>You cannot message this user either but you can still view this user's profile.</li>
													<li>This user will not be informed.</li>
													<li>You can unblock this user from "Lists" or by visiting this user's profile.</li>
												</>
									}
								</ul>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  size="lg"
                  onClick={props.closeBlockModal}
                >
                  back
                </Button>
                <Button
                  size="lg"
                  className="ftr-btn"
                  type="submit"
									onClick={(event) => {
										event.preventDefault();
										dispatch(saveBlockUserStart({
											user_id: userId,
											is_other_profile: 1,
										}))
									}}
                >
                  {t("block_the_user")}
                </Button>
              </Modal.Footer>
      </Modal>
    </>
  );
}

export default BlockModal