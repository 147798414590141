import React, { useState } from 'react'
import { Button, Modal } from "react-bootstrap";
import { translate, t } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { saveBlockUserStart, saveRestrictUserStart } from '../../../store/actions/UserAction';

const RestrictModal = (props) => {
	const { restrictModalFlag, closeRestrictModal, userId, creator, isFollowing, subscribeByUser, certifiedCreator, isUserPrepaid} = props;
  const dispatch = useDispatch();

	const restrictBulletStyle = {
		margin: "0",
		fontSize: "14px"
	}

  return (
    <>
      <Modal
        show={restrictModalFlag}
        onHide={closeRestrictModal}
        backdrop="static"
        keyboard={false}
        centered
        className={`${
          localStorage.getItem("theme") !== "" &&
          localStorage.getItem("theme") !== null &&
          localStorage.getItem("theme") !== undefined &&
          localStorage.getItem("theme") === "dark"
            ? "dark-theme-modal"
            : ""
        }`}
      >
              <Modal.Header closeButton>
                <Modal.Title>Restrict user</Modal.Title>
              </Modal.Header>

              <Modal.Body>
								<ul
									style={restrictBulletStyle}
								>
                  {
                    (certifiedCreator === 2 && subscribeByUser)
                      ?
                    <>
                      <li>This user is your Level ? subscriber, you will not see comments and messages from this user (including Push) but you can still send Push and receive PPV request.</li>
                      <li>You cannot comment and message this user either but you can still view, purchase, follow, and subscribe content, and send PPV request to this user.</li>
                      <li>This user will not be informed.</li>
                      <li>You can remove your restriction on this user from "List" or by visiting this user's profile.</li>
                    </>
                      :
                    (certifiedCreator === 2 && (isFollowing !== 0 || isUserPrepaid !== 0))
                      ?
                      <>
                        <li>You will not see comments and messages from this user (including Push) but you can still send Push.</li>
                        <li>You cannot comment and message this user either but you can still view, purchase, follow, and subscribe content, and send PPV request to this user.</li>
                        <li>This user will not be informed.</li>
                        <li>You can remove your restriction on this user from "List" or by visiting this user's profile.</li>
                      </>
                      :
                      (certifiedCreator === 2 && subscribeByUser === 0 && isFollowing === 0)
                        ?
                      <>
                        <li>You will not see comments and messages from this user (including Push).</li>
                        <li>You cannot comment and message this user either but you can still view, purchase, follow, subscribe content, and send PPV request to this user.</li>
                        <li>This user will not be informed.</li>
                        <li>You can remove your restriction on this user from "List" or by visiting this user's profile.</li>
                      </>
                        :
                      (creator === 2 && subscribeByUser)
                        ?
                      <>
                        <li>This user is your Level ? subscriber, you will not see comments and messages from this user but you can still send Push and receive PPV request.</li>
                        <li>You cannot comment and message this user either but you can still view and follow this user.</li>
                        <li>This user will not be informed.</li>
                        <li>You can remove your restriction on this user from "List" or by visiting this user's profile.</li>
                      </>
                        :
                      (creator && (isFollowing !== 0 || isUserPrepaid !== 0))
                        ?
                      <>
                        <li>You will not see comments and messages from this user but you can still send push.</li>
                        <li>You cannot comment and message this user either but you can still view and follow this user.</li>
                        <li>This user will not be informed.</li>
                        <li>You can remove your restriction on this user from "List" or by visiting this user's profile.</li>
                      </>
                        :
                        (creator && isFollowing === 0 && isFollowing === 0)
                          ?
                          <>
                            <li>You will not see comments and messages from this user.</li>
                            <li>You cannot comment and message this user either but you can still view and follow this user.</li>
                            <li>This user will not be informed.</li>
                            <li>You can remove your restriction on this user from "List" or by visiting this user's profile.</li>
                          </>
                          :
                          <>
                            <li>You will not see comments and messages from this user.</li>
                            <li>You cannot comment and message this user either but you can still view this user's profile.</li>
                            <li>This user will not be informed.</li>
                            <li>You can remove your restriction on this user from "List" or by visiting this user's profile.</li>
                          </>
                  }
                  
								</ul>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  size="lg"
                  onClick={props.closeRestrictModal}
                >
                  back
                </Button>
                <Button
                  size="lg"
                  className="ftr-btn"
                  type="submit"
									onClick={(event) => {
										event.preventDefault();
										dispatch(saveRestrictUserStart({
											user_id: userId,
										}))
									}}
                >
                  {/* {t("block_the_user")}*/}
                  Restrict
                </Button>
              </Modal.Footer>
      </Modal>
    </>
  );
}

export default RestrictModal