import React, { useEffect, useState } from 'react'
import { Button, Modal ,Table } from "react-bootstrap";
import configuration from "react-global-configuration";



const ExchangeRateModel = ({isOpen , handleClose ,profile_ranking , userRankData}) => {
  
    const [dimondConversions, setDimondConversions] = useState([]);
    console.log("🚀 ~ ExchangeRateModel ~ dimondConversions:", dimondConversions)

    useEffect(()=>{
    
        setDimondConversions(configuration.get("configData.dimond_conversional")
    )

    },[])

  return (
    <Modal
    show={isOpen}
    onHide={() => handleClose()}
    // backdrop="static"
    keyboard={false}
    centered
    className={`${
      localStorage.getItem("theme") !== "" &&
      localStorage.getItem("theme") !== null &&
      localStorage.getItem("theme") !== undefined &&
      localStorage.getItem("theme") === "dark"
        ? "dark-theme-modal"
        : ""
    }
  `}
  >
    <div>
      <Modal.Header closeButton>
        <Modal.Title>Exchnage rate</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <div className="main_comunity_guid_wrapper" dangerouslySetInnerHTML={{__html : configuration.get("configData.guidelines")}} /> */}
        <div className="form-group exchangeContainer">
           <h3>My current month ranking : {profile_ranking ? profile_ranking : "0"}</h3>
          
           <Table borderedless responsive>
                        <thead>
                          <tr className="bg-white">
                            <th>From {"(Rank)"}</th>
                            <th>To {"(Rank)"}</th>
                            <th>Conversion rate</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dimondConversions?.map(
                            (ele, index) => (
                              <tr key={index} className={ele.dimond_conversion == userRankData ? "userRank_cls" : "exchnage_normal" }>
                                <td>
                                  {index === 0 ? "-" : ele.form_level}
                                </td>
                                <td>{dimondConversions.length - 1 === index ? `${ele.to_level}+` : ele.to_level}</td>
                                <td>
                                  {`${ele.dimond_conversion} coin = 1 Diamond`}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </Table>

        </div>
      </Modal.Body>

      {/* <Modal.Footer>
        <Button
          variant="secondary"
          size="lg"
          onClick={()=> handleClose()}
        >
          Back
        </Button>
      </Modal.Footer> */}
    </div>
  </Modal>
  )
}

export default ExchangeRateModel