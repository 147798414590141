import { FETCH_SUBSCRIBING_FAILURE, FETCH_SUBSCRIBING_START, FETCH_SUBSCRIBING_SUCCESS } from "./ActionConstant";

export function fetchSubscribingStart(data) {
  return {
    type: FETCH_SUBSCRIBING_START,
    payload : data
  }
}

export function fetchSubscribingSuccess(data) {
  return {
    type: FETCH_SUBSCRIBING_SUCCESS,
    data,
  }
}

export function fetchSubscribingFailure(error) {
  return {
    type: FETCH_SUBSCRIBING_FAILURE,
    error,
  }
}