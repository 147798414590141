import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  ACCEPT_FRIEND_REQUEST_START,
  ADD_FRIEND_REQUEST_START,
  GET_ALL_FRIEND_REQUEST_START,
  GET_ALL_FRIEND_START,
  REMOVE_FRIEND_REQUEST_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";



import {
    checkLogoutStatus,
  } from "../actions/ErrorAction";
import { acceptFriendRequestFailure, acceptFriendRequestSuccess, addFriendRequestFailure, addFriendRequestSuccess, getAllFriendFailure, getAllFriendRequestFailure, getAllFriendRequestSuccess, getAllFriendSuccess, removeRejectFriendRequestFailure, removeRejectFriendRequestStart, removeRejectFriendRequestSuuceess } from "../actions/FriendRequestAction";
import { fetchActiveFollowersSuccess, fetchActiveFollowingSuccess } from "../actions/FollowAction";
import { fetchSubscribersSuccess } from "../actions/SubscriberAction";
import { fetchSubscribingSuccess } from "../actions/SubscribingAction";
import { fetchFavSuccess } from "../actions/FavAction";
import { fetchUnsubscribedSuccess } from "../actions/UnsubscribedAction";
import { fetchFormerSubscriberSuccess } from "../actions/FormerSubscriberAction";
import { addUserListSuccessD, getSingleSearchListUserSuccess } from "../actions/CustomListAction";
import { fetchRestrictedUsersSuccess } from "../actions/UserAction";
import { fetchSingleUserProfileSuccess } from "../actions/OtherUserAction";

  
function* fetchAddFriendRequestApi({data}) {
  
    try {
      const response = yield api.postMethod("send_friend_requet", {user_id : data.user_id});

      if (response.data.success) {
        yield put(addFriendRequestSuccess(response.data.data));

         if (data?.singleProfile === "singleProfile") {
          
          const singleProfileState = yield select(state => state.otherUser.userDetails.data)

          if (singleProfileState?.user?.user_id === Number(response.data.data?.friends_id)) {
            const updatedSingleProfile = {
              ...singleProfileState,
              is_request_pending: 1
            }
            yield put(fetchSingleUserProfileSuccess(updatedSingleProfile))
          }
         }
         if(data?.activeFollowers?.followers !== undefined && data?.activeFollowers?.followers?.length > 0){
          
          const isData = data.activeFollowers.followers ;

          const filterData = isData.map((ele)=>{
             
             if(ele?.otherUser?.user_id == response.data?.data?.friends_id){
               
                return {
                  ...ele,
                  otherUser : {
                    ...ele.otherUser,
                    is_request_pending : 1

                  }
                }

             }else{
               return ele;
             }

          });
          yield put(fetchActiveFollowersSuccess({followers : filterData , total : data.activeFollowers.total}));
         }  

         if(data?.activeFollowing?.followers !== undefined && data?.activeFollowing?.followers?.length > 0){
          
          const isData = data.activeFollowing.followers;

          const filterData = isData.map((ele)=>{
             
             if(ele?.otherUser?.user_id == response.data?.data?.friends_id){
               
                return {
                  ...ele,
                  otherUser : {
                    ...ele.otherUser,
                    is_request_pending : 1

                  }
                }

             }else{
               return ele;
             }

          });
          yield put(fetchActiveFollowingSuccess({followers : filterData , total : data.activeFollowing.total}));
         }  



         if(data?.activeSubscribers?.subscriber !== undefined && data?.activeSubscribers?.subscriber?.length > 0){
             
          const isData = data.activeSubscribers.subscriber;
 
          const filterData = isData.map((ele)=>{
             
            if(ele?.from_user?.user_id == response.data?.data?.friends_id){
              
               return {
                 ...ele,
                 is_request_pending : 1
               }

            }else{
              return ele;
            }


         });
         yield put(fetchSubscribersSuccess({subscriber : filterData , total: data.activeSubscribers.total}));
         }


         if(data?.activeSubscribing?.subscribing !== undefined && data?.activeSubscribing?.subscribing?.length > 0){

 
          const filterData = data.activeSubscribing.subscribing.map((ele) => {
             
            if(ele?.to_user?.user_id == response.data?.data?.friends_id){
               return {
                 ...ele,
                 is_request_pending : 1
               }
            }else{
              return ele;
            }

         });


          console.log("🚀 ~ filterData ~ filterData:", filterData)
       
         yield put(fetchSubscribingSuccess({subscribing : filterData , total: data.activeSubscribing.total}));

         }



         if(data?.activeFavories?.data?.favs !== undefined && data?.activeFavories?.data?.favs?.length){


          // const isData = data.activeFavories.data.favs;
             
        //   const filterData = isData.map((ele) => {
             
        //     if(ele?.fav_user?.user_id == response.data?.data?.friends_id){
        //        return {
        //          ...ele,
        //          fav_user : {
        //           ...ele.fav_user,
        //           is_request_pending : 1
        //          }
        //        }
        //     }else{
        //       return ele;
        //     }

        //  });

              window.location.reload();    
          }


           
            if(data?.activeUnsubscriberd?.unsubscribed !== undefined && data?.activeUnsubscriberd?.unsubscribed?.length){
 
              
              const filterData = data?.activeUnsubscriberd?.unsubscribed?.map((ele)=> {
               
                if(ele?.to_user?.user_id ==  response.data?.data?.friends_id){
                   return {
                      ...ele,
                      is_request_pending : 1
                   }
                }else{
                 return ele;
                }
 
              });

              console.log("🚀 ~ filterData ~ filterData:", filterData)
  
              yield put(fetchUnsubscribedSuccess({unsubscribed : filterData ,  total : filterData.length }));

            }


            if(data?.activeFormerSub?.subscriber !== undefined && data?.activeFormerSub?.subscriber?.length){
 
              
              const filterData = data?.activeFormerSub?.subscriber?.map((ele)=> {
               
                if(ele?.from_user?.user_id ==  response.data?.data?.friends_id){
                   return {
                      ...ele,
                      is_request_pending : 1
                   }
                }else{
                 return ele;
                }
 
              });

             
              yield put(fetchFormerSubscriberSuccess({subscriber : filterData , total : filterData.length}));

            }

          if (data?.activeRestricted?.restrict_users !== undefined && data?.activeRestricted?.restrict_users?.length) {

            const filterData = data?.activeRestricted?.restrict_users?.map(elem => {
              if (elem?.restricteduser?.user_id == response.data?.data?.friends_id) {
                return {
                  ...elem,
                  is_request_pending: 1  
                }
              } else {
                return elem
              }
            })

            yield put(fetchRestrictedUsersSuccess({restrict_users: filterData, total: filterData.length}))
          }
          
         
          if(data?.fromType !== undefined && data?.fromType === "singleListUserSearch"){
             
            const singleUserList = yield select((state) => state.customList.singleUserListSerach)

            
            const filterData = singleUserList.data?.map(elem => {
              if (elem?.user_id == response.data?.data?.friends_id) {
                return {
                  ...elem,
                  is_request_pending: 1  
                }
              } else {
                return elem
              }
            })

            yield put(getSingleSearchListUserSuccess(filterData));

          }
    
        const notificationMessage = getSuccessNotificationMessage(
            response.data.message
          );
          yield put(createNotification(notificationMessage));

      } else {
        yield put(addFriendRequestFailure(response.data.error));
        const notificationMessage = getErrorNotificationMessage(
          response.data.error
        );
        yield put(checkLogoutStatus(response.data));
        yield put(createNotification(notificationMessage));
      }
    } catch (error) {
      yield put(addFriendRequestFailure(error));
      const notificationMessage = getErrorNotificationMessage(error.message);
      yield put(createNotification(notificationMessage));
    }
  }
  

  function* getAllFriendRequestsAPI() {
    try {
      const response = yield api.postMethod("list_friend_requet");
      if (response.data.success) {
        yield put(getAllFriendRequestSuccess(response.data.data));
      } else {
        yield put(getAllFriendRequestFailure(response.data.error));
        const notificationMessage = getErrorNotificationMessage(
          response.data.error
        );
        yield put(checkLogoutStatus(response.data));
        yield put(createNotification(notificationMessage));
      }
    } catch (error) {
      yield put(getAllFriendRequestFailure(error));
      const notificationMessage = getErrorNotificationMessage(error.message);
      yield put(createNotification(notificationMessage));
    }
  }


  function* removeFriendRequestsAPI({data}) {
    console.log("🚀 ~ function*removeFriendRequestsAPI ~ data:", data)
    try {

      const response =  data.from === "friend" ?  yield api.postMethod("remove_frequet_or_friend", {request_id : data.request_id, key: data.key}) : yield api.postMethod("remove_frequet_or_friend", {user_id : data.user_id});
      if (response.data.success) {  
        
       if(data.type !== undefined && data.type === "reject"){

         const filterFriendList = data.friendData?.filter((ele)=>{
           return  ele?.id.toString() !== response.data?.data;
         })
        
         yield put(getAllFriendRequestSuccess(filterFriendList));

       }

       if (data?.type !== undefined && data?.type === "removeFriend") {
        const singleProfileState = yield select(state => state.otherUser.userDetails.data)

          if (singleProfileState?.user?.user_id === Number(response.data?.data)) {
            const updatedSingleProfile = {
              ...singleProfileState,
              is_friend: 0
            }

            yield put(fetchSingleUserProfileSuccess(updatedSingleProfile))
          }
       }
        

       if(data.type !== undefined && data.type === "remove"){
         
          if(data.from === "friend"){
             
              const filterData = data.friendData.filter((ele)=>{
                
                return ele.id?.toString() !== response.data?.data;

              });

              yield put(getAllFriendSuccess({friends: filterData , total : filterData.length}));

          }


          if(data?.pagePath === "followers"){
             
             if(data?.activeFollowers?.followers !== undefined && data?.activeFollowers?.followers?.length > 0){
                
              const isData = data.activeFollowers.followers ;

              const filterData = isData.map((ele)=>{
                 
                 if(ele?.otherUser?.user_id?.toString() ===  response.data?.data?.toString()){
                   
                    return {
                      ...ele,
                      otherUser : {
                        ...ele.otherUser,
                        is_friend : 0
    
                      }
                    }
    
                 }else{
                   return ele;
                 }
    
              });
              yield put(fetchActiveFollowersSuccess({followers : filterData , total : data.activeFollowers.total}));

             }
          }


          if(data?.pagePath === "following"){
             
            if(data?.activeFollowing?.followers !== undefined && data?.activeFollowing?.followers?.length > 0){
               
             const isData = data?.activeFollowing?.followers;

             const filterData = isData.map((ele)=> {
                
                if(ele?.otherUser?.user_id?.toString() ===  response.data?.data?.toString()){
                  
                   return {
                     ...ele,
                     otherUser : {
                       ...ele.otherUser,
                       is_friend : 0
   
                     }
                   }
   
                }else{
                  return ele;
                }
   
             });
             yield put(fetchActiveFollowingSuccess({followers : filterData , total : data.activeFollowing.total}));

            }
         }


         if(data?.pagePath === "subscribing"){
           
           if(data?.activeSubscribing?.subscribing !== undefined && data?.activeSubscribing?.subscribing?.length > 0){

            const filterData = data.activeSubscribing.subscribing.map((ele) => {
             
              if(ele?.to_user?.user_id?.toString() === response.data?.data?.toString()){
                 return {
                   ...ele,
                   is_friend : 0
                 }
              }else{
                return ele;
              }
  
           });
  
            console.log("🚀 ~ filterData ~ filterData:", filterData)
         
           yield put(fetchSubscribingSuccess({subscribing : filterData , total: data.activeSubscribing.total}));

           }
         }

         if(data?.pagePath === "subscriber"){
           
           if(data?.activeSubscribers?.subscriber !== undefined && data?.activeSubscribers?.subscriber?.length > 0){

            const filterData = data?.activeSubscribers?.subscriber.map((ele) => {
             
              if(ele?.from_user?.user_id?.toString() === response.data?.data?.toString()){
                 return {
                   ...ele,
                   is_friend : 0
                 }
              }else{
                return ele;
              }
  
           });
  
            console.log("🚀 ~ filterData ~ filterData:", filterData)
         
            yield put(fetchSubscribersSuccess({subscriber : filterData , total: data.activeSubscribers.total}));

           }
         }

         if (data?.pagePath === "restricted") {
          if (data?.activeRestricted?.restrict_users !== undefined && data?.activeRestricted?.restrict_users?.length > 0) {
            
            const filterData = data?.activeRestricted?.restrict_users?.map(elem => {
              if (elem?.restricteduser?.user_id?.toString() === response.data?.data?.toString()) {
                return {
                  ...elem,
                  is_friend: 0
                }
              } else {
                return elem
              }
            })

            yield put(fetchRestrictedUsersSuccess({restrict_users: filterData, total: data.activeRestricted.total}))
          }
         }


         if(data?.pagePath === "favorite"){
           
           if(data?.activeFavories?.data?.favs !== undefined && data?.activeFavories?.data?.favs?.length){

             window.location.reload();

           }
         }

         if(data?.pagePath === "unsubscribed"){
           
           if(data?.activeUnsubscriberd?.unsubscribed !== undefined && data?.activeUnsubscriberd?.unsubscribed?.length){

             const isData = data?.activeUnsubscriberd?.unsubscribed;
             
             const filterData = isData.map((ele)=> {
              
               if(ele?.to_user?.user_id?.toString() ===  response.data?.data?.toString()){
                  return {
                     ...ele,
                     is_friend : 0
                  }
               }else{
                return ele;
               }

             });


             yield put(fetchUnsubscribedSuccess({unsubscribed : filterData ,  total : filterData.length }));


           }
         }


         if(data?.pagePath === "formerSub"){
            
          
          if(data?.activeFormerSub?.subscriber !== undefined && data?.activeFormerSub?.subscriber?.length){
 
              
            const filterData = data?.activeFormerSub?.subscriber?.map((ele)=> {
             
              if(ele?.from_user?.user_id?.toString() ===  response.data?.data?.toString()){
                 return {
                    ...ele,
                    is_friend : 0
                 }
              }else{
               return ele;
              }

            });

           
            yield put(fetchFormerSubscriberSuccess({subscriber : filterData , total : filterData.length}));

          }
            
         }

         if(data?.pagePath === "SingleListUserSearch"){
            
          
          const singleUserList = yield select((state) => state.customList.singleUserListSerach)
               
            const filterData = singleUserList.data?.map((ele)=> {
             
              if(ele?.user_id?.toString() ===  response.data?.data?.toString()){
                 return {
                    ...ele,
                    is_friend : 0
                 }
              }else{
               return ele;
              }

            });
           
            yield put(getSingleSearchListUserSuccess(filterData));
            
         }

       }

        
        yield put(removeRejectFriendRequestSuuceess(response.data));
        


        const notificationMessage = getSuccessNotificationMessage(
            response.data.message
          );
          yield put(createNotification(notificationMessage));
         
      } else {
        const notificationMessage = getErrorNotificationMessage(
          response.data.error
        );
        yield put(checkLogoutStatus(response.data));
        yield put(createNotification(notificationMessage));
        yield put(removeRejectFriendRequestFailure(response.data));
      }


    } catch (error) {
      const notificationMessage = getErrorNotificationMessage(error.message);
      yield put(createNotification(notificationMessage));
      yield put(removeRejectFriendRequestFailure(error.response.data));

    }
  }



  function* acceptFriendRequestsAPI({data}) {
    try {

      const response = yield api.postMethod("accept_friend_requet", {request_id : data.request_id});
      if (response.data.success) {
        

        const filterFriendList = data.friendData?.filter((ele)=>{
          return  ele?.id.toString() !== response.data?.data;
        })

        yield put(getAllFriendRequestSuccess(filterFriendList));
        
        yield put(acceptFriendRequestSuccess(response.data));
        

        const notificationMessage = getSuccessNotificationMessage(
            response.data.message
          );
          yield put(createNotification(notificationMessage));
         
      } else {
        const notificationMessage = getErrorNotificationMessage(
          response.data.error
        );
        yield put(checkLogoutStatus(response.data));
        yield put(createNotification(notificationMessage));
        yield put(acceptFriendRequestFailure(response.data));

      }


    } catch (error) {
      const notificationMessage = getErrorNotificationMessage(error.message);
      yield put(createNotification(notificationMessage));
      yield put(removeRejectFriendRequestFailure(error.response.data));
      yield put(acceptFriendRequestFailure(error.response.data));

    }
  }




  function* getAllFriendsAPI({data}) {
    try {

       if(data !== undefined && data.setIsLoadingList !== undefined){
         data.setIsLoadingList(true);

       }

      const response = yield api.postMethod("active_friends");
      if (response.data.success) {
        if(data !== undefined && data.setIsLoadingList !== undefined){
          data.setIsLoadingList(false);
 
        }
        
        const updatedFriendsData = response.data?.data?.friends?.map((item) => {
          return {
            ...item,
            user: {
              ...item.user,
              isSelected: false
            }
          }
        })
        yield put(getAllFriendSuccess({friends: updatedFriendsData, total: response.data?.data?.total}));
       

        if(data !== undefined && data.allListData !== undefined){
           
           const updatedData = data.allListData.map((ele)=> {
          
             if(ele.list_name === data.typeList){

              const updatedData = response.data?.data?.friends.map((ele)=>{
                
                return{
                  ...ele,
                  customListFlag : "FriendList"
                }

              })
               
              return {
                ...ele,
                data :  updatedData,
                total : response.data?.data?.total
              }

             }else {
              return ele
             }

           });

           yield put(addUserListSuccessD(updatedData));


           console.log("🚀 ~ updatedData ~ updatedData:", updatedData)
          
        } 

         
      } else {
        if(data !== undefined && data.setIsLoadingList !== undefined){
          data.setIsLoadingList(false);
 
        }
        const notificationMessage = getErrorNotificationMessage(
          response.data.error
        );
        yield put(checkLogoutStatus(response.data));
        yield put(createNotification(notificationMessage));
        yield put(getAllFriendSuccess(response.data));
      }
    
    } catch (error) {
      if(data !== undefined && data.setIsLoadingList !== undefined){
        data.setIsLoadingList(false);
      }
      const notificationMessage = getErrorNotificationMessage(error.message);
      yield put(createNotification(notificationMessage));
      yield put(removeRejectFriendRequestFailure(error.response.data));
      yield put(getAllFriendFailure(error.response.data));

    }
  }



  export default function* pageSaga() {
    yield all([yield takeLatest(ADD_FRIEND_REQUEST_START, fetchAddFriendRequestApi)]);
    yield all([yield takeLatest(GET_ALL_FRIEND_REQUEST_START, getAllFriendRequestsAPI)]);
    yield all([yield takeLatest(REMOVE_FRIEND_REQUEST_START,  removeFriendRequestsAPI)]);
    yield all([yield takeLatest(ACCEPT_FRIEND_REQUEST_START,  acceptFriendRequestsAPI)]);
    yield all([yield takeLatest(GET_ALL_FRIEND_START,  getAllFriendsAPI)]);
    // yield all([yield takeLatest(UNFOLLOW_USER_SUBSCRIPTION_START, unFollowUserSubscriptionAPI)]);
    // yield all([yield takeLatest(FETCH_FOLLOWERS_START, fetchFollowersAPI)]);
    // yield all([
    //   yield takeLatest(FETCH_ACTIVE_FOLLOWERS_START, fetchActiveFollowersAPI),
    // ]);
    // yield all([
    //   yield takeLatest(FETCH_EXPIRED_FOLLOWERS_START, fetchExpiredFollowersAPI),
    // ]);
    // yield all([yield takeLatest(FETCH_FOLLOWING_START, fetchFollowingAPI)]);
    // yield all([
    //   yield takeLatest(FETCH_ACTIVE_FOLLOWING_START, fetchActiveFollowingAPI),
    // ]);
    // yield all([
    //   yield takeLatest(FETCH_EXPIRED_FOLLOWING_START, fetchExpiredFollowingAPI),
    // ]);
  }
  