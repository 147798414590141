import {
  FETCH_NOTIFICATIONS_START,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
  FETCH_MORE_NOTIFICATIONS_START,
  FETCH_NOTIFICATION_SETTING_START,
  FETCH_NOTIFICATION_SETTING_SUCCESS,
  FETCH_NOTIFICATION_SETTING_FAILURE,
  POST_NOTIFICATION_SETTING_START,
  POST_NOTIFICATION_SETTING_SUCCESS,
  POST_NOTIFICATION_SETTING_FAILURE,
} from "./ActionConstant";

export function fetchNotificationsStart(data) {
  return {
    type: FETCH_NOTIFICATIONS_START,
    data,
  };
}

export function fetchMoreNotificationsStart(data) {
  return {
    type: FETCH_MORE_NOTIFICATIONS_START,
    data,
  };
}


export function fetchNotificationsSuccess(data) {
  return {
    type: FETCH_NOTIFICATIONS_SUCCESS,
    data,
  };
}

export function fetchNotificationsFailure(error) {
  return {
    type: FETCH_NOTIFICATIONS_FAILURE,
    error,
  };
}

export function fetchNotificationSettingStart(data) {
  return {
    type: FETCH_NOTIFICATION_SETTING_START,
    payload: data
  }
}

export function fetchNotificationSettingSuccess(data) {
  return {
    type: FETCH_NOTIFICATION_SETTING_SUCCESS,
    payload: data
  }
}

export function fetchNotificationSettingFailure(error) {
  return {
    type: FETCH_NOTIFICATION_SETTING_FAILURE,
    error
  }
}

export function postNotificationSettingStart(data) {
  return {
    type: POST_NOTIFICATION_SETTING_START,
    payload: data
  }
}

export function postNotificationSettingSuccess(data) {
  return {
    type: POST_NOTIFICATION_SETTING_SUCCESS,
    payload: data
  }
}

export function postNotificationSettingFailure(error) {
  return {
    type: POST_NOTIFICATION_SETTING_FAILURE,
    error
  }
}
