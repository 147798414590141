import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
// import { t } from "react-multi-lang";
import { Field, Formik } from "formik";
import { saveReportUserStart } from "../../../store/actions/UserAction";
import * as Yup from "yup";

const checkReportUser = Yup.object().shape({
  reportReason: Yup.string().required("report is required"),
});

const ReportUserModel = (props) => {
  const { reportMode, closeReportModeModal, userId } = props;
  const dispatch = useDispatch();

	const [imagePreview, setImagePreview] = useState(null);

  const isLoading = useSelector((state) => state.users.reportUsers.loading);

  const inititialReportReason = {
    reportReason: "",
		reportFile: null
  };

  return (
    <>
      <Modal
        show={reportMode}
        onHide={closeReportModeModal}
        backdrop="static"
        keyboard={false}
        centered
        className={`${
          localStorage.getItem("theme") !== "" &&
          localStorage.getItem("theme") !== null &&
          localStorage.getItem("theme") !== undefined &&
          localStorage.getItem("theme") === "dark"
            ? "dark-theme-modal"
            : ""
        }`}
      >
        <Formik
          initialValues={inititialReportReason}
          validationSchema={checkReportUser}
          onSubmit={(values) => {

            if (values !== "") {
              dispatch(
                saveReportUserStart({
                  user_id: userId,
                  reason: values.reportReason,
									reportFile: values.reportFile,
                  closeReportModeModal: props.closeReportModeModal,
                })
              );
            }
          }}
        >
          {({ errors, touched, setFieldValue, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Report user</Modal.Title>
              </Modal.Header>

              <Modal.Body>
								<div 
									className="report-container"
									style={{
										display: "flex",
										flexDirection: "column",
										gap: "10px"
									}}
								>
									<div>
										<Field
											name="reportReason"
											placeholder="report user"
											style={{
												width: "100%",
												padding: "5px",
												fontSize: "12px",
											}}
										/>

										{errors.reportReason && touched.reportReason ? (
											<p
												style={{
													color: "rgb(255, 0, 0)",
													fontSize: "13px",
													fontWeight: "400",
													marginTop: "4px",
												}}
											>
												{errors.reportReason}
											</p>
										) : (
											""
										)}
									</div>

									<div 
										className="report-screenshot-wrapper"
										style={{
											display: "flex",
											flexDirection: "column",
											gap: "4px",
										}}
									>
										<p style={{ fontSize: "13px", fontWeight: "700", margin: "0"}}>
											Screenshot (optional)
										</p>
										<div style={{ display: "flex", gap: "10px" }}>
										
											{
												imagePreview 
													? (
														<img
															style={{ height: "110px", width: "110px" }}
															src={imagePreview}
															alt="report-screenshot"
														/>
													) : ""
											}
											<label
												htmlFor="reportFile"
												style={{
													fontSize: "11px",
													background: "#6661e7",
													color: "#fff",
													padding: "8px 30px",
													borderRadius: "5px",
													alignSelf: "end",
													cursor: "pointer"
												}}
											>
												Browse
											</label>
											<input
												type="file"
												name="reportFile"
												onChange={(event) => {
													const file = event.currentTarget.files[0];
													setFieldValue("reportFile", file)
													if (file) {
														const previewUrl = URL.createObjectURL(file);
														console.log(previewUrl, "Looking for previewUrl");
														setImagePreview(previewUrl);
													}
												}}
												id="reportFile"
												accept="image/gif, image/png, image/jpg"
												style={{ display: "none" }}
											/>
										</div>
									</div>
								</div>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant="secondary"
                  size="lg"
                  onClick={props.closeReportModeModal}
                >
                  back
                </Button>
                <Button
                  size="lg"
                  className="ftr-btn"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "report"}
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ReportUserModel;
