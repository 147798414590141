import React from 'react'
import FollowingLoader from '../Loader/FollowingLoader'
import UserCard from '../Accounts/FansFollowing/UserCard'
import NoDataFound from '../NoDataFound/NoDataFound'
import { Row } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { t } from 'react-multi-lang'


const SubscribersActiveSec = (props) => {
  return (
    <>
    <div
      role="tabpanel"
      className={
        props.activeSec === "active-sec"
          ? "tab-pane fade in active"
          : "tab-pane fade"
      }
      id="active"
    >
      {props.subscribers.loading ? (
         <FollowingLoader/>
      ) : (
        <>
          <div className="bookmarkes-list bookmarks-right-side">
            <div className="pull-left">
              <Link className="bookmarkes-list" to="#">
                <span className="mr-2">
                  {props.subscribers?.data?.total
                    ? props.subscribers?.data?.total
                    : 0}{" "}
                </span>{" "}
                {t("users")}
              </Link>
            </div>
          </div>
          <Row>
            {props.subscribers?.data?.subscriber && props.subscribers.data?.subscriber?.length > 0 ? (
              props.subscribers.data?.subscriber?.map((subscriber, index) => (
                // <UserCard user={subscriber?.otherUser} key={index} />
                <UserCard user={subscriber?.from_user} key={index} />
              ))
            ) : (
              <NoDataFound></NoDataFound>
            )}
          </Row>
        </>
      )}
    </div>
  </>
  )
}

export default SubscribersActiveSec